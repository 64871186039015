import io from 'socket.io-client';
import { FRONTENDURL, BACKURL } from './env';


const socket = io(`${BACKURL}`);
console.log("111 socketContext_socketContext", socket, socket.connected);

socket.on('connection', function () {
    console.log('Client Connected');
    const token = localStorage.getItem("accessToken");
    if (token) {
        createSocketUser()

    }
});

const createSocketUser = () => {
    console.log("emit_frontend")
    // socket.emit('LOGINUSER', "venkatesh")
}

// socket.on("FORCELOGOUT", (data) => {
//     try {
//         if (data) {
//             console.log("FORCELOGOUT", data);
//             if (data?.walletAddress) {
//                 localStorage.removeItem('accessToken');
//                 sessionStorage.removeItem('accountInfo')
//                 // window.location.href = FRONTENDURL;
//             }


//         }
//     } catch (e) {
//         console.log("socketError : ", e);
//         localStorage.clear();
//         // window.location.href = FRONTENDURL;
//     }
// });



export {
    socket,
    createSocketUser
}