import React, { Component } from "react";
import Chart from 'react-apexcharts'
// import ApexCharts from 'apexcharts'
class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
        
          series: [ 46,24,8, ],
          options: {
            colors: ['#476e94', '#4e4953','#af6943',],
            // tooltip: {
            //     fillSeriesColor: true,
            //   },
            labels:['wETH','USDC.e','wBTC'],
            chart: {
              type: 'donut',
            },
            stroke: {
                colors: ['#b0b0b0'],
                width:6,
              },
            plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        fontSize: '20px',
                        fontWeight: 600,
                        label: 'WLP POOL',
                        // formatter: () => 'WLP POOL'
                      },
                    },
                    
                  }
                }
              },            
            responsive: [{
              breakpoint: 1199,
              options: {
                chart: {
                  width: 210
                },
                legend: {
                  position: 'bottom'
                },
                plotOptions: {
                    pie: {
                      donut: {
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            fontSize: '13px',
                            fontWeight: 600,
                            label: 'WLP POOL',
                            // formatter: () => 'WLP POOL'
                          },
                        },
                        
                      }
                    }
                  },
              }
            }
          ],          
          },
        
        
        };
      }
    render() {
        return (
            <>
                <div className="app">

                    <div id="chart">
                    <Chart options={this.state.options} series={this.state.series} type="donut" width={320} height={350} />    
                    </div>

                </div>

            </>
        );
    }
}

export default App;