import React, { useEffect, useState } from "react";

import { NavLink, Link } from "react-router-dom";

import gaming from "../assets/gaming.svg";
import gamingactive from "../assets/gamingactive.png";
import earnimg from "../assets/gameimg.svg";
import earnactive from "../assets/rocketss.png";
import refimg from "../assets/ref.png";
import refactive from "../assets/refactive.png";

import ranges from "../assets/range.png";
import coinflip from "../assets/coinfilp.png";
import wheel from "../assets/wheel.png";
import plinko from "../assets/plinko.png";
import moon from "../assets/moon.png";
import rpc from "../assets/rpc.png";
import limbo from "../assets/limbo.png";
import dice from "../assets/dice.png";
import logoimg from "../assets/logoimg.png";
import { getAllGamesInfoHooks } from "../hooks/UseUser";


export default function Sidebar() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [gamedetails, setGameDetails] = useState([])

    console.log("gamedetails_gamedetails", gamedetails)
    useEffect(() => {
        async function fetchData() {
            let res = await getAllGamesInfoHooks();
            console.log("respondgamedata", res?.data)
            if(res?.data?.status == true) {
                setGameDetails(res?.data?.data)
            }
        }
        fetchData()
    }, [])

    return (
        <>
            <div>

                <div class="sidebar">
                   <Link className="logos" to="/"> 
                   <img src={logoimg} className="sidelogo mb-3" alt="image" />
                   </Link>
                    {/* <NavLink activeClassName={"active"} to="/games">
                        <img className="mb-2 normalimg" src={gaming} alt="gameimg" />
                        <img className="mb-2 hoverimg" src={gamingactive} alt="gameimg" />
                        Games</NavLink> */}

                    <div class="dropdown">
                    <NavLink activeClassName={"active"} to="/games">
                        <img className="mb-2 normalimg" src={gaming} alt="gameimg" />
                        <img className="mb-2 hoverimg" src={gamingactive} alt="gameimg" />
                        Games</NavLink>

                        
                    {gamedetails && gamedetails?.length > 0 && gamedetails?.map((val) => 
                    <div class="dropdown-content">
                        {console.log("gamedetails", gamedetails)}
                   {(gamedetails[7]["GameName"] == "Dice" && gamedetails[7]["Status"] == "Active") && <NavLink activeClassName={"active"} to="/games/Dice" >
                    <img className="mb-0 dropsideimg" src={dice} alt="gameimg" /> Dice</NavLink>}
                    {(gamedetails[6]["GameName"] == "Range" && gamedetails[6]["Status"] == "Active") && <NavLink activeClassName={"active"} to="/games/Range">
                    <img className="mb-0 dropsideimg" src={ranges} alt="gameimg" />
                    
                     Range</NavLink>
                    }
                    {(gamedetails[5]["GameName"] == "CoinFlip" && gamedetails[5]["Status"] == "Active") &&  <NavLink activeClassName={"active"}  to="/games/CoinFlip">
                    <img className="mb-0 dropsideimg" src={coinflip} alt="gameimg" /> Coin Flip</NavLink>}
                    {(gamedetails[4]["GameName"] == "Wheel" && gamedetails[4]["Status"] == "Active") &&  <NavLink activeClassName={"active"} to="/games/Wheel">
                    <img className="mb-0 dropsideimg" src={wheel} alt="gameimg" /> Wheel</NavLink>}
                  {(gamedetails[3]["GameName"] == "Plinko" && gamedetails[3]["Status"] == "Active") && <NavLink activeClassName={"active"} to="/games/Plinko">
                    <img className="mb-0 dropsideimg" src={plinko} alt="gameimg" /> Plinko</NavLink>}
                   {(gamedetails[2]["GameName"] == "Moon" && gamedetails[2]["Status"] == "Active") &&<NavLink activeClassName={"active"} to="/games/Moon">
                    <img className="mb-0 dropsideimg" src={moon} alt="gameimg" /> Moon</NavLink>}
                    {(gamedetails[1]["GameName"] == "Limbo" && gamedetails[1]["Status"] == "Active") && <NavLink activeClassName={"active"} to="/games/Limbo">
                    <img className="mb-0 dropsideimg" src={limbo} alt="gameimg" /> Limbo</NavLink>}
                   {(gamedetails[0]["GameName"] == "RPC" && gamedetails[0]["Status"] == "Active") &&<NavLink activeClassName={"active"} to="/games/RPC">
                    <img className="mb-0 dropsideimg" src={rpc} alt="gameimg" /> RPC</NavLink>}
         
                    </div>)}
                    </div>                
                    <NavLink activeClassName={"active"} to="/earn">
                        <img className="mb-2 normalimg" src={earnimg} alt="gameimg" />
                        <img className="mb-2 hoverimg" src={earnactive} alt="gameimg" />
                        Earn</NavLink>
                    <NavLink activeClassName={"active"} to="/stake">
                        <img className="mb-2 normalimg" src={earnimg} alt="gameimg" />
                        <img className="mb-2 hoverimg" src={earnactive} alt="gameimg" />
                        Stake</NavLink>
                    <NavLink activeClassName={"active"} to="/referral">
                        <img className="mb-2 normalimg" src={refimg} alt="gameimg" />
                        <img className="mb-2 hoverimg" src={refactive} alt="gameimg" />
                        Referral</NavLink>  
                </div>

            </div >
        </>
    );
}


