import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Logo from '../assets/logo1.png';
import time from '../assets/time.png';
import tablecoin from '../assets/tablecoin.png';

export default function Gametable() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [gametable, setgametable] = useState([
        { "id": 1, }, { "id": 2, }, { "id": 3, }, { "id": 4, }, { "id": 5, }, { "id": 6, }, { "id": 7, }, { "id": 8, }, { "id": 9, }, { "id": 10, },
    ]);

    return (
        <>
            <div className="alltable vestingtable">
              
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">vWINR Input</th>
                            <th scope="col">WINR Output</th>
                            <th scope="col">Time left</th>
                            <th scope="col">Cancel/Redeem</th>
                            <th scope="col">Rewards</th>
                        </tr>
                    </thead>
                    {/* <tbody>
                        {gametable.map((e, i) =>
                            <tr>
                                <td>12:12:23 <img src={time} alt="images" /></td>
                                <td style={{ color: "white" }}>0x8BaB...477a</td>
                                <td>99</td>
                                <td style={{ color: "white" }}>2.079 <img src={tablecoin} alt="images" /></td>
                                <td>0.98x</td>                           
                            </tr>
                        )}
                    </tbody> */}
                </table>

            </div>

        </>
    );
}