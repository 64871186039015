
import { useState, useEffect } from 'react';
import Slider from 'react-rangeslider';
import { UpdateGameSettingBalancehooks, getAllGameTransactionDetails, getGameSettingDetails } from '../hooks/UseUser';
import { GamePrediction, createOrderdetails } from '../hooks/useBot';
import { CreateGameTransactionHooks, getGameAllTransactionInfoHooks } from '../hooks/UseTransaction';
import { getAccount } from '../hooks/useAccount';
import { DiceGameCondition, checkAlreadyWon } from '../hooks/useDice';
import toast from 'react-hot-toast';
import { iconTheme, position, style } from '../hooks/useToast';
import { toFixedNumber } from '../hooks/UseContract';


var plinkobets;
function getRandomInt(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

console.log('plinkobddddddddd', plinkobets)

function Plinkogame(props) {


	const [ballPosition, setBallPosition] = useState({ x: 0, y: 0 });
	const [btnDisabled, setBtnDisabled] = useState(false);
	const [colfinal, setColfinal] = useState(0);
	const [rowwidth, setRowwidth] = useState(0);
	const [falling, setFalling] = useState(false);


	//venkatesh
	const [gamesetting, setGameSetting] = useState({});
	const [allgametransacdata, setAllGameTransData] = useState([]);
	const [transactionInfo, setTransactionInfo] = useState([]);
	const [onemonthwonInfo, setOneMonthWonInfo] = useState([]);
	const [oneweekwonInfo, setOneWeekWonInfo] = useState([]);
	const [accountInfo, setAccountInfo] = useState("");
	const [activeplinko, setActivePlinko] = useState(false);
	const [resultplinko, setResultPlinko] = useState(0)
	const [gamecondition, setGameCondition] = useState({})
	const [totalwager, setTotalWager] = useState(0);
	const [maxpayout, setMaxPayout] = useState(0);
	const [incrementbetcount, setIncrementBetCount] = useState(1);
	const [multipleBets, setMultipleBets] = useState(0);
	const [resultarr, setResultArr] = useState([]);
	const [minplinko, setMinPlinko] = useState(0)
	const [maxplinko, setMaxPlinko] = useState(0)
	const [rowcolarr, setRowColArr] = useState()
	// const [plinkobets,setPlinkoBets] = useState()

	console.log('Plinko_result_arr', resultarr, resultplinko)
	useEffect(() => {

		// setTimeout(()=>{
		//   var container_width = document.getElementById("row_div").clientWidth;

		//   setRowwidth(container_width);
		// },1000)



		const initial_ballx = document.getElementById("plinko_container").clientWidth / 2;
		const initial_bally = document.getElementById("plinko_container").clientHeight / 2;


		console.log('initial_ballxinitial_ballx', initial_ballx)
		console.log(initial_ballx, "initial_ballx");
		setBallPosition({ x: initial_ballx + 25, y: 0 });
		// console.log()

		//   const intervalId = setInterval(randomizeBallPosition, 1000); // Change interval as needed

		//   return () => clearInterval(intervalId);
	}, []);


	useEffect(() => {
		getGameSettings();
		getAllGameTransactionData();
	}, [props]);

	var a = [[0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8], [0, 9], [0, 10], [0, 11], [0, 12],
	[1, 1], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8], [1, 9], [1, 10], [1, 11], [1, 12],
	[2, 1], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8], [2, 9], [2, 10], [2, 11], [2, 12],
	[3, 1], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8], [3, 9], [3, 10], [3, 11], [3, 12],
	[4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8], [4, 9], [4, 10], [4, 11], [4, 12],
	[5, 1], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8], [5, 9], [5, 10], [5, 11], [5, 12],
		//  [6, 1], [6, 2], [6, 3], [6, 4], [6, 5], [6, 6],[6,7], [6,8], [6,9], [6,10], [6,11], [6,12],
	];

	// 12 X 12

	// console.log('aaaaaaaaaa', a)
	// console.log('aaaaaaaaaaaaaaa',a?.length, a?.length - 12)
	var container = []
	var index = 5;
	useEffect(() => {

		console.log('sgdfgsdfgsdfgsdfgsdf', index);
		console.log('aaaaaaaaaaaaaaa', a?.length, a[0])


		a && a?.length > 0 && a?.map((val, ind) => {
			console.log('innnnnnnn', val, ind + 1, a?.length - 12)
			if ((a?.length - 12) <= ind) {
				console.log('asdfasdfasdf', val);
				container.push(val)
			}

		});


		console.log('container_container', container)
		let plinkoarr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
		// let right = [{ 'fir': 2, 'sec': 3, 'thir': 4, 'four': 5 }];
		// let left = [{ 'fir': 7, 'sec': 8, 'thir': 9, 'four': 10 }];
		let right = [];
		let left = [];

		for (let i = 0; i < plinkoarr?.length; i++) {
			if (index - 4 <= plinkoarr[i] && index + 4 >= plinkoarr[i] && index != plinkoarr[i]) {
				console.log('sdfasdfasdfasdf', plinkoarr[i]);

				if (index < plinkoarr[i]) {
					if (left?.length > 0) {
						if (left?.length == 1) {
							left.push({ 'sec': plinkoarr[i] })
						}
					} else {
						left.push({ 'fir': plinkoarr[i] })
					}

				} else {
					if (right?.length > 0) {
						if (right?.length == 1) {
							right.push({ 'sec': plinkoarr[i] })
						}
					} else {
						right.push({ 'fir': plinkoarr[i] })
					}
				}
			}
		}
		console.log('left data', left, right)
	}, [])


	useEffect(() => {
		console.log('randomlygenerate', 5)
		let arr = [{ "right": { 'fir': 2, 'sec': 3, 'thir': 4, 'four': 5 } }, { "left": { 'fir': 7, 'sec': 8, 'thir': 9, 'four': 10 } }];
		let arr1 = [2, 3, 4, 5, 7, 8, 9, 10];
		const randomColumn = getRandomInt(2, 10);

		console.log('sdfasreafasd', randomColumn);
		let ouput = container[index];
		console.log('ouput_ouput', ouput);

		a?.map((val) => {
			console.log('23452345234', val, val[0], val[1], ouput[0], ouput[1])
			if (val[0] == ouput[0] && val[1] == ouput[1]) {
				console.log('vasdfasdf', val, ouput, a.indexOf(val))
			}
		})
		// arr.map((val, ind) => {
		// 	console.log('sdfasreafasdsdfasreafasdsdfasreafasd', val?.right, val?.left)
		// 	if (val?.right) {
		// 		Object.values(val?.right['start'])
		// 	} else if (val?.left) {

		// 	}
		// })







	}, [])
	useEffect(() => {
		(async () => {
			const walletAddress = getAccount();
			setAccountInfo(walletAddress);
			// Call the function 
			let transc = await getTransactionDetails(walletAddress);
			console.log("oneweekwonInfoZ_OneMonthWonInfo", transc);
			if (transc?.oneweekdata?.length > 0) {
				OneWeekWonInfo(transc?.oneweekdata)
			}
			if (transc?.onemonthdata?.length > 0) {
				OneMonthWonInfo(transc?.onemonthdata)
			}

		})();
	}, [gamesetting]);


	useEffect(() => {
		async function fetchData() {
			try {
				console.log("activeplinko", resultplinko, activeplinko, oneweekwonInfo, onemonthwonInfo, oneweekwonInfo?.length > 0 || oneweekwonInfo?.length > 0)
				if (activeplinko) {
					if (oneweekwonInfo?.length > 0 || onemonthwonInfo?.length > 0) {
						console.log('activeplinko_activeplinko', resultplinko, minplinko, maxplinko)
						let gamedata = {
							resultPlinko: resultplinko,
							LesserPlinko: minplinko,
							GreaterPlinko: maxplinko
						};
						console.log('game datasss', gamedata)
						let dicecondition = await DiceGameCondition(gamedata, props?.pathName, gamecondition);
						console.log("condition return", dicecondition)
						let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
						console.log("presresp data", presresp);

						let checkalreadywon = await checkAlreadyWon(presresp, oneweekwonInfo, onemonthwonInfo, "Plinko");
						console.log("checkalreadywon", checkalreadywon)

						console.log("allow data juju", checkalreadywon?.res1, checkalreadywon?.res2, presresp, checkalreadywon);
						if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
							console.log("never ever come this console")

						} else {
							FinishInterval();
						}
					} else {
						FinishInterval();
					}
				}
			} catch (e) {
				console.log('useEff err', e)
			}
		}
		fetchData()
	}, [activeplinko]);

	useEffect(() => {
		if (props?.bet && props?.disabledPlinkobtn) {
			setBtnDisabled(false);
		} else {
			setBtnDisabled(true);
			props?.setPlinkoStart(true)
		};
		console.log('props?.plinkobets', props?.plinkobets)
		if (props?.plinkobets) {
			plinkobets = props?.plinkobets;
		}
	}, [props])


	useEffect(() => {
		setMultipleBets(props?.multiplebets);
		setTotalWager(props.totalwager);
		setMaxPayout(props.maxpayout);
		let remainingrolls = (props?.multiplebets - props?.remainingbets) + 1;
		console.log('props incremtn remainingbets_remainingbets', props?.multiplebets, props?.remainingbets, remainingrolls)
		setIncrementBetCount(remainingrolls);
	}, [props, resultplinko])


	const PlinkoData = (plinkobets) => {
		try {
			console.log('useEffect_onemonthwonInfodata', onemonthwonInfo, plinkobets);
			let arr = [];
			if (plinkobets == 6) {
				arr = [10, 1, 0.4, 0.1, 1, 10];
			} else if (plinkobets == 7) {
				arr = [15, 2, 0.7, 0.4, 0.7, 2, 15];
			} else if (plinkobets == 8) {
				arr = [20, 4, 0.9, 0.6, 0.4, 0.6, 4, 20];
			} else if (plinkobets == 9) {
				arr = [45, 8, 0.9, 0.6, 0.4, 0.6, 0.9, 8, 45];
			} else if (plinkobets == 10) {
				arr = [47, 8, 2, 0.9, 0.6, 0.4, 0.6, 2, 8, 47];
			} else if (plinkobets == 11) {
				arr = [65, 17, 4, 0.9, 0.6, 0.4, 0.6, 0.9, 4, 17, 65];
			} else if (plinkobets == 12) {
				arr = [70, 16, 3, 2, 0.9, 0.6, 0.4, 0.6, 2, 3, 16, 70]
			}
			let min = arr[0];
			let max = arr[0];
			for (let i = 0; i < arr?.length; i++) {
				console.log('vvvvvvv4353vvvvvv', arr[i], min)
				if (min >= arr[i] && arr[i] >= 1) {
					min = arr[i];
				}
				if (max <= arr[i]) {
					max = arr[i]
				}
			}
			console.log('aaaaaaaaaaaaa', min, max);
			return {
				min: min, max: max, arr: arr
			}
		} catch (e) {
			console.log('plinkodata_err', e)
		}
	}



	useEffect(() => {
		async function fetchData() {
			console.log('useEffect_onemonthwonInfodata', onemonthwonInfo, plinkobets);
			const { min, max, arr } = PlinkoData(plinkobets);
			console.log('rrrrrrrr', arr, min, max)

			if (oneweekwonInfo?.length > 0 || onemonthwonInfo?.length > 0) {
				console.log('onemonthwonInfodata', onemonthwonInfo)
				for (let i = 0; i < onemonthwonInfo?.length; i++) {
					console.log('vallllllll', onemonthwonInfo[i], arr);
					for (let j = 0; j < arr?.length; j++) {
						console.log('match arr', arr, (onemonthwonInfo[i]['Multiplier']).split('x')[0], arr[j], Number((onemonthwonInfo[i]['Multiplier']).split('x')[0]) == arr[j],
							onemonthwonInfo[i]['allowwin'] == 0)
						if (Number((onemonthwonInfo[i]['Multiplier']).split('x')[0]) == arr[j] && onemonthwonInfo[i]['allowwin'] == 0) {
							console.log('enter numbers', onemonthwonInfo[i], arr, arr[j], arr[j] - 1);
							let Indexposition = arr.indexOf(arr[j]);
							console.log('IndexPosition', Indexposition, arr)
							// At position 2, remove 1 item:
							arr.splice(Indexposition, 1);
							console.log('removeAllowinNumber', Indexposition, arr);
						}
					}
				}
				console.log('arrforletj', arr)
				setResultArr(arr)
				setMaxPlinko(max)
				setMinPlinko(min)
			} else {
				setResultArr(arr)
				setMaxPlinko(max)
				setMinPlinko(min)
			}

			console.log('arr_data_result', arr)
		}
		fetchData()
	}, [props, onemonthwonInfo]);


	console.log('resultArr_resultArr', resultarr)
	useEffect(() => {
		let plinko = {};
		console.log('resultplinko_data', resultplinko)
		plinko[`${resultplinko}x`] = "##LesserPlinko##  <= ##resultPlinko## && ##GreaterPlinko## >= ##resultPlinko##"
		setGameCondition({ "Plinko": [plinko] })
	}, [resultplinko]);



	var row;
	var columns;
	var plinkoarr = []

	const PlinkoRowData = async (ind) => {
		try {
			if (ind) {
				for (let i = (plinkobets - 1); i >= 0; i--) {
					let data;
					console.log('rowdatasdfasdf', i, row, columns)
					if (!row && !columns) {
					  data = [(plinkobets - 1), ind];
					} else {
					  data = [row, columns];
					}
					console.log('dafasdfasdfasrew rowdatasdfasdf', data)
					if (data) {
					  console.log('ddddddddd', data);
					  let lefdata = data[1] - 1;
					  let rigdata = data[1] + 1;
					  console.log('left_rig', data, lefdata, rigdata);
					  let arr1 = []
					  if (0 < lefdata) {
						arr1.push([i, lefdata])
					  }
					  if (rigdata < plinkobets) {
						arr1.push([i, rigdata])
					  }          let min = 0;
					  let max = arr1?.length - 1;
					  
					  const randomColumn = getRandomInt(min, max);
					  row = arr1[randomColumn][0];
					  columns = arr1[randomColumn][1];
					  plinkoarr.unshift(arr1[randomColumn])
					  
					}
				  }
				console.log("plinko_row_data", plinkoarr);
				setRowColArr(plinkoarr)
				return plinkoarr;
			}

		} catch (e) {
			console.log("PlinkoRowData_err", e)
		}
	}



	const getGameSettings = async () => {
		try {
			let res = await getGameSettingDetails();
			//The function use to admin withdrawpermonth data get
			console.log('getGameSettings', res?.data)
			if (res?.data?.status == true) {
				let result = res?.data?.data[0];
				if (result?.Status == "Active") {
					setGameSetting(result)
				}
			}
		} catch (e) {
			console.log("gameGameSettings_err", e)
		}
	};


	const getAllGameTransactionData = async () => {
		try {
			// The month start to end vise, get all gamedetails
			let res = await getAllGameTransactionDetails();
			if (res?.data?.status == true) {
				setAllGameTransData(res?.data?.alltranscdata)
			}
		} catch (e) {
			console.log("getAllTransactionData_err", e)
		}
	};


	const getTransactionDetails = async (address) => {
		let data = {
			walletAddress: address,
			gameName: "Plinko"
		}
		// This function use gameName depends to get that user last onemonthwondata & last oneweekdata.
		let res = await getGameAllTransactionInfoHooks(data);
		console.log("respond_data", res)
		if (res?.status == 200) {
			if (res?.data?.alltranscdata?.length > 0) {
				//That is useless state , just store all transactiondata
				setTransactionInfo(res?.data?.alltranscdata)
			}
		}

		if (res?.data?.oneweekdata?.length > 0 || res?.data?.onemonthdata?.length > 0) {
			return {
				oneweekdata: res?.data?.oneweekdata?.length > 0 ? res?.data?.oneweekdata : [],
				onemonthdata: res?.data?.onemonthdata?.length > 0 ? res?.data?.onemonthdata : []
			}
		} else {
			return {
				oneweekdata: [],
				onemonthdata: []
			}
		}
	}


	const OneMonthWonInfo = async (transc) => {
		try {
			let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
			console.log("GamePrediction_OneMonthWonInfo", res, transc)
			setOneMonthWonInfo(res)
		} catch (e) {
			console.log("PredictionInfo_err", e)
		}
	}


	const OneWeekWonInfo = async (transc) => {
		try {
			console.log('transdata', transc)
			let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
			console.log("OneWeekWonInfo", res)
			setOneWeekWonInfo(res);
		} catch (err) {
			console.log("OneWeekWonInfo_err", err)
		}
	}


	const randomizeBallPosition = async () => {
		try {
			console.log('incrementbetcount,multipleBets', incrementbetcount, multipleBets)
			if (incrementbetcount <= multipleBets) {
				console.log('resultarr_resultarr', resultarr, resultarr?.length);
				let min = 0;
				let max = resultarr?.length - 1;
				setBtnDisabled(true);
				// const randomColumn = Math.floor(Math.random() * 12);
				// const randomColumn = getRandomInt(1, plinkobets);
				console.log('min, max data', min, max)
				const randomColumn = getRandomInt(min, max);
				console.log("randomColumn", resultarr, resultarr[randomColumn], randomColumn);
				let data = PlinkoData(plinkobets);
				let indexdata = 0;
				data?.arr && data?.arr?.length > 0 && data?.arr?.map((val, ind) => {
					console.log('searchingnumber', val, resultarr[randomColumn], String(val), String(val) == resultarr[randomColumn], ind)
					if (String(val) == resultarr[randomColumn]) {
						indexdata = ind
					}
				});
				console.log("index_data_index", indexdata)
				let rowcoldata = await PlinkoRowData(indexdata);
				console.log('rowcoldata_rowcoldata', rowcoldata)

				console.log('resultnum', data?.arr, indexdata);
				const randomX = document.getElementById("bucket_" + 7).offsetLeft;
				console.log("randomX_randomX row_index", randomX, indexdata);
				setColfinal(randomX);
				setBallPosition({ x: randomX, y: 0 });
				console.log(indexdata, "indexdata")
				setFalling(true);

				setTimeout(() => {
					setFalling(false);
					const initial_ballx = document.getElementById("plinko_container").clientWidth / 2;
					console.log(initial_ballx, "initial_ballx");
					setBallPosition({ x: initial_ballx + 30, y: 0 });
				}, 7000); // Adjust the duration as needed

				setTimeout(() => {
					setResultPlinko(0.6);
				}, 3000)
				setTimeout(() => {
					setActivePlinko(true);
				}, 4000)

			}
		} catch (e) {
			console.log("randomizeBallPosition_err", e)
		}

	};



	const FinishInterval = async () => {
		try {
			if (incrementbetcount <= multipleBets) {
				let gamedata = {
					resultPlinko: resultplinko,
					LesserPlinko: minplinko,
					GreaterPlinko: maxplinko
				};
				console.log("result plinko gamedata", gamedata, incrementbetcount, multipleBets)
				let plinkocondition = await DiceGameCondition(gamedata, "Plinko", gamecondition);
				let presresp = await GamePrediction(plinkocondition, [], props?.calculatewager, props?.multiplebets, totalwager, "Plinko", gamesetting, allgametransacdata, props?.maxpayout);
				console.log("plinkocondition_plinkocondition", plinkocondition, presresp);

				if (presresp) {
					let data = {};
					if (presresp?.isWin == true) {
						props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
							console.log('Object.keys(val)[0]', Object.keys(val)[0], presresp?.Multiplier)
							if (String(Object.keys(val)[0]) == String(presresp?.Multiplier)) {
								let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
								let amount = (Number(props?.calculatewager) + Number(wonpercentage));
								console.log('wonpercentage_wonpercentage', wonpercentage, props?.calculatewager, Object.values(val)[0], amount)
								if (presresp?.isWin == true) {
									data = {
										walletAddress: accountInfo,
										transaction: presresp,
										gameName: props?.pathName,
										Bets: multipleBets,
										Wager: props?.wager,
										CalculateWager: props?.calculatewager,
										TotalWager: props?.totalwager,
										Amount: amount,
										OrderId: props?.usergamedetails?._id
									}
								} else if (presresp?.isWin == false) {
									data = {
										walletAddress: accountInfo,
										transaction: presresp,
										gameName: props?.pathName,
										Bets: multipleBets,
										Wager: props?.wager,
										CalculateWager: props?.calculatewager,
										TotalWager: props?.totalwager,
										Amount: presresp?.maxpayout,
										OrderId: props?.usergamedetails?._id
									}
								}
							} else if ("0x" == String(presresp?.Multiplier)) {
								data = {
									walletAddress: accountInfo,
									transaction: presresp,
									gameName: props?.pathName,
									Bets: multipleBets,
									Wager: props?.wager,
									CalculateWager: props?.calculatewager,
									TotalWager: props?.totalwager,
									Amount: presresp?.maxpayout,
									OrderId: props?.usergamedetails?._id
								}
							}

						})
					} else if (presresp?.isWin == false) {
						if ("0x" == String(presresp?.Multiplier)) {
							data = {
								walletAddress: accountInfo,
								transaction: presresp,
								gameName: props?.pathName,
								Bets: multipleBets,
								Wager: props?.wager,
								CalculateWager: props?.calculatewager,
								TotalWager: props?.totalwager,
								Amount: presresp?.maxpayout,
								OrderId: props?.usergamedetails?._id
							}
						}
					}
					let lossamount = 0, gainamount = 0;
					var resultamount = 0;

					props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
						console.log('String(Object.keys(val)[0])', String(Object.keys(val)[0]), String(presresp?.Multiplier))
						if (String(Object.keys(val)[0]) == String(presresp?.Multiplier)) {
							let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
							let amount = Number(props?.calculatewager) + Number(wonpercentage);
							console.log('multipliet data', Object.values(val)[0], wonpercentage, amount);
							if (presresp?.isWin == false) {
								lossamount = presresp?.maxpayout;
								resultamount = presresp?.maxpayout;
							} else {
								gainamount = amount;
								resultamount = amount;
							}
						} else if ("0x" == String(presresp?.Multiplier)) {
							if (presresp?.isWin == false) {
								lossamount = presresp?.maxpayout;
								resultamount = presresp?.maxpayout;
							}
						}
					});
					console.log("lossamount, gainamount", lossamount, gainamount, data);
					let res = await CreateGameTransactionHooks(data);
					console.log("orderdata ruru1111", res);

					if (res?.status == 200) {
						let createres = await createOrderdetails(props?.pathName, props?.wager, props?.remainingbets, props?.betamount, resultplinko, accountInfo, props.stopgain, props.stoploss, gainamount, lossamount, props?.maxpayout, props?.calculatewager, props?.totalwager, '', '');
						console.log('createres', createres)
						let result = createres?.data?.data;

						if (createres?.status == 200) {
							if (createres?.data?.status == true) {
								console.log("update balance", result, result?.RemainingBets == 0);
								if (result?.RemainingBets == 0) {
									let reqData = { _id: result?._id, walletAddress: accountInfo }
									let res = await UpdateGameSettingBalancehooks(reqData);
									console.log("update balance 212", res)
									if (res?.data?.status == true) {
										setTimeout(() => {
											props?.setFeeData()
										}, 3000)
									}
								}


								if ((createres?.data?.data?.StopGain != 0 || createres?.data?.data?.StopLoss != 0) && (((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopGain)) / 100) <= (createres?.data?.data?.GainAmount) || ((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopLoss)) / 100) <= (createres?.data?.data?.LossAmount))) {
									setTimeout(function () {
										console.log('222 who come first')
										console.log('555 play scenario enter');
										props?.checkOrder()
										props.onCheck()
									}, 4000)
								} else if (props?.remainingbets != 1) {
									console.log('who come first')
									console.log('555 play scenario enter');
									setTimeout(function () {
										props?.checkOrder()
										props.onCheck()
									}, 2000)
								}

							}


						}
						let sum = incrementbetcount + 1;
						setIncrementBetCount(sum);
					};
				}
				if (presresp?.isWin == false) {
					setTimeout(function () {
						toast.error(incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + (presresp?.maxpayout).toFixed(4), {
							position: position.position,
							style: style,
							iconTheme: iconTheme,

						})
						setActivePlinko(false)
					}, 1000);

					setTimeout(() => {
						console.log('111 who come first')
						if (props?.remainingbets == 1) {
							console.log('666 play scenario enter');
							props?.checkOrder()
							props.onCheck()
						}
					}, 4000);
				} else {
					setTimeout(function () {
						toast.success(incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + toFixedNumber(Number(presresp?.maxpayout)).toFixed(6), {
							position: position.position,
							style: style,
							iconTheme: iconTheme,
						})
						setActivePlinko(false)
					}, 1000);

					setTimeout(() => {
						if (props?.remainingbets == 1) {
							console.log('111 who come first')
							console.log('666 play scenario enter');
							props?.checkOrder()
							props.onCheck()
						}
					}, 3000)
				}
				let sum = incrementbetcount + 1;
				setIncrementBetCount(sum);

				console.log("gamedata_gamedata", gamedata)
			}

		} catch (e) {
			console.log("FinishInterval_err", e)
		}
	}





	return (
		<>
			<div className='pt-5 felx_mob_div_ew'>
			
				<div className="plinko-container" id="plinko_container"
				//  style={{maxWidth:rowwidth}}
				>
					<Ball position={ballPosition} falling={falling} colfinal={colfinal} />
					<Board />
					<Boardbucket />
					{/* <Bucket /> */}



				</div>
			</div>

			<div id="buttonContainerplinkostart" className='text-center pb-4'>
				{/* disabled={btnDisabled} */}
				<button id="submitbetstart" className='btn btn-warning wallet play mt-0'
					disabled={btnDisabled}
					onClick={() => { randomizeBallPosition() }}>Start Game</button>

			</div>
		</>
	);
};


const Ball = ({ position, falling, columns }) => {
	console.log('position, falling, columns', position, falling, columns)
	let keyframes =
		`@keyframes rubbish {
   0%  {top:` + 0 + `%; left:` + columns + `%; transform:translateY(0px);}

   10%  {top:` + 10 + `%; left:` + 55 + `%; transform:translateY(0px);}

   25%  {top:` + 25 + `%; left:` + 30 + `%; transform:translateY(0px);}

   45%  {top:` + 45 + `%; left:` + 45 + `%; transform:translateY(0px);}

   65%  {top:` + 65 + `%; left:` + columns + `%; transform:translateY(0px);}

   85%  {top:` + 85 + `%; left:` + columns + `%; transform:translateY(0px);}
   
  100%  {top:` + (73 + plinkobets) + `%; left:` + columns + `%; transform:translateY(0px);}`;

	//  100%  {top:` + position.y + `%; left: ${position.x - 20}px;}`;
	const head = document.getElementsByTagName('head')[0];
	let style = document.createElement('STYLE');
	style.innerHTML = keyframes;
	head.appendChild(style);


	// console.log("keyframes_keyframes", keyframes)
	const stylefinal = {
		left: `${position.x - 20}px`,
		top: `${position.y}%`,
		animation: falling ? 'rubbish 6s ease-in-out' : 'none',
	};


	// console.log(ballPosition,"ballPosition");


	// animation.onfinish = () => {
	//   console.log("anim finish");
	// };
	// const style = {
	//   left: `${position.x - 100}px`,
	//   top: `${position.y - 20}%`,
	//   animation: falling ? 'fallAnimation 1s ease-in-out' : 'none',
	// };
	// const style1 = {
	//   left: `${position.x - 50}px`,
	//   top: `${position.y - 40}%`,
	//   animation: falling ? 'fallAnimation 2s ease-in-out' : 'none',
	// };

	// const style2 = {
	//   left: `${position.x - 20}px`,
	//   top: `${position.y - 0}%`,
	//   animation: falling ? 'fallAnimation 3s ease-in-out' : 'none',
	// };
	console.log("stylefinal", stylefinal)

	return <div className="ball" id="ballpos"
		style={stylefinal}
	// style={keyframes}
	// animationName="rubbish"
	></div>


	// setTimeout(function()
	// {
	//   return <div className="ball" style={style}></div>;

	// },10);
	// setTimeout(function()
	// {
	//   return <div className="ball" style={style1}></div>;

	// },2000);
	// setTimeout(function()
	// {
	//   return <div className="ball" style={style2}></div>;

	// },3000);

};

const Board = () => {
	const rows = Array.from({ length: plinkobets });

	return (
		<div className="board">
			<div className='row_div' id="row_div">
				{rows.map((row, index) => (
					<Row key={index} />
				))}
			</div>
		</div>
	);
};

const Row = () => {
	const columns = Array.from({ length: plinkobets });

	return (
		<div className="row row_plinko">
			{console.log('columnsdata_columnsdata_', columns)}
			{columns.map((column, index) => (
				<>
					{console.log('row_index', index)}
					<div className="bucket" id={"bucket_" + index}>
						<div className='bucket_dot'>
						</div></div>
				</>

			))}
		</div>
	);
};

// const Bucket = ({ index }) => {


//   return <div className="bucket" id={"bucket_"+index}>
//   <div className='bucket_dot'>
//   </div></div>;
// };



const Boardbucket = () => {
	const rows = Array.from({ length: 1 });

	return (
		<div className="boardbucket">
			{rows.map((row, index) => (
				<Rowbucket key={index} />
			))}
		</div>
	);
};

const Rowbucket = () => {
	// const columns = Array.from({ length: plinkobets });
	var columns = [];
	if (plinkobets == 12) {
		columns = [70, 16, 3, 2, 0.9, 0.6, 0.4, 0.6, 2, 3, 16, 70];
	} else if (plinkobets == 11) {
		columns = [65, 17, 4, 0.9, 0.6, 0.4, 0.6, 0.9, 4, 17, 65];
	} else if (plinkobets == 10) {
		columns = [47, 8, 2, 0.9, 0.6, 0.4, 0.6, 2, 8, 47];
	} else if (plinkobets == 9) {
		columns = [45, 8, 0.9, 0.6, 0.4, 0.6, 0.9, 8, 45];
	} else if (plinkobets == 8) {
		columns = [20, 4, 0.9, 0.6, 0.4, 0.6, 4, 20];
	} else if (plinkobets == 7) {
		columns = [15, 2, 0.7, 0.4, 0.7, 2, 15];
	} else if (plinkobets == 6) {
		columns = [10, 1, 0.4, 0.1, 1, 10]
	}


	
};

const Bucketbucket = ({ keys }) => {
	console.log('122 vvvvvvvvvv', keys)
	return <div className="bucketbucket bucket_red">
		<p className='bucket_text mb-0'>{keys}x</p>
	</div>;
};




export default Plinkogame











