import React, { useEffect } from 'react'

export default function Redirectsite() {
    useEffect(() => {
        window.location.href = 'https://blueiceegiant.maticz.in/';
    })
  return (
    <div>Redirectsite</div>
  )
}
