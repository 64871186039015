import React, { useState, useEffect } from "react";
import { Button } from 'react-bootstrap';

import Footer from '../Layouts/Footer';
import Sidebar from "../Layouts/sidebar1";
import Sidebarheader from "../Components/sidebarheader";
import dashimg from "../assets/gradient.svg";
import graphdash from "../assets/graphdash.png";
import earndash from "../assets/earndash.png";
import { BsArrowUpShort, BsArrowDownShort, BsQuestionCircle } from 'react-icons/bs';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import goldcoin from '../assets/goldicon.png';
import arrowimg from '../assets/arrow.png';
import Wallets from '../Components/Modals/Wallet';
import reward from '../assets/rewardsvg.svg';
import coingold from '../assets/coingold.png';
import bitsmallcoin from '../assets/bitsmallcoin.png';
import Vestingtable from "../Components/vestingtable";
import Gameheader from '../Layouts/gamesheader';
import bitwinr from '../assets/bitwinr.png';
import { CHAINS } from "../config/env";
import { getChainId, getAccount } from "../hooks/useAccount";
import { stakeTokens } from '../config/Farms'
import BigNumber from 'bignumber.js';
import { checkIsApproved, getUserBalance, approveContract, toFixedNumber, UseTokenInfo } from "../hooks/UseContract";
import { OnStake, UnStake, getUser, pooldetails, pending, getWholeNumber } from '../hooks/UseStake'

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import tailsimg from "../assets/tails-img.png";


export default function Stake() {
    console.log("stakeTokens_stakeTokens", stakeTokens);
    const [accountInfo, setAccountInfo] = useState("");
    console.log("accountInfoV_V", accountInfo);
    const [pendingTx, setpendingTx] = useState(true);
    const [currentChain, setcurrentChain] = useState(0)
    const [status, setStatus] = useState(false);
    const [pendingdata, setpendingdata] = useState()
    const [isMax, setIsMax] = useState(false);
    const [Maxdeposit, setmaxDeposit] = useState("0");
    const [isApproved, setIsApproved] = useState(false);
    const [isApproved1, setIsApproved1] = useState(false)
    console.log("isApproved", isApproved)
    const [depositTokenBalance, setDepositTokenBalance] = useState();

    const [DepositTokenBalancetwo, setDepositTokenBalancetwo] = useState();
    console.log("depositTokenBalance_depositTokenBalance", DepositTokenBalancetwo);
    const [selectStakeToken, setSelectStakeToken] = useState();
    const [walletmodal, setWalletmodal] = useState(false);
    const [volumess, setvolumess] = useState(0)
    const [volumes, setvolumes] = useState(0)
    const [volumess1, setvolumess1] = useState(0)
    const [volumes1, setvolumes1] = useState(0)
    const [counter, setCounter] = useState(0);
    const [counter1, setCounter1] = useState(0);
    const [counter2, setCounter2] = useState(0);
    const [counter3, setCounter3] = useState(0);
    const [userstackamount, setuserstackamount] = useState()

    const [userstackamounttwo, setuserstackamounttwo] = useState()

    console.log("userstackamount_userstackamount", userstackamount);
    const [Earned, setEarned] = useState()
    console.log("Earned--", Earned);
    const [Earnedtwo, setEarnedtwo] = useState()
    console.log("Earnedtwo_Earnedtwo", Earnedtwo);
    const [Earnedthree, setEarnedthree] = useState()
    console.log("Earnedthree_Earnedthree", Earnedthree);

    const [depositcalculateamount1, setDepositCalculateAmount1] = useState(0);
    console.log("depositcalculateamount1_depositcalculateamount1_v", depositcalculateamount1);
    const [depositcalculateamount2, setDepositCalculateAmount2] = useState(0);
    const [withdrawcalculateamount1, setWithdrawCalculateAmount1] = useState(0);
    const [withdrawcalculateamount2, setWithdrawCalculateAmount2] = useState(0);
    const [Maxwithdraw, setmaxwithdraw] = useState("0");

    const handleOnChange1 = (value) => {
        console.log("volume_value", value, depositTokenBalance)
        setvolumess(value);
        let percentageamount = parseInt(depositTokenBalance * value) / 100;
        console.log("percentageamount", percentageamount)
        setDepositCalculateAmount1(percentageamount)
    }

    const handleOnChange2 = (value) => {
        setvolumess1(value);
        let percentageamount = parseInt(DepositTokenBalancetwo * value) / 100;
        setDepositCalculateAmount2(percentageamount)
    }
    const handleOnChange = (value) => {
        setvolumes(value);
        console.log("value_data", value, userstackamount)
        let percentageamount = parseInt(userstackamount * value) / 100;
        setWithdrawCalculateAmount1(percentageamount)
    }
    const handleOnChange3 = (value) => {
        setvolumes1(value);
        let percentageamount = parseInt(userstackamounttwo * value) / 100;
        setWithdrawCalculateAmount2(percentageamount)
    }


    const incrementCounter = () => {
        setCounter(counter + 1);
    };
    const incrementCounter1 = () => {
        setCounter1(counter1 + 1);
    };
    const incrementCounter2 = () => {
        setCounter2(counter2 + 1);
    };
    const incrementCounter3 = () => {
        setCounter3(counter3 + 1);
    };
    const decrementCounter = () => {
        if (counter !== 0) {
            setCounter(counter - 1);
        }
    };
    const decrementCounter1 = () => {
        if (counter1 !== 0) {
            setCounter1(counter1 - 1);
        }
    }; const decrementCounter2 = () => {
        if (counter2 !== 0) {
            setCounter2(counter2 - 1);
        }
    }; const decrementCounter3 = () => {
        if (counter3 !== 0) {
            setCounter3(counter3 - 1);
        }
    };
    const chainId = async () => {
        let chaindd = getChainId()
        console.log("chaindd_chainddchaindd", chaindd.at, CHAINS[currentChain]);
        setcurrentChain(chaindd)

    }
    const maxDeposit = () => {
        // console.log("userTokenBalance", props.userTokenBalance)
        if (depositTokenBalance >= 0) {
            setIsMax(true);
            setmaxDeposit(depositTokenBalance);
        }
    }
    const claim = async () => {

        let pid = 1
        const decimal = stakeTokens[0]?.decimal
        const deposit = 0

        // await pending(getAccount(), pid)

        await OnStake(getAccount(), pid, deposit, decimal)

    }



    const Pending = async () => {
        try {
            // if(accountInfo){
            console.log("logsspending");
            let pid = 1
            const data = await pending(getAccount(), pid)

            const decimal = stakeTokens[0]?.decimal
            // const balance = new BigNumber(data).pow(decimal);
            const datas = ((new BigNumber(data)).div(new BigNumber(10).pow(decimal))).toNumber();
            //   const datas = await getWholeNumber(data,decimal)
            console.log("datasssss", datas);

            setpendingdata(datas)
            // }

        } catch (error) {
            console.log("errr--", error);

        }

    }
    const getUserStakeTokenBalance = async () => {
        try {
            // if(accountInfo){
            console.log("getaccoo", getAccount());
            const tokenBalance = getAccount() ? await getUserBalance(stakeTokens[0]?.address, getAccount()) : ""

            console.log("tokenBalance", tokenBalance);
            const tokenBalancetwo = getAccount() ? await getUserBalance(stakeTokens[1]?.address, getAccount()) : ""
            console.log("tokenBalancetwo_tokenBalancetwo", tokenBalancetwo, tokenBalance);
            setDepositTokenBalancetwo(tokenBalancetwo)
            // setDepositTokenBalance(parseInt(tokenBalance).toFixed(2))
            setDepositTokenBalance(tokenBalance)
            // }

        } catch (error) {

        }

    }

    const checkIsApproval = async () => {
        try {
            // if (accountInfo) {
            console.log("APPROVAL...selectStakeToken ", stakeTokens[0]?.address)
            const approval1 = await checkIsApproved(getAccount(), stakeTokens[0]?.address);
            const approval2 = await checkIsApproved(getAccount(), stakeTokens[1]?.address);
            console.log("APPROVAL...", approval1, approval2)
            setIsApproved(approval1);
            setIsApproved1(approval2)
            // }else{
            //     console.log("err");
            // }


        } catch (error) {

        }

    }

    //approve
    const approve = async (pid) => {
        console.log('pid---', pid);
        // if (accountInfo) {

        if (pid == 1) {
            console.log("accountInfo_V_accountInfo", accountInfo);
            await approveContract(accountInfo, stakeTokens[0]?.address);
            setIsApproved(true);
        }
        else if (pid == 2) {
            console.log("accountInfotwooo", accountInfo);
            await approveContract(accountInfo, stakeTokens[1]?.address);
            setIsApproved(true);
        }
        // }

        else {
            alert("SORRY NOT FOUND");
        }
    }

    const TotalEarned = async () => {
        try {

            // if(accountInfo){

            let pid = 1
            var getuser = await getUser(pid, getAccount());
            console.log("getuser-----", getuser);
            const decimal = stakeTokens[0]?.decimal
            const data = ((new BigNumber(getuser.earned)).div(new BigNumber(10).pow(decimal))).toNumber();
            console.log("data_data_V", data);
            // console.log("getuser_getuser", getuser); 
            setEarned(data)
            // }else{

            // }

        } catch (error) {
            console.log("err", error);
        }

    }
    const TotalEarnedTwo = async () => {
        try {
            // if(accountInfo){
            console.log("newdataentryyyy");
            let pid = 2
            var getuser = await getUser(pid, getAccount());
            const decimal = stakeTokens[1]?.decimal
            const data = ((new BigNumber(getuser.earned)).div(new BigNumber(10).pow(decimal))).toNumber();
            setEarnedtwo(data)
            // }else{
            //     console.log("errrr");
            // }

        } catch (error) {
            console.log("errorrr-", error);

        }

    }
    const TotalEarnedThree = async () => {
        console.log("earned-------", Earned, Earnedtwo, parseInt(Earned) + parseInt(Earnedtwo));

        let data = parseInt(Earned) + parseInt(Earnedtwo)
        console.log("totalearnamount", data);
        if (isNaN(data)) {
            data = 0
        }
        setEarnedthree(data)
    }



    //deposite
    const depositTokens = async (pid) => {
        // console.log("piddata------", pid);
        if (pid == 1) {
            let pid = 1
            const decimal = stakeTokens[0]?.decimal
            const deposit = depositcalculateamount1 == 0 ? Math.round(depositcalculateamount1) : Math.round(depositcalculateamount1)
            console.log("deposit_deposit_v", deposit);
            const planId = 1
            await OnStake(getAccount(), pid, deposit, decimal)
            setpendingTx(false)
        }
        else if (pid == 2) {
            let pid = 2
            const decimal = stakeTokens[0]?.decimal
            const deposit = depositcalculateamount2 == 0 ? Math.round(depositcalculateamount2) : Math.round(depositcalculateamount2)
            const planId = 1
            await OnStake(getAccount(), pid, deposit, decimal)
            setpendingTx(false)
        }
    }




    const Withdraw = async (Piddata) => {

        console.log("pid-pid_pid", Piddata);


        try {


            if (Piddata == 1) {

                let pid = "1"
                var getuser = await getUser(pid, getAccount());
                const tokenInfo = stakeTokens[0]?.decimal
                const data = ((new BigNumber(getuser.amount)).div(new BigNumber(10).pow(tokenInfo))).toNumber();
                setuserstackamount(data)
                const poolDetails = await pooldetails(pid);
                // let amounts = getuser.amount
                // console.log("amountsssdata",amounts);
                const decimal = stakeTokens[0]?.decimal
                const deposit = withdrawcalculateamount1 == 0 ? Math.round(withdrawcalculateamount1) : Math.round(withdrawcalculateamount1)
                console.log("deposit------", deposit);
                const amount = toFixedNumber(deposit * 10 ** 18)

                await UnStake(getAccount(), pid, amount, decimal).then((data, err) => {
                    if (err) {
                        console.log("errrrrrdataaa", err)
                    } else {
                        console.log("UNSTAKE DATA", data)
                    }
                })

            }
            else if (Piddata == 2) {
                let pid = "2"

                var getuser = await getUser(pid, getAccount());
                const tokenInfo = "18"
                const data = ((new BigNumber(getuser.amount)).div(new BigNumber(10).pow(tokenInfo))).toNumber();
                setuserstackamounttwo(data)
                const poolDetails = await pooldetails(pid);
                // let amount = getuser.amount
                const decimal = stakeTokens[1]?.decimal

                const deposit = withdrawcalculateamount2 == 0 ? Math.round(withdrawcalculateamount2) : Math.round(withdrawcalculateamount2)

                const amount = toFixedNumber(deposit * 10 ** 18)

                await UnStake(getAccount(), pid, amount, decimal).then((data, err) => {
                    if (err) {
                        console.log("errrrrrdataaa", err)
                    } else {
                        console.log("UNSTAKE DATA", data)
                    }
                })
            }


        } catch (error) {
            console.log("errorrrr-----", error);

        }
    }

    // const Withdraw = async () => {
    //     try {
    //         let pid = "1"

    //         var getuser = await getUser(pid, getAccount());
    //         console.log("getuser_getuser_V", getuser);
    //         // pooldetails
    //         const tokenInfo = stakeTokens[0]?.decimal
    //         console.log("tokenInfo",tokenInfo);
    //         setuserstackamount(getuser.amount)
    //         // const data = ((new BigNumber(getuser.amount)).div(new BigNumber(10).pow(tokenInfo))).toNumber();
    //         // const poolDetails = await pooldetails(pid);
    //         // console.log("withdarwtoken_info",data);
    //         let amount = getuser.amount
    //         const decimal = stakeTokens[0]?.decimal


    //         await UnStake(getAccount(), pid, amount, decimal).then((data, err) => {
    //             if (err) { 
    //                 console.log("errrrrrdataaa", err)
    //             } else {
    //                 console.log("UNSTAKE DATA", data)
    //             }
    //         })

    //     } catch (error) {
    //         console.log("errorrrr-----", error);

    //     }

    // }

    const withdrawamount = async () => {
        try {
            // if(accountInfo){
            let pid = "1"
            var getuser = await getUser(pid, getAccount());
            const tokenInfo = "18"
            const data = ((new BigNumber(getuser.amount)).div(new BigNumber(10).pow(tokenInfo))).toNumber();
            console.log("withdrawamountone", data);
            // pooldetails
            setuserstackamount(data)
            //     }
            // else{
            //     console.log("errrr");
            // }

        } catch (error) {

        }

    }
    const withdrawamount2 = async () => {
        try {
            // if(accountInfo){
            let pid = "2"
            var getuser = await getUser(pid, getAccount());
            const tokenInfo = "18"


            const data = ((new BigNumber(getuser.amount)).div(new BigNumber(10).pow(tokenInfo))).toNumber();

            setuserstackamounttwo(data)
            // }
            // else{
            //     console.log("errrrr--");
            // }
        } catch (error) {

        }

    }


    useEffect(() => {
        Withdraw()
        withdrawamount()
        withdrawamount2()
    })

    useEffect(() => {
        depositTokens()
        TotalEarned()
        TotalEarnedTwo()
        TotalEarnedThree()
    }, [])
    useEffect(() => {
        TotalEarnedThree()
    }, [Earned, Earnedtwo])

    useEffect(() => {
        getUserStakeTokenBalance();
        checkIsApproval()
    }, [selectStakeToken]);


    useEffect(() => {
        console.log("dataenter");
        Pending()
    }, [])

    useEffect(() => {
        chainId()
        const walletAddress = sessionStorage.accountInfo ? setAccountInfo(sessionStorage.getItem("accountInfo")) : ""
    }, [accountInfo]);

    return (
        <>
            {walletmodal && <Wallets onDismiss={() => setWalletmodal(false)} />}
            <div className="sidebarlist">
                <Gameheader />
                <div className="d-none d-xl-block"> <Sidebar /></div>

                <div className="content pt-3">
                    <div className="container container_max">
                        {/* <Sidebarheader /> */}
                        <div className="content_right position pt-4">
                            <h4 className="pt-5 fw-700 whtclr">Staking</h4>
                            <p className="f-14">Stake your WINR and vWINR tokens to earn real yield in the form of WLP. Learn more here.</p>



                            <div className="row pt-2">
                                <div className="col-xl-3 col-sm-6 col-lg-6 col-md-6 mt-3">
                                    <div className="dashboard d-flex">
                                        <div><img className="img-fluid" src={dashimg} alt="contest" /></div>
                                        {/* <div className="ms-3"><h6 className="fw-700">Staked vWINRXzsXS</h6><p><span className="dblack">{Earned}</span><span className="lgray"> ($3,258,350)</span></p></div> */}
                                        <div className="ms-3">
                                            <h5 className="fw-700 whtclr">Staked vWINR</h5>
                                            <p><span className="dblack whtclr">133,700,357</span><span className="lgray"></span></p>
                                            {/* <p><span className="dblack">{Earned}</span><span className="lgray"></span></p> */}
                                        </div>

                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6 col-lg-6 col-md-6 mt-3">
                                    <div className="dashboard dashboardimg1 d-flex">
                                        <div><img className="img-fluid" src={bitwinr} alt="contest" /></div>
                                        {/* <div className="ms-3"><h6 className="fw-700">Staked WINR</h6><p><span className="dblack">{Earnedtwo}</span><span className="lgray"> ($3,258,350)</span></p></div> */}
                                        <div className="ms-3"><h5 className="fw-700 whtclr">Staked WINR</h5>
                                            <p><span className="dblack whtclr">70,940,763($1,848,495)</span><span className="lgray"></span></p>
                                            {/* <p><span className="dblack">{Earnedtwo}</span><span className="lgray"></span></p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6 col-lg-6 col-md-6 mt-3">
                                    <div className="dashboard dashboardimg2 d-flex">
                                        <div><img className="img-fluid" src={graphdash} alt="contest" /></div>
                                        <div className="ms-3">
                                            <h5 className="fw-700 whtclr">APR
                                            </h5>
                                            <p className="flex_rto_div"
                                            ><span className="dblack whtclr">3.02%</span>
                                                <div>
                                                    <OverlayTrigger
                                                        delay={{ hide: 450, show: 300 }}
                                                        overlay={(props) => (
                                                            <Tooltip {...props}>
                                                                <p className="mb-1">Weekly average APR</p>
                                                                <p className="mb-1">1.51% for WINR</p>
                                                                <p className="mb-0">3.02% for vWINR</p>
                                                            </Tooltip>
                                                        )}
                                                        placement="bottom"
                                                    ><Button variant="warning" className="btn_tooltip_othng py-0">
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.49251 6.85717C6.66045 6.3798 6.9919 5.97725 7.4282 5.72084C7.86448 5.46443 8.37744 5.3707 8.87619 5.45625C9.37496 5.5418 9.82737 5.80112 10.1533 6.18827C10.4792 6.57541 10.6576 7.06541 10.6568 7.57146C10.6568 9.00004 8.51394 9.7143 8.51394 9.7143M8.57108 12.5715H8.57825M15.714 9.00004C15.714 12.945 12.516 16.1429 8.57108 16.1429C4.62619 16.1429 1.42822 12.945 1.42822 9.00004C1.42822 5.05514 4.62619 1.85718 8.57108 1.85718C12.516 1.85718 15.714 5.05514 15.714 9.00004Z" stroke="#68758c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </div>
                                            </p></div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6 col-lg-6 col-md-6 mt-3">
                                    <div className="dashboard d-flex">
                                        <div><img className="img-fluid" src={earndash} alt="contest" /></div>

                                        {/* <div className="ms-3"><h6 className="fw-700">Total Earned</h6><p><span className="dblack">{parseInt(Earned) + parseInt(Earnedtwo)}</span><span className="lgray"> ($3,258,350)</span></p></div> */}
                                        <div className="ms-3"><h5 className="fw-700 whtclr">Total Earned</h5>
                                            <p><span className="dblack whtclr">$166,933</span><span className="lgray"></span></p>
                                            {/* <p><span className="dblack">{Earnedthree}</span><span className="lgray"></span></p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-lg-4 mt-3">
                                    <div className="gameborder stakeborder mt-lg-5">

                                        <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                        <h5 className="fw-700 mb-0 whtclr">Staked vWINR</h5>
                                        <span className="whtclr">Get 2x weight in the staking pool.</span>
                                        </div>

                                        <div>
                                            <h6 className="fw-700 mb-0 whtclr">APR</h6>
                                            <p className="whtclr mb-0">2%</p>
                                        </div>
                                        </div>


                                        {/* <div>
                                          <img src={tailsimg} className="img-fluid tailcoin"/>
                                        </div> */}


                                        <div className="d-flex justify-content-between mt-2">
                                            <p className="fw-600"></p> <p className="fw-700 f-14 mb-1">{depositcalculateamount1 == 0 ? depositTokenBalance : depositcalculateamount1} WINR</p>
                                        </div>
                                        <div className="position-relative range1">
                                            <Slider
                                                min={0}
                                                max={100}
                                                value={volumess}
                                                onChange={handleOnChange1}
                                                tooltip={false}
                                            />
                                            <div class="input-group mb-3">
                                                <input type="number" class="form-control" id="example" aria-describedby="number" />
                                                <p className="mb-0 nochange1">{volumess}</p>
                                                <p className="mb-0 nochange"></p>
                                                {/* <span class="input-group-text" id="basic-addon1"><BsArrowUpShort onClick={incrementCounter} className="uparrow" /><BsArrowDownShort onClick={decrementCounter} className="downarrow" /><img className="mr-3" src={goldcoin} alt="images" /></span> */}
                                                <span class="input-group-text" id="basic-addon1"></span>
                                            </div>
                                        </div>
                                        {(accountInfo == "" || accountInfo == null) ?
                                            <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2"
                                                onClick={() => setWalletmodal(true)} >CONNECT WALLET</button>
                                            :
                                            <> {isApproved ? <button class="btn w-100 btn-success earnbtn walletss mt-2" onClick={() => depositTokens(1)}>Deposit</button> :
                                                <button class="btn w-100 btn-success earnbtn walletss mt-2" onClick={() => approve(1)}>Approve</button>
                                            } </>


                                        }

                                        <div className="d-flex justify-content-between pt-3">

                                            <p className="fw-700 mb-2 f-14">Staked2</p> <p className="fw-700 f-14 mb-1">{withdrawcalculateamount1 == 0 ? userstackamount : withdrawcalculateamount1} vWINR</p>
                                        </div>
                                        <div className="position-relative range1">
                                            <Slider
                                                min={0}
                                                max={100}
                                                value={volumes}
                                                onChange={handleOnChange}
                                                tooltip={false}
                                            />
                                            <div class="input-group mb-3">
                                                <input type="number" class="form-control" id="example" aria-describedby="number" />
                                                <p className="mb-0 nochange1">{volumes}</p>
                                                <p className="mb-0 nochange"></p>
                                                {/* <p className="mb-0 nochange">$ {counter1}</p> */}

                                                {/* <span class="input-group-text" id="basic-addon1"><BsArrowUpShort onClick={incrementCounter1} className="uparrow" /><BsArrowDownShort onClick={decrementCounter1} className="downarrow" /><img className="mr-3" src={goldcoin} alt="images" /></span> */}
                                                <span class="input-group-text" id="basic-addon1"></span>
                                            </div>
                                        </div>

                                        <p className="f-13 pt-2 pb-1 mb-0 fw-700"><span className="whtclr">Burn Fee :</span> 2%</p>
                                        {(accountInfo == "" || accountInfo == null) ?
                                            <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2" onClick={() => setWalletmodal(true)}
                                            // disabled
                                            >  CONNECT WALLET</button> :
                                            <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2"
                                                // disabled
                                                onClick={async () => { await Withdraw(1) }}  >WITHDRAW</button>}
                                    </div>
                                </div>



                                <div className="col-md-6 col-lg-4 mt-3">
                                    <div className="gameborder stakeborder  mt-lg-5">
                                        <h5 className="fw-700 whtclr mb-0">Staked vWINR</h5>
                                        <span className="whtclr">Get 1x weight in the staking pool.</span>

                                        <div className="d-flex justify-content-between mt-2">
                                            <p className="fw-600"></p> <p className="fw-700 f-14 mb-1">{depositcalculateamount2 == 0 ? DepositTokenBalancetwo : depositcalculateamount2}vWINR</p>
                                        </div>
                                        <div className="position-relative range1">
                                            <Slider
                                                min={0}
                                                max={100}
                                                value={volumess1}
                                                onChange={handleOnChange2}
                                                tooltip={false}
                                            />
                                            <div class="input-group mb-3">
                                                <input type="number" class="form-control" id="example" aria-describedby="number" />
                                                <p className="mb-0 nochange1">{volumess1}</p>
                                                {/* <p className="mb-0 nochange">$ {counter2}</p> */}
                                                <p className="mb-0 nochange"></p>

                                                {/* <span class="input-group-text" id="basic-addon1"><BsArrowUpShort onClick={incrementCounter2} className="uparrow" /><BsArrowDownShort onClick={decrementCounter2} className="downarrow" /><img className="mr-3" src={pigcoin} alt="images" /></span> */}
                                                <span class="input-group-text" id="basic-addon1"></span>
                                            </div>
                                        </div>

                                        {/* {(accountInfo == "" || accountInfo == null) ?
                                        // <button type="button" class="btn btn-outline-secondary w-100 mt-2">                                     
                                        //     <TailSpin
                                        //         height="28"
                                        //         width="28"
                                        //         color="#ffff"
                                        //         ariaLabel="tail-spin-loading"
                                        //         radius="2"
                                        //         wrapperStyle={{}}
                                        //         wrapperClass=""
                                        //         visible={true}
                                        //     />                                   
                                        // </button> 
                                        <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2"
                                        >CONNECT WALLET</button>
                                        :
                                        <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2"
                                        // disabled
                                        >APPROVE AND STAKE</button>} */}



                                        {(accountInfo == "" || accountInfo == null) ?
                                            <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2"
                                                onClick={() => setWalletmodal(true)} >CONNECT WALLET</button>
                                            :
                                            <> {isApproved1 ? <button class="btn w-100 btn-success earnbtn walletss mt-2" onClick={() => depositTokens(2)}>Deposit</button> :
                                                <button class="btn w-100 btn-success earnbtn walletss mt-2" onClick={() => approve(2)}>Approve</button>
                                            } </>


                                        }

                                        <div className="d-flex justify-content-between pt-3">
                                            <p className="fw-700 mb-2 f-14">Staked3</p> <p className="fw-700 f-14 mb-1"> {withdrawcalculateamount2 == 0 ? userstackamounttwo : withdrawcalculateamount2} vWINR</p>
                                        </div>
                                        <div className="position-relative range1">
                                            <Slider
                                                min={0}
                                                max={100}
                                                value={volumes1}
                                                onChange={handleOnChange3}
                                                tooltip={false}
                                            />
                                            <div class="input-group mb-3">
                                                <input type="number" class="form-control" id="example" aria-describedby="number" />
                                                <p className="mb-0 nochange1">{volumes1}</p>
                                                {/* <p className="mb-0 nochange">$ {counter3}</p> */}
                                                <p className="mb-0 nochange"></p>
                                                {/* <span class="input-group-text" id="basic-addon1"><BsArrowUpShort onClick={incrementCounter3} className="uparrow" /><BsArrowDownShort onClick={decrementCounter3} className="downarrow" /><img className="mr-3" src={pigcoin} alt="images" /></span> */}
                                                <span class="input-group-text" id="basic-addon1"></span>
                                            </div>
                                        </div>

                                        <p className="f-13 pt-2 pb-1 mb-0 fw-700"><span className="whtclr">Burn Fee :</span> 2%</p>
                                        {/* {(accountInfo == "" || accountInfo == null) ?
                                        <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2" onClick={() => setWalletmodal(true)}
                                        // disabled
                                        >  CONNECT WALLET</button> :
                                        <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2"  >WITHDRAW</button>} */}

                                        {(accountInfo == "" || accountInfo == null) ?
                                            <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2" onClick={() => setWalletmodal(true)}
                                            // disabled
                                            >  CONNECT WALLET</button> :
                                            <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2"
                                                // disabled
                                                onClick={async () => { await Withdraw(2) }}  >WITHDRAW</button>}


                                    </div>
                                </div>


                                <div className="col-md-6 col-lg-4 mt-3">
                                    <div className="gameborder stakeborder  mt-lg-5">
                                        <div className="text-center rewardimg">
                                            <h4 className="fw-700 whtclr mb-4">Rewards</h4>
                                            {/* <img className="img-fluid" src={reward} alt="images" /> */}
                                            {/* <img className="img-fluid coingold" src={coingold} alt="images" /> */}
                                            {/* <h6 className="fw-700 ">REWARDS</h6> */}
                                        </div>
                                        {(accountInfo == "" || accountInfo == null) ?
                                            <div className="border_div_wrp borders pt-3 pb-3 px-3 d-flex align-items-center justify-content-between">
                                                <div><h6 className="fw-700 mb-0" onClick={TotalEarned()}>Total Earned</h6></div>
                                                <div>
                                                    <p className="mb-2 border_div_wrp_txt1">{Earned}</p>
                                                    <p className="mb-0 border_div_wrp_txt">0 <img className="img-fluid" src={bitsmallcoin} alt="images" /></p>
                                                </div>
                                            </div>
                                            :
                                            <div className="borders pt-3 pb-3 px-3 d-flex align-items-center justify-content-between">

                                                <div><h6 className="fw-700 mb-0">Total Earned</h6></div>

                                                <div>
                                                    <p className="mb-2">{Earned}</p>
                                                    {/* <p className="mb-0">0 <img className="img-fluid" src={bitsmallcoin} alt="images" /></p> */}
                                                    {/* <p className="mb-0">0 <img className="img-fluid" src={bitsmallcoin} alt="images" /></p> */}

                                                </div>
                                            </div>}


                                        <div className="borders mt-3 mb-0 pt-3 pb-3 px-3">
                                            <div className="border_div_wrp d-flex align-items-center justify-content-between">
                                                <div><h6 className="fw-700 mb-0">Claimable</h6></div>
                                                <div>
                                                    <p className="mb-0 border_div_wrp_txt">{pendingdata} <img className="img-fluid" src={bitsmallcoin} alt="images" /></p>
                                                </div></div>
                                            {(accountInfo == "" || accountInfo == null) ?
                                                <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2" onClick={() => setWalletmodal(true)}
                                                // disabled
                                                >  CONNECT WALLET</button> :
                                                <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2"
                                                    // disabled
                                                    onClick={() => { claim() }} > CLAIM</button>}
                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-8 mt-4 mb-4">
                                    <div className="gameborder stakeborder createdborder">
                                        <h5 className="fw-700 mb-0 whtclr">Vesting</h5>
                                        <span className="whtclr">Vested vWINR is entitled to 50% of the real yield.</span>
                                        <div className="borders my-3 py-3 px-3">
                                            <div className="row">
                                                <div className="col-sm-6 col-md-3 col-lg-3 mt-2">
                                                    <h6 className="f-14 fw-700 mb-0">Min. Vesting</h6>
                                                    <span className="whtclr">15 days</span>
                                                </div>
                                                <div className="col-sm-6 col-md-3  col-lg-3 mt-2">
                                                    <h6 className="f-14 fw-700 mb-0">Max. Vesting</h6>
                                                    <span className="whtclr">180 days</span>
                                                </div>
                                                <div className="col-sm-6 col-md-3  col-lg-3 mt-2">
                                                    <h6 className="f-14 fw-700 mb-0">Min. Ratio</h6>
                                                    <span className="whtclr">15 days</span>
                                                </div>
                                                <div className="col-sm-6 col-md-3 col-lg-3 mt-2">
                                                    <h6 className="f-14 fw-700 mb-0">Max. Ratio</h6>
                                                    <span className="whtclr">15 days</span>
                                                </div>
                                            </div>
                                        </div>
                                        <Vestingtable />
                                    </div>
                                </div>


                                <div className="col-md-6 col-lg-4 mt-3 mb-4">
                                    <div className="gameborder stakeborder mt-lg-2">
                                        <h6 className="fw-700 whtclr mb-0">Conversion</h6>
                                        <h5 className="fw-700 whtclr mb-1 pt-2">vWINR <img className="mr-3" src={arrowimg} alt="images" /> WINR</h5>
                                        <span className="whtclr">Customize your vesting schedule.</span>

                                        <div className="d-flex justify-content-between mt-2">
                                            <p className="fw-600"></p> <p className="fw-700 f-14 mb-1">0 vWINR</p>
                                        </div>
                                        <div className="position-relative range1">
                                            <Slider
                                                min={0}
                                                max={100}
                                                value={volumess1}
                                                onChange={handleOnChange2}
                                                tooltip={false}
                                            />
                                            <div class="input-group mb-3">
                                                <input type="number" class="form-control" id="example" aria-describedby="number" />
                                                <p className="mb-0 nochange1">{volumess1}</p>
                                                <p className="mb-0 nochange"></p>
                                                {/* <p className="mb-0 nochange">$ {counter2}</p> */}

                                                <span class="input-group-text" id="basic-addon1"><BsArrowUpShort onClick={incrementCounter2} className="uparrow" /><BsArrowDownShort onClick={decrementCounter2} className="downarrow" /><img className="mr-3" src={goldcoin} alt="images" /></span>
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-between pt-3">
                                            <p className="fw-700 mb-2 f-14">Vesting Duration</p> <p className="fw-700 f-14 mb-1"></p>
                                        </div>
                                        <div className="position-relative range1">
                                            <Slider
                                                min={0}
                                                max={100}
                                                value={volumes1}
                                                onChange={handleOnChange3}
                                                tooltip={false}
                                            />
                                            <div class="input-group mb-3">
                                                <input type="number" class="form-control" id="example" aria-describedby="number" />
                                                <p className="mb-0 nochange1">{volumes1}</p>
                                                <span class="input-group-text fw-600 whtclr" id="basic-addon1"> Days</span>
                                            </div>
                                        </div>

                                        <p className="f-13 pt-2 pb-0 mb-0 fw-700"><span className="lgray whtclr">Redeem Percentage :</span> 2%</p>
                                        <p className="f-13 pt-2 pb-1 mb-0 fw-700"><span className="lgray whtclr">Redeemable WINR :</span> 2%</p>

                                        <button type="button" class="btn w-100 btn-success earnbtn walletss mt-3" onClick={() => setWalletmodal(true)}
                                        // disabled
                                        >Connect Wallet</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>


            </div>
        </>
    );
}


