// import React, { useEffect } from "react";
// import {  Row, Col, Nav, Tab } from 'react-bootstrap';

// import Sidebar from "../Layouts/sidebar";
// import Sidebarheader from "../Components/sidebarheader";

// import Footer from "../Layouts/Footer";

// import Buywlp from '../Components/Earntabs/buywlp';
// import Sellwlp from '../Components/Earntabs/sellwlp';
// import Swap from '../Components/Earntabs/swap';

// import Gameheader from "../Layouts/gamesheader";

// export default function Earn() {

//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, []);

// return (
//   <>
//     <div className="sidebarlist">
//       <Gameheader />
//       <Sidebar />

//       <div className="content pt-3">
//         <div className="container">
//           <Sidebarheader />

//           <h4 className="pt-4 fw-700">Earn</h4>
//           <p className="f-14 pb-3">Provide liquidity to the house by purchasing WLP and earning yield from the losses of the players. Learn more here.</p>

//           <div className="row align-items-center">
//             {/* <div className="col-lg-7 col-md-12 mt-3"> */}
//               {/* <div className="piecharts gameborder stakeborder">
//                 <div className="row">
//                   <div className="col-md-6 mt-3">
//                     <Dropdown>
//                       <Dropdown.Toggle variant="Info" className="wlpdrop" id="dropdown-basic">
//                         <img className="img-fluid" src={tablecoin} alt="images" /> WLP
//                       </Dropdown.Toggle>

//                       <Dropdown.Menu>
//                         <Dropdown.Item href="/">Proof Of Reserves</Dropdown.Item>
//                         <Dropdown.Item href="/">View On Explorer</Dropdown.Item>
//                         <Dropdown.Item href="/">Add to MetaMask</Dropdown.Item>
//                       </Dropdown.Menu>
//                     </Dropdown>
//                     <ul>
//                       <li><p className="mt-2 mb-2">Price</p><p className="mt-2 mb-2">$1,045</p></li>
//                       <li><p className="mt-2 mb-2">Supply</p><p className="mt-2 mb-2">2,576,134 WLP</p></li>
//                       <li><p className="mt-2 mb-2">Market Cap</p><p className="mt-2 mb-2">$2,712,601</p></li>
//                       <li><p className="mt-2 mb-2">Stablecoin Percentage</p><p className="mt-2 mb-2">75.55%</p></li>
//                       <li><p className="mt-2 mb-2">Rewards APR</p><p className="mt-2 mb-2">123.87%</p></li>
//                       <li><p className="mt-2 mb-2">Wallet</p><p className="mt-2 mb-2">0 WLP</p></li>
//                     </ul>
//                   </div>
//                   <div className="col-md-6 mt-3">
//                     <Earngraph/>
//                   </div>
//                   <div className="col-md-12 mt-2">
//                     <div className="rewardbg">
//                       <div className="row align-items-center">
//                         <div className="col-md-4 col-sm-4 mt-3">
//                           <img className="img-fluid" src={rewardcup} alt="images" /> <span className="nclr fw-600">Rewards</span>
//                         </div>
//                         <div className="col-md-4 col-sm-4 textshort mt-3">
//                           <img className="img-fluid" src={tablecoin} alt="images" /> <span className="nclr fw-600">0</span>  <span className="fw-600 nthno">($0)</span>
//                           <img className="img-fluid ms-2" src={goldcoin} alt="images" /> <span className="nclr fw-600">0</span>  <span className="fw-600 nthno">($0)</span>
//                         </div>
//                         <div className="col-md-4 col-sm-4 mt-3">
//                           <button type="button" class="btn w-100 btn-success earnbtn walletss">CLAIM</button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div> */}
//             {/* </div> */}
//             <div className="col-lg-5 col-md-12 mt-3 mx-auto">
//               <div className="gameborder stakeborder">
//                 <Tab.Container id="left-tabs-example" defaultActiveKey="first">
//                   <Row>
//                     <Col md={12}>
//                       <Nav variant="pills">
//                         <Nav.Item>
//                           <Nav.Link eventKey="first">BUY WLP</Nav.Link>
//                         </Nav.Item>
//                         <Nav.Item>
//                           <Nav.Link eventKey="second">SELL WLP</Nav.Link>
//                         </Nav.Item>
//                         <Nav.Item>
//                           <Nav.Link eventKey="third">SWAP</Nav.Link>
//                         </Nav.Item>
//                       </Nav>
//                     </Col>
//                     <Col md={12} className="mt-3">
//                       <Tab.Content>
//                         <Tab.Pane eventKey="first"><Buywlp/></Tab.Pane>
//                         <Tab.Pane eventKey="second"><Sellwlp/></Tab.Pane>
//                         <Tab.Pane eventKey="third"><Swap/></Tab.Pane>
//                       </Tab.Content>
//                     </Col>
//                   </Row>
//                 </Tab.Container>
//               </div>
//             </div>
//           </div>

//           {/* <div className="pt-4 pb-4">
//           <h2 className="pt-3 pb-3 fw-700">WLP Index Composition</h2>
//             <Earntable />
//           </div> */}
//         </div>
//         <Footer />
//       </div>


//     </div>
//   </>
// );
// }


import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Nav, Navbar, NavDropdown, Dropdown, Tab } from 'react-bootstrap';
import Logo from '../assets/logo1.png';
import Header from '../Layouts/Header';
import Sidebar from "../Layouts/sidebar1";
import Sidebarheader from "../Components/sidebarheader";
import Earntable from "../Components/earntable"
import Footer from "../Layouts/Footer";
import tablecoin from '../assets/pigii.png';
import rewardcup from '../assets/rewardcup.png';
import goldcoin from '../assets/goldicon.png';
import Buywlp from '../Components/Earntabs/buywlp';
import Sellwlp from '../Components/Earntabs/sellwlp';
import Swap from '../Components/Earntabs/swap';
import Earngraph from '../Components/Earntabs/earnchart';
import Gameheader from "../Layouts/gamesheader";
import rightcoin from "../assets/rightcoin.png";
import leftcoin from "../assets/leftcoin.png";
import Offswap from "../Components/Earntabs/offswap";

export default function Earn() {

  const [swapselect, setSwapselect] = useState("internalswap");
  const [offswapselect, setOffswapselect] = useState("buyss")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <div className="sidebarlist">
        <Gameheader />
        <div className="d-none d-xl-block"> <Sidebar /></div>

        <div className="content pt-3">
          <div className="container container_max">
            {/* <Sidebarheader /> */}
            <div className="content_right position pt-4">
              <h4 class="ftext pt-5 mb-2">BLUE ICE <span class="sectext">GIANT</span></h4>
              <h4 className="pt-3 fw-700 whtclr">Exchange</h4>
              <p className="f-14 pb-3">Provide liquidity to the house by purchasing WLP and earning yield from the losses of the players. Learn more here.</p>
              <img src={rightcoin} className="rightcoin flip" alt="rightcoin" />
              <img src={leftcoin} className="leftcoin flip" alt="leftcoin" />
              <div className="row align-items-center">
                {/* <div className="col-lg-7 col-md-12 mt-3">
                <div className="piecharts gameborder stakeborder">
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <Dropdown>
                        <Dropdown.Toggle variant="Info" className="wlpdrop" id="dropdown-basic">
                          <img className="img-fluid" src={tablecoin} alt="images" /> WLP
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="/">Proof Of Reserves</Dropdown.Item>
                          <Dropdown.Item href="/">View On Explorer</Dropdown.Item>
                          <Dropdown.Item href="/">Add to MetaMask</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <ul>
                        <li><p className="mt-2 mb-2">Price</p><p className="mt-2 mb-2">$1,045</p></li>
                        <li><p className="mt-2 mb-2">Supply</p><p className="mt-2 mb-2">2,576,134 WLP</p></li>
                        <li><p className="mt-2 mb-2">Market Cap</p><p className="mt-2 mb-2">$2,712,601</p></li>
                        <li><p className="mt-2 mb-2">Stablecoin Percentage</p><p className="mt-2 mb-2">75.55%</p></li>
                        <li><p className="mt-2 mb-2">Rewards APR</p><p className="mt-2 mb-2">123.87%</p></li>
                        <li><p className="mt-2 mb-2">Wallet</p><p className="mt-2 mb-2">0 WLP</p></li>
                      </ul>
                    </div>
                    <div className="col-md-6 mt-3">
                      <Earngraph/>
                    </div>
                    <div className="col-md-12 mt-2">
                      <div className="rewardbg">
                        <div className="row align-items-center">
                          <div className="col-md-4 col-sm-4 mt-3">
                            <img className="img-fluid" src={rewardcup} alt="images" /> <span className="nclr fw-600">Rewards</span>
                          </div>
                          <div className="col-md-4 col-sm-4 textshort mt-3">
                            <img className="img-fluid" src={tablecoin} alt="images" /> <span className="nclr fw-600">0</span>  <span className="fw-600 nthno">($0)</span>
                            <img className="img-fluid ms-2" src={goldcoin} alt="images" /> <span className="nclr fw-600">0</span>  <span className="fw-600 nthno">($0)</span>
                          </div>
                          <div className="col-md-4 col-sm-4 mt-3">
                            <button type="button" class="btn w-100 btn-success earnbtn walletss">CLAIM</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                <div className="col-lg-5 col-md-12 mt-3 mx-auto mb-3">

                  <ul className="swap_border mb-5">
                    <li className={swapselect == "internalswap" ? "active" : ""} onClick={() => setSwapselect("internalswap")}>Internal Swap</li>
                    <li className={swapselect == "offswap" ? "active" : ""} onClick={() => setSwapselect("offswap")}>Off Swap</li>
                  </ul>


                  {swapselect == "internalswap" &&
                    <>
                      <div className="gameborder stakeborder">
                        <h5 className="swap_title">Swap</h5>
                        <Swap />
                      </div>
                    </>
                  }

                  {swapselect == "offswap" &&
                    <>
                      <div className="gameborder stakeborder stages">
                        <h5 className="swap_title">Off Swap</h5>
                        <ul className="swap_borderss mb-4">
                          <li className={offswapselect == "buyss" ? "active" : ""} onClick={() => setOffswapselect("buyss")}>Buy</li>
                          <li className={offswapselect == "sellss" ? "active" : ""} onClick={() => setOffswapselect("sellss")}>Sell</li>
                        </ul>

                            <Offswap offswapselect={offswapselect}/>
                       


                        {/* <Swap /> */}
                      </div>
                    </>
                  }



                  {/* <div className="gameborder stakeborder">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="third">
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          <Nav variant="pills">
                      
                            <Nav.Item>
                              <Nav.Link eventKey="third">SWAP</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col md={12} className="mt-3">
                          <Tab.Content>
                        
                            <Tab.Pane eventKey="third"><Swap /></Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div> */}
                </div>
              </div>

              {/* <div className="pt-4 pb-4">
            <h2 className="pt-3 pb-3 fw-700">WLP Index Composition</h2>
              <Earntable />
            </div> */}

            </div>

          </div>
          <Footer />
        </div>


      </div>
    </>
  );
}





