import { BACKURL } from '../config/env';
import axios from 'axios';
import toast from "react-hot-toast"
import { style, position, iconTheme } from './useToast';
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const getAuthToken = () => {

    try {
        let token = localStorage.getItem("accessToken")
        if (token != '' && token != null && token != undefined) {
            return localStorage.getItem("accessToken");
        }

    } catch (err) {
        console.log("getAuthTokenerrrrrr", err)
    }
}

axios.defaults.headers.common['Authorization'] = getAuthToken();

export const UserWalletRegisterHooks = async (data) => {
    try {
        console.log("walletregistration req data", data)
        let respData = await axios.post(BACKURL + "/api/user/walletregister", data);
        console.log("UserWalletRegisterHooks", respData)
        if (respData?.status == 200) {
            localStorage.removeItem("refferalId")
            return respData;
        }

    } catch (e) {
        console.log("UserWalletRegister_err", e)
    }

}


export const getgamesInfoHooks = async (data) => {
    try {
        let respData = await axios.post(BACKURL + "/api/user/getgamesdetails", data);
        console.log("respData_respData getgamesInfoHooks 1111 keke", respData)
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getgamesInfoHooks_err", e)
    }
}


export const getAllGamesInfoHooks = async () => {
    try {
        let respData = await axios.post(BACKURL + "/api/user/getallgamesdetails");
        // console.log("respData_respData getallgamesInfoHooks 1111 keke", respData)
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getgamesInfoHooks_err", e)
    }
}


export const UserGameDetailsUpdateHooks = async (data) => {

    try {
        console.log("UserGameDetailsUpdateHooks_data juje", data)
        let respData = await axios.post(BACKURL + "/api/user/gamedetailupdate", data);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (err) {
        console.log("UserGameDetailsBetsUpdateHooks_err", err)
    }
}


export const getUserInfoHooks = async (data) => {
    try {
        let respData = await axios.post(BACKURL + "/api/user/getuserdetails", data);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (err) {
        console.log("getUserInfoHooks_err", err)
    }

}


export const createOrderDetailsHooks = async (data) => {

    try {
        let respData = await axios.post(BACKURL + "/api/user/createOrders", data);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("createOrderDetailsHooks_err", e)
    }
}

export const getOrderDetailsHooks = async (data) => {
    try {
        console.log("getOrderDetailsHooks", data)
        let respData = await axios.post(BACKURL + "/api/user/getOrdersInfo", data);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getOrderDetailsHooks_err", e)
    }
}

export const getOrderTransactionDetailsHooks = async (data) => {

    try {
        console.log('req data', data)
        let respData = await axios.post(BACKURL + "/api/user/getordertransaction", data);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getOrderTransactionDetailsHooks_err", e)
    }
}


export const UpdateOrderDetailsHooks = async (data) => {
    try {
        let respData = await axios.post(BACKURL + "/api/user/updateorderdetails", data);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("UpdateOrderDetailsHooks_err", e)
    }
}

export const getUserCliamDetailsHooks = async (data) => {

    try {
        let respData = await axios.post(BACKURL + "/api/user/getusercliamdetails", data);
        console.log('respData_cliamdata', respData)
        if (respData?.status == 200) {
            return respData;
        }
    } catch (err) {
        console.log("getUserCliamDetailsHooks_err", err)
    }
}


export const UpdateUserCliamHooks = async (data) => {
    try {
        let respData = await axios.post(BACKURL + "/api/user/userupdatecliamdetails", data);
        console.log('respData_cliamdata', respData)
        if (respData?.status == 200) {
            return respData;
        }
    } catch (err) {
        console.log("getUserCliamDetailsHooks_err", err)
    }
}


export const getuserdatabyrefferalIdhooks = async (refferalId) => {
    try {
        let respData = await axios.get(`${BACKURL}/api/user/getuserdetailsbyrefferalId/${refferalId}`);
        if (respData?.status == 200) {
            return respData;
        }
        return respData;
    } catch (e) {
        console.log("getusermerchantId_err", e)
    }
}


export const getRefferRankInfo = async () => {
    try {
        let respData = await axios.get(`${BACKURL}/api/admin/getRefferalRanksInfo`);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getRefferRankInfo_err", e)
    }
}

export const getuserallreffersdetailhooks = async (data) => {
    try {
        let respData = await axios.post(`${BACKURL}/api/user/getuserallreffersdetails`, data);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getuserreffersdetailhooks_err", e)
    }
}

export const getuserallorderdetails = async (data) => {
    try {
        let respData = await axios.post(`${BACKURL}/api/user/getuserallorderdetails`, data);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getuserallorderdetails_err", e)
    }
}



export const updateuserrefferaldetails = async (data) => {
    try {
        let respData = await axios.post(`${BACKURL}/api/user/updateuserrefferaldetails`, data);
        if (respData?.status == 200) {
            // await Toast("success", respData?.data?.message)
        }
        return respData;
    } catch (e) {
        console.log("getuserallorderdetails_err", e)
    }
}


export const getuserrefferaldetails = async (data) => {
    try {
        console.log('datsssss', data)
        let respData = await axios.post(`${BACKURL}/api/user/getuserrefferaldetails`, data);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getuserallorderdetails_err", e)
    }
}

export const getrefferuserdetails = async (id) => {
    try {

        let respData = await axios.get(`${BACKURL}/api/user/getuserrefferdetails/${id}`);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getuserrefferadetails_err", e)
    }
}

export const updaterefferalclaimdetails = async (data) => {
    try {
        let respData = await axios.post(`${BACKURL}/api/user/updaterefferalclaimdetails`, data);
        if (respData?.status == 200) {
            // await Toast("success", respData?.data?.message)
        }
        return respData;
    } catch (e) {
        console.log("updaterefferalclaimdetails_err", e)
    }
}


export const getFeeInfo = async () => {
    try {
        let respData = await axios.get(`${BACKURL}/api/admin/getFeeInfo`);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getAllGamesDetails_err", e)
    }
}

export const getRefferInfo = async (userId) => {
    try {
        let respData = await axios.get(`${BACKURL}/api/user/getrefferdetails/${userId}`);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getRefferInfo_err", e)
    }
}


export const getAdminRefferInfo = async () => {
    try {
        let respData = await axios.get(`${BACKURL}/api/admin/getadminrefferdetails`);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getAdminRefferInfo_err", e)
    }
}


export const updateAdminCommissionDetails = async (data) => {
    try {
        let respData = await axios.post(`${BACKURL}/api/admin/updateadmincommissionfee`, data);
        console.log('updateAdminCommissionDetailsrespData', respData)
        if (respData?.status == 200) {
            return respData;
        } else {
            return respData
        }
    } catch (e) {
        console.log("updateAdminCommissionDetails_err", e)
    }
}


export const getGameSettingDetails = async () => {  
    try {
        let respData = await axios.get(`${BACKURL}/api/admin/getgamesettings`);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getGameSettingDetails_err", e)
    }
}

export const getAllGameTransactionDetails = async () => {
    try {
        let respData = await axios.get(`${BACKURL}/api/user/getallgametransactionsdetails`);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getAllGameTransactionDetails_err", e)
    }
}

export const UpdateGameSettingBalancehooks = async (data) => {
    try {
        console.log('update gamesettingbalacedata', data)
        let respData = await axios.post(`${BACKURL}/api/user/updategamesettings`, data);
        console.log('respond_gamesettingdata_data', respData)
        return respData;
    } catch (e) {
        console.log("UpdateGameSettingBalancehooks_err", e)
    }
}

export const copyUrl = async (message) => {
    toast.success(message, {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
    });
    await sleep(1000);
};


export const Toast = async (filter, message) => {
    try {
        if (filter == "success") {
            toast.success(message, {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
            });
            await sleep(1000);
        } else {
            console.log("tilerrrrrr", filter, message);
            toast.error(message, {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
            });
            await sleep(1000);
        }
    } catch (e) {
        console.log("Toast_err", e);
    }
};