import React, { useState, useEffect } from "react";
import Footer from '../Layouts/Footer';
import Sidebar from "../Layouts/sidebar";
import Sidebarheader from "../Components/sidebarheader";
import Gameheader from '../Layouts/gamesheader';
import Gametable from "../Components/gametabel";
// import { BsStarFill } from 'react-icons/bs';
import { useLocation } from "react-router-dom";
import tablecoin from '../assets/tablecoin.png';
import volumeimg from '../assets/volume.png';
import { BsArrowUpShort, BsArrowDownShort, BsQuestionCircle } from 'react-icons/bs';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import Dicemodal from '../Components/Modals/dicemodal';
import Wallets from '../Components/Modals/Wallet';

import headImage from "../assets/game/heads-img.png"
import tailImage from "../assets/game/tails-img.png"
import shadoImage from "../assets/game/shadow_coin.png"
import { getgamesInfoHooks } from "../hooks/UseUser";

export default function Gamecoin() {
    let location = useLocation()
    const [gameCard, setGameCard] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
    }, []);

    const getData = async () => {
        try {
            let pathname = location.pathname.split('/')[1];
            console.log("pathname_Gamecoin", pathname)
            if (pathname != undefined) {
                let data = {
                    gameName: pathname
                }
                let res = await getgamesInfoHooks(data);
                console.log("getgamesInfoHooks", res);
                if (res?.status == 200) {
                    if (res?.data?.data) {
                        setGameCard(res?.data?.data?.AllgameImages)
                    }
                }
            }
        } catch (e) {
            console.log("getData_err", e)
        }
    }

    const [selected, setSelected] = useState([]);
    const [volumeup, setvolumeup] = useState(0)
    const [volumes, setvolumes] = useState(0)
    const [volumess, setvolumess] = useState(0)
    const [dicemodal, setdicemodal] = useState(false)
    const [walletmodal, setWalletmodal] = useState(false)

    const [toss, setToss] = useState("Tails");
    const [totalflip,setTotalflip] = useState(0);
    const [headflip,setHeadflip] = useState(0);
    const [tailflip,setTailflip] = useState(0);
    const [coinimage, setCoinImage] = useState(tailImage);
    const [isRotated, setIsRotated] = useState(false);
    const [buttoncoin, setButtoncoin] = useState(0);

   const [btnDisabled, setBtnDisabled] = useState(false);


    const [gamecard, setgamecard] = useState([
        { "id": 1, "images": require("../assets/cardimg.png"), "title": "Coin Flip" },
        { "id": 2, "images": require("../assets/range.svg.png"), "title": "Range" },
        { "id": 3, "images": require("../assets/moon.svg.png"), "title": "Moon" },
        { "id": 4, "images": require("../assets/wheel.svg.png"), "title": "Wheel" },
        { "id": 5, "images": require("../assets/plinko.svg.png"), "title": "Plinko" },
        { "id": 6, "images": require("../assets/Group (2).png"), "title": "Dice" },
        { "id": 7, "images": require("../assets/limbo.svg.png"), "title": "Limbo" },
    ]);
    const [counter, setCounter] = useState(0);
    const incrementCounter = () => {
        setCounter(counter + 1);
    };
    const decrementCounter = () => {
        if (counter !== 0) {
            setCounter(counter - 1);
        }
    };
    const handleOnChange = (value) => {
        setvolumes(value)
    }
    const handleOnChange1 = (value) => {
        setvolumess(value)
    }
    const handleOnChange2 = (value) => {
        setvolumeup(value)
    }



    function tossCoin() {

        setTotalflip(totalflip + 1);
        var flipResult = Math.random();
        document.getElementById("coin").classList.remove("heads");
        document.getElementById("coin").classList.remove("tails");
        setBtnDisabled(true);

                setTimeout(function(){
          if(flipResult <= 0.5){
        document.getElementById("coin").classList.add("heads");

            console.log('it is head');



          }
          else{
            document.getElementById("coin").classList.add("tails");

            console.log('it is tails');


          }
        }, 100);

        setTimeout(function(){
        setBtnDisabled(false);
        if(flipResult <= 0.5){
            setHeadflip(headflip + 1);
        }
        else
        {
            setTailflip(tailflip + 1);

        }
          
          }, 3000);
      
      
    }


    function setCoin(val)
    {
        console.log(val, "val", isRotated, "bi",buttoncoin );
        document.getElementById("coin").classList.remove("heads");
        document.getElementById("coin").classList.remove("tails");
        if(val == "Tails")
        {
            setCoinImage(headImage);
        document.getElementById("coin").classList.add("tails");

        }
        else
        {
            setCoinImage(headImage);
        document.getElementById("coin").classList.add("heads");

      

        }
    }



    return (
        <>
            {dicemodal && <Dicemodal onDismiss={() => setdicemodal(false)} />}
            {walletmodal && <Wallets onDismiss={() => setWalletmodal(false)} />}
            <div className="sidebarlist">
                <Gameheader />
                <Sidebar />


                <div className="content pt-3">
                    <div className="container">
                        <Sidebarheader />

                        <div className="gameborder  mt-5">
                            <div className="row">
                                <div class="col-md-8 backsideimg">
                                    {localStorage.getItem("metamask") !== "true" ?
                                    <>
                                          <div className='coin_img_section'>

                                          <div id="coin">
                                    <div className="side-a">
                                    {/* <img src={tailImage}  className={`img-fluid coin_image`}  /> */}
                                      
                                    </div>
                                    <div className="side-b">
                                    {/* <img src={headImage}  className={`img-fluid coin_image`}  /> */}
                                  
                                    </div>
                                  </div>
                                  
                                  
                                  
                                       
                                  <img src={shadoImage} className='img_sahdow img-fluid' />
                                  
                                  
                                              
                                  
                                          </div>
                                          
                                          <div className='btn_grp_border'>
                                          <button className={toss == "Tails"?'btn btn_coin active':'btn btn_coin'} onClick={()=>{setToss("Tails");setCoin("Tails"); setButtoncoin(0)}}>
                                      <img src={tailImage} className='img-fluid coin_image' />
                                      <span>Tails</span>
                                          </button>
                              
                                          <button className={toss == "Heads"?'btn btn_coin active':'btn btn_coin'} onClick={()=>{setToss("Heads");setCoin("Heads");setButtoncoin(1)}}>
                                      <img src={headImage} className='img-fluid coin_image' />
                                      <span>Heads</span>
                                          </button>
                                      </div>

                 </>
                                      
                                      :
                                      <>
                                      <button className="btn btn_danger" 
                                      disabled={btnDisabled}
                                       onClick={tossCoin}
                                       >
                                       {btnDisabled}
                                       Toss Coin
                                     </button>

<div>
<p>
    Total Toss : <span>{totalflip}</span>
</p>

<p>
    Total Heads : <span>{headflip}</span>
</p>

<p>
    Total Tails : <span>{tailflip}</span>
</p>
</div>

</>
                                                      }

                                </div>
                                <div class="col-md-4 pt-4 pb-4">
                                    <div className="d-flex justify-content-between">
                                        <p className="fw-600">Wager</p> <p className="fw-600">0 wETH</p>
                                    </div>
                                    <div className="position-relative range1">
                                        <Slider
                                            min={0}
                                            max={500}
                                            value={volumess}
                                            onChange={handleOnChange1}
                                            tooltip={false}
                                        />
                                        <div class="input-group mb-3">
                                            <input type="number" class="form-control" id="example" aria-describedby="number" />
                                            <p className="mb-0 nochange">$ {counter}</p>
                                            <span class="input-group-text" id="basic-addon1"><BsArrowUpShort onClick={incrementCounter}  className="uparrow" /><BsArrowDownShort onClick={decrementCounter} className="downarrow" /><img className="mr-3" src={tablecoin} alt="images" /></span>
                                        </div>
                                    </div>

                                    <div className="d-flex mb-3">
                                        <button type="button" class="btn btn-dark fw-700">1/3</button>
                                        <button type="button" class="btn btn-dark mx-2 fw-700">2x</button>
                                        <button type="button" class="btn btn-dark fw-700">Max</button>
                                    </div>


                                    <label className="mb-2 fw-600">Multiple Bets <BsQuestionCircle className="que" /></label>
                                    <div class="position-relative mb-3">
                                        <input type="number" class="form-control" id="example" aria-describedby="number" />
                                        <p className="mb-0 nochange1">{volumes}</p>
                                        <Slider
                                            min={0}
                                            max={500}
                                            value={volumes}
                                            onChange={handleOnChange}
                                            tooltip={false}
                                        />
                                    </div>

                                    <div className="row pt-1">
                                        <div class="col-sm-6 col-md-6 mt-3">
                                            <label class="form-label fw-600">Max Payout</label>
                                            <div className="borders d-flex justify-content-between">
                                                <p className="mb-0">0</p>  <img src={tablecoin} alt="images" />
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-6 mt-3">
                                            <label class="form-label fw-600">Total Wager</label>
                                            <div className="borders d-flex justify-content-between">
                                                <p className="mb-0">0.001</p>  <img src={tablecoin} alt="images" />
                                            </div>
                                        </div>
                                    </div>

                                    <details>
                                        <summary class="form-label mt-3 fw-600">Advanced</summary>
                                        <p>
                                            {/* <div className="row pt-0">
                                                <div class="col-sm-6 col-md-6 mt-3">
                                                    <label class="form-label f-15 fw-600">Stop Gain <BsQuestionCircle className="que" /></label>
                                                    <div className="borders d-flex justify-content-between">
                                                        <p className="mb-0">0</p>  <img src={tablecoin} alt="images" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-6 col-md-6 mt-3">
                                                    <label class="form-label f-15 fw-600">Stop Loss <BsQuestionCircle className="que" /></label>
                                                    <div className="borders d-flex justify-content-between">
                                                        <p className="mb-0">0.001</p>  <img src={tablecoin} alt="images" />
                                                    </div>
                                                </div>
                                            </div> */}

                                        </p>
                                    </details>

                                    {localStorage.getItem("metamask") !== "true" ? 
                                    <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2" onClick={() => setWalletmodal(true)}
                                    // disabled
                                    >Connect Wallet</button> :
                                    <button type="button" class="btn w-100 btn-success earnbtn walletss mt-2"
                                    // disabled
                                    >APPROVE AND BET </button>}

                                </div>
                            </div>
                        </div>
                        <div className="bottomborder">
                            <div className="row align-items-center mx-2">
                                <div class="col-md-9 col-sm-7 mt-3 mt-sm-0">
                                    <p className="mb-0 fw-600 dicename" style={{ color: "white" }}><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="2" y="2" width="20" height="20" rx="2" stroke="#fff" stroke-width="2"></rect><circle cx="8" cy="7.828" r="2" transform="rotate(45 8 7.828)" fill="#fff"></circle><circle cx="12.243" cy="12.071" r="2" transform="rotate(45 12.243 12.071)" fill="#fff"></circle><circle cx="16.485" cy="16.314" r="2" transform="rotate(45 16.485 16.314)" fill="#fff"></circle></svg> Dice <span className="ms-2 f-14 play" onClick={() => setdicemodal(true)}>HOW TO PLAY?</span></p>
                                </div>
                                <div class="col-md-3 col-sm-5 mt-3 mt-sm-0">
                                    <div className="row align-items-center">
                                        <div class="col-lg-2 col-md-4 col-4 text-end"><img className="img-fluid volumeimg" src={volumeimg} alt="contest" /></div>
                                        <div class="col-lg-10 col-md-8 col-8 px-0">
                                            <Slider
                                                min={0}
                                                max={100}
                                                value={volumeup}
                                                onChange={handleOnChange2}
                                            // tooltip={false}
                                            /></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cardss pt-3 pb-4">
                            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-6">
                                {/* {gamecard.map((e, i) =>
                                    <div class="col mt-3">
                                        <Link to="/gamesdetail">
                                            <div class="card">
                                                <div className="card-img">
                                                    <img className="img-fluid" src={e.images} alt="contest" />
                                                </div>
                                                <div class="card-body">
                                                    <h6 class="card-title mb-0">{e.title}</h6>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )} */}
                            </div>
                        </div>

                        <div className="pt-3 pb-4">
                           
                            <Gametable />
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
}      