
import { useState, useEffect } from 'react';
// import './App.css';

import { getAccount } from '../hooks/useAccount';
import { CreateGameTransactionHooks } from '../hooks/UseTransaction';
import { getGameAllTransactionInfoHooks } from "../hooks/UseTransaction";
import { createOrderdetails, GamePrediction } from '../hooks/useBot';
import { checkAlreadyWon, DiceGameCondition } from '../hooks/useDice';
import toast from 'react-hot-toast';
import { style, iconTheme, position } from '../hooks/useToast';
import { getAllGameTransactionDetails, getGameSettingDetails, UpdateGameSettingBalancehooks } from '../hooks/UseUser';
import { toFixedNumber } from '../hooks/UseContract';
import winss from "../assets/winss.gif";
import winning from "../assets/winning.gif";
import down from "../assets/downs.gif";
import Swal from 'sweetalert2';





function Wheel(props) {

    console.log('propsdatadtasdfasdfasdfasfas', props)
    console.log('props?.gamepercentage', props?.gamepercentage)
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [wheelSelected, setWheelSelected] = useState(0);
    const [activeBg, setActiveBg] = useState("");
    const [activewheel, setActiveWheel] = useState(false)

    const [wheelbet, setWheelbet] = useState(false); // if  you select any kind of number this state become true
    console.log("wheelbetsdfasdf", wheelbet)
    const [color, setColor] = useState("");
    const [accountInfo, setAccountInfo] = useState("");
    const [transactionInfo, setTransactionInfo] = useState([]);
    const [onemonthwonInfo, setOneMonthWonInfo] = useState([]);
    const [oneweekwonInfo, setOneWeekWonInfo] = useState([]);
    const [check, setCheck] = useState(false);
    const [multipleBets, setMultipleBets] = useState(0)
    const [maxpayout, setMaxPayout] = useState(0);
    const [totalwager, setTotalWager] = useState(0);
    const [incrementbetcount, setIncrementBetCount] = useState(1);
    console.log("incrementbetcount", incrementbetcount)
    const [spinStatus, setSpinStatus] = useState("SPIN")
    const [roatedcount, setRoatedCount] = useState(0);
    const [wonorlossstatus, setWonorLossStatus] = useState(false);
    const [count, setCount] = useState(0)
    const [gamesetting, setGameSetting] = useState({})
    const [allgametransacdata, setAllGameTransData] = useState([]);
    const [resultarr, setResultArr] = useState([]);
    const [allowin, setAllowin] = useState(false);
    const [wheelarr, setWheelArr] = useState([]);
    const [randomarrnumber, setRandomArrNumber] = useState(0);
    const [neverwondata, setNeverWonData] = useState([])
    console.log("wheelSelected)_wheelSelected", props, wheelSelected, activeBg, props?.selectedWheel)


    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    // common useEffect
    useEffect(() => {
        (async () => {
            const walletAddress = getAccount();
            setAccountInfo(walletAddress);
            let transc = await getTransactionDetails(walletAddress);
            setCheck(false);
            console.log("oneweekwonInfoZ_OneMonthWonInfo", transc);
            if (transc?.oneweekdata?.length > 0) {
                OneWeekWonInfo(transc?.oneweekdata)
            }
            if (transc?.onemonthdata?.length > 0) {
                OneMonthWonInfo(transc?.onemonthdata)
            }

        })();
    }, [wheelSelected, gamesetting]);


    useEffect(() => {
        getGameSettings();
        getAllGameTransactionData();
    }, [props])


    useEffect(() => {
        console.log("wheelprops?.bet", count, props?.remainingbets, count, props?.bet, props?.bet && props?.disabledWheelbtn, props?.disabledWheelbtn, props?.selectedWheel)
        if (props?.bet && props?.disabledWheelbtn) {

            console.log("not disabled s 111 sssss", props?.remainingbets == 1, count, props?.multiplebets, props?.remainingbets);
            if (props?.remainingbets == 1) {
                console.log('hiiiiiiiiiiiiiiii 111 sssss', props?.selectedWheel)
                setBtnDisabled(false);
                setWheelbet(true);
                props?.selectedWheel == 0 ? setWheelSelected(0) : setWheelSelected(props?.selectedWheel)
            } else {
                let myTimeout = setTimeout(function () {
                    console.log('hiiiiiiiiiiiiiiii sssss', props?.selectedWheel)
                    setBtnDisabled(false);
                    setWheelbet(true);
                    props?.selectedWheel == 0 ? setWheelSelected(0) : setWheelSelected(props?.selectedWheel)
                }, 2000);

            }

        } else {
            console.log('wheeeeeeee', wheelbet, wheelSelected)
            setBtnDisabled(true);
            wheelbet ? setWheelbet(true) : setWheelbet(false)
            wheelbet ? wheelSelected ? setWheelSelected(wheelSelected) : props?.selectedWheel == 0 ? setWheelSelected(0) : setWheelSelected(props?.selectedWheel) : setWheelSelected(0)
        }
        setMultipleBets(props?.multiplebets);
        setMaxPayout(props?.maxpayout);
        setTotalWager(props.totalwager);

        console.log("wheelselecteed initial", props?.remainingbets)
        props.setInitialSelectedWheel(wheelSelected)
        let remainingrolls = (props?.multiplebets - props?.remainingbets) + 1;
        console.log("Remainingrools", remainingrolls, props?.multiplebets, props?.remainingbets)
        // setRoatedCount(remainingrolls);
        setIncrementBetCount(remainingrolls);
        if (props?.remainingbets == 1) {
            setRoatedCount(0)
        }
    }, [props, wheelSelected]);



    // useEffect(() => {
    //     if (props?.bet && props?.disabledWheelbtn) {
    //         setBtnDisabled(false);
    //         setWheelbet(true);
    //     } else {
    //         setBtnDisabled(true);
    //         wheelbet ? setWheelbet(true) : setWheelbet(false)
    //         wheelbet ? wheelSelected ? setWheelSelected(wheelSelected) : props?.selectedWheel == 0 ? setWheelSelected(0) : setWheelSelected(props?.selectedWheel) : setWheelSelected(0)
    //     }
    // }, [props, wheelSelected]);


    // useEffect(() => {
    //     setMultipleBets(props?.multiplebets);
    //     setMaxPayout(props?.maxpayout);
    //     setTotalWager(props.totalwager);
    //     props.setInitialSelectedWheel(wheelSelected);
    //     let remainingrolls = (props?.multiplebets - props?.remainingbets) + 1;
    //     console.log("Remainingrools", remainingrolls, props?.multiplebets, props?.remainingbets)
    //     setIncrementBetCount(remainingrolls);
    //     if (props?.remainingbets == 1) {
    //         setRoatedCount(0)
    //     }
    // }, [props, wheelSelected]);


    useEffect(() => {
        if (spinStatus == "WON" || spinStatus == "LOSE") {
            // let myTimeout = setTimeout(function () {
            setSpinStatus("SPIN")
            // }, 2000);
        }

    }, [spinStatus]);


    useEffect(() => {
        (async () => {
            try {
                console.log("wheelselected_data", props?.remainingbets, count, activewheel, wheelSelected, activeBg);
                if (activewheel) {
                    if (activeBg == "grey" || activeBg == "blue" || activeBg == "green" || activeBg == "red" || activeBg == "") {
                        console.log("enter this one", oneweekwonInfo, onemonthwonInfo, oneweekwonInfo?.length > 0 || onemonthwonInfo?.length > 0, oneweekwonInfo?.length,
                            onemonthwonInfo?.length)
                        setActiveWheel(false);
                        if (oneweekwonInfo?.length > 0 || onemonthwonInfo?.length > 0) {
                            let gamedata = {
                                predictWheel: wheelSelected,
                                greyWheel: activeBg == "grey" ? 1 : 0,
                                blueWheel: activeBg == "blue" ? 2 : 0,
                                greenWheel: activeBg == "green" ? 3 : 0,
                                redWheel: activeBg == "red" ? 4 : 0
                            }
                            console.log("gamedatasssss", gamedata)

                            let dicecondition = await DiceGameCondition(gamedata, props?.pathName, {}, props?.gameInfo?.GameCondition);
                            console.log("condition return", gamedata, dicecondition, dicecondition[0], props?.pathName);
                            let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
                            console.log("presresp data", presresp, dicecondition);

                            let checkalreadywon = await checkAlreadyWon(presresp, oneweekwonInfo, onemonthwonInfo, "Wheel");
                            console.log("checkalreadywon", checkalreadywon)

                            console.log("allow data juju", checkalreadywon?.res1, checkalreadywon?.res2, presresp, checkalreadywon);

                            if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
                                console.log("allow1 data juju")

                                let roatedCount = roatedcount - 1;
                                setRoatedCount(roatedCount);
                                playWheel(roatedCount)
                            } else {

                                if (props?.remainingbets == 1) {
                                    console.log("not disabled AAA 333 sssss")
                                    setBtnDisabled(false);
                                    FinishInterval();
                                } else {
                                    let myTimeout = setTimeout(function () {
                                        console.log("not disabled FASDASFD 333 sssss")
                                        setBtnDisabled(false);
                                    }, 3000);
                                    FinishInterval();
                                }
                            }
                        } else {
                            if (props?.remainingbets == 1) {
                                console.log("not disabled bbb 333 sssss")
                                setBtnDisabled(false);
                                FinishInterval();
                            } else {
                                let myTimeout = setTimeout(function () {
                                    console.log("not disabled CCCC 333 sssss")
                                    setBtnDisabled(false);
                                }, 3000);
                                FinishInterval();
                            }
                        }

                    }
                }

            } catch (e) {
                console.log('useEffect err wheelgame', e)
            }
        })();
    }, [activewheel]);


    useEffect(() => {
        async function fetchData() {
            let arr = [2, 3, 6, 48];
            var removedata = []
            console.log("useEffeonemonthwonInfo", onemonthwonInfo)
            if (oneweekwonInfo?.length > 0 || onemonthwonInfo?.length > 0) {
                for (let i = 0; i < onemonthwonInfo?.length; i++) {
                    console.log('onemonthdataaaaaa', onemonthwonInfo[i], arr)
                    for (let j = 0; j < arr?.length; j++) {
                        console.log('j conditions check', (onemonthwonInfo[i]['Multiplier']).split('x')[0], arr[j])
                        if (Number((onemonthwonInfo[i]['Multiplier']).split('x')[0]) == arr[j] && onemonthwonInfo[i]['allowwin'] == 0) {
                            let Indexposition = arr.indexOf(arr[j]);
                            console.log('IndexPosition', Indexposition, arr)
                            // At position 2, remove 1 item:
                            // arr.splice(Indexposition, 1);
                            // let l = arr.splice(Indexposition, 1);
                            removedata.push((arr.splice(Indexposition, 1)[0]))
                            // console.log('removeAllowinNumber', l);
                        }
                    }
                }
                console.log('arrforletj', arr)
                setResultArr(arr)
                setNeverWonData(removedata)
            } else {
                console.log('111 arrforletj', arr)
                setResultArr(arr)
                setNeverWonData([])
            }
            console.log('arr_data_result', arr, removedata)
        }
        fetchData()
    }, [props, wheelSelected]);


    useEffect(() => {
        if (neverwondata?.length >= 1) {
            let selectedNum = wheelSelected == 1 ? 2 : wheelSelected == 2 ? 3 : wheelSelected == 3 ? 6 : wheelSelected == 4 ? 48 : 0;
            let arr = [{ 2: [9892, 4707, 5614, 9232, 2507, 8786, 8872] },
            { 3: [9268, 3956, 1635, 8429, 4516, 2338] },
            { 6: [7895, 4010, 4934, 7253, 8376] },
            { 48: [2889] }];
            var resultdata = [];

            console.log('remove item data', neverwondata, selectedNum);
            let checkdata = true;
            for (let i = 0; i < neverwondata?.length; i++) {
                console.log('neverwondata[i]', neverwondata[i], selectedNum, neverwondata[i] == selectedNum)
                if (neverwondata[i] == selectedNum && checkdata) {
                    checkdata = false;
                }
            }
            // console.log("checkData_checkData", checkdata, wheelSelected)
            if (!checkdata) {
                for (let i = 0; i < arr?.length; i++) {
                    console.log('111 checkData_checkData', selectedNum, Number(Object.keys(arr[i])[0]));
                    if (selectedNum != Number(Object.keys(arr[i])[0])) {
                        for (let k = 0; k < Object.values(arr[i])[0]?.length; k++) {
                            resultdata.push(Object.values(arr[i])[0][k])
                        }
                    }
                }
            } else {
                for (let i = 0; i < arr?.length; i++) {
                    for (let k = 0; k < Object.values(arr[i])[0]?.length; k++) {
                        resultdata.push(Object.values(arr[i])[0][k])
                    }
                }
            }
            console.log('resultdata_resultdata', resultdata);
            setWheelArr(resultdata)
            setAllowin(false)
        } else {
            setAllowin(true)
        }
    }, [wheelSelected, neverwondata]);





    const getTransactionDetails = async (address) => {
        let data = {
            walletAddress: address,
            gameName: "Wheel"
        }
        let res = await getGameAllTransactionInfoHooks(data);
        console.log("respond_data", res, res?.data?.alltranscdata?.length)
        if (res?.status == 200) {
            if (res?.data?.alltranscdata?.length > 0) {
                //That is useless state , just store all transactiondata
                setTransactionInfo(res?.data?.alltranscdata)
            }
        }

        if (res?.data?.oneweekdata?.length > 0 || res?.data?.onemonthdata?.length > 0) {
            return {
                oneweekdata: res?.data?.oneweekdata?.length > 0 ? res?.data?.oneweekdata : [],
                onemonthdata: res?.data?.onemonthdata?.length > 0 ? res?.data?.onemonthdata : []
            }
        } else {
            return {
                oneweekdata: [],
                onemonthdata: []
            }
        }
    }

    // common
    const OneMonthWonInfo = async (transc) => {
        try {
            let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
            console.log("OneMonthWonInfo", res)
            setOneMonthWonInfo(res)
        } catch (e) {
            console.log("PredictionInfo_err", e)
        }
    }

    // common
    const OneWeekWonInfo = async (transc) => {
        try {
            let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
            console.log("OneWeekWonInfo", res)
            setOneWeekWonInfo(res);
        } catch (err) {
            console.log("OneWeekWonInfo_err", err)
        }
    }
    console.log('JSHFJSJKHFKSJ', activeBg, activewheel)

    const getGameSettings = async () => {
        try {
            let res = await getGameSettingDetails();
            console.log('getGameSettings', res?.data)
            if (res?.data?.status == true) {
                let result = res?.data?.data[0];
                if (result?.Status == "Active") {
                    setGameSetting(result)
                }
            }
        } catch (e) {
            console.log("gameGameSettings_err", e)
        }
    }

    const getAllGameTransactionData = async () => {
        try {
            let res = await getAllGameTransactionDetails();
            if (res?.data?.status == true) {
                setAllGameTransData(res?.data?.alltranscdata)
            }
        } catch (e) {
            console.log("getAllTransactionData_err", e)
        }
    }

    function recursive(min, max, oldRandomNumber) {
        const randomNumber = getRandomInt(min, max);
        console.log('randomNumber_data', oldRandomNumber, randomNumber, oldRandomNumber == randomNumber)
        if (oldRandomNumber == randomNumber) {
            console.log('enter this function again',min, max, oldRandomNumber, randomNumber)
            recursive(min, max, randomNumber);
        } else {
            console.log('else enter this function again', min, max,oldRandomNumber, randomNumber)
            setRandomArrNumber(randomNumber)
            return randomNumber;
        }

    }

    function playWheel(roatedcount) {
        try {
            let roatedCount = roatedcount + 1;
            console.log('roatedCount_roatedCount', roatedCount, incrementbetcount)
            if (allowin) {
                if (incrementbetcount <= multipleBets) {
                    setActiveBg("");

                    let container = document.querySelector(".container_wheel");
                    //    let btn = document.getElementById("spin");
                    setBtnDisabled(true);
                    setRoatedCount(roatedCount);

                    var x = 1024; //min value
                    var y = 9999; // max value
                    document.getElementById("spinresult").innerHTML = "";
                    var deg = Math.floor(Math.random() * (x - y)) + y;
                    console.log('dddddddddddddd', deg)
                    container.style.transform = "rotate(" + (deg) + "deg)";





                    setTimeout(function () {
                        console.log("insiede logiv")

                        // document.querySelector('#spinresult').textContent = `Congratulations, you've won ${getSpin()}!`;



                        console.log(wheelSelected, "wheelSelected");

                        while (deg >= 360) {
                            deg = deg % 360;
                            // deg = 115;
                        }

                        console.log(deg, "deg");


                        if ((deg >= 0 && deg < 3.7) ||
                            (deg >= 10.9 && deg < 18.1) ||
                            (deg >= 25.3 && deg < 32.5) ||
                            (deg >= 39.7 && deg < 46.9) ||
                            (deg >= 54.1 && deg < 61.3) ||
                            (deg >= 68.5 && deg < 75.7) ||
                            (deg >= 82.9 && deg < 90.1) ||
                            (deg >= 97.3 && deg < 104.5) ||
                            (deg >= 111.7 && deg < 118.9) ||
                            (deg >= 126.1 && deg < 133.3) ||
                            (deg >= 140.5 && deg < 147.7) ||
                            (deg >= 154.9 && deg < 162.1) ||
                            (deg >= 169.3 && deg < 176.5) ||
                            (deg >= 183.7 && deg < 190.9) ||
                            (deg >= 198.1 && deg < 205.3) ||
                            (deg >= 212.5 && deg < 219.7) ||
                            (deg >= 226.9 && deg < 234.1) ||
                            (deg >= 241.3 && deg < 248.5) ||
                            (deg >= 255.7 && deg < 262.9) ||
                            (deg >= 270.1 && deg < 277.3) ||
                            (deg >= 284.5 && deg < 291.7) ||
                            (deg >= 298.9 && deg < 306.1) ||
                            (deg >= 313.3 && deg < 320.5) ||
                            (deg >= 327.7 && deg < 334.9) ||
                            (deg >= 342.1 && deg < 349.3) ||
                            (deg >= 356.5 && deg <= 360) ||
                            (deg >= 0 && deg < 3.7)
                        ) {
                            console.log('enter this grey dddddddddddddd')
                            setColor("grey");
                            // if (wheelSelected == 1) {
                            setActiveBg("grey");
                            // }
                        }
                        else if ((deg >= 3.7 && deg < 10.9)) {
                            console.log('enter this red dddddddddddddd')
                            setColor("red");
                            // if (wheelSelected == 4) {
                            setActiveBg("red");
                            // }
                        }
                        else if ((deg >= 18.1 && deg < 25.3) ||
                            (deg >= 32.5 && deg < 39.7) ||
                            (deg >= 61.3 && deg < 68.5) ||
                            (deg >= 75.7 && deg < 82.9) ||
                            (deg >= 104.5 && deg < 111.7) ||
                            (deg >= 118.9 && deg < 126.1) ||
                            (deg >= 147.7 && deg < 154.9) ||
                            (deg >= 176.5 && deg < 183.7) ||
                            (deg >= 190.9 && deg < 198.1) ||
                            (deg >= 234.1 && deg < 241.3) ||
                            (deg >= 262.9 && deg < 270.1) ||
                            (deg >= 277.3 && deg < 284.5) ||
                            (deg >= 306.1 && deg < 313.3) ||
                            (deg >= 320.5 && deg < 327.7) ||
                            (deg >= 349.3 && deg < 356.5)
                        ) {
                            console.log('enter this blue dddddddddddddd')
                            setColor("blue");
                            // if (wheelSelected == 2) {
                            setActiveBg("blue");
                            // }

                        }
                        else if ((deg >= 46.9 && deg < 54.1) ||
                            (deg >= 90.1 && deg < 97.3) ||
                            (deg >= 133.3 && deg < 140.5) ||
                            (deg >= 162.1 && deg < 169.3) ||
                            (deg >= 205.3 && deg < 212.5) ||
                            (deg >= 219.7 && deg < 226.9) ||
                            (deg >= 248.5 && deg < 255.7) ||
                            (deg >= 291.7 && deg < 298.9) ||
                            (deg >= 334.9 && deg < 342.1)

                        ) {
                            console.log('enter this green dddddddddddddd')
                            setColor("green");
                            // if (wheelSelected == 3) {
                            setActiveBg("green");
                            // }

                        }
                        else {
                            setActiveBg("");
                        }

                        setActiveWheel(true)

                    }, 2000);


                    console.log(activeBg, "avtive bg")


                } else {
                    console.log('asdfasdf sssss')
                    setBtnDisabled(true);
                }
            } else {
                console.log('enterry this', incrementbetcount, multipleBets)
                if (incrementbetcount <= multipleBets) {
                    setActiveBg("");
                    let container = document.querySelector(".container_wheel");
                    setBtnDisabled(true);
                    setRoatedCount(roatedCount);
                    document.getElementById("spinresult").innerHTML = "";
                    let min = 1;
                    let max = wheelarr?.length - 1;
                    
                    let randNumber = recursive(min, max, randomarrnumber);
                    console.log("randNumber_data", randNumber)
                    let deg = wheelarr[randNumber];
                    console.log('output deg', randNumber, wheelarr, deg)
                    console.log('dddddddddddddd', deg)
                    container.style.transform = "rotate(" + (deg) + "deg)";

                    setTimeout(function () {
                        while (deg >= 360) {
                            deg = deg % 360;
                            // deg = 115;
                        }
                        console.log('deg_data', deg)
                        if ((deg >= 0 && deg < 3.7) ||
                            (deg >= 10.9 && deg < 18.1) ||
                            (deg >= 25.3 && deg < 32.5) ||
                            (deg >= 39.7 && deg < 46.9) ||
                            (deg >= 54.1 && deg < 61.3) ||
                            (deg >= 68.5 && deg < 75.7) ||
                            (deg >= 82.9 && deg < 90.1) ||
                            (deg >= 97.3 && deg < 104.5) ||
                            (deg >= 111.7 && deg < 118.9) ||
                            (deg >= 126.1 && deg < 133.3) ||
                            (deg >= 140.5 && deg < 147.7) ||
                            (deg >= 154.9 && deg < 162.1) ||
                            (deg >= 169.3 && deg < 176.5) ||
                            (deg >= 183.7 && deg < 190.9) ||
                            (deg >= 198.1 && deg < 205.3) ||
                            (deg >= 212.5 && deg < 219.7) ||
                            (deg >= 226.9 && deg < 234.1) ||
                            (deg >= 241.3 && deg < 248.5) ||
                            (deg >= 255.7 && deg < 262.9) ||
                            (deg >= 270.1 && deg < 277.3) ||
                            (deg >= 284.5 && deg < 291.7) ||
                            (deg >= 298.9 && deg < 306.1) ||
                            (deg >= 313.3 && deg < 320.5) ||
                            (deg >= 327.7 && deg < 334.9) ||
                            (deg >= 342.1 && deg < 349.3) ||
                            (deg >= 356.5 && deg <= 360) ||
                            (deg >= 0 && deg < 3.7)
                        ) {
                            console.log('enter this grey dddddddddddddd')
                            setColor("grey");
                            // if (wheelSelected == 1) {
                            setActiveBg("grey");
                            // }
                        }
                        else if ((deg >= 3.7 && deg < 10.9)) {
                            console.log('enter this red dddddddddddddd')
                            setColor("red");
                            // if (wheelSelected == 4) {
                            setActiveBg("red");
                            // }
                        }
                        else if ((deg >= 18.1 && deg < 25.3) ||
                            (deg >= 32.5 && deg < 39.7) ||
                            (deg >= 61.3 && deg < 68.5) ||
                            (deg >= 75.7 && deg < 82.9) ||
                            (deg >= 104.5 && deg < 111.7) ||
                            (deg >= 118.9 && deg < 126.1) ||
                            (deg >= 147.7 && deg < 154.9) ||
                            (deg >= 176.5 && deg < 183.7) ||
                            (deg >= 190.9 && deg < 198.1) ||
                            (deg >= 234.1 && deg < 241.3) ||
                            (deg >= 262.9 && deg < 270.1) ||
                            (deg >= 277.3 && deg < 284.5) ||
                            (deg >= 306.1 && deg < 313.3) ||
                            (deg >= 320.5 && deg < 327.7) ||
                            (deg >= 349.3 && deg < 356.5)
                        ) {
                            console.log('enter this blue dddddddddddddd')
                            setColor("blue");
                            // if (wheelSelected == 2) {
                            setActiveBg("blue");
                            // }

                        }
                        else if ((deg >= 46.9 && deg < 54.1) ||
                            (deg >= 90.1 && deg < 97.3) ||
                            (deg >= 133.3 && deg < 140.5) ||
                            (deg >= 162.1 && deg < 169.3) ||
                            (deg >= 205.3 && deg < 212.5) ||
                            (deg >= 219.7 && deg < 226.9) ||
                            (deg >= 248.5 && deg < 255.7) ||
                            (deg >= 291.7 && deg < 298.9) ||
                            (deg >= 334.9 && deg < 342.1)

                        ) {
                            console.log('enter this green dddddddddddddd')
                            setColor("green");
                            // if (wheelSelected == 3) {
                            setActiveBg("green");
                            // }

                        }
                        else {
                            setActiveBg("");
                        }

                        setActiveWheel(true)
                    }, 2000)
                } else {
                    console.log('else asdfasdf sssss')
                    setBtnDisabled(true);
                }

            }

        } catch (e) {
            console.log("playWheel_err", e)
        }

    }


    const FinishInterval = async () => {
        try {
            if (incrementbetcount <= multipleBets) {
                console.log("activeBg_activeBg", activeBg, activeBg == "blue", activeBg == "grey", activeBg == "red", activeBg == "green", color)
                let gamedata = {
                    predictWheel: wheelSelected,
                    greyWheel: activeBg == "grey" ? 1 : 0,
                    blueWheel: activeBg == "blue" ? 2 : 0,
                    greenWheel: activeBg == "green" ? 3 : 0,
                    redWheel: activeBg == "red" ? 4 : 0
                }
                console.log("FinishInterval_gamedatas wheel game", gamedata);
                let dicecondition = await DiceGameCondition(gamedata, props?.pathName, {}, props?.gameInfo?.GameCondition);
                console.log("result condition return coinflip game", dicecondition, transactionInfo, props?.wager, props?.multiplebets, totalwager)
                let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
                console.log("final presrep coinflip game", presresp, dicecondition);

                if (presresp) {
                    let data = {}
                    if (presresp?.isWin == true) {
                        props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
                            if (String(Object.keys(val)[0]) == String(presresp?.Multiplier)) {
                                let multiplier = presresp?.Multiplier.split('x')[0];
                                let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
                                let amount = Number(props?.calculatewager) + Number(wonpercentage);
                                console.log('multipliet data', multiplier, Object.values(val)[0], wonpercentage, amount)
                                if (presresp?.isWin == true) {
                                    data = {
                                        walletAddress: accountInfo,
                                        transaction: presresp,
                                        gameName: props?.pathName,
                                        Bets: multipleBets,
                                        Wager: props?.wager,
                                        CalculateWager: props?.calculatewager,
                                        TotalWager: props?.totalwager,
                                        Amount: amount,
                                        OrderId: props?.usergamedetails?._id
                                    }
                                } else if (presresp?.isWin == false) {
                                    data = {
                                        walletAddress: accountInfo,
                                        transaction: presresp,
                                        gameName: props?.pathName,
                                        Bets: multipleBets,
                                        Wager: props?.wager,
                                        CalculateWager: props?.calculatewager,
                                        TotalWager: props?.totalwager,
                                        Amount: presresp?.maxpayout,
                                        OrderId: props?.usergamedetails?._id
                                    }
                                }

                            } else if ("0x" == String(presresp?.Multiplier)) {
                                data = {
                                    walletAddress: accountInfo,
                                    transaction: presresp,
                                    gameName: props?.pathName,
                                    Bets: multipleBets,
                                    Wager: props?.wager,
                                    CalculateWager: props?.calculatewager,
                                    TotalWager: props?.totalwager,
                                    Amount: presresp?.maxpayout,
                                    OrderId: props?.usergamedetails?._id
                                }
                            }
                        })

                    } else if (presresp?.isWin == false) {
                        if ("0x" == String(presresp?.Multiplier)) {
                            data = {
                                walletAddress: accountInfo,
                                transaction: presresp,
                                gameName: props?.pathName,
                                Bets: multipleBets,
                                Wager: props?.wager,
                                CalculateWager: props?.calculatewager,
                                TotalWager: props?.totalwager,
                                Amount: presresp?.maxpayout,
                                OrderId: props?.usergamedetails?._id
                            }
                        }
                    }


                    let lossamount = 0, gainamount = 0;
                    var resultamount = 0;

                    props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
                        console.log('valllgamepercentage', val, Object.keys(val), Object.values(val)[0], String(Object.keys(val)) == String(presresp?.Multiplier), String(Object.keys(val)[0]) == String(presresp?.Multiplier));
                        if (String(Object.keys(val)[0]) == String(presresp?.Multiplier)) {
                            let multiplier = presresp?.Multiplier.split('x')[0];
                            let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
                            let amount = Number(props?.calculatewager) + Number(wonpercentage);
                            console.log('multipliet data', Object.values(val)[0], wonpercentage, amount)
                            if (presresp?.isWin == false) {
                                lossamount = presresp?.maxpayout;
                                resultamount = presresp?.maxpayout;
                            } else {
                                gainamount = amount;
                                resultamount = amount;
                            }
                        } else if ("0x" == String(presresp?.Multiplier)) {
                            if (presresp?.isWin == false) {
                                lossamount = presresp?.maxpayout;
                                resultamount = presresp?.maxpayout;
                            }
                        }

                    })
                    console.log("lossamount, gainamount", lossamount, gainamount, data)


                    let res = await CreateGameTransactionHooks(data);
                    console.log("orderdata ruru1111", res);

                    if (res?.status == 200) {
                        let createres = await createOrderdetails(props?.pathName, props?.wager, props?.remainingbets, props?.betamount, props.selectedCoin, accountInfo, props.stopgain, props.stoploss, gainamount, lossamount, props?.maxpayout, props?.calculatewager, props?.totalwager, '', '');
                        console.log('createrescreateres', createres, props?.remainingbets)
                        let result = createres?.data?.data;
                        if (createres?.status == 200) {
                            if (createres?.data?.status == true) {
                                console.log("update balance", result, result?.RemainingBets == 0)
                                if (result?.RemainingBets == 0) {
                                    let reqData = { _id: result?._id, walletAddress: accountInfo }
                                    let res = await UpdateGameSettingBalancehooks(reqData);
                                    console.log("update balance 212", res)
                                    if (res?.data?.status == true) {
                                        setTimeout(() => {
                                            props?.setFeeData()
                                        }, 3000)
                                    }
                                }
                            }
                            setCheck(true);
                            props?.checkOrder()
                            props.onCheck()
                            console.log("condition data", (createres?.data?.data?.StopGain != 0 || createres?.data?.data?.StopLoss != 0) && (((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopGain)) / 100) <= (createres?.data?.data?.GainAmount) || ((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopLoss)) / 100) <= (createres?.data?.data?.LossAmount)))
                            console.log("stopgaindata", createres?.data?.data, createres?.data?.data?.StopGain != 0 && createres?.data?.data?.StopLoss != 0)
                            if (props?.remainingbets == 1) {
                                console.log("remainingbets createrescreateres")
                                setTimeout(function () {
                                    setWheelSelected(0)
                                    setWheelbet(false)
                                }, 2000);
                            } else if ((createres?.data?.data?.StopGain != 0 || createres?.data?.data?.StopLoss != 0) && (((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopGain)) / 100) <= (createres?.data?.data?.GainAmount) || ((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopLoss)) / 100) <= (createres?.data?.data?.LossAmount))) {
                                console.log("df createrescreateres")
                                setTimeout(function () {
                                    setWheelSelected(0)
                                    setWheelbet(false)
                                }, 4000);
                            }
                        }
                    }

                }


                if (presresp?.isWin == false) {
                    let resultamounts =
                    Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
                      Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
                        Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                          Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                            Number(toFixedNumber(Number(resultamount))).toFixed(11);
                    setTimeout(function () {
                        // toast.error(incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + (resultamounts), {
                        //     position: position.position,
                        //     style: style,
                        //     iconTheme: iconTheme,
                        // })
                        Swal.fire({
                            title: "You Lose!",
                            text: incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + (resultamounts),
                            // imageUrl: "../assets/flip1.png",
                            iconHtml: `<img src=${down}>`,
                            timer: 4200,
                            imageWidth: 400,
                            imageHeight: 200,
                            imageAlt: "Custom image",
                          });  
                    }, 2000);
                    setTimeout(function () {
                        setSpinStatus("LOSE")
                    }, 1000);


                } else {
                    let resultamounts =
                    Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
                      Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
                        Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                          Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                            Number(toFixedNumber(Number(resultamount))).toFixed(11);
                    setTimeout(function () {
                        // toast.success(incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + (resultamounts), {
                        //     position: position.position,
                        //     style: style,
                        //     iconTheme: iconTheme,
                        // })
                        Swal.fire({
                            title: "Success!",
                            text: incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + (resultamounts),
                            // imageUrl: "../assets/flip1.png",
                            iconHtml: `<img src=${winning}>`,
                            timer: 4200,
                            backdrop: `
                            rgba(0,0,123,0.4)
                            url("${winss}")
                            center top            
                            no-repeat
                          `,
                            imageWidth: 400,
                            imageHeight: 200,
                            imageAlt: "Custom image"
                          });
                    }, 2000)
                    setTimeout(function () {
                        setSpinStatus("WON")
                    }, 1000);

                }
                let sum = incrementbetcount + 1;
                setIncrementBetCount(sum);
                let counts = count + 1;
                setCount(counts)
            }

        } catch (e) {
            console.log("FinishInterval_errr", e)
        }
    }



    return (
        <div>
            <div className='containersss'>
                <div className='container game_container pos wheel_contain'>
                <span className="squaress wheelside square1"></span>
                <span className="squaress wheelside square2"></span>
                <span className="squaress wheelside square3"></span>
                <span className="squaress wheelside square4"></span>
                <span className="squaress wheelside square5"></span>
                <span className="squaress wheelside square6"></span>
                <span className="squaress wheelside square7"></span>
                <h2 className="whtclr poppins text-center mb-5">Wheel</h2>
                    <div className='flex_row_badge'>
                        <div className={wheelSelected == 1 ? 'badge bange_grey active' : 'badge bange_grey'}>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                2x
                            </span>
                        </div>
                        <div className={wheelSelected == 2 ? 'badge bange_blue active' : 'badge bange_blue'}>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                3x
                            </span>
                        </div>
                        {/* <div className={wheelSelected == 3 ? 'badge bange_green active' : 'badge bange_green'}>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                6x
                            </span>
                        </div> */}
                        {/* <div className={wheelSelected == 1 ? 'badge bange_grey active' : 'badge bange_grey'}>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                2x
                            </span>
                        </div> */}
                        <div className={wheelSelected == 3 ? 'badge bange_green active' : 'badge bange_green'}>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                6x
                            </span>
                        </div>
                        <div className={wheelSelected == 4 ? 'badge bange_red active' : 'badge bange_red'}>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                48x
                            </span>
                        </div>
                        {/* <div className='badge bange_grey'>
                            <div className='bet_cirle'>

                            </div>
                            <span>
                                3x
                            </span>
                        </div> */}
                    </div>

                    <div className='wheel_div'>
                        {console.log("wheelbet_activebg", btnDisabled, wheelSelected, activeBg, wheelbet,)}
                        {
                            wheelbet ?
                                <>{


                                    spinStatus == "SPIN" ? <button id="spin" className='spin_rollbtn' disabled={btnDisabled}
                                        onClick={() => playWheel(roatedcount)}>{spinStatus}</button> : spinStatus == "Win" ?
                                        <div className={'text_div_wheel_win activebg_' + activeBg}> <p className='activebg_text'>You Won!</p> </div>
                                        : spinStatus == "LOSE" && <div className={'text_div_wheel_win activebg_red'}> <p className='activebg_text'>You lose!</p> </div>
                                }</>

                                : <div className='text_div_wheel'>
                                    <p>Bet to start the game</p> </div>
                            // wheelbet && activeBg == "" && wonorlossstatus ?
                            //     <button id="spin" className='spin_rollbtn' disabled={btnDisabled}
                            //         onClick={() => playWheel(roatedcount)}>{spinStatus}</button>
                            //     :
                            //     // wheelbet && activeBg != "" && (activeBg == (wheelSelected == 1 ? "grey" : wheelSelected == 2 ? "blue" : wheelSelected == 3 ? "green" : "red")) ?
                            //     //     <div className={'text_div_wheel_win activebg_' + activeBg}>
                            //     //         <>{setTimeout(() => {
                            //     //             { setActiveBg("") }
                            //     //         })}</>
                            //     //         {setActiveBg("")}
                            //     //         <p className='activebg_text'>You Won!</p>
                            //     //     </div>
                            //     //     :
                            //        (!wonorlossstatus ? <div className='text_div_wheel'>
                            //             <p>Bet to start the game</p> </div> : <> {``}</>
                            //         )

                        }



                        <div id="wheel_cursor"></div>
                        <span class="arrow" ></span>
                        <div class="container_wheel">
                            {/* <span className='box1'> */}
                            <div class="one grey">1</div>
                            <div class="two blue">2</div>
                            <div class="three grey">3</div>
                            <div class="four green">4</div>
                            <div class="five grey">5</div>
                            <div class="six blue">6</div>
                            <div class="seven grey">7</div>
                            <div class="eight blue">8</div>
                            <div class="nine grey">9</div>
                            <div class="ten green">10</div>
                            <div class="eleven grey">11</div>
                            <div class="twelve blue">12</div>
                            <div class="thirteen grey">13</div>
                            <div class="fourteen blue">14</div>
                            <div class="fifteen grey">15</div>
                            <div class="sixteen green">16</div>
                            <div class="seventeen grey">17</div>
                            <div class="eighteen blue">18</div>
                            <div class="nineteen grey">19</div>
                            <div class="twenty green">20</div>
                            <div class="twentyone grey">21</div>
                            <div class="twentytwo green">22</div>
                            <div class="twentythree grey">23</div>
                            <div class="twentyfour blue">24</div>
                            <div class="twentyfive grey">25</div>
                            {/* </span> */}
                            {/* <span className='box2'> */}
                            <div class="twentysix blue">26</div>
                            <div class="twentyseven grey">27</div>
                            <div class="twentyeight green">28</div>
                            <div class="twentynine grey">29</div>
                            <div class="thirty blue">30</div>
                            <div class="thirtyone grey">31</div>
                            <div class="thirtytwo green">32</div>
                            <div class="thirtythree grey">33</div>
                            <div class="thirtyfour blue">34</div>
                            <div class="thirtyfive grey">35</div>
                            <div class="thirtysix blue">36</div>
                            <div class="thirtyseven grey">37</div>
                            <div class="thirtyeight green">38</div>
                            <div class="thirtynine grey">39</div>
                            <div class="fourty blue">40</div>
                            <div class="fourtyone grey">41</div>
                            <div class="fourtytwo blue">42</div>
                            <div class="fourtythree grey">43</div>
                            <div class="fourtyfour green">44</div>
                            <div class="fourtyfive grey">45</div>
                            <div class="fourtysix blue">46</div>
                            <div class="fourtyseven grey">47</div>
                            <div class="fourtyeight blue">48</div>
                            <div class="fourtynine grey">49</div>
                            <div class="fifty red">50</div>
                            {/* </span> */}

                        </div>
                    </div>
                    <div className='flex_row_2'>
                        <div className={wheelSelected == 1 ? "card card_wheel selected_card_wheel" : "card card_wheel"} onClick={() => { setWheelSelected(1); setWheelbet(true); }}>
                            <div className='card_header_wheel card_grey_wheel'>
                                <div className='wheel_bet_title'>
                                    <svg width="6" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.581 3.409a.724.724 0 0 1-1.024 0l-.936-.937v4.77a.724.724 0 0 1-1.449 0v-4.77l-.936.937A.724.724 0 1 1 .212 2.385L2.385.212a.724.724 0 0 1 1.024 0L5.58 2.385a.724.724 0 0 1 0 1.024Z" fill="#fff"></path></svg>
                                    <span className='wheel_multiplier_text'>2x</span>
                                </div>

                            </div>
                            <div className='card_body_wheel'>
                                <div className='card_body_flex'>
                                    <div>
                                        {/* <svg width="21" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 3.5c0 .73.223 1.408.606 1.969A5.787 5.787 0 0 0 .68 9.836l-.278 1.67A3 3 0 0 0 3.36 15h7.278a3 3 0 0 0 2.96-3.493l-.28-1.671A5.817 5.817 0 0 0 13.117 9h2.429c1.377 0 2.54.914 2.777 2.146l.145.629A1 1 0 0 1 17.493 13h-.67a1 1 0 1 0 0 2h.67a3 3 0 0 0 2.923-3.675l-.137-.594c-.34-1.764-1.741-3.138-3.508-3.58a3 3 0 1 0-5.77-1.054 5.792 5.792 0 0 0-1.107-.628A3.5 3.5 0 1 0 3.5 3.5ZM14 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM8.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm-5.847 6.664A3.787 3.787 0 0 1 6.388 7H7.611a3.787 3.787 0 0 1 3.736 3.164l.278 1.672A1 1 0 0 1 10.64 13H3.361a1 1 0 0 1-.987-1.164l.279-1.672Z" fill="#68758C"></path></svg>
                                        <span>0</span> */}
                                    </div>
                                    <div>
                                        {/* <span>$0</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={wheelSelected == 2 ? "card card_wheel selected_card_wheel" : "card card_wheel"} onClick={() => { setWheelSelected(2); setWheelbet(true); }}>
                            <div className='card_header_wheel card_blue_wheel'>
                                <div className='wheel_bet_title'>
                                    <svg width="6" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.581 3.409a.724.724 0 0 1-1.024 0l-.936-.937v4.77a.724.724 0 0 1-1.449 0v-4.77l-.936.937A.724.724 0 1 1 .212 2.385L2.385.212a.724.724 0 0 1 1.024 0L5.58 2.385a.724.724 0 0 1 0 1.024Z" fill="#fff"></path></svg>
                                    <span className='wheel_multiplier_text'>3x</span>
                                </div>

                            </div>
                            <div className='card_body_wheel'>
                                <div className='card_body_flex'>
                                    <div>
                                        {/* <svg width="21" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 3.5c0 .73.223 1.408.606 1.969A5.787 5.787 0 0 0 .68 9.836l-.278 1.67A3 3 0 0 0 3.36 15h7.278a3 3 0 0 0 2.96-3.493l-.28-1.671A5.817 5.817 0 0 0 13.117 9h2.429c1.377 0 2.54.914 2.777 2.146l.145.629A1 1 0 0 1 17.493 13h-.67a1 1 0 1 0 0 2h.67a3 3 0 0 0 2.923-3.675l-.137-.594c-.34-1.764-1.741-3.138-3.508-3.58a3 3 0 1 0-5.77-1.054 5.792 5.792 0 0 0-1.107-.628A3.5 3.5 0 1 0 3.5 3.5ZM14 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM8.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm-5.847 6.664A3.787 3.787 0 0 1 6.388 7H7.611a3.787 3.787 0 0 1 3.736 3.164l.278 1.672A1 1 0 0 1 10.64 13H3.361a1 1 0 0 1-.987-1.164l.279-1.672Z" fill="#68758C"></path></svg>
                                        <span>0</span> */}
                                    </div>
                                    <div>
                                        {/* <span>$0</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={wheelSelected == 3 ? "card card_wheel selected_card_wheel" : "card card_wheel"} onClick={() => { setWheelSelected(3); setWheelbet(true); }}>
                            <div className='card_header_wheel card_green_wheel'>
                                <div className='wheel_bet_title'>
                                    <svg width="6" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.581 3.409a.724.724 0 0 1-1.024 0l-.936-.937v4.77a.724.724 0 0 1-1.449 0v-4.77l-.936.937A.724.724 0 1 1 .212 2.385L2.385.212a.724.724 0 0 1 1.024 0L5.58 2.385a.724.724 0 0 1 0 1.024Z" fill="#fff"></path></svg>
                                    <span className='wheel_multiplier_text'>6x</span>
                                </div>

                            </div>
                            <div className='card_body_wheel'>
                                <div className='card_body_flex'>
                                    <div>
                                        {/* <svg width="21" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 3.5c0 .73.223 1.408.606 1.969A5.787 5.787 0 0 0 .68 9.836l-.278 1.67A3 3 0 0 0 3.36 15h7.278a3 3 0 0 0 2.96-3.493l-.28-1.671A5.817 5.817 0 0 0 13.117 9h2.429c1.377 0 2.54.914 2.777 2.146l.145.629A1 1 0 0 1 17.493 13h-.67a1 1 0 1 0 0 2h.67a3 3 0 0 0 2.923-3.675l-.137-.594c-.34-1.764-1.741-3.138-3.508-3.58a3 3 0 1 0-5.77-1.054 5.792 5.792 0 0 0-1.107-.628A3.5 3.5 0 1 0 3.5 3.5ZM14 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM8.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm-5.847 6.664A3.787 3.787 0 0 1 6.388 7H7.611a3.787 3.787 0 0 1 3.736 3.164l.278 1.672A1 1 0 0 1 10.64 13H3.361a1 1 0 0 1-.987-1.164l.279-1.672Z" fill="#68758C"></path></svg>
                                        <span>0</span> */}
                                    </div>
                                    <div>
                                        {/* <span>$0</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={wheelSelected == 4 ? "card card_wheel selected_card_wheel" : "card card_wheel"} onClick={() => { setWheelSelected(4); setWheelbet(true); }}>
                            <div className='card_header_wheel card_red_wheel'>
                                <div className='wheel_bet_title'>
                                    <svg width="6" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.581 3.409a.724.724 0 0 1-1.024 0l-.936-.937v4.77a.724.724 0 0 1-1.449 0v-4.77l-.936.937A.724.724 0 1 1 .212 2.385L2.385.212a.724.724 0 0 1 1.024 0L5.58 2.385a.724.724 0 0 1 0 1.024Z" fill="#fff"></path></svg>
                                    <span className='wheel_multiplier_text'>48x</span>
                                </div>

                            </div>
                            <div className='card_body_wheel'>
                                <div className='card_body_flex'>
                                    <div>
                                        {/* <svg width="21" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.5 3.5c0 .73.223 1.408.606 1.969A5.787 5.787 0 0 0 .68 9.836l-.278 1.67A3 3 0 0 0 3.36 15h7.278a3 3 0 0 0 2.96-3.493l-.28-1.671A5.817 5.817 0 0 0 13.117 9h2.429c1.377 0 2.54.914 2.777 2.146l.145.629A1 1 0 0 1 17.493 13h-.67a1 1 0 1 0 0 2h.67a3 3 0 0 0 2.923-3.675l-.137-.594c-.34-1.764-1.741-3.138-3.508-3.58a3 3 0 1 0-5.77-1.054 5.792 5.792 0 0 0-1.107-.628A3.5 3.5 0 1 0 3.5 3.5ZM14 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM8.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm-5.847 6.664A3.787 3.787 0 0 1 6.388 7H7.611a3.787 3.787 0 0 1 3.736 3.164l.278 1.672A1 1 0 0 1 10.64 13H3.361a1 1 0 0 1-.987-1.164l.279-1.672Z" fill="#68758C"></path></svg>
                                        <span>0</span> */}
                                    </div>
                                    <div>
                                        {/* <span>$0</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>


            <div className='container game_container dice_btn coin_flex'>
                <div>
                    <p id="spinresult"></p>

                </div>
            </div>


        </div>
    );
}

export default Wheel;
