import toast from "react-hot-toast";
import { createOrderDetailsHooks, UserGameDetailsUpdateHooks } from "./UseUser";
import { style, iconTheme, position } from '../hooks/useToast';
import { toFixedNumber } from "./UseContract";
import winss from "../assets/winss.gif";
import winning from "../assets/winning.gif";
import down from "../assets/downs.gif";
import Swal from 'sweetalert2';

export const GamePrediction = async (condition, transactiondetails, wager, multiplebets, totalwager, gameName, admincondition, allgametrans, maxpayout) => {

    try {
        let arr = [];
        let result = [];
        let output;
        console.log("transaction data tarasdfasdfa", gameName, transactiondetails, transactiondetails.length > 0);
        console.log("select, condition, gard", "-----", condition, "------", wager, multiplebets, "totalwager", totalwager);
        let sum = 0, sum1 = 0, sum2 = 0, sum3 = 0, sum4 = 0, sum5 = 0, sum6 = 0, sum7 = 0, sum8 = 0, sum9 = 0;
        console.log('susdfsdfsdfsdf', sum)
        //Totally useless the allgametrans
        console.log('allgametrans_data', allgametrans)

        //useless these this
        if (transactiondetails?.length > 0) {
            transactiondetails.map((val, i) => {
                if (gameName == "Limbo") {
                    if (val?._id?.split('x')[0] != 0 && (1 <= val?._id?.split('x')[0] && 10 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (1 <= Number((val._id)?.split('x')[0]) && 10 >= Number((val._id)?.split('x')[0]))) {
                            sum = sum + val.count
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (11 <= val?._id?.split('x')[0] && 20 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (11 <= Number((val._id)?.split('x')[0]) && 20 >= Number((val._id)?.split('x')[0]))) {
                            sum1 = sum1 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (21 <= val?._id?.split('x')[0] && 30 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (21 <= Number((val._id)?.split('x')[0]) && 30 >= Number((val._id)?.split('x')[0]))) {
                            sum2 = sum2 + val.count
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (31 <= val?._id?.split('x')[0] && 40 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (31 <= Number((val._id)?.split('x')[0]) && 40 >= Number((val._id)?.split('x')[0]))) {
                            sum3 = sum3 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (41 <= val?._id?.split('x')[0] && 50 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (41 <= Number((val._id)?.split('x')[0]) && 50 >= Number((val._id)?.split('x')[0]))) {
                            sum4 = sum4 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (51 <= val?._id?.split('x')[0] && 60 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (51 <= Number((val._id)?.split('x')[0]) && 60 >= Number((val._id)?.split('x')[0]))) {
                            sum5 = sum5 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (61 <= val?._id?.split('x')[0] && 70 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (61 <= Number((val._id)?.split('x')[0]) && 70 >= Number((val._id)?.split('x')[0]))) {
                            sum6 = sum6 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (71 <= val?._id?.split('x')[0] && 80 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (71 <= Number((val._id)?.split('x')[0]) && 80 >= Number((val._id)?.split('x')[0]))) {
                            sum7 = sum7 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (81 <= val?._id?.split('x')[0] && 90 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (81 <= Number((val._id)?.split('x')[0]) && 90 >= Number((val._id)?.split('x')[0]))) {
                            sum8 = sum8 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (91 <= val?._id?.split('x')[0] && 100 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (91 <= Number((val._id)?.split('x')[0]) && 100 >= Number((val._id)?.split('x')[0]))) {
                            sum9 = sum9 + val.count;
                        }
                    }
                } else if (gameName == "Moon") {
                    console.log('moon vals_data', val?._id, val)
                    if (val?._id?.split('x')[0] != 0 && (1 <= val?._id?.split('x')[0] && 30 >= val?._id?.split('x')[0])) {
                        sum = sum + val.count
                    } else if (val?._id?.split('x')[0] != 0 && (31 <= val?._id?.split('x')[0] && 60 >= val?._id?.split('x')[0])) {
                        sum1 = sum1 + val.count
                    } else if (val?._id?.split('x')[0] != 0 && (61 <= val?._id?.split('x')[0] && 100 >= val?._id?.split('x')[0])) {
                        sum2 = sum2 + val.count
                    }
                } else if (gameName == "Range") {
                    if (val?._id?.split('x')[0] != 0 && (5 <= val?._id?.split('x')[0] && 10 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (5 <= Number((val._id)?.split('x')[0]) && 10 >= Number((val._id)?.split('x')[0]))) { 
                            sum = sum + val.count
                        }
                    } else if(val?._id?.split('x')[0] != 0 && (11 <= val?._id?.split('x')[0] && 20 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (11 <= Number((val._id)?.split('x')[0]) && 20 >= Number((val._id)?.split('x')[0]))) {
                            sum1 = sum1 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (31 <= val?._id?.split('x')[0] && 40 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (31 <= Number((val._id)?.split('x')[0]) && 40 >= Number((val._id)?.split('x')[0]))) {
                            sum3 = sum3 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (41 <= val?._id?.split('x')[0] && 50 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (41 <= Number((val._id)?.split('x')[0]) && 50 >= Number((val._id)?.split('x')[0]))) {
                            sum4 = sum4 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (51 <= val?._id?.split('x')[0] && 60 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (51 <= Number((val._id)?.split('x')[0]) && 60 >= Number((val._id)?.split('x')[0]))) {
                            sum5 = sum5 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (61 <= val?._id?.split('x')[0] && 70 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (61 <= Number((val._id)?.split('x')[0]) && 70 >= Number((val._id)?.split('x')[0]))) {
                            sum6 = sum6 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (71 <= val?._id?.split('x')[0] && 80 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (71 <= Number((val._id)?.split('x')[0]) && 80 >= Number((val._id)?.split('x')[0]))) {
                            sum7 = sum7 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (81 <= val?._id?.split('x')[0] && 90 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (81 <= Number((val._id)?.split('x')[0]) && 90 >= Number((val._id)?.split('x')[0]))) {
                            sum8 = sum8 + val.count;
                        }
                    } else if (val?._id?.split('x')[0] != 0 && (91 <= val?._id?.split('x')[0] && 95 >= val?._id?.split('x')[0])) {
                        if (val.isWin && (91 <= Number((val._id)?.split('x')[0]) && 95 >= Number((val._id)?.split('x')[0]))) {
                            sum9 = sum9 + val.count;
                        }
                    } 
                }
            })
        }
        if (transactiondetails.length > 0) {
            console.log('enter this map');
            // transactiondetails.map((item))  
            transactiondetails.map((val, i) => {
                let res;
                if (gameName == "Dice") {
                    console.log("asdfasdf", (Object.values(admincondition)?.length > 0), Object.values(admincondition)?.length)
                    if (Object.values(admincondition)?.length > 0) {
                        if (admincondition.Balance <= 0) {
                            res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                            result.push(res)
                        } else {
                            console.log('dice data val', val)
                            if (val.isWin && val._id == "8x") {
                                if (val.count <= 1) {
                                    // They all (if) condition did not fail 
                                    // because that condition is true means : allowwin scenario is 0 , 
                                    // In that case (checkAlreadywon fun) call and that allowwin condition check and they return true means , 
                                    // they again roll the dice even you should be failed.  
                                    // when you did add a transaction (else)
                                    // They count will be more 
                                    //example : your allowin(val.count) 2 times means , that scenario (if) condition will be handled, but you more than 1 transaction will be add, In that case they if condition will be failed, So else will come , and the allowin is again set 0.
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val.isWin && val._id == "4x") {
                                if (val.count <= 2) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 3 - val.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val.isWin && val._id == "2x") {
                                if (val.count <= 4) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 4 - val.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            }
                        }
                    } else {
                        if (val.isWin && val._id == "8x") {
                            if (val.count <= 1) {
                                // They all (if) condition did not fail 
                                // because that condition is true means : allowwin scenario is 0 , 
                                // In that case (checkAlreadywon fun) call and that allowwin condition check and they return true means , 
                                // they again roll the dice even you should be failed.  

                                // when you did add a transaction (else)
                                // They count will be more 
                                //example : your allowin(val.count) 2 times means , that scenario (if) condition will be handled, but you more than 1 transaction will be add, In that case they if condition will be failed, So else will come , and the allowin is again set 0.
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val.isWin && val._id == "4x") {
                            if (val.count <= 2) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 3 - val.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val.isWin && val._id == "2x") {
                            if (val.count <= 4) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 4 - val.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        }
                    }

                    // else if(val.isWin) {
                    //     if(val.WithdrawPerMonth > val.totalMaxPayout) {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     }
                    // }
                } else if (gameName == "CoinFlip") {
                    console.log("asdfasdf", (Object.values(admincondition)?.length > 0), Object.values(admincondition)?.length)
                    if (Object.values(admincondition)?.length > 0) {
                        if (admincondition.Balance <= 0) {
                            res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                            result.push(res)
                        } else {
                            if (val.isWin && val._id == "4x") {
                                if (val.count <= 3) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 4 - val.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            }
                        }
                    } else {
                        if (val.isWin && val._id == "4x") {
                            if (val.count <= 3) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 4 - val.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        }
                    }

                } else if (gameName == "Wheel") {
                    console.log("asdfasdf", (Object.values(admincondition)?.length > 0), Object.values(admincondition)?.length)
                    if (Object.values(admincondition)?.length > 0) {
                        if (admincondition.Balance <= 0) {
                            res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                            result.push(res)
                        } else {
                            console.log('wheel valdata', val)
                            if (val.isWin && val._id == "48x") {
                                if (val.count <= 1) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val.isWin && val._id == "6x") {
                                if (val.count <= 2) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val.isWin && val._id == "3x") {
                                if (val.count <= 3) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 3 - val.count };
                                    result.push(res)
                                }
                            } else if (val.isWin && val._id == "2x") {
                                if (val.count <= 4) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 4 - val.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            }
                        }
                    } else {
                        if (val.isWin && val._id == "48x") {
                            if (val.count <= 1) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val.isWin && val._id == "6x") {
                            if (val.count <= 2) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val.isWin && val._id == "3x") {
                            if (val.count <= 3) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 3 - val.count };
                                result.push(res)
                            }
                        } else if (val.isWin && val._id == "2x") {
                            if (val.count <= 4) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 4 - val.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        }
                    }

                } else if (gameName == "RPC") {
                    console.log("asdfasdf", (Object.values(admincondition)?.length > 0), Object.values(admincondition)?.length)
                    if (Object.values(admincondition)?.length > 0) {
                        if (admincondition.Balance <= 0) {
                            res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                            result.push(res)
                        } else {
                            console.log('valrpcgal', val, val.count)
                            if (val.isWin && val._id == "4x") {
                                if (val.count <= 3) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 3 - val.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            }
                        }
                    } else {
                        if (val.isWin && val._id == "4x") {
                            if (val.count <= 3) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 3 - val.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        }
                    }

                } else if (gameName == "Limbo") {
                    console.log("valgameNamegal", val, "valsdfasd", val?._id?.split('x')[0], 1 <= val?._id?.split('x')[0] && 10 >= val?._id?.split('x')[0]);
                    console.log("asdfasdf", (Object.values(admincondition)?.length > 0), Object.values(admincondition)?.length)
                    if (Object.values(admincondition)?.length > 0) {
                        if (admincondition.Balance <= 0) {
                            res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                            result.push(res)
                        } else {
                            console.log('vaaaaaaaaaaaaaaaa', val)
                            if (val?._id?.split('x')[0] != 0 && (1 <= val?._id?.split('x')[0] && 10 >= val?._id?.split('x')[0])) {
                                console.log('sum valsdfasd', val.count, sum, sum >= 3 ? 0 : 3 - sum)
                                if (val.isWin && (1 <= Number((val._id)?.split('x')[0]) && 10 >= Number((val._id)?.split('x')[0]))) {
                                    console.log("asdfasdfas", sum)
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum >= 3 ? 0 : 3 - sum };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (11 <= val?._id?.split('x')[0] && 20 >= val?._id?.split('x')[0])) {
                                console.log('sum1 valsdfasd', val.count, sum1, sum1 >= 4 ? 0 : 4 - sum1)
                                if (val.isWin && (11 <= Number((val._id)?.split('x')[0]) && 20 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum1 >= 3 ? 0 : 3 - sum1 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (21 <= val?._id?.split('x')[0] && 30 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (21 <= Number((val._id)?.split('x')[0]) && 30 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum2 >= 3 ? 0 : 3 - sum2 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (31 <= val?._id?.split('x')[0] && 40 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (31 <= Number((val._id)?.split('x')[0]) && 40 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum3 >= 2 ? 0 :  - sum3 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (41 <= val?._id?.split('x')[0] && 50 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (41 <= Number((val._id)?.split('x')[0]) && 50 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum4 >= 2 ? 0 : 2 - sum4 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (51 <= val?._id?.split('x')[0] && 60 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (51 <= Number((val._id)?.split('x')[0]) && 60 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum5 >= 2 ? 0 : 2 - sum5 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (61 <= val?._id?.split('x')[0] && 70 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (61 <= Number((val._id)?.split('x')[0]) && 70 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum6 >= 1 ? 0 : 1 - sum6 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (71 <= val?._id?.split('x')[0] && 80 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (71 <= Number((val._id)?.split('x')[0]) && 80 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum7 >= 1 ? 0 : 1 - sum7 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (81 <= val?._id?.split('x')[0] && 90 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (81 <= Number((val._id)?.split('x')[0]) && 90 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum8 >= 1 ? 0 : 1 - sum8 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (91 <= val?._id?.split('x')[0] && 100 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (91 <= Number((val._id)?.split('x')[0]) && 100 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum9 >= 1 ? 0 : 1 - sum9 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            }
                        }
                    }  else {
                        console.log('vaaaaaaaaaaaaaaaa', val)
                        if (val?._id?.split('x')[0] != 0 && (1 <= val?._id?.split('x')[0] && 10 >= val?._id?.split('x')[0])) {
                            console.log('sum valsdfasd', val.count, sum, sum >= 3 ? 0 : 3 - sum)
                            if (val.isWin && (1 <= Number((val._id)?.split('x')[0]) && 10 >= Number((val._id)?.split('x')[0]))) {
                                console.log("asdfasdfas", sum)
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum >= 3 ? 0 : 3 - sum };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (11 <= val?._id?.split('x')[0] && 20 >= val?._id?.split('x')[0])) {
                            console.log('sum1 valsdfasd', val.count, sum1, sum1 >= 4 ? 0 : 4 - sum1)
                            if (val.isWin && (11 <= Number((val._id)?.split('x')[0]) && 20 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum1 >= 3 ? 0 : 3 - sum1 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (21 <= val?._id?.split('x')[0] && 30 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (21 <= Number((val._id)?.split('x')[0]) && 30 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum2 >= 3 ? 0 : 3 - sum2 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (31 <= val?._id?.split('x')[0] && 40 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (31 <= Number((val._id)?.split('x')[0]) && 40 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum3 >= 2 ? 0 :  - sum3 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (41 <= val?._id?.split('x')[0] && 50 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (41 <= Number((val._id)?.split('x')[0]) && 50 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum4 >= 2 ? 0 : 2 - sum4 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (51 <= val?._id?.split('x')[0] && 60 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (51 <= Number((val._id)?.split('x')[0]) && 60 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum5 >= 2 ? 0 : 2 - sum5 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (61 <= val?._id?.split('x')[0] && 70 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (61 <= Number((val._id)?.split('x')[0]) && 70 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum6 >= 1 ? 0 : 1 - sum6 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (71 <= val?._id?.split('x')[0] && 80 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (71 <= Number((val._id)?.split('x')[0]) && 80 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum7 >= 1 ? 0 : 1 - sum7 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (81 <= val?._id?.split('x')[0] && 90 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (81 <= Number((val._id)?.split('x')[0]) && 90 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum8 >= 1 ? 0 : 1 - sum8 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (91 <= val?._id?.split('x')[0] && 100 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (91 <= Number((val._id)?.split('x')[0]) && 100 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum9 >= 1 ? 0 : 1 - sum9 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        }
                    }



                    // if (val.isWin && (1 <= Number((val._id)?.split('x')[0]) && 10 >= Number((val._id)?.split('x')[0]))) {
                    //     if (val.count <= 4) {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 4 - val.count };
                    //         result.push(res)
                    //     } else {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     }
                    // } else if (val.isWin && (11 <= Number((val._id)?.split('x')[0]) && 20 >= Number((val._id)?.split('x')[0]))) {
                    //     if (val.count <= 4) {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 4 - val.count };
                    //         result.push(res)
                    //     } else {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     }

                    // } else if (val.isWin && (21 <= Number((val._id)?.split('x')[0]) && 30 >= Number((val._id)?.split('x')[0]))) {
                    //     if (val.count <= 3) {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 3 - val.count };
                    //         result.push(res)
                    //     } else {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     }

                    // } else if (val.isWin && (31 <= Number((val._id)?.split('x')[0]) && 40 >= Number((val._id)?.split('x')[0]))) {
                    //     if (val.count <= 3) {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 3 - val.count };
                    //         result.push(res)
                    //     } else {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     }

                    // } else if (val.isWin && (41 <= Number((val._id)?.split('x')[0]) && 50 >= Number((val._id)?.split('x')[0]))) {
                    //     if (val.count <= 2) {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val.count };
                    //         result.push(res)
                    //     } else {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     }

                    // } else if (val.isWin && (51 <= Number((val._id)?.split('x')[0]) && 60 >= Number((val._id)?.split('x')[0]))) {
                    //     if (val.count <= 2) {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val.count };
                    //         result.push(res)
                    //     } else {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     }

                    // } else if (val.isWin && (61 <= Number((val._id)?.split('x')[0]) && 70 >= Number((val._id)?.split('x')[0]))) {
                    //     if (val.count <= 1) {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     } else {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     }

                    // } else if (val.isWin && (71 <= Number((val._id)?.split('x')[0]) && 80 >= Number((val._id)?.split('x')[0]))) {
                    //     if (val.count <= 1) {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     } else {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     }

                    // } else if (val.isWin && (81 <= Number((val._id)?.split('x')[0]) && 90 >= Number((val._id)?.split('x')[0]))) {
                    //     if (val.count <= 1) {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     } else {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     }

                    // } else if (val.isWin && (91 <= Number((val._id)?.split('x')[0]) && 100 >= Number((val._id)?.split('x')[0]))) {
                    //     if (val.count <= 1) {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     } else {
                    //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                    //         result.push(res)
                    //     }

                    // }
                    // if (val.isWin && val._id >= ) {

                    // }
                } else if (gameName == "Moon") {
                    console.log("asdfasdf", (Object.values(admincondition)?.length > 0), Object.values(admincondition)?.length)
                    if (Object.values(admincondition)?.length > 0) {
                        if (admincondition.Balance <= 0) {
                            res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                            result.push(res)
                        } else {
                            console.log("val?._id?.split('x')[0]", sum, sum1, sum2, val?._id?.split('x')[0], (1 <= Number((val._id)?.split('x')[0]) && 30 >= Number((val._id)?.split('x')[0])))
                            if ((val?._id?.split('x')[0] != 0) && (1 <= val?._id?.split('x')[0] && 30 >= val?._id?.split('x')[0])) {
                                if (val?.isWin && (1 <= Number((val._id)?.split('x')[0]) && 30 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum >= 4 ? 0 : 4 - sum };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if ((val?._id?.split('x')[0] != 0) && (30 <= val?._id?.split('x')[0] && 60 >= val?._id?.split('x')[0])) {
                                if (val?.isWin && (30 <= Number((val._id)?.split('x')[0]) && 60 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum1 >= 4 ? 0 : 4 - sum1 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if ((val?._id?.split('x')[0] != 0) && (60 <= val?._id?.split('x')[0] && 100 >= val?._id?.split('x')[0])) {
                                if (val?.isWin && (60 <= Number((val._id)?.split('x')[0]) && 100 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum2 >= 1 ? 0 : 1 - sum2 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            }
                            // console.log("valtransddata", val)
                            // if (val.isWin) {
                            //     res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager }
                            // }
                        }
                    } else {
                        console.log("val?._id?.split('x')[0]", sum, sum1, sum2, val?._id?.split('x')[0], (1 <= Number((val._id)?.split('x')[0]) && 30 >= Number((val._id)?.split('x')[0])))
                        if ((val?._id?.split('x')[0] != 0) && (1 <= val?._id?.split('x')[0] && 30 >= val?._id?.split('x')[0])) {
                            if (val?.isWin && (1 <= Number((val._id)?.split('x')[0]) && 30 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum >= 4 ? 0 : 4 - sum };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if ((val?._id?.split('x')[0] != 0) && (30 <= val?._id?.split('x')[0] && 60 >= val?._id?.split('x')[0])) {
                            if (val?.isWin && (30 <= Number((val._id)?.split('x')[0]) && 60 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum1 >= 4 ? 0 : 4 - sum1 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if ((val?._id?.split('x')[0] != 0) && (60 <= val?._id?.split('x')[0] && 100 >= val?._id?.split('x')[0])) {
                            if (val?.isWin && (60 <= Number((val._id)?.split('x')[0]) && 100 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum2 >= 1 ? 0 : 1 - sum2 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        }
                        // console.log("valtransddata", val)
                        // if (val.isWin) {
                        //     res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager }
                        // }
                    }

                } else if (gameName == "Range") {
                    console.log('admincondition_data', admincondition)
                    if (Object.values(admincondition)?.length > 0) {
                        if (admincondition.Balance <= 0) {
                            res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                            result.push(res)
                        } else {
                            console.log('vals data', val, val?._id?.split('x')[0], (5 <= val?._id?.split('x')[0] && 10 >= val?._id?.split('x')[0]));
                            if (val?._id?.split('x')[0] != 0 && (5 <= val?._id?.split('x')[0] && 10 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (5 <= Number((val._id)?.split('x')[0]) && 10 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum >= 1 ? 0 : 1 - sum };
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (11 <= val?._id?.split('x')[0] && 20 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (11 <= Number((val._id)?.split('x')[0]) && 20 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum1 >= 2 ? 0 : 2 - sum1 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (21 <= val?._id?.split('x')[0] && 30 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (21 <= Number((val._id)?.split('x')[0]) && 30 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum2 >= 2 ? 0 : 2 - sum2 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (31 <= val?._id?.split('x')[0] && 40 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (31 <= Number((val._id)?.split('x')[0]) && 40 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum3 >= 3 ? 0 : 3 - sum3 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (41 <= val?._id?.split('x')[0] && 50 >= val?._id?.split('x')[0])) {
                                console.log('sum4 data', sum4, sum4 >= 3)
                                if (val.isWin && (41 <= Number((val._id)?.split('x')[0]) && 50 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum4 >= 3 ? 0 : 3 - sum4 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (51 <= val?._id?.split('x')[0] && 60 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (51 <= Number((val._id)?.split('x')[0]) && 60 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum5 >= 3 ? 0 : 3 - sum5 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (61 <= val?._id?.split('x')[0] && 70 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (61 <= Number((val._id)?.split('x')[0]) && 70 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum6 >= 3 ? 0 : 3 - sum6 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (71 <= val?._id?.split('x')[0] && 80 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (71 <= Number((val._id)?.split('x')[0]) && 80 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum7 >= 3 ? 0 : 3 - sum7 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (81 <= val?._id?.split('x')[0] && 90 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (81 <= Number((val._id)?.split('x')[0]) && 90 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum8 >= 3 ? 0 : 3 - sum8 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?._id?.split('x')[0] != 0 && (91 <= val?._id?.split('x')[0] && 100 >= val?._id?.split('x')[0])) {
                                if (val.isWin && (91 <= Number((val._id)?.split('x')[0]) && 100 >= Number((val._id)?.split('x')[0]))) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum9 >= 4 ? 0 : 4 - sum9 };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            }
                        }
                    } else {
                        console.log('vals data', val, val?._id?.split('x')[0], (5 <= val?._id?.split('x')[0] && 10 >= val?._id?.split('x')[0]));
                        if (val?._id?.split('x')[0] != 0 && (5 <= val?._id?.split('x')[0] && 10 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (5 <= Number((val._id)?.split('x')[0]) && 10 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum >= 1 ? 0 : 1 - sum };
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (11 <= val?._id?.split('x')[0] && 20 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (11 <= Number((val._id)?.split('x')[0]) && 20 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum1 >= 2 ? 0 : 2 - sum1 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (21 <= val?._id?.split('x')[0] && 30 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (21 <= Number((val._id)?.split('x')[0]) && 30 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum2 >= 2 ? 0 : 2 - sum2 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (31 <= val?._id?.split('x')[0] && 40 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (31 <= Number((val._id)?.split('x')[0]) && 40 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum3 >= 3 ? 0 : 3 - sum3 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (41 <= val?._id?.split('x')[0] && 50 >= val?._id?.split('x')[0])) {
                            console.log('sum4 data', sum4, sum4 >= 3)
                            if (val.isWin && (41 <= Number((val._id)?.split('x')[0]) && 50 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum4 >= 3 ? 0 : 3 - sum4 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (51 <= val?._id?.split('x')[0] && 60 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (51 <= Number((val._id)?.split('x')[0]) && 60 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum5 >= 3 ? 0 : 3 - sum5 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (61 <= val?._id?.split('x')[0] && 70 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (61 <= Number((val._id)?.split('x')[0]) && 70 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum6 >= 3 ? 0 : 3 - sum6 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (71 <= val?._id?.split('x')[0] && 80 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (71 <= Number((val._id)?.split('x')[0]) && 80 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum7 >= 3 ? 0 : 3 - sum7 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (81 <= val?._id?.split('x')[0] && 90 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (81 <= Number((val._id)?.split('x')[0]) && 90 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum8 >= 3 ? 0 : 3 - sum8 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?._id?.split('x')[0] != 0 && (91 <= val?._id?.split('x')[0] && 100 >= val?._id?.split('x')[0])) {
                            if (val.isWin && (91 <= Number((val._id)?.split('x')[0]) && 100 >= Number((val._id)?.split('x')[0]))) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: sum9 >= 4 ? 0 : 4 - sum9 };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        }
                    }
                } else if (gameName == "Plinko") {
                    if (Object.values(admincondition)?.length > 0) {
                        if (admincondition.Balance <= 0) {
                            res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                            result.push(res)
                        } else {
                            if (val?.isWin && val?._id == "1x") {
                                if (val.count <= 3) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 3 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "2x") {
                                if (val.count <= 3) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 3 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "3x") {
                                if (val.count <= 2) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "4x") {
                                if (val.count <= 1) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "8x") {
                                if (val.count <= 1) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "10x") {
                                if (val.count <= 2) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "15x") {
                                if (val.count <= 2) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "16x") {
                                if (val.count <= 1) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "17x") {
                                if (val.count <= 2) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "20x") {
                                if (val.count <= 2) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "45x") {
                                if (val.count <= 2) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 1 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "47x") {
                                if (val.count <= 2) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 1 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "65x") {
                                if (val.count <= 2) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 1 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            } else if (val?.isWin && val?._id == "70x") {
                                if (val.count <= 2) {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 1 - val?.count };
                                    result.push(res)
                                } else {
                                    res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                    result.push(res)
                                }
                            }
                        }
                    } else {
                        if (val?.isWin && val?._id == "1x") {
                            if (val.count <= 3) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 3 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "2x") {
                            if (val.count <= 3) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 3 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "3x") {
                            if (val.count <= 2) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "4x") {
                            if (val.count <= 1) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "8x") {
                            if (val.count <= 1) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "10x") {
                            if (val.count <= 2) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "15x") {
                            if (val.count <= 2) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "16x") {
                            if (val.count <= 1) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "17x") {
                            if (val.count <= 2) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "20x") {
                            if (val.count <= 2) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "45x") {
                            if (val.count <= 2) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 1 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "47x") {
                            if (val.count <= 2) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 1 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "65x") {
                            if (val.count <= 2) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 1 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        } else if (val?.isWin && val?._id == "70x") {
                            if (val.count <= 2) {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 1 - val?.count };
                                result.push(res)
                            } else {
                                res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                                result.push(res)
                            }
                        }
                    }
                }
                // if (val.isWin && val._id == "8x") {
                //     if (val.count <= 1) {
                //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                //         result.push(res)
                //     }
                // } else if (val.isWin && val._id == "6x") {
                //     if (val.count <= 2) {
                //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 1 - val.count };
                //         result.push(res)
                //     }
                // } else if (val.isWin && val._id == "3x") {
                //     if (val.count <= 2) {
                //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val.count };
                //         result.push(res)
                //     }
                // }else if (val.isWin && val._id == "4x") {
                //     if (val.count <= 2) {
                //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 2 - val.count };
                //         result.push(res)
                //     }
                // } else if (val.isWin && val._id == "48x") {
                //     if (val.count <= 2) {
                //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 0 };
                //         result.push(res)
                //     }
                // } else if (val.isWin && val._id == "2x") {
                //     if (val.count <= 4) {
                //         res = { Multiplier: val._id, isWin: val.isWin, maxpayout: val.totalMaxPayout, totalwager: val.totalWager, allowwin: 4 - val.count };
                //         result.push(res)
                //     }
                // }
            });

        }
        // else {
        output = { Multiplier: '0x', isWin: false, value: 0, maxpayout: (wager), totalwager: totalwager };
        condition.map((val, i) => {
            console.log("val_datasgal", val, totalwager, Object.values(val)[0],Number(Object.keys(val)[0].split('x')[0]));
            console.log("maxpayout result", maxpayout, multiplebets,"won means", Number(maxpayout) / Number(multiplebets), "loss means", wager);
            if (eval(Object.values(val)[0])) {
                let times = toFixedNumber(Number(Object.keys(val)[0].split('x')[0]))
                if (times >= output.value) {
                    output = { Multiplier: Object.keys(val)[0], isWin: true, value: times, maxpayout: Number(maxpayout) / Number(multiplebets), totalwager: totalwager }
                }
                // arr.push({ "Amount": (wager * multiplebets) * Number(Object.keys(val)[0].split('x')[0]), "Multiplier": Object.keys(val)[0], "isWin": true, "TotalWager": totalwager })
            } else {
                if (!output.isWin) {
                    output = { Multiplier: '0x', isWin: false, value: 0, maxpayout: (wager), totalwager: totalwager }
                }
                // (wager * multiplebets) * Number(Object.keys(val)[0].split('x')[0])
                // arr.push({ "Amount": (wager * multiplebets) * Number(Object.keys(val)[0].split('x')[0]), "Multiplier": "0x", "isWin": false, "TotalWager": totalwager })
            }
        })

        console.log('sumsdata', sum)
        console.log("GamePrediction_arr", output);
        console.log('result data game', transactiondetails.length, result);
        if (transactiondetails.length > 0) {
            return result;
        } else {
            return output;
        }

    } catch (e) {
        console.log("GamePrediction_err", e)
    }
}



export const ToastBet = async (Id, data) => {

    try {
        console.log("dataaaaaa", data, Id)
        let toastId = Id != "" ? Id : ""
        if (data == "rejected") {
            toast.error(`Denied Transaction Signature`,
                {
                    id: toastId, duration: 3000, style: { minWidth: '300px', minHeight: '55px' }
                });
        } else if (data == "loading") {
            toastId = toast.loading(`Awaiting tx Confirmation`,
                {
                    duration: 1000000,
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme
                }
                // {
                //     duration: 1000000, style: { minWidth: '250px', minHeight: '55px' },
                // }
            );
            return toastId
        } else if (data == "something wrong !!!") {
            toast.error(`Something Wrong !!!`,
                {
                    id: toastId, duration: 3000,
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme
                }
                // {
                //     id: toastId, duration: 3000, style: { minWidth: '300px', minHeight: '55px' }
                // }
            );
        }
        else {
            if (data != undefined) {
                toast.success('Transaction Accepted',
                    {
                        id: toastId, duration: 4000,
                        position: position.position,
                        style: style,
                        iconTheme: iconTheme
                    }
                    // {
                    //     id: toastId, duration: 4000, style: { minWidth: '300px', minHeight: '55px' }
                    // }
                );
            }

        }
        console.log("toastId_toastId", toastId)
    } catch (e) {
        console.log("Toast_bet_err", e)
    }
}


export const setlocalStoragegamebet = async (pathName, wager, multiplebets, betamount, dices, address, stopgain, stoploss) => {

    try {
        console.log("enter this setlocalStoragegamebet juje", pathName, wager, multiplebets, betamount, dices, address, stopgain, stoploss)

        let arr = [{ [pathName]: [{ 'Wager': wager, 'Multiplebets': multiplebets, 'Betamount': betamount, "firstDice": dices[0], "secondDice": dices[1], 'Walletaddress': address, "StopLoss": stoploss, "StopGain": stopgain }] }];
        let data = {
            gamedata: arr,
            walletAddress: address,
            gameName: pathName,
        }
        console.log("data_setlocalStoragegamebet jujue", data)
        let res = await UserGameDetailsUpdateHooks(data);
        console.log("setlocalStoragegamebet_respond_data final jujue", res?.status == 200, res?.data?.data)
        if (res?.status == 200) {
            let data = res?.data?.data?.GameDetails
            // localStorage.setItem('MultipleBets', JSON.stringify(data))
            return res
        }

    } catch (err) {
        console.log("setlocalStoragegamebet_err", err)
    }
}


export const createOrderdetails = async (pathName, wager, multiplebets, betamount, selecteddata, address, stopgain, stoploss, gainamount, lossamount, maxpayout, calculatewager, wagerclaimfee, rangesarray, rangemultiplier) => {
    try {

        console.log("pathName, wager, multiplebets, betamount, dices, address, stopgain, stoploss, gainamount, lossamount", pathName, wager, multiplebets, betamount, selecteddata, address, stopgain, stoploss, gainamount, lossamount, maxpayout, calculatewager, wagerclaimfee, rangemultiplier, rangemultiplier)
        let data = {
            walletAddress: address,
            gameName: pathName,
            wager: wager,
            multiplebets: multiplebets,
            betamount: betamount,
            stopgain: stopgain,
            stoploss: stoploss,
            gainamount: gainamount,
            lossamount: lossamount,
            maxpayout: maxpayout,
            calculatewager : calculatewager,
            totalwager : wagerclaimfee
        }
        if (pathName == 'Dice') {
            data['firstDice'] = selecteddata[0];
            data['secondDice'] = selecteddata[1];
        } else if (pathName == 'CoinFlip') {
            data['selectedCoin'] = selecteddata;
        } else if (pathName == 'RPC') {
            data['selectedRpc'] = selecteddata;
        } else if (pathName == 'Wheel') {
            data['selectedWheel'] = selecteddata;
        } else if (pathName == 'Limbo') {
            data['selectedLimbo'] = selecteddata;
        } else if (pathName == 'Moon') {
            data['selectedMoon'] = selecteddata;
        } else if (pathName == 'Range') {
            data['selectedRange'] = selecteddata;
            data['rangeArray'] = rangesarray;
            data['rangeMultiplier'] = rangemultiplier;
        } else if (pathName == 'Plinko') {
            data['selectedPlinko'] = selecteddata;
        }
        console.log('update_dasta', data)
        let res = await createOrderDetailsHooks(data)
        console.log("createOrderdetailshooks_data", selecteddata, data);
        console.log("setlocalStoragegamebet_respond_data final jujue", res?.status == 200, res?.data?.data);
        if (res?.status == 200) {
            localStorage.setItem('MultipleBets', JSON.stringify(data));
            return res;
        }

    } catch (e) {
        console.log("createOrderdetailshooks_err", e)
    }
}

export const ToastGame = async (gameName, Id, data, incrementbetcount, multipleBets, resultamount) => {
    try {
        let toastId = Id != "" ? Id : "";
        console.log("ToastGame time", gameName, toastId, data, incrementbetcount, multipleBets)
        if (gameName == 'Limbo') {
            if (data == "loading") {
                if (Id != "") {
                    setTimeout(function () {
                        toast.loading('Initializing .....', {
                            id: toastId, position: position.position,
                            style: style,
                            iconTheme: iconTheme,
                        })
                    }, 3000)
                } else {
                    toastId = toast.loading('Initializing .....', {
                        duration: 1000000, position: position.position,
                        style: style,
                        iconTheme: iconTheme,
                    });
                    console.log("enter 1st time")
                    return toastId;
                }

            } else if (data == "error") {
                setTimeout(function () {
                    // toast.error(incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + (resultamount), {
                    //     id: toastId, duration: 3000, position: position.position,
                    //     style: style,
                    //     iconTheme: iconTheme,
                    // })
                    Swal.fire({
                        title: "You Lose!",
                        text: incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + (resultamount),
                        // imageUrl: "../assets/flip1.png",
                        iconHtml: `<img src=${down}>`,
                        timer: 4200,
                        imageWidth: 400,
                        imageHeight: 200,
                        imageAlt: "Custom image",
                      }); 
                }, 3000)
            } else if (data == "success") {
                setTimeout(function () {
                    // toast.success(incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + (resultamount), {
                    //     id: toastId, duration: 3000, position: position.position,
                    //     style: style,
                    //     iconTheme: iconTheme,
                    // })
                    Swal.fire({
                        title: "Success!",
                        text: incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + (resultamount),
                        // imageUrl: "../assets/flip1.png",
                        iconHtml: `<img src=${winning}>`,
                        timer: 4200,
                        backdrop: `
                        rgba(0,0,123,0.4)
                        url("${winss}")
                        center top            
                        no-repeat
                      `,
                        imageWidth: 400,
                        imageHeight: 200,
                        imageAlt: "Custom image"
                      });
                }, 3000)
            }
        } else if (gameName == 'Moon') {
            console.log('moongameName', Id, gameName, data);
            if (data == "loading") {
                toast.loading('Preparing for Launch .....', {
                    id: toastId, duration: 5000, position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                });
                return true;
            }
        }
        toast.dismiss(toastId)
    } catch (e) {
        console.log("ToastGame_err", e)
    }
}

