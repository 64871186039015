
import { useState, useEffect } from 'react';
import { getAccount } from '../hooks/useAccount';
import { createOrderdetails, GamePrediction } from '../hooks/useBot';
import { checkAlreadyWon, DiceGameCondition } from '../hooks/useDice';
import { CreateGameTransactionHooks } from '../hooks/UseTransaction';
import { getGameAllTransactionInfoHooks } from "../hooks/UseTransaction";
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from '../hooks/useToast';
import { UpdateGameSettingBalancehooks, getAllGameTransactionDetails, getGameSettingDetails } from '../hooks/UseUser';
import { toFixedNumber } from '../hooks/UseContract';
import flip1 from "../assets/flip1.png";
import flip2 from "../assets/flip2.png";
import flip3 from "../assets/flip3.png";
import flip4 from "../assets/flip4.png";
import flip5 from "../assets/flip5.png";
import winss from "../assets/winss.gif";
import winning from "../assets/winning.gif";
import down from "../assets/downs.gif";
import Swal from 'sweetalert2';

function Dice(props) {

  const faces = 6;
  const maxRollTimes = 15;

  const [intrvl, setIntrvl] = useState();
  console.log("intrvl_intrvl", props?.wager, intrvl, props, props?.usergamedetails, props?.checkOrder);
  const [diceFace, setDiceFace] = useState(1);
  const [diceFaceSecond, setDiceFaceSecond] = useState(5);



  const [btnDisabled, setBtnDisabled] = useState(false);
  console.log("button_disabled", btnDisabled)
  const [rollTimes, setRollTimes] = useState();


  //venkatesh
  const [predictiondata, setPrediction] = useState(0);
  const [rollCount, setRollCount] = useState(0);
  console.log("rollCount_rollCount", rollCount)
  const [multipleBets, setMultipleBets] = useState('');
  const [firstDice, setFirstDice] = useState();
  const [secondDice, setSecondDice] = useState();
  const [transactionInfo, setTransactionInfo] = useState([]);
  console.log("transaction_record", transactionInfo)
  const [accountInfo, setAccountInfo] = useState("");
  const [maxpayout, setMaxPayout] = useState(0);
  const [totalwager, setTotalWager] = useState(0);
  const [incrementbetcount, setIncrementBetCount] = useState(1)
  const [onemonthwonInfo, setOneMonthWonInfo] = useState([]);
  const [oneweekwonInfo, setOneWeekWonInfo] = useState([]);
  const [check, setCheck] = useState(false)
  const [gamesetting, setGameSetting] = useState({})
  const [allgametransacdata, setAllGameTransData] = useState([]);

  const [neverwondata, setNeverWonData] = useState([]);
  const [allowin, setAllowin] = useState(false);
  const [resultdicearr, setResultDiceArr] = useState([]);
  const [stop, setStop] = useState(false)

  console.log("onemonthwonInfo_oneweekwonInfo 4444 keke", oneweekwonInfo, onemonthwonInfo, oneweekwonInfo?.length, onemonthwonInfo?.length)


  console.log("diceFace_diceFaceSecond", multipleBets, maxpayout, firstDice, secondDice);

  useEffect(() => {
    console.log("3333 keke", props?.gameInfo?.GameCondition)
    console.log("eeeee juju useeffect", rollTimes, rollTimes === 0, props?.gameInfo?.GameCondition);
    (async () => {
      try {
        console.log('same console data', rollTimes, rollTimes <= 0)
        if (rollTimes <= 0) {
          console.log("4444 keke", props?.gameInfo?.GameCondition, rollTimes, oneweekwonInfo?.length)
          console.log('condition inside 4444 keke', rollTimes, (oneweekwonInfo?.length > 0 || onemonthwonInfo?.length > 0), oneweekwonInfo.length > 0 || onemonthwonInfo.length > 0, onemonthwonInfo, oneweekwonInfo, props?.gameInfo?.GameCondition)
          if (oneweekwonInfo?.length > 0 || onemonthwonInfo?.length > 0) {
            let gamedata = {
              predictdice1: firstDice,
              predictdice2: secondDice,
              rollDice1: diceFace,
              rollDice2: diceFaceSecond
            }
            let dicecondition = await DiceGameCondition(gamedata, props?.pathName, {}, props?.gameInfo?.GameCondition);
            console.log("dicecondition", gamedata, dicecondition)
            let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
            console.log("presresp data", presresp);

            let checkalreadywon = await checkAlreadyWon(presresp, oneweekwonInfo, onemonthwonInfo, "Dice");
            console.log("checkalreadywon", checkalreadywon)

            console.log("allow data juju", checkalreadywon?.res1, checkalreadywon?.res2, presresp, checkalreadywon)
            if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
              console.log('never ever come this console')
              console.log("rollcountdata00 juju", rollCount)
              let rollcount = rollCount - 1;
              setRollCount(rollcount)
              await rollDice(rollcount)

            } else {
              console.log("doesn't exist profit transc 111");
              if (props?.remainingbets == 1) {
                clearInterval(intrvl);
                FinishInterval()
              } else {
                clearInterval(intrvl);
                setBtnDisabled(false);
                FinishInterval()
              }

            }
          } else {
            console.log("doesn't exist profit transc 222")
            clearInterval(intrvl);
            setBtnDisabled(false);
            FinishInterval();
          }

        }
        console.log("rollCount <= multipleBets", rollCount, multipleBets, rollCount >= props.multiplebets, props.multiplebets)
        if (rollCount >= props.multiplebets) {
          setBtnDisabled(true);
        }
      } catch (e) {
        console.log("useEffect_err", e)
      }

    })();
  }, [rollTimes]);



  useEffect(() => {
    async function fetchData() {
      if (!stop) {
        let arr = [2, 4, 8];
        var removedata = [];
        if (oneweekwonInfo?.length > 0 || onemonthwonInfo?.length > 0) {
          for (let i = 0; i < onemonthwonInfo?.length; i++) {
            console.log('onemonthdataaaaaa', onemonthwonInfo[i], arr);
            for (let j = 0; j < arr?.length; j++) {
              console.log('j conditions check', (onemonthwonInfo[i]['Multiplier']).split('x')[0], arr[j]);
              if (Number((onemonthwonInfo[i]['Multiplier']).split('x')[0]) == arr[j] && onemonthwonInfo[i]['allowwin'] == 0) {
                let Indexposition = arr.indexOf(arr[j]);
                console.log('IndexPosition', Indexposition, arr);
                removedata.push((arr.splice(Indexposition, 1)[0]))
              }
            }
          }
          removedata.sort((a, b) => a - b);
          console.log('removedataremovedata', removedata);
          setNeverWonData(removedata);
        } else {
          setNeverWonData([]);
        }
      }
    }
    fetchData()
  }, [props]);

  useEffect(() => {
    if (!stop) {
      let a = [[1, 1], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6],
      [2, 1], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6],
      [3, 1], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6],
      [4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6],
      [5, 1], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6],
      [6, 1], [6, 2], [6, 3], [6, 4], [6, 5], [6, 6]];
      if (neverwondata?.length >= 1) {
        let diceface = props?.selected[0];
        let secondface = props?.selected[1];
        let a1 = [];
        let a2 = [];
        let a3 = [];
        let resutlarrdata = []
        console.log('never won datasgal', neverwondata, diceface, secondface);
        for (let i = 0; i < neverwondata?.length; i++) {
          for (let j = 0; j < a?.length; j++) {
            // console.log('1111 enter this a444535645', j, neverwondata[i],  neverwondata[i] == 2, neverwondata[i] == 4, neverwondata[i] == 8)
            if ((neverwondata[i] == 2)) {
              console.log('asdfasdfasdf', a[j][0], a[j][1], a[j][0] != diceface, a[j][1] != secondface)
              if (a[j][0] != diceface && a[j][1] != secondface) {
                console.log('asdreassdf', a[j]);
                a1.push(a[j])
              }

            } else if (neverwondata[i] == 4) {
              if (a1?.length > 0) {
                console.log('al datagal', a1?.length, j + 1)
                if (a1?.length >= j + 1) {
                  console.log('a1[j][0] + a1[j][1]', a1[j][0], a1[j][1], a1[j][0] + a1[j][1], diceface + secondface, a1[j][0] + a1[j][1] != diceface + secondface)
                  if (a1[j][0] + a1[j][1] != diceface + secondface) {
                    console.log('enter a[j] data', a1[j])
                    a2.push(a1[j])
                  }
                }
              } else {
                if (a[j][0] + a[j][1] != diceface + secondface) {
                  a2.push(a[j])
                }
              }
            } else if (neverwondata[i] == 8) {
              if (a2?.length > 0) {
                let a4 = a2;
                console.log('a4 datasssss', a4)
                if (a4?.length >= j + 1) {
                  console.log('a444535645', a4?.length, j, a4[j][0], a4[j][1], !(((a4[j][0] == diceface) && (a4[j][1] == secondface)) || ((a4[j][0] == secondface) && (a4[j][1] == diceface))))
                  if (!(((a4[j][0] == diceface) && (a4[j][1] == secondface)) || ((a4[j][0] == secondface) && (a4[j][1] == diceface)))) {
                    a3.push(a4[j])
                  }
                }
              } else if (a1?.length > 0) {
                let a4 = a1;
                console.log('a4 datasssss', a4)
                if (a4?.length >= j + 1) {
                  console.log('a444535645', a4?.length, j, a4[j][0], a4[j][1], !(((a4[j][0] == diceface) && (a4[j][1] == secondface)) || ((a4[j][0] == secondface) && (a4[j][1] == diceface))))
                  if (!(((a4[j][0] == diceface) && (a4[j][1] == secondface)) || ((a4[j][0] == secondface) && (a4[j][1] == diceface)))) {
                    a3.push(a4[j])
                  }
                }
              } else {
                //Ex : firstdace = 5, seconddace = 3
                // (5 == 5 && 3 == 3) ||(3 == 3 && 5 == 5);
                if (!(((a[j][0] == diceface) && (a[j][1] == secondface)) || ((a[j][0] == secondface) && (a[j][1] == diceface)))) {
                  a3.push(a[j])
                }

              }
            }
          }

        };
        console.log('a3 datasgal', a3)
        if (a3?.length > 0) {
          resutlarrdata = a3;
        } else if (a2?.length > 0) {
          resutlarrdata = a2;
        } else if (a1?.length > 0) {
          resutlarrdata = a1;
        }

        console.log('resultarrdatasgal', resutlarrdata);
        setResultDiceArr(resutlarrdata)
        setAllowin(false);
      } else {
        setAllowin(true);
      }
    }
  }, [neverwondata])




  //venkatesh
  useEffect(() => {
    console.log("props_props_props", props, props.multiplebets)
    console.log('ererer 4444 keke', props?.oneweekwondata, props?.onemonthwondata,
      props?.oneweekwondata?.length > 0, props?.onemonthwondata?.length > 0)
    setPrediction()
    let sum = 0;
    for (let i = 0; i < props.selected.length; i++) {
      sum = Number(sum) + Number(props.selected[i]);
    }
    setPrediction(sum);
    let rolltimes = props.multiplebets;
    console.log("rolltimesssssssss", rolltimes)
    setMultipleBets(rolltimes);

    // if (props?.oneweekwondata?.length > 0) {
    //   console.log("enter this week 4444 keke")
    //   setOneWeekWonInfo(props?.oneweekwondata)
    // }

    // if (props?.onemonthwondata?.length > 0) {
    //   console.log("enter this month 4444 keke")
    //   setOneMonthWonInfo(props?.onemonthwondata)
    // }
    let remainingrolls = (props.multiplebets - props?.remainingbets) + 1;

    setIncrementBetCount(remainingrolls)
  }, [props.multiplebets]);

  useEffect(() => {
    if (props.selected) {
      setFirstDice(props.selected[0]);
      setSecondDice(props.selected[1]);
    }
  }, [props]);

  useEffect(() => {
    (async () => {
      console.log("enter check transc")
      const walletAddress = getAccount();
      setAccountInfo(walletAddress);
      setMaxPayout(props.maxpayout);
      setTotalWager(props.totalwager);

      let transc = await getTransactionDetails(walletAddress);
      setCheck(false);
      console.log("oneweekwonInfoZ_OneMonthWonInfo", transc)
      if (transc?.oneweekdata?.length > 0) {
        OneWeekWonInfo(transc?.oneweekdata)
      }
      if (transc?.onemonthdata?.length > 0) {
        OneMonthWonInfo(transc?.onemonthdata)
      }

    })();


  }, [props, accountInfo, check, gamesetting])


  useEffect(() => {
    getGameSettings();
    getAllGameTransactionData();
  }, [props])


  const OneMonthWonInfo = async (transc) => {
    try {
      console.log('gamesetting, allgametransacdata month 3434', gamesetting, allgametransacdata)
      let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
      console.log("OneMonthWonInfo", res)
      setOneMonthWonInfo(res)
    } catch (e) {
      console.log("PredictionInfo_err", e)
    }
  }


  const OneWeekWonInfo = async (transc) => {
    try {
      console.log('gamesetting, allgametransacdata week 3434', gamesetting, allgametransacdata)
      let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
      console.log("OneWeekWonInfo", res)
      setOneWeekWonInfo(res);
    } catch (err) {
      console.log("OneWeekWonInfo_err", err)
    }
  }

  const getTransactionDetails = async (address) => {
    let data = {
      walletAddress: address,
      gameName: "Dice"
    }
    let res = await getGameAllTransactionInfoHooks(data);
    console.log("respond_data dice", res, res?.data)
    if (res?.status == 200) {
      if (res?.data?.alltranscdata?.length > 0) {
        //That is useless state , just store all transactiondata
        setTransactionInfo(res?.data?.alltranscdata)
      }
    }

    if (res?.data?.oneweekdata?.length > 0 || res?.data?.onemonthdata?.length > 0) {
      return {
        oneweekdata: res?.data?.oneweekdata?.length > 0 ? res?.data?.oneweekdata : [],
        onemonthdata: res?.data?.onemonthdata?.length > 0 ? res?.data?.onemonthdata : []
      }
    } else {
      return {
        oneweekdata: [],
        onemonthdata: []
      }
    }
  }


  const getGameSettings = async () => {
    try {
      let res = await getGameSettingDetails();
      console.log('dicegetGameSettings', res?.data?.data)
      if (res?.data?.status == true) {
        let result = res?.data?.data[0];
        if (result?.Status == "Active") {
          setGameSetting(result)
        }
      }
    } catch (e) {
      console.log("gameGameSettings_err", e)
    }
  }

  const getAllGameTransactionData = async () => {
    try {
      let res = await getAllGameTransactionDetails();
      if (res?.data?.status == true) {
        setAllGameTransData(res?.data?.alltranscdata)
      }
    } catch (e) {
      console.log("getAllTransactionData_err", e)
    }
  }

 

  const FinishInterval = async () => {
    try {
      console.log("props?.wager_props?.wager  5555 keke", props?.usergamedetails, props?.gameInfo?.GameCondition);
      if (incrementbetcount <= multipleBets) {
        let gamedata = {
          predictdice1: firstDice,
          predictdice2: secondDice,
          rollDice1: diceFace,
          rollDice2: diceFaceSecond
        }
        let dicecondition = await DiceGameCondition(gamedata, props?.pathName, {}, props?.gameInfo?.GameCondition);
        let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
        console.log("final presrep", presresp, dicecondition, props?.calculatewager);


        if (presresp) {
          let data = {}
          if (presresp?.isWin == true) {
            props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
              if (String(Object.keys(val)[0]) == String(presresp?.Multiplier)) {
                let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
                let amount = Number(props?.calculatewager) + Number(wonpercentage);
                console.log('multipliet data', wonpercentage, amount, Object.values(val)[0], props?.calculatewager)
                if (presresp?.isWin == true) {
                  data = {
                    walletAddress: accountInfo,
                    transaction: presresp,
                    gameName: props?.pathName,
                    Bets: multipleBets,
                    Wager: props?.wager,
                    CalculateWager: props?.calculatewager,
                    TotalWager: props?.totalwager,
                    Amount: amount,
                    OrderId: props?.usergamedetails?._id
                  }
                } else if (presresp?.isWin == false) {
                  data = {
                    walletAddress: accountInfo,
                    transaction: presresp,
                    gameName: props?.pathName,
                    Bets: multipleBets,
                    Wager: props?.wager,
                    CalculateWager: props?.calculatewager,
                    TotalWager: props?.totalwager,
                    Amount: presresp?.maxpayout,
                    OrderId: props?.usergamedetails?._id
                  }
                }

              } else if ("0x" == String(presresp?.Multiplier)) {
                data = {
                  walletAddress: accountInfo,
                  transaction: presresp,
                  gameName: props?.pathName,
                  Bets: multipleBets,
                  Wager: props?.wager,
                  CalculateWager: props?.calculatewager,
                  TotalWager: props?.totalwager,
                  Amount: presresp?.maxpayout,
                  OrderId: props?.usergamedetails?._id
                }
              }
            })

          } else if (presresp?.isWin == false) {
            if ("0x" == String(presresp?.Multiplier)) {
              data = {
                walletAddress: accountInfo,
                transaction: presresp,
                gameName: props?.pathName,
                Bets: multipleBets,
                Wager: props?.wager,
                CalculateWager: props?.calculatewager,
                TotalWager: props?.totalwager,
                Amount: presresp?.maxpayout,
                OrderId: props?.usergamedetails?._id
              }
            }
          }

          let lossamount = 0, gainamount = 0;
          var resultamount = 0;

          console.log('props?.gamepercentage', props?.gamepercentage)
          props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
            if (String(Object.keys(val)[0]) == String(presresp?.Multiplier)) {
              console.log("result amount", Object.values(val)[0], props?.calculatewager)
              let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
              let amount = Number(props?.calculatewager) + Number(wonpercentage);
              console.log('multipliet data', Object.values(val)[0], wonpercentage, amount)
              if (presresp?.isWin == false) {
                lossamount = presresp?.maxpayout;
                resultamount = presresp?.maxpayout;
              } else {
                gainamount = amount;
                resultamount = amount;
              }
            } else if ("0x" == String(presresp?.Multiplier)) {
              if (presresp?.isWin == false) {
                lossamount = presresp?.maxpayout;
                resultamount = presresp?.maxpayout;
              }
            }
          });
          console.log("lossamount, gainamount", lossamount, gainamount, data)
          let res = await CreateGameTransactionHooks(data);
          console.log("orderdata ruru1111", res);

          if (res?.status == 200) {
            // let createres = await createOrderdetails(props?.pathName, props?.wager, props?.remainingbets, props?.betamount, [props.selected[0], props.selected[1]], accountInfo, props.stoploss, props.stopgain, gainamount, lossamount, props?.calculatewager);
            let createres = await createOrderdetails(props?.pathName, props?.wager, props?.remainingbets, props?.betamount, [props.selected[0], props.selected[1]], accountInfo, props.stopgain, props.stoploss, gainamount, lossamount, props?.maxpayout, props?.calculatewager, props?.totalwager, '', '');
            console.log("createrres", createres)
            let result = createres?.data?.data;
            if (createres?.status == 200) {
              if (createres?.data?.status == true) {
                console.log("update balance", result, result?.RemainingBets == 0)
                if (result?.RemainingBets == 0) {
                  let reqData = { _id: result?._id, walletAddress: accountInfo }
                  let res = await UpdateGameSettingBalancehooks(reqData);
                  console.log("update balance 212", res)
                  if (res?.data?.status == true) {
                    setTimeout(() => {
                      props?.setFeeData()
                    }, 3000)
                  }
                }
              }
              console.log('update balance 3232', props?.remainingbets)
              if (props?.remainingbets == 1) {
                setTimeout(function () {
                  setCheck(true);
                  props?.checkOrder()
                  props.onCheck()
                  setStop(true)
                }, 3000);
              } else {
                setCheck(true);
                props?.checkOrder()
                props.onCheck();
                setStop(true)
              }

            }

          }
        }
        console.log("FinishInterval arr", incrementbetcount, multipleBets, presresp?.maxpayout);
        let resultamounts =
          Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
            Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
              Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                  Number(toFixedNumber(Number(resultamount))).toFixed(11);
        console.log('result amount sdata', resultamount)
        if (presresp?.isWin == false) {
          // toast.error(incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + resultamounts, {
          //   position: position.position,
          //   style: style,
          //   iconTheme: iconTheme,
          // });
          Swal.fire({
            title: "You Lose!",
            text: incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + resultamounts,
            // imageUrl: "../assets/flip1.png",
            iconHtml: `<img src=${down}>`,
            timer: 4200,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
          });                   
        } else {
          // ${incrementbetcount}  /  ${multipleBets}  You Victory   +${(presresp.maxpayout).toFixed(4)}
          // toast.success(incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + resultamounts, {
          //   position: position.position,
          //   style: style,
          //   iconTheme: iconTheme,
          // });
          Swal.fire({
            title: "Success!",
            text: incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + resultamounts,
            // imageUrl: "../assets/flip1.png",
            iconHtml: `<img src=${winning}>`,
            timer: 4200,
            backdrop: `
            rgba(0,0,123,0.4)
            url("${winss}")
            center top            
            no-repeat
          `,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image"
          });
        }
        // setResult([...arr, ...result]);
        let sum = incrementbetcount + 1;
        setIncrementBetCount(sum);
        console.log("props?.usergamedetails?.length orderdata ruru", props?.usergamedetails)

      }
    } catch (e) {
      console.log("FinishInterval_err", e)
    }
  }


  function generateRandom(min, max) {

    // find diff
    let difference = max - min;

    // generate random number 
    let rand = Math.random();

    // multiply with difference 
    rand = Math.floor(rand * difference);

    // add with min value 
    rand = rand + min;
    console.log('randomly genearate number', rand)
    return rand;
  }

  const rollDice = async (rollCount) => {
    try {
      console.log('rollcount data', rollCount, allowin)
      if (allowin) {
        if (!btnDisabled) {
          let rollcount = rollCount + 1;
          // let rollData = localStorage.setItem("MultipleBets")

          let diceface;
          let dicefacesecond;
          console.log("check_condition", rollcount, multipleBets, rollcount <= multipleBets)
          if (rollcount <= multipleBets) {
            console.log("dice roll");
            setBtnDisabled(true);
            clearInterval(intrvl);
            let maxrollrand = generateRandom(7, maxRollTimes);
            let counter = maxrollrand;
            console.log("counter_data", counter)
            // setRollTimes(counter);
            const interval = setInterval(async () => {
              console.log("Math.random()_Math.random()", counter, Math.random());
              diceface = Math.floor(Math.random() * faces) + 1;
              // dicefacesecond = Math.floor((Math.random() + (Math.random() / 2)) * faces) + 1;
              dicefacesecond = Math.floor(Math.random() * faces) + 1;
              console.log("diceface_dicefacesecond kuku", diceface, dicefacesecond)
              setDiceFace(diceface);
              setDiceFaceSecond(dicefacesecond);
              counter--;
              console.log('counterrrrrrrr', counter)
              setRollTimes(counter);
            }, 200);
            console.log('counter_data', counter)
            console.log("Interval_data", interval)
            setIntrvl(interval);
            setRollCount(rollcount);
          } else {
            console.log("enter this condition juju")
            setBtnDisabled(true)
            clearInterval(intrvl)
          }
        }

      } else {

        if (!btnDisabled) {
          console.log('dicearrdata_dicearrdata', resultdicearr)
          let rollcount = rollCount + 1;
          // let rollData = localStorage.setItem("MultipleBets")
          setStop(true)
          let diceface;
          let dicefacesecond;
          console.log("check_condition", rollcount, multipleBets, rollcount <= multipleBets)
          if (rollcount <= multipleBets) {
            console.log("dice roll", resultdicearr);
            let min = 0;
            let max = resultdicearr?.length - 1;
            console.log('min_max_data', resultdicearr, resultdicearr?.length, min, max)
            let randdata = generateRandom(min, max);

            console.log('randddddddd', resultdicearr, randdata, resultdicearr[randdata])
            setBtnDisabled(true);
            clearInterval(intrvl);
            let maxrollrand = generateRandom(7, maxRollTimes);
            let counter = maxrollrand;
            console.log("counter_data", counter)
            // setRollTimes(counter);
            const interval = setInterval(async () => {
              console.log("Math.random()_Math.random()", counter, Math.random());
              diceface = Math.floor(Math.random() * faces) + 1;
              // dicefacesecond = Math.floor((Math.random() + (Math.random() / 2)) * faces) + 1;
              dicefacesecond = Math.floor(Math.random() * faces) + 1;
              console.log("diceface_dicefacesecond kuku", diceface, dicefacesecond)

              console.log('asdfasdfasdf randddddddd', diceface, dicefacesecond)
              setDiceFace(diceface);
              setDiceFaceSecond(dicefacesecond);
              counter--;
              console.log('a53453445345345', counter);
              console.log('same console data', counter)
              if (counter <= 0) {
                if (diceface) {
                  setDiceFace(resultdicearr[randdata][0]);
                }
                if (dicefacesecond) {
                  setDiceFaceSecond(resultdicearr[randdata][1]);
                }
              }
              setRollTimes(counter);
            }, 200);
            console.log('counter_data', counter)
            console.log("Interval_data", interval)
            setIntrvl(interval);
            setRollCount(rollcount);
          } else {
            console.log("enter this condition juju")
            setBtnDisabled(true)
            clearInterval(intrvl)
          }
        }
      }


    } catch (err) {
      console.log("rollDice_errrrr", err)
    }
  }

  return (
    <div className="App gamesdices">         
      <div className='container game_container'>
        <div className='dice_flex'>
          <div className="dice-container">
            <div className={`dice face-${diceFace}`}>
              <div className="face-1 faces_dice">
                <div className="dot-container">
                  <div className="dot"></div>
                </div>
              </div>
              <div className="face-3 faces_dice">
                <div className="dot-container">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
              <div className="face-4 faces_dice">
                <div className="dot-container">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
              <div className="face-2 faces_dice">
                <div className="dot-container">
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
              <div className="face-5 faces_dice">
                <div className="dot-container">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
              <div className="face-6 faces_dice">
                <div className="dot-container">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="dice-container">
            <div className={`dice face-${diceFaceSecond}`}>
              <div className="face-1 faces_dice">
                <div className="dot-container">
                  <div className="dot"></div>
                </div>
              </div>
              <div className="face-3 faces_dice">
                <div className="dot-container">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
              <div className="face-4 faces_dice">
                <div className="dot-container">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
              <div className="face-2 faces_dice">
                <div className="dot-container">
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
              <div className="face-5 faces_dice">
                <div className="dot-container">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
              <div className="face-6 faces_dice">
                <div className="dot-container">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* { showProgress && result && result.length > 0?

<>

{ <div className='pos_stargery_card' id="pos_stargery_card">
    <div className="text-right mb-2">
    <button className="btn-close" 
    onClick={()=>{setShowprogress(false)}}
    
    >
        <i className="fa fa-times" />
    </button>
    </div>
{ result && result.length > 0 && result.map((val, i) =><div className='card-body p-1' id="pos_stargery_card_body">
<div className={val.isWin == true ?  'intimate_card intimate_card_green mb-2' : 'intimate_card intimate_card_red mb-2'} id="intimate_card_1">
<div className="d-flex align-items-center justify-content-between">
<p className="mb-0">{i +1 +"/"+multipleBets }
<span className={val.isWin == true ? 'badge badge_grn' : 'badge badge_rd'}>{multipleBets}</span>
</p>
<p className="mb-0">
{(val.isWin == true ?  val.maxpayout : "")}
<span>
<img src={tablecoin} alt="images" className="img_cin_stra"/>
</span>
</p>
</div>

<div className="progress_time">
<div className="prog_bar_hide">
<div className="myBar" id={`myBar_${String(i + 1)}`}></div>
</div>
</div>
</div>
</div>)}
</div>}</>
   
:
<></>
} */}

        {/* <div className='intimate_card intimate_card_red mb-2' id="intimate_card_2">
<div className="d-flex align-items-center justify-content-between">
<p className="mb-0">2/10
<span className='badge badge_rd'>14</span>
</p>
<p className="mb-0">
+22.47
<span>
<img src={tablecoin} alt="images" className="img_cin_stra"/>
</span>
</p>
</div>
<div className="progress_time">
<div className="prog_bar_hide">
<div className="myBar" id="myBar_2"></div>
</div>
</div>
</div>

<div className='intimate_card intimate_card_green mb-2' id="intimate_card_3">
<div className="d-flex align-items-center justify-content-between">
<p className="mb-0">2/10
<span className='badge badge_grn'>14</span>
</p>
<p className="mb-0">
+22.47
<span>
<img src={tablecoin} alt="images" className="img_cin_stra"/>
</span>
</p>
</div>
<div className="progress_time">
<div className="prog_bar_hide">
<div className="myBar" id="myBar_3"></div>
</div>
</div>
</div>
<div className='intimate_card intimate_card_red mb-2' id="intimate_card_4">
<div className="d-flex align-items-center justify-content-between">
<p className="mb-0">2/10
<span className='badge badge_rd'>14</span>
</p>
<p className="mb-0">
+22.47
<span>
<img src={tablecoin} alt="images" className="img_cin_stra"/>
</span>
</p>
</div>
<div className="progress_time">
<div className="prog_bar_hide">
<div className="myBar" id="myBar_4"></div>
</div>
</div>
</div> */}
        <div className='container game_container dice_btn rolldice'>
          <button className="btn btn-warning wallet play mt-4" disabled={btnDisabled} onClick={() => { rollDice(rollCount) }}>
            {btnDisabled}
            Play
          </button>
        </div>




      </div>
    </div>
  );
}

export default Dice;
