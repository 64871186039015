import BigNumber from 'bignumber.js';

import toast, { Toaster } from 'react-hot-toast';
import { UseTokenInfo, toFixedNumber, UseMasterChef, UsehttpMasterChef } from './UseContract'



export const getPoolInfoLimit = async (start, end) => {
    try {
        const totalPool = [];
        const length = await getPoolLength();
        if (length > 0) {
            for (var i = start; i <= end; i++) {
                if (i < length) {
                    const poolarr = await getPool(i);
                    totalPool.push(poolarr);
                }
            }
        }
        console.log("totalPool_totalPooltotalPooltotalPoolv", totalPool);
        return totalPool;
    } catch (e) {

    }
}

export const getPool = async (pid) => {
    try {
        // const stakecontract = await UseMasterChef();
        const stakecontract = await UsehttpMasterChef();
        const Pool = await stakecontract.methods.poolInfo(pid).call();
        return Pool;
    } catch (e) {

    }
}

export const getPoolLength = async () => {
    try {
        // const stakecontract = await UseMasterChef();
        const stakecontract = await UsehttpMasterChef();
        console.log("stakecontract_stakecontract", stakecontract);
        const length = await stakecontract.methods.poolLength().call();
        console.log("length_length_lengthlength", length);
        return length;
    } catch (e) {

    }
}

export const plan = async (pid) => {
    // console.log('PID : ', pid);
    try {
        const stakecontract = await UsehttpMasterChef()
        const planDetails = await stakecontract.methods._plan(pid).call();
        // console.log("planLength",planDetails)
        return planDetails
    } catch (e) {
        console.log("ERR", e)
    }

}


export const pooldetails = async (pid) => {
    // console.log('PID : ', pid);
    try {
        const stakecontract = await UsehttpMasterChef()
        const planDetails = await stakecontract.methods.poolInfo(pid).call();
        console.log("planLength", planDetails)
        return planDetails
    } catch (e) {
        console.log("ERR", e)
    }

}

export const getPairPid = async (stakeAddress, rewardAddress) => {
    // console.log('PID : ', stakeAddress,rewardAddress);
    try {
        const stakecontract = await UsehttpMasterChef()
        // console.log("stakecontract>>>>",stakeAddress,rewardAddress);
        const planDetails = await stakecontract.methods.pid(stakeAddress, rewardAddress).call();
        console.log("planLength_planDetails", planDetails)
        return planDetails
    } catch (e) {
        console.log("ERR", e)
    }

}


export const getUser = async (pid, account) => {
    try {
        // const stakecontract = await UseMasterChef();
        console.log("pid_account", pid, account);
        const stakecontract = await UsehttpMasterChef();
        const User = await stakecontract.methods.userInfo(pid, account).call();
        console.log("User_user", User);
        return User;
    } catch (e) {

    }
}


export const getUSDTprice = async (value, stakeaddress, rewardToken) => {
    console.log("value", value, "stakeaddress", stakeaddress, "rewardToken", rewardToken);
    try {

        let USDTprice = 0;
        if (value > 0) {
            const stakecontract = await UsehttpMasterChef();
            // const stakecontract = await UseMasterChef();
            // USDTprice = await stakecontract.methods.calculatePrice(value).call();
            // USDTprice = await stakecontract.methods.calculatePrice(String(value),stakeaddress).call();
            USDTprice = await stakecontract.methods.calculatePrice(String(value), stakeaddress, rewardToken).call();
        }
        console.log("PRICE", USDTprice)
        return USDTprice;
    } catch (e) {

    }




}

export const getUserReward = async (pid, account) => {
    try {
        // const stakecontract = await UseMasterChef();
        const stakecontract = await UsehttpMasterChef();
        const user = await stakecontract.methods.pendingTost(pid, account).call()
        return user;
    } catch (e) {

    }
}

export const getRewardTokenInfo = async (_token) => {
    console.log("_token__token__tokenv_token", _token);
    try {

        const tokeninfo = await UseTokenInfo(_token);
        console.log("tokeninfotokeninfotokeninfo", tokeninfo);
        return tokeninfo;
    } catch (e) {

    }
}

export const getWholeNumber = (value, decimal) => {
    console.log("decimal_decimal_decimaldecimal", value,decimal);
    try {
        const balance = new BigNumber(value).times(new BigNumber(10).pow(decimal));
        let finalval = (balance).toNumber();
        finalval = toFixedNumber(finalval);
        console.log("finalval_finalval", finalval)
        return finalval.toString();
    } catch (e) {

    }
}

export const OnStake = async (account, pid, amount, decimal) => {
    try {
        console.log("pid>>>>>>>>>>>> juju", pid, amount, account)
        const stakingContract = await UseMasterChef();
        const convertedAmount = getWholeNumber(amount, decimal);
        console.log("convertedAmount", convertedAmount,stakingContract?.methods);
        const data = stakingContract.methods.deposit(pid.toString(), convertedAmount.toString(), account).send({ from: account }).on('transactionHash', async (tx) => {

            localStorage.setItem("HASH", JSON.stringify(tx));
        })

        await toast.promise(data, {
            loading: 'Depositing ...',
            success: 'Deposit Successfully',
            error: 'Try Again',
        }, {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })
        await window.location.reload()
    } catch (error) {
        console.log("error on onstake", error);
    }

}

export const pending = async (account, pid) => {
    const stakingContract = await UseMasterChef();
    const data = await stakingContract.methods.pendingWINR(pid, account).call();
    console.log("pendingg---", data);
    return data
}

export const UnStake = async (account, pid, amount, decimal) => {
    try{
    console.log("account , pid, amount, decimal,isMax_account , pid, amount, decimal,isMax", account, pid, amount);
    const stakingContract = await UseMasterChef();
    // const amt = toFixedNumber(amount * 10 ** decimal) 
    // console.log("amt",amt,amount,);
    // const convertedAmount = getWholeNumber(amount, decimal);
    console.log("stakingContract_stakingContract_",stakingContract);
    console.log("pid--", pid, amount);
    const data =  stakingContract.methods.withdraw(pid, amount).send({ from: account });

    await toast.promise(data, {
        loading: 'Withdrawing ...',
        success: 'Withdraw Successfully',
        error: 'Try Again',
    }, {
        style: {
            minWidth: '300px',
            minHeight: '55px'
        }
    })
    await window.location.reload()
}
catch(err){
    console.log("error on unstake", err);
}
}

export const Claim = async (account, pid) => {
    try {
        const stakingContract = await UseMasterChef();
        const data = stakingContract.methods.claim(pid).send({ from: account });
        await toast.promise(data, {
            loading: 'Claim ...',
            success: 'claim Rewards Successfully',
            error: 'Try Again',
        }, {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })
    
        await window.location.reload()
        
    } catch (error) {
        console.log("error--",error);
    }
 
}

export const UseRoundValue = (VALUE, DECIMALS) => {
    try {
        return VALUE > 0 ? new BigNumber(VALUE).div(new BigNumber(10).pow(DECIMALS)) : new BigNumber(0);
    } catch (e) {

    }
}

export const convertToDate = (dateString) => {
    try {
        let d = dateString.split("/");
        let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
        return dat
    } catch (e) {
        console.log("convertToDate ERR", e)
    }
}

export const FARMS = [
    {
        lpSymbol: 7,
    },
    {
        lpSymbol: 30,
    },
    {
        lpSymbol: 90,
    },
    {
        lpSymbol: 180,
    }
    ,
    {
        lpSymbol: 365,
    }
]

