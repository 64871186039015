import React,{useState, useEffect} from "react";
import { Modal } from 'react-bootstrap';
import {  useLocation, useNavigate } from "react-router-dom";
import meta from '../../assets/metamask-logo.svg';
import walletconnect from '../../assets/walletconnect-logo.svg';
import coinbase from '../../assets/coinbase-logo.svg';
import okx from '../../assets/okx-logo.svg';
import toast, { Toaster } from 'react-hot-toast';
import bitkeep from '../../assets/bitkeep-logo.svg';
import foxwallet from '../../assets/fox-wallet.svg';
import  { MetamaskWallet, WalletConnectfun } from '../../hooks/useWallet'
import {sleep} from "../../hooks/UseContract"
import { UseWeb3, useWeb3 } from "../../hooks/useWeb3";
import { getuserdatabyrefferalIdhooks } from "../../hooks/UseUser";

 
const Walletmodal = (props) => {
    const [showpage, setShowPage] = useState(true);
    const [userdata, setUserData] = useState({});
    let location = useLocation();
    let navigate = useNavigate();
    useEffect(() => {
        async function fetchData() {
            let refferalId = location?.pathname?.split("/")[1];
            console.log("licatiasdfasdf", location?.pathname?.split("/")[1]);
            if (refferalId != null && refferalId != "" && refferalId != undefined && refferalId != "referral" && refferalId != "earn" && refferalId != "games") {
                let res = await getuserdatabyrefferalIdhooks(refferalId);
                console.log("resdata", res?.data?.record);
                if (res?.data?.success == true) {
                    setUserData(res?.data?.record)
                    setShowPage(true)
                } else {
                    setUserData({})
                    setShowPage(false)
                }
            }
        }

        fetchData()
    }, [location]);


    

    const [walletmodal, setWalletmodal] = useState(true)

    const [wallet , setShowWallet] = useState(true)  
    const [walletcount,setWalletcount] = useState(0);
    const [accountInfo, setAccountInfo] = useState("");
    const [walletConnect, set_walletConnect] = useState("");


    const MetaMask = async () => {
        try {
            console.log("metamaskwalletconnect")
            await MetamaskWallet()
            await sleep(1000)
           let data = await UseWeb3();
            console.log('enter this', data)
            console.log(sessionStorage.getItem("accountInfo"),"ACCOUNT INFO........");
            if(data) {
                setAccountInfo(sessionStorage.getItem("accountInfo"))
                console.log(accountInfo,"Wallet  Address........")
                if (sessionStorage.accountInfo) {
                    setShowWallet(false)
                    toast.success("Wallet Connected successfully", {
                        duration: 2000,
                        style: {
                            minWidth: '300px',
                            minHeight: '55px'
                        }
                    })
                  
                }
            }
        } catch (e) {
            console.log('MetaMask_err', e)
        }

    }


    const ConnectWallet = async () => {
        try {
            console.log("metamaskwalletconnect userdatasdfasdfasdf")
            props?.onDismiss()
            let wallet = await WalletConnectfun();
            console.log("wakkksdfasdfasd",wallet)

            await sleep(1000)
           let data = await UseWeb3();
            console.log('enter this userdatasdfasdfasdf', data)

            console.log(sessionStorage.getItem("accountInfo"),"ACCOUNT INFO........");
            if(data) {
                setAccountInfo(sessionStorage.getItem("accountInfo"))
                console.log(accountInfo,"Wallet  Address........")
                if (sessionStorage.accountInfo) {
                    setShowWallet(false)
                    toast.success("Wallet Connected successfully", {
                        duration: 2000,
                        style: {
                            minWidth: '300px',
                            minHeight: '55px'
                        }
                    })
                    window.location.reload();
                }
            }
        } catch (e) {
            console.log("ConnectWallet_err", e);
        }
    }
    console.log("walletmodaldatatrue", walletmodal, showpage)

    return (

        <>
       {showpage ? <Modal show={walletmodal}
            className='stakess add-nft-inner'
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>

            <Modal.Header onClick={props.onDismiss}>                 
                <Modal.Title className="fw-700 whtclr">Connect Wallet</Modal.Title>
                <button type="button" class="btn-close" aria-label="Close" ></button>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                <div className="col-md-6 col-sm-6 mt-3">
                    <div className="d-flex align-items-center walletlist">
                    <img className="img-fluid" src={meta} alt="pig" />
                    {/* <h6 className="px-3 mb-0" onClick={()=>{localStorage.setItem("metamask",true);props.onDismiss();window.location.reload()}}>Meta Mask</h6> */}
                    <div className="px-3 mb-0 whtclr" onClick={()=>{setWalletcount(1);MetaMask(); console.log("Meta mask")}} >Meta Mask</div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 mt-3">
                    <div className="d-flex align-items-center walletlist">
                    <img className="img-fluid" src={walletconnect} alt="pig" />
                    <h6 className="px-3 mb-0 whtclr" onClick={() => {ConnectWallet(); console.log("walletconnect")}}>Wallet Connect </h6>
                    </div>
                </div>
                {/* <div className="col-md-6 col-sm-6 mt-3">
                    <div className="d-flex align-items-center walletlist">
                    <img className="img-fluid" src={coinbase} alt="pig" />
                    <h6 className="px-3 mb-0">Coinbase</h6>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 mt-3">
                    <div className="d-flex align-items-center walletlist">
                    <img className="img-fluid" src={okx} alt="pig" />
                    <h6 className="px-3 mb-0">OKX Wallet</h6>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 mt-3">
                    <div className="d-flex align-items-center walletlist">
                    <img className="img-fluid" src={bitkeep} alt="pig" />
                    <h6 className="px-3 mb-0">Bitkeep Mask</h6>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 mt-3">
                    <div className="d-flex align-items-center walletlist">
                    <img className="img-fluid" src={foxwallet} alt="pig" />
                    <h6 className="px-3 mb-0">Fox Wallet</h6>
                    </div>
                </div> */}
                </div>
            </Modal.Body>

        </Modal> : <></>}

        </>

    );
}

export default Walletmodal;