import axios from 'axios';
import toast from "react-hot-toast"
import { BACKURL } from '../config/env';

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const getAuthToken = () => {

    try {
        let token = localStorage.getItem("accessToken")
        if (token != '' && token != null && token != undefined) {
            return localStorage.getItem("accessToken");
        }

    } catch (err) {
        console.log("getAuthTokenerrrrrr", err)
    }
}

axios.defaults.headers.common['Authorization'] = getAuthToken(); 

export const CreateGameTransactionHooks = async (data) => {
    try {
        let respData = await axios.post(BACKURL + "/api/user/addusergametransaction", data);
        console.log("respData_respData", respData)
        if (respData?.status == 200) {
            return respData;
        }

    } catch (e) {
        console.log("UserTransaction_err", e)
    }

}



export const getGameAllTransactionInfoHooks = async (data) => {
    try {
        console.log("datas", data)
        let respData = await axios.post(BACKURL + "/api/user/getallgameusertransactiondetails", data);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getGameAllTransactionInfoHooks_err", e)
    }
}

export const createTransactionHooks = async (data) => {
    try {
        let respData = await axios.post(`${BACKURL}/api/user/createtransaction`, data);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getRefferInfo_err", e)
    }
}


export const getgametransdetails = async (data) => {
    try {
        console.log("asnodfasdfasdfs sdfasdf", data)
        let respData = await axios.post(`${BACKURL}/api/user/getgametransactiondata`, data);
        if (respData?.status == 200) {
            return respData;
        }
    } catch (e) {
        console.log("getRefferInfo_err", e)
    }
}