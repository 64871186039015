import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from 'react-bootstrap';
import Header from '../Layouts/Header';
import pig from '../assets/pig.png';
import groupcoin from '../assets/groupcoin.png';
import bottomcoin from '../assets/bottomcoin.png';
import { Link, useLocation, useNavigate } from "react-router-dom";
import facebook from '../assets/Frame (1).png';
import telegram from '../assets/Frame (2).png';
import discard from '../assets/Frame (3).png';
import mirror from '../assets/Frame (4).png';
import git from '../assets/Frame (5).png';
import AOS from "aos";
import "aos/dist/aos.css";
import { getuserdatabyrefferalIdhooks } from "../hooks/UseUser";
// import videoss from "../assets/honeybannervideo (2).mp4";
import Lottie from "lottie-react";
import home1 from "../assets/data.json";
import backimg from "../assets/backss.json";
import topcoin from "../assets/topcoin.png";
import botcoin from "../assets/botcoin.png";

export default function Home() {
    const [userdata, setUserData] = useState({});
    let location = useLocation();
    const [showpage, setShowPage] = useState(true);
    useEffect(() => {
        AOS.init();
        AOS.refresh();
        
    }, []);


    let navigate = useNavigate()
    useEffect(() => {
        async function fetchData() {
            let refferalId = location?.pathname?.split("/")[1];
            console.log("licatiasdfasdf", location?.pathname?.split("/")[1]);
            let accountInfo = sessionStorage.getItem("accountInfo")
            console.log("check condition", (refferalId != null && refferalId != "" && refferalId != undefined) && (accountInfo == null || accountInfo == undefined || accountInfo == "undefined"))
            if ((refferalId != null && refferalId != "" && refferalId != undefined) && (accountInfo == null || accountInfo == undefined || accountInfo == "undefined")) {

                let res = await getuserdatabyrefferalIdhooks(refferalId);
                console.log("resdata", res?.data?.record);
                if (res?.data?.success == true) {
                    localStorage.setItem("refferalId", refferalId)
                    setUserData(res?.data?.record)
                    setShowPage(true)
                } else {
                    setUserData({})
                    setShowPage(false)
                }
            }
        }
        fetchData()
    }, [location]);

    useEffect(() => {
        let getRefferal = localStorage.getItem("refferalId");
        let accountInfo = sessionStorage.getItem("accountInfo")
        console.log("getRefferaldata", getRefferal)
        if ((getRefferal != "" && getRefferal != null && getRefferal != undefined) && (accountInfo == null || accountInfo == undefined || accountInfo == "undefined")) {
            navigate(`/${getRefferal}`)
        }
    }, [location])
    return (
        <>
            <div className="bg_dice pos">
            <Lottie animationData={backimg} loop={true} className="backsss" />
                {showpage ? <Header /> : ""}

                {showpage ? <div className="home">
                    <div className="pt-0 socials">
                        <Link className="mx-0" to="https://twitter.com/" target="_blank"><img className="img-fluid" src={facebook} alt="pig" /></Link>
                        <Link to="https://telegram.org/" target="_blank"><img className="img-fluid" src={telegram} alt="pig" /></Link>
                        <Link to="https://discord.com/" target="_blank"><img className="img-fluid" src={discard} alt="pig" /></Link>
                        <Link to="/" target="_blank"><img className="img-fluid" src={mirror} alt="pig" /></Link>
                        <Link to="https://github.com/" target="_blank"><img className="img-fluid" src={git} alt="pig" /></Link>
                    </div>
                     <img src={topcoin} className="topcoin"/>
                     <img src={botcoin} className="botcoin"/>
                    <div className="container home_container allhome">
 
                        <div className="row pt-4 align-items-center">
                            <div className="col-md-11 col-lg-9 col-xl-10 col-xxl-8 pt-3 mx-auto">
                                <h1 className="fw-800 mb-4 whtclr text-center hom_head">Win More Than You Can Lose</h1>
                                <p className="text-center px-1 px-xxl-4 mb-3 f-12">Decentralized on-chain casino. Be the house, or beat the house. Token
                                    rewards on each bet that <br></br>owns you a piece of the casino forever, with
                                    daily revenue sharing.
                                    <br></br><br></br>
                                    Bet directly through your wallet, settled instantly into your address:<br></br>
                                    no middleman, no KYC, no tampering, no bullsh*t.
                                </p>
                                <Link to="/games"><Lottie animationData={home1} loop={true} className="mt-5 mt-sm-5 mt-xxl-4 mb-4 blueice" /></Link>
                            </div>

                            {/* <div className="col-md-6 col-xl-5 col-xxl-4 pt-3" data-aos="fade-up" data-aos-duration="2000">
                                <h1 className="fw-800 mb-4 whtclr">Win More Than<br></br> You Can Lose</h1>
                                <p>Decentralized on-chain casino. Be the house, or beat the house. Token
                                    rewards on each bet that owns you a piece of the casino forever, with
                                    daily revenue sharing.
                                    <br></br><br></br>
                                    Bet directly through your wallet, settled instantly into your address:
                                    no middleman, no KYC, no tampering, no bullsh*t.
                                </p>

                                <Link to="/games"><button type="button" class="btn btn-warning wallet play mt-4"> PLAY </button></Link>



                                <div className="row contribution pt-3 align-items-center">
                                    <div className="col-6 col-sm-4 col-md-4 mt-3">
                                        <h6 className="fw-700">Betting Volume</h6>
                                        <h6 className="fw-600">$31,346,848</h6>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-4 mt-3">
                                        <h6 className="fw-700">Bankroll Size</h6>
                                        <h6 className="fw-600">$2,712,632</h6>
                                    </div>
                                    <div className="col-6 col-sm-4 col-md-4 mt-3">
                                        <h6 className="fw-700">Revenue Shared</h6>
                                        <h6 className="fw-600">$167,018</h6>
                                    </div>
                                </div>

                                <div className="pt-5 social">
                                    <Link className="mx-0" to="https://twitter.com/" target="_blank"><img className="img-fluid" src={facebook} alt="pig" /></Link>
                                    <Link to="https://telegram.org/" target="_blank"><img className="img-fluid" src={telegram} alt="pig" /></Link>
                                    <Link to="https://discord.com/" target="_blank"><img className="img-fluid" src={discard} alt="pig" /></Link>
                                    <Link to="/" target="_blank"><img className="img-fluid" src={mirror} alt="pig" /></Link>
                                    <Link to="https://github.com/" target="_blank"><img className="img-fluid" src={git} alt="pig" /></Link>
                                </div>
                            </div> */}

                            {/* <div className="col-md-6 col-xl-7 col-xxl-8 pt-3 leftside" data-aos="fade-down" data-aos-duration="2000">
                                <img className="img-fluid" src={pig} alt="pig" />
                                <img className="img-fluid allcoin" src={groupcoin} alt="pig" />

                                <video style={{ width:"100%", height:"100%" }} autoPlay loop muted>
                                    <source src={videoss} type="video/mp4" />                              
                                </video>
                            </div> */}
                        </div>
                        {/* <img className="bottomcoin img-fluid" src={bottomcoin} alt="pig" /> */}
                    </div>
                </div> : <></>}

            </div>
        </>
    );
}