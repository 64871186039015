
import isEmpty from "is-empty";
const MinuteHourFormat = (data) => {
    return ((Number(isEmpty(data) ? 0 : data ) < 10 ? '0' : '')+data)
  }

  
  export const DateTimeForm = (date,datealone,timealone,ampm) => {
    try{
        console.log("DateTimeForm", date, MinuteHourFormat(new Date(date)?.getSeconds()))
      if(datealone){
        return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}`
      }
      else if(timealone){
        if(ampm){
          return `
          ${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours()) }
          : ${MinuteHourFormat(new Date(date)?.getMinutes())} : ${MinuteHourFormat(new Date(date)?.getSeconds())}`
        }
        else{
          return `${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())}: ${MinuteHourFormat(new Date(date)?.getSeconds())} `
        }
      }
      else if(ampm){
        return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}, ${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes()) } ${new Date(date)?.getHours() >= 12 ? 'PM' : 'AM'} `
      }
      return `${MinuteHourFormat(new Date(date)?.getDate())}:${MinuteHourFormat(new Date(date)?.getMonth()+1)}:${new Date(date)?.getFullYear()},${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())} `
    }
    catch(err){
      console.log('dskhfskfs',err)
      return "No Date"
    }
  }