
import {  getPredictionByName } from "../config/env";

export const DiceGameCondition = async (gamedata, pathName, range, gameconditiondetails) => {

  try {
    // debugger
    if (pathName == "Limbo" || pathName == "Range") {
      // const data = range;
      console.log("raesfdhasdfamsdf", Object.values(range))
      let gameconditions = Object.values(range)[0];

      console.log("gamecondition_gamecondition", gameconditions[0])
      const values = []
      let stringdata = '';
      let str = ''
      const Replacedata = (replacedata, replacestring, replaceNumber) => {
        console.log('Replace_data', replacedata, replacestring, replaceNumber);
        str = replacedata.replace(replacestring, replaceNumber);
        console.log('statatataqtatatata', str)
        return str;
      }
      for (let i = 0; i < Object.keys(gamedata)?.length; i++) {
        for (let j = 0; j < gameconditions?.length; j++) {
          console.log("gameconditions_gameconditions", values?.[j]?.[Object.keys(gameconditions[j])[0]], Object.values(gameconditions[j])[0], Object.values(gamedata)[i])
          if (values?.[j]?.[Object.keys(gameconditions[j])[0]]) {
            let text = values?.[j]?.[Object.keys(gameconditions[j])[0]];
            let findata = text.includes("##predictRps##");
            if (findata) {
              stringdata = Replacedata(text, "##predictRps##", Object.values(gamedata)[0])
            } else {
              stringdata = values?.[j]?.[Object.keys(gameconditions[j])[0]]
            }

          } else {
            stringdata = Object.values(gameconditions[j])[0]
          }
          console.log("stringdata sdfasdfasdf", stringdata)
          stringdata = stringdata.replace('##' + String(Object.keys(gamedata)[i]) + '##', Object.values(gamedata)[i]);
          values[j] = { [Object.keys(gameconditions[j])[0]]: stringdata }
        }
      }

      console.log(" quju arr_data 6666", values, values?.length)
      return values;
    } else if (pathName == "Plinko") {
      console.log("raesfdhasdfamsdf", Object.values(range))
      let gameconditions = Object.values(range)[0];
      console.log("gamecondition_gamecondition", gameconditions[0])
      const values = []
      let stringdata = '';
      let str = ''
      const Replacedata = (replacedata, replacestring, replaceNumber) => {
        console.log('Replace_data', replacedata, replacestring, replaceNumber);
        str = replacedata.replace(replacestring, replaceNumber);
        console.log('statatataqtatatata', str)
        return str;
      };



      for (let i = 0; i < Object.keys(gamedata)?.length; i++) {
        for (let j = 0; j < gameconditions?.length; j++) {
          console.log("gameconditions_gameconditions", values?.[j]?.[Object.keys(gameconditions[j])[0]], Object.values(gameconditions[j])[0], Object.values(gamedata)[i]);

          if (values?.[j]?.[Object.keys(gameconditions[j])[0]]) {
            let text = values?.[j]?.[Object.keys(gameconditions[j])[0]];
            console.log("111 enter this stringdata", text);
            let findata = text.includes("##resultPlinko##");
            if (findata) {
              stringdata = Replacedata(text, "##resultPlinko##", Object.values(gamedata)[0]);
              console.log('if enter this stringdata', stringdata)
            } else {
              console.log('else enter this stringdata', values?.[j]?.[Object.keys(gameconditions[j])[0]])
              stringdata = values?.[j]?.[Object.keys(gameconditions[j])[0]]
            }

          } else {
            console.log('000 enter this stringdata', Object.values(gameconditions[j])[0])
            stringdata = Object.values(gameconditions[j])[0]
          }
          stringdata = stringdata.replace('##' + String(Object.keys(gamedata)[i]) + '##', Object.values(gamedata)[i]);
          console.log('result enter this stringdata', stringdata, [Object.keys(gameconditions[j])[0]])
          values[j] = { [Object.keys(gameconditions[j])[0]]: stringdata }
        }};

        console.log(" quju arr_data 6666", values, values?.length)
        return values;
    } else {

      const data = getPredictionByName(pathName);
      console.log("DAta 6666 : ", range, pathName, data,gameconditiondetails, gameconditiondetails)
      // let gameconditions = data;
      let gameconditions = gameconditiondetails;
      
      console.log("gamedata_condition 6666", gamedata, gameconditions);


      const values = []
      let stringdata = '';
      let str = ''
      console.log('stringdata', stringdata)
      // for (let i = 0; i < Object.keys(gamedata)?.length; i++) {
      //   for (let j = 0; j < gameconditions?.length; j++) {
      //     if (values?.[j]?.[Object.keys(gameconditions[j])[0]]) {
      //       stringdata = values?.[j]?.[Object.keys(gameconditions[j])[0]]
      //     } else {
      //       stringdata = Object.values(gameconditions[j])[0]
      //     }
      //     stringdata = stringdata.replace('##' + String(Object.keys(gamedata)[i]) + '##', Object.values(gamedata)[i]);
      //     values[j] = { [Object.keys(gameconditions[j])[0]]: stringdata }
      //   }
      // }
      const Replacedata = (replacedata, replacestring, replaceNumber) => {
        console.log('Replace_data', replacedata, replacestring, replaceNumber);
        str = replacedata.replace(replacestring, replaceNumber);
        console.log('statatataqtatatata', str)
        return str;
      }
      for (let i = 0; i < Object.keys(gamedata)?.length; i++) {
        for (let j = 0; j < gameconditions?.length; j++) {
          if (values?.[j]?.[Object.keys(gameconditions[j])[0]]) {
            let text = values?.[j]?.[Object.keys(gameconditions[j])[0]];
            let findata = text.includes("##predictRps##");
            if (findata) {
              stringdata = Replacedata(text, "##predictRps##", Object.values(gamedata)[0])
            } else {
              stringdata = values?.[j]?.[Object.keys(gameconditions[j])[0]]
            }

          } else {
            stringdata = Object.values(gameconditions[j])[0]
          }
          stringdata = stringdata.replace('##' + String(Object.keys(gamedata)[i]) + '##', Object.values(gamedata)[i]);
          values[j] = { [Object.keys(gameconditions[j])[0]]: stringdata }
        }
      }

      console.log(" quju arr_data 6666", values, values?.length)
      return values;
    }


  } catch (e) {
    console.log("DiceGameCondition_errr", e)
  }
}


export const checkAlreadyWon = async (presresp, oneweekwonInfo, onemonthwonInfo, gameName) => {
  try {
    let res1 = false;
    let res2 = false;
    console.log("onewdsafasdfasdf", oneweekwonInfo, gameName)
    if (gameName == "Limbo") {
      if (presresp?.isWin == true) {

        oneweekwonInfo.map((val, i) => {
          console.log("Number(presresp.Multiplier?.split('x')[0])", Number(presresp.Multiplier?.split('x')[0]), Number(val.Multiplier?.split('x')[0]), (1 <= Number(presresp.Multiplier?.split('x')[0]) && 10 >= Number(presresp.Multiplier?.split('x')[0])), (1 <= val?._id?.split('x')[0] && 10 >= val?._id?.split('x')[0]), val.allowwin)

          if (((1 <= Number(presresp.Multiplier?.split('x')[0]) && 10 >= Number(presresp.Multiplier?.split('x')[0]))
            && (1 <= Number(val.Multiplier?.split('x')[0]) && 10 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            console.log("asdfasdfasdfntasdfasnd")
            res1 = true;
          } else if (((11 <= Number(presresp.Multiplier?.split('x')[0]) && 20 >= Number(presresp.Multiplier?.split('x')[0]))
            && (11 <= Number(val.Multiplier?.split('x')[0]) && 20 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((21 <= Number(presresp.Multiplier?.split('x')[0]) && 30 >= Number(presresp.Multiplier?.split('x')[0]))
            && (21 <= Number(val.Multiplier?.split('x')[0]) && 30 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((31 <= Number(presresp.Multiplier?.split('x')[0]) && 40 >= Number(presresp.Multiplier?.split('x')[0]))
            && (31 <= Number(val.Multiplier?.split('x')[0]) && 40 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((41 <= Number(presresp.Multiplier?.split('x')[0]) && 50 >= Number(presresp.Multiplier?.split('x')[0]))
            && (41 <= Number(val.Multiplier?.split('x')[0]) && 50 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((51 <= Number(presresp.Multiplier?.split('x')[0]) && 60 >= Number(presresp.Multiplier?.split('x')[0]))
            && (51 <= Number(val.Multiplier?.split('x')[0]) && 60 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((61 <= Number(presresp.Multiplier?.split('x')[0]) && 70 >= Number(presresp.Multiplier?.split('x')[0]))
            && (61 <= Number(val.Multiplier?.split('x')[0]) && 70 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((71 <= Number(presresp.Multiplier?.split('x')[0]) && 80 >= Number(presresp.Multiplier?.split('x')[0]))
            && (71 <= Number(val.Multiplier?.split('x')[0]) && 80 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((81 <= Number(presresp.Multiplier?.split('x')[0]) && 90 >= Number(presresp.Multiplier?.split('x')[0]))
            && (81 <= Number(val.Multiplier?.split('x')[0]) && 90 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((91 <= Number(presresp.Multiplier?.split('x')[0]) && 100 >= Number(presresp.Multiplier?.split('x')[0]))
            && (91 <= Number(val.Multiplier?.split('x')[0]) && 100 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          }

        })


        onemonthwonInfo.map((val, i) => {
          console.log("Number(presresp.Multiplier?.split('x')[0])", Number(presresp.Multiplier?.split('x')[0]), Number(val.Multiplier?.split('x')[0]), (1 <= Number(presresp.Multiplier?.split('x')[0]) && 10 >= Number(presresp.Multiplier?.split('x')[0])), (1 <= val?._id?.split('x')[0] && 10 >= val?._id?.split('x')[0]), val.allowwin)

          if (((1 <= Number(presresp.Multiplier?.split('x')[0]) && 10 >= Number(presresp.Multiplier?.split('x')[0]))
            && (1 <= Number(val.Multiplier?.split('x')[0]) && 10 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            console.log("asdfasdfasdfntasdfasnd")
            res2 = true;
          } else if (((11 <= Number(presresp.Multiplier?.split('x')[0]) && 20 >= Number(presresp.Multiplier?.split('x')[0]))
            && (11 <= Number(val.Multiplier?.split('x')[0]) && 20 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((21 <= Number(presresp.Multiplier?.split('x')[0]) && 30 >= Number(presresp.Multiplier?.split('x')[0]))
            && (21 <= Number(val.Multiplier?.split('x')[0]) && 30 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((31 <= Number(presresp.Multiplier?.split('x')[0]) && 40 >= Number(presresp.Multiplier?.split('x')[0]))
            && (31 <= Number(val.Multiplier?.split('x')[0]) && 40 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((41 <= Number(presresp.Multiplier?.split('x')[0]) && 50 >= Number(presresp.Multiplier?.split('x')[0]))
            && (41 <= Number(val.Multiplier?.split('x')[0]) && 50 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((51 <= Number(presresp.Multiplier?.split('x')[0]) && 60 >= Number(presresp.Multiplier?.split('x')[0]))
            && (51 <= Number(val.Multiplier?.split('x')[0]) && 60 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((61 <= Number(presresp.Multiplier?.split('x')[0]) && 70 >= Number(presresp.Multiplier?.split('x')[0]))
            && (61 <= Number(val.Multiplier?.split('x')[0]) && 70 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((71 <= Number(presresp.Multiplier?.split('x')[0]) && 80 >= Number(presresp.Multiplier?.split('x')[0]))
            && (71 <= Number(val.Multiplier?.split('x')[0]) && 80 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((81 <= Number(presresp.Multiplier?.split('x')[0]) && 90 >= Number(presresp.Multiplier?.split('x')[0]))
            && (81 <= Number(val.Multiplier?.split('x')[0]) && 90 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((91 <= Number(presresp.Multiplier?.split('x')[0]) && 100 >= Number(presresp.Multiplier?.split('x')[0]))
            && (91 <= Number(val.Multiplier?.split('x')[0]) && 100 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          }

        })
      }
    } else if (gameName == "Moon") {

      if (presresp?.isWin == true) {
        oneweekwonInfo.map((val, i) => {
          console.log('valsdatasgal', val)
          if (((1 <= Number(presresp.Multiplier?.split('x')[0]) && 30 >= Number(presresp.Multiplier?.split('x')[0]))
            && (1 <= Number(val.Multiplier?.split('x')[0]) && 30 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((31 <= Number(presresp.Multiplier?.split('x')[0]) && 60 >= Number(presresp.Multiplier?.split('x')[0]))
            && (31 <= Number(val.Multiplier?.split('x')[0]) && 60 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((61 <= Number(presresp.Multiplier?.split('x')[0]) && 100 >= Number(presresp.Multiplier?.split('x')[0]))
            && (61 <= Number(val.Multiplier?.split('x')[0]) && 100 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          }
        });

        onemonthwonInfo.map((val, i) => {
          if (((1 <= Number(presresp.Multiplier?.split('x')[0]) && 30 >= Number(presresp.Multiplier?.split('x')[0]))
            && (1 <= Number(val.Multiplier?.split('x')[0]) && 30 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((31 <= Number(presresp.Multiplier?.split('x')[0]) && 60 >= Number(presresp.Multiplier?.split('x')[0]))
            && (31 <= Number(val.Multiplier?.split('x')[0]) && 60 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((61 <= Number(presresp.Multiplier?.split('x')[0]) && 100 >= Number(presresp.Multiplier?.split('x')[0]))
            && (61 <= Number(val.Multiplier?.split('x')[0]) && 100 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          }
        });
      }

    } else if (gameName == "Range") {
      if (presresp?.isWin == true) {
        console.log('oneweek data & onemonth data', oneweekwonInfo, onemonthwonInfo)
        oneweekwonInfo.map((val, i) => {
          console.log('pres val',val, presresp.Multiplier?.split('x')[0], Number(val.Multiplier?.split('x')[0]), val?.allowwin);
          console.log('valssssssss',val?.Multiplier?.split('x')[0], 31 <= Number(presresp.Multiplier?.split('x')[0]) && 40 >= Number(presresp.Multiplier?.split('x')[0]) )
          if (((5 <= Number(presresp.Multiplier?.split('x')[0]) && 10 >= Number(presresp.Multiplier?.split('x')[0]))
            && (5 <= Number(val.Multiplier?.split('x')[0]) && 10 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((11 <= Number(presresp.Multiplier?.split('x')[0]) && 20 >= Number(presresp.Multiplier?.split('x')[0]))
            && (11 <= Number(val.Multiplier?.split('x')[0]) && 20 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((21 <= Number(presresp.Multiplier?.split('x')[0]) && 30 >= Number(presresp.Multiplier?.split('x')[0]))
            && (21 <= Number(val.Multiplier?.split('x')[0]) && 30 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((31 <= Number(presresp.Multiplier?.split('x')[0]) && 40 >= Number(presresp.Multiplier?.split('x')[0]))
            && (31 <= Number(val.Multiplier?.split('x')[0]) && 40 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((41 <= Number(presresp.Multiplier?.split('x')[0]) && 50 >= Number(presresp.Multiplier?.split('x')[0]))
            && (41 <= Number(val.Multiplier?.split('x')[0]) && 50 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((51 <= Number(presresp.Multiplier?.split('x')[0]) && 60 >= Number(presresp.Multiplier?.split('x')[0]))
            && (51 <= Number(val.Multiplier?.split('x')[0]) && 60 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((61 <= Number(presresp.Multiplier?.split('x')[0]) && 70 >= Number(presresp.Multiplier?.split('x')[0]))
            && (61 <= Number(val.Multiplier?.split('x')[0]) && 70 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((71 <= Number(presresp.Multiplier?.split('x')[0]) && 80 >= Number(presresp.Multiplier?.split('x')[0]))
            && (71 <= Number(val.Multiplier?.split('x')[0]) && 80 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((81 <= Number(presresp.Multiplier?.split('x')[0]) && 90 >= Number(presresp.Multiplier?.split('x')[0]))
            && (81 <= Number(val.Multiplier?.split('x')[0]) && 90 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          } else if (((91 <= Number(presresp.Multiplier?.split('x')[0]) && 95 >= Number(presresp.Multiplier?.split('x')[0]))
            && (91 <= Number(val.Multiplier?.split('x')[0]) && 95 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res1 = true;
          }
        });


        onemonthwonInfo.map((val, i) => {
          console.log("Number(presresp.Multiplier?.split('x')[0])", Number(presresp.Multiplier?.split('x')[0]), Number(val.Multiplier?.split('x')[0]), (1 <= Number(presresp.Multiplier?.split('x')[0]) && 10 >= Number(presresp.Multiplier?.split('x')[0])), (1 <= val?._id?.split('x')[0] && 10 >= val?._id?.split('x')[0]), val.allowwin)

          if (((5 <= Number(presresp.Multiplier?.split('x')[0]) && 10 >= Number(presresp.Multiplier?.split('x')[0]))
            && (5 <= Number(val.Multiplier?.split('x')[0]) && 10 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((11 <= Number(presresp.Multiplier?.split('x')[0]) && 20 >= Number(presresp.Multiplier?.split('x')[0]))
            && (11 <= Number(val.Multiplier?.split('x')[0]) && 20 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((21 <= Number(presresp.Multiplier?.split('x')[0]) && 30 >= Number(presresp.Multiplier?.split('x')[0]))
            && (21 <= Number(val.Multiplier?.split('x')[0]) && 30 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((31 <= Number(presresp.Multiplier?.split('x')[0]) && 40 >= Number(presresp.Multiplier?.split('x')[0]))
            && (31 <= Number(val.Multiplier?.split('x')[0]) && 40 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((41 <= Number(presresp.Multiplier?.split('x')[0]) && 50 >= Number(presresp.Multiplier?.split('x')[0]))
            && (41 <= Number(val.Multiplier?.split('x')[0]) && 50 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((51 <= Number(presresp.Multiplier?.split('x')[0]) && 60 >= Number(presresp.Multiplier?.split('x')[0]))
            && (51 <= Number(val.Multiplier?.split('x')[0]) && 60 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((61 <= Number(presresp.Multiplier?.split('x')[0]) && 70 >= Number(presresp.Multiplier?.split('x')[0]))
            && (61 <= Number(val.Multiplier?.split('x')[0]) && 70 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((71 <= Number(presresp.Multiplier?.split('x')[0]) && 80 >= Number(presresp.Multiplier?.split('x')[0]))
            && (71 <= Number(val.Multiplier?.split('x')[0]) && 80 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((81 <= Number(presresp.Multiplier?.split('x')[0]) && 90 >= Number(presresp.Multiplier?.split('x')[0]))
            && (81 <= Number(val.Multiplier?.split('x')[0]) && 90 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          } else if (((91 <= Number(presresp.Multiplier?.split('x')[0]) && 95 >= Number(presresp.Multiplier?.split('x')[0]))
            && (91 <= Number(val.Multiplier?.split('x')[0]) && 100 >= Number(val.Multiplier?.split('x')[0]))) && val.allowwin == 0) {
            res2 = true;
          }

        })
      }

    } else {
      console.log('checkAlreadyWon enter this one', gameName, oneweekwonInfo, onemonthwonInfo)
      if (presresp?.isWin == true) {
        oneweekwonInfo.map((val, i) => {
          console.log('val.Multiplier == presresp.Multiplier',val, val.Multiplier, presresp.Multiplier, val.Multiplier == presresp.Multiplier, val.allowwin)
          console.log("oneweekwonInfo val", oneweekwonInfo, val.allowwin, val.Multiplier, presresp.Multiplier, (val.Multiplier == presresp.Multiplier), val.allowwin == 0);
          if ((val.Multiplier == presresp.Multiplier) && val.allowwin == 0) {
            res1 = true;
          }
        })
        onemonthwonInfo.map((val, i) => {
          console.log("onemonthwonInfo val", val);
          if ((val.Multiplier == presresp.Multiplier) && val.allowwin == 0) {
            res2 = true;
          }
        })
      };
    }

    return {
      res1: res1,
      res2: res2
    }
  } catch (e) {
    console.log("checkAlreadyWon_err", e)
  }
}


 






/* for (gamedata , val,i){
    for(coinflip){
      var prediction = [value]
      replace
      value.push(prediction)
    }
}*/

// export const MinuteHourFormat = (data) => {
//   return ((Number(isEmpty(data) ? 0 : data ) < 10 ? '0' : '')+data)
// }

// export  const DateTimeForm = (date,datealone,timealone,ampm) => {
//   try{
//     if(datealone){
//       return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}`
//     }
//     else if(timealone){
//       if(ampm){
//         return `${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours()) }:${MinuteHourFormat(new Date(date)?.getMinutes())} ${new Date(date)?.getHours() >= 12 ? 'PM' : 'AM' }`
//       }
//       else{
//         return `${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())} `
//       }
//     }
//     else if(ampm){
//       return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}, ${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes()) } ${new Date(date)?.getHours() >= 12 ? 'PM' : 'AM'} `
//     }
//     return `${MinuteHourFormat(new Date(date)?.getDate())}:${MinuteHourFormat(new Date(date)?.getMonth()+1)}:${new Date(date)?.getFullYear()},${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())} `
//   }
//   catch(err){
//     console.log('dskhfskfs',err)
//     return "No Date"
//   }
// }