import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Nav, Navbar, NavDropdown, Dropdown } from 'react-bootstrap';
import Logo from '../assets/logo1.png';
import Header from '../Layouts/Header';
import Footer from '../Layouts/Footer';
import Sidebar from "../Layouts/sidebar1";
import Sidebarheader from "../Components/sidebarheader";
import { Link, useLocation } from "react-router-dom";
import dashimg from "../assets/bitimg.png";
import betimg from "../assets/bet.png";
import Lottery from "../assets/lottery.png";
import triimg from "../assets/tri.png";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BsStarFill } from 'react-icons/bs';
import Gameheader from '../Layouts/gamesheader';
import { getAllGamesInfoHooks } from "../hooks/UseUser";
import { BACKURL, FRONTENDURL } from "../config/env";
import bannerimg from "../assets/bannerss.png";
import CopyToClipboard from "react-copy-to-clipboard";
import { copyUrl } from "../hooks/UseUser";
import { MdOutlineContentCopy } from "react-icons/md";
import walletimg from '../assets/wallet.png';
import Wallets from '../Components/Modals/Wallet';
import bannertext from '../assets/sidemenu/bannertext.png'
import splashimg from '../assets/sidemenu/splash1.webp'
import playbtn from '../assets/sidemenu/playbtn.png'
export default function Games() {

    useEffect(() => {
        window.scrollTo(0, 0)
        getData();
    }, []);
    let location = useLocation();
    const [showDropdown, setShowDropdown] = useState(false);
    const [cardhover,setCardhover]= useState(false)
    const [gameCard, setGameCard] = useState([])
    const [accountInfo, setAccountInfo] = useState("");
    console.log("accountt------", accountInfo);
    const [walletmodal, setWalletmodal] = useState(false);
    const getData = async () => {
        try {
            let res = await getAllGamesInfoHooks();
            console.log("getallgamesInfoHooks", res, res?.data?.data);
            if (res?.status == 200) {
                if (res?.data?.success == false) {

                } else {
                    if (res?.data?.data) {
                        setGameCard(res?.data?.data)
                    }
                }

            }
        } catch (e) {
            console.log("getData_err", e)
        }
    }


    const [listed, setlisted] = useState([
        { "id": 1, }, { "id": 2, }, { "id": 3, }, { "id": 4, },
    ]);
    const [gamecard, setgamecard] = useState([
        { "id": 1, "images": require("../assets/cardimg.png"), "title": "Coin Flip" },
        { "id": 2, "images": require("../assets/range.svg.png"), "title": "Range" },
        { "id": 3, "images": require("../assets/moon.svg.png"), "title": "Moon" },
        { "id": 4, "images": require("../assets/wheel.svg.png"), "title": "Wheel" },
        { "id": 5, "images": require("../assets/plinko.svg.png"), "title": "Plinko" },
        { "id": 6, "images": require("../assets/Group (2).png"), "title": "Dice" },
        { "id": 7, "images": require("../assets/limbo.svg.png"), "title": "Limbo" },
    ]);

        const logOut = async () => {
        console.log("header logout call")
        setAccountInfo("");
      
        localStorage.removeItem('accessToken');
        sessionStorage.removeItem('accountInfo')
        window.location.href = "https://honeyblue.maticz.in/games"

    }


    const options12 = {
        margin: 10,
        responsiveClass: true,
        nav: false,
        dots: true,
        items: 3,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            }
        }
    }


    return (
        <>
           {walletmodal && <Wallets onDismiss={() => setWalletmodal(false)} />}
            <div className="sidebarlist">
                <Gameheader />

                <div className="d-none d-xl-block">
                <Sidebar /></div>

                <div className="content pt-3">
                    <div className="container container_max">
                        {/* <Sidebarheader/> */}

                        <div className="content_right innerright pt-5">

                            <div className="banner_listed py-5 py-xl-4 py-xxl-5 px-4 mt-5">
                                {/* <div className="row justify-content-start py-4 py-xl-0 py-xxl-0">
                                 <div className="col-xxl-5">
                                     <h2 className="whtclr mt-4 mt-xl-2 mt-xxl-4" style={{ fontWeight:"700" }}>Setup Smart Wallet, Play<br></br>with One-click</h2>
                                     <p className="whtclr mt-3 text-uppercase">- with connect web3 wallet -</p>
                                     {
                                    (accountInfo == "" || accountInfo == null) ?
                                        <button class="btn btn-success earnbtn mt-2 mb-2" onClick={() => setWalletmodal(true)}><img className="wimg" src={walletimg} alt="images" /> Connect Wallet</button> :
                                        <div className="ms-3 walletdrop">
                                        <Dropdown onMouseLeave={() => setShowDropdown(false)}
                                            onMouseOver={() => setShowDropdown(true)}>
                                            <Dropdown.Toggle className="ptoken btn btn-success earnbtn" id="dropdown-basic">
             
                                                <img className="wimg" src={walletimg} alt="images" /> {`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu show={showDropdown} className="right_menu_custom">
                                                <div className="d-flex justify-content-between">
                                                    <p className="f-13 mb-0">{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</p>
                                                    <CopyToClipboard text={`${accountInfo}`} onCopy={() => { copyUrl('Copied WalletAddress', `${accountInfo}`) }}>
                                                        <MdOutlineContentCopy className="copysvg" />
                                                    </CopyToClipboard></div>
                                                <div className="mt-3">
                                                    <Link className="mt-2 mb-0" onClick={()=>logOut()} >Disconnect</Link>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                }
                                 </div>
                                 </div> */}
                                 <img src={bannertext} className="img-fluid bannertext" alt="image" />
                            </div>


                            {/* <div className="row pt-5">
                                <div className="col-xl-4 col-lg-6 col-md-6 mt-3">
                                    <div className="dashboard">
                                        <div className="row align-items-center">
                                            <div className="col-lg-3 col-sm-4 col-md-3"></div>
                                            <div className="col-lg-9 col-sm-8 col-md-9">
                                                <h6 className="fw-800 mb-1 whtclr">WLS</h6>
                                                <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Size</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">2,14,300 $</p></div> </div>
                                                <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">APR</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">123.87 %</p></div> </div>
                                                <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Bribe</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">0.0125 x</p></div> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 mt-3">
                                    <div className="dashboard dashboardimg1">
                                        <div className="row align-items-center">
                                            <div className="col-lg-3 col-sm-4 col-md-3"></div>
                                            <div className="col-lg-9 col-sm-8 col-md-9">
                                                <h6 className="fw-800 mb-1 whtclr">Real Yield</h6>
                                                <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Staked</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">2,14,300 $</p></div> </div>
                                                <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">APR</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">123.87 %</p></div> </div>
                                                <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Multiplier</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">0.0125 x</p></div> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 mt-3">
                                    <div className="dashboard dashboardimg2">
                                        <div className="row align-items-center">
                                            <div className="col-lg-3 col-sm-4 col-md-3"></div>
                                            <div className="col-lg-9 col-sm-8 col-md-9">
                                                <h6 className="fw-800 mb-1 whtclr">Lottery</h6>
                                                <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Jackpot</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">2,14,300 $</p></div> </div>
                                                <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Ticket</p></div> <div className="col-md-7 col-7 text-end"><p className="fw-600">123.87 %</p></div> </div>
                                                <div className="row"> <div className="col-md-5 col-5"><p className="fw-700">Next Draw</p></div> <div className="col-md-7 col-7 text-end"><button type="button" class="btn w-100 btn-success earnbtn notactive mt-1">Not Active Yet</button></div> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="owlcarousel pt-4">
                                <OwlCarousel {...options12} className='owl-theme'>
                                    {listed.map((e, i) =>
                                        <div class='item'>
                                            <div className="row align-items-center">
                                                <div className="col-md-12 mt-2 mt-sm-4">
                                                    <img src={bannerimg} className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </OwlCarousel>
                            </div> */}


                            <div className="cardss gamecardsec pt-3 pb-4">
                                {/* <div className="d-flex justify-content-between align-items-center">
                                    <p className="fw-600 mb-0 whtclr"><BsStarFill /> Our Games</p>
                                  
                                </div> */}
                                <div class="row justify-content-start">
                                    {console.log("gameCard", gameCard)}
                                    {gameCard && gameCard?.length > 0 && gameCard?.map((e, i) => {
                                        { console.log("eeeeeeeeee", e, e?.GameName) }
                                        return (<div class="col-lg-3 col-sm-6 col-md-4 mt-3">
                                            <Link onClick={() => window.location.href = `${FRONTENDURL}games/${e?.GameName}`} >
                                                <div class="gamecard card" onMouseEnter={()=>setCardhover(i)} onMouseLeave={()=>setCardhover()}>
                                                    <div className="card-img">
                                                        <img className="img-fluid gameimg" src={`${BACKURL}/Allgames/${e?.Image}`} alt="contest" />
                                                    </div>
                                                    <div class="card-body">
                                                        <h6 class="card-title mb-0">{e?.GameName}</h6>
                                                    </div>
                                                 
                                                    <div className={`${cardhover === i ? "cardhoverlayer active" : "cardhoverlayer"}`}>
                                                        {cardhover === i &&   <img src={splashimg} className="img-fluid" alt="image" />}
                                                      
                                                       <img src={playbtn} className="playbtn" onClick={() => window.location.href = `${FRONTENDURL}games/${e?.GameName}`}/>
                                                    </div>
                                  
                                                </div>
                                            </Link>
                                        </div>)
                                    }
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>


                    <Footer />
                </div>


            </div>
        </>
    );
}


