export const  style = {  
    minWidth: '300px',
    minHeight: '55px',
    border: '1px solid #000',
    padding: '18px',
    color: '#fff',
    fontWeight:"500",
    background: '#4F75E4',
    zIndex:"99999"   
}

export const iconTheme = {
    primary: '#fff',
    secondary: '#000',
}

export const  position= {
    position: "top-center"
}


export const  zindex= {
    zIndex: "99999"
}

export const duration = {
    duration : 4000
}