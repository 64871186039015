
import { useState, useEffect } from 'react';
import Slider from 'react-rangeslider';
import { UpdateGameSettingBalancehooks, getAllGameTransactionDetails, getGameSettingDetails } from '../hooks/UseUser';
import { GamePrediction, createOrderdetails } from '../hooks/useBot';
import { CreateGameTransactionHooks, getGameAllTransactionInfoHooks } from '../hooks/UseTransaction';
import { getAccount } from '../hooks/useAccount';
import { DiceGameCondition, checkAlreadyWon } from '../hooks/useDice';
import toast from 'react-hot-toast';
import { iconTheme, position, style } from '../hooks/useToast';
import { toFixedNumber } from '../hooks/UseContract';
import flip1 from "../assets/flip1.png";
import flip2 from "../assets/flip2.png";
import flip3 from "../assets/flip3.png";
import flip4 from "../assets/flip4.png";
import flip5 from "../assets/flip5.png";
import winss from "../assets/winss.gif";
import winning from "../assets/winning.gif";
import down from "../assets/downs.gif";
import Swal from 'sweetalert2';

function Rangegame(props) {

    const [buttonSelected, setButtonSelected] = useState(false);
    console.log('basic enter this or waht', buttonSelected)
    const [rangeval, setRangeval] = useState(50);
    const [inputval, setInputval] = useState(50);
    const [randomnum, setRandomnum] = useState(0);
    const [randomnumfinal, setRandomnumfinal] = useState(0);

    const [wintext, setWintext] = useState("");
    console.log('game finding way', rangeval, inputval, randomnumfinal)
    //ven
    const [accountInfo, setAccountInfo] = useState("");
    const [transactionInfo, setTransactionInfo] = useState([]);
    const [onemonthwonInfo, setOneMonthWonInfo] = useState([]);
    const [oneweekwonInfo, setOneWeekWonInfo] = useState([]);
    const [gamesetting, setGameSetting] = useState({})
    const [allgametransacdata, setAllGameTransData] = useState([]);
    const [incrementbetcount, setIncrementBetCount] = useState(1);
    const [multiplier, setMultiplier] = useState(1.96)
    const [resultrandom, setResultRandom] = useState(0)
    const [gamecondition, setGameCondition] = useState({})
    const [maxpayout, setMaxpayout] = useState(0)
    const [totalwager, setTotalWager] = useState(0)
    const [multipleBets, setMultipleBets] = useState(0)
    const [activerange, setActiveRange] = useState(false);
    const [ranges, setRanges] = useState([]);
    const [allowin, setAllowin] = useState(false);
    const [play, setPlay] = useState(false)
    console.log("allowin status data", allowin)
    //common
    useEffect(() => {
        getGameSettings();
        getAllGameTransactionData();
    }, [props]);


    const getGameSettings = async () => {
        try {
            let res = await getGameSettingDetails();
            //The function use to admin withdrawpermonth data get
            console.log('getGameSettings', res?.data)
            if (res?.data?.status == true) {
                let result = res?.data?.data[0];
                if (result?.Status == "Active") {
                    setGameSetting(result)
                }
            }
        } catch (e) {
            console.log("gameGameSettings_err", e)
        }
    }


    const getAllGameTransactionData = async () => {
        try {
            // The month start to end vise, get all gamedetails
            let res = await getAllGameTransactionDetails();
            if (res?.data?.status == true) {
                setAllGameTransData(res?.data?.alltranscdata)
            }
        } catch (e) {
            console.log("getAllTransactionData_err", e)
        }
    };


    useEffect(() => {
        (async () => {
            const walletAddress = getAccount();
            setAccountInfo(walletAddress);
            // Call the function 
            let transc = await getTransactionDetails(walletAddress);
            console.log("oneweekwonInfoZ_OneMonthWonInfo", transc);
            if (transc?.oneweekdata?.length > 0) {
                OneWeekWonInfo(transc?.oneweekdata)
            }
            if (transc?.onemonthdata?.length > 0) {
                OneMonthWonInfo(transc?.onemonthdata)
            }

        })();
    }, [gamesetting, rangeval])




    const getTransactionDetails = async (address) => {
        let data = {
            walletAddress: address,
            gameName: "Range"
        }
        // This function use gameName depends to get that user last onemonthwondata & last oneweekdata.
        let res = await getGameAllTransactionInfoHooks(data);
        console.log("respond_data", res)
        if (res?.status == 200) {
            if (res?.data?.alltranscdata?.length > 0) {
                //That is useless state , just store all transactiondata
                setTransactionInfo(res?.data?.alltranscdata)
            }
        }

        if (res?.data?.oneweekdata?.length > 0 || res?.data?.onemonthdata?.length > 0) {
            return {
                oneweekdata: res?.data?.oneweekdata?.length > 0 ? res?.data?.oneweekdata : [],
                onemonthdata: res?.data?.onemonthdata?.length > 0 ? res?.data?.onemonthdata : []
            }
        } else {
            return {
                oneweekdata: [],
                onemonthdata: []
            }
        }
    }


    useEffect(() => {
        // Initially check that user how many times won &
        // that user again won or loss deciding this useEffect
        async function fetchData() {
            let gamedata = {
                predictRange: rangeval,
                resultRange: 25,
            }
            let rangecondition = await DiceGameCondition(gamedata, "Range", gamecondition);
            let presresp = await GamePrediction(rangecondition, [], props?.calculatewager, props?.multiplebets, totalwager, "Range", gamesetting, allgametransacdata, props?.maxpayout);
            console.log("initially range check presresp", presresp, oneweekwonInfo, onemonthwonInfo)
            let checkalreadywon = await checkAlreadyWon(presresp, oneweekwonInfo, onemonthwonInfo, "Range");
            // That checkalreadywon response return false means --> Again that user won anyway,
            //Otherwise response return true means --> That user never won onemonth --> That way false means --> allowin-possible,
            // true means allowin - not-possible
            console.log('checkalreadywon data', checkalreadywon)
            if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
                setAllowin(false)
            } else {
                setAllowin(true)
            }
            console.log('checkalreadywonddata', checkalreadywon)
            // if()

            console.log('range condition data', rangecondition)
        }
        fetchData();

    }, [props, rangeval])

    useEffect(() => {
        // useless function
        (async () => {
            try {
                console.log('active range ', activerange)
                if (activerange) {
                    console.log('already won sometimes', oneweekwonInfo?.length > 0 || oneweekwonInfo?.length > 0)
                    if (oneweekwonInfo?.length > 0 || onemonthwonInfo?.length > 0) {
                        let gamedata = {
                            predictRange: rangeval,
                            resultRange: resultrandom,
                        }
                        console.log('gamedatasgal', gamedata)
                        let rangecondition = await DiceGameCondition(gamedata, "Range", gamecondition);
                        let presresp = await GamePrediction(rangecondition, [], props?.calculatewager, props?.multiplebets, totalwager, "Range", gamesetting, allgametransacdata, props?.maxpayout);
                        console.log("initially check presresp", presresp)
                        // CheckAlreadyWon scenario ,Check the user only won the game (presresp?.isWin == true, In that case only check)
                        let checkalreadywon = await checkAlreadyWon(presresp, oneweekwonInfo, onemonthwonInfo, "Range");
                        console.log('initially check', checkalreadywon);
                        console.log('finishgamedata', checkalreadywon, gamedata, rangecondition)

                        // 
                        if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
                            console.log('never come this console')
                        } else {
                            FinishInterval()
                        }
                    } else {
                        FinishInterval();
                    }
                }
            } catch (e) {
                console.log('useEff err', e)
            }
        })()
    }, [activerange])

    useEffect(() => {
        let range = {};
        range[`${rangeval}x`] = "##predictRange## >= ##resultRange##";
        console.log('rangedata', range, maxpayout)
        setGameCondition({ "Range": [range] })
        props?.setRangeVal(rangeval)
        setMultiplier(props?.rangemultiplier)
    }, [rangeval, props]);





    useEffect(() => {
        console.log('234234 who come first', props?.maxpayout, props?.bet && props?.disabledrangebtn, props?.bet, props?.disabledrangebtn)
        console.log('last 111 play scenario enter', props?.bet && props?.disabledrangebtn, props?.bet, props?.disabledrangebtn)
        if (props?.bet && props?.disabledrangebtn) {
            console.log('enter this or waht');
            if (!play) {
                setButtonSelected(false);
            }
        } else {
            console.log('true va enter this or waht')
            setButtonSelected(true)
        }

        // common

    }, [props]);


    useEffect(() => {
        console.log('last 222 play scenario enter', props?.bet && props?.disabledrangebtn, props?.bet, props?.disabledrangebtn)
        props.setInitialRange(rangeval)
        props.setMaxpayout(multiplier)
        setTotalWager(props.totalwager);
        setMultipleBets(props?.multiplebets);
        props.selectedrange != '' ? setRangeval(props.selectedrange) : setRangeval(rangeval);
        props?.maxpayout != 0 ? setMaxpayout(props?.maxpayout) : setMaxpayout(multiplier)
        let remainingrolls = (props?.multiplebets - props?.remainingbets) + 1;
        console.log("Remainingrools", remainingrolls, props?.multiplebets, props?.remainingbets)
        setIncrementBetCount(remainingrolls);
    }, [rangeval, props])

    useEffect(() => {
        console.log('inputval', inputval)
        if (inputval <= 5) {
            setRangeval(5)
        }
        else if (inputval >= 95) {
            setRangeval(95)

        }
        else {
            setRangeval(inputval)

        }

    }, [inputval])

    useEffect(() => {
        console.log("props?.rangearray", props, props?.rangearray)
        if (ranges?.length > 0) {
            if (randomnumfinal != 0) {
                console.log('111 which places will enter')
                console.log('rangessssssss', ranges, ...ranges, randomnumfinal, [...ranges, randomnumfinal])
                setRanges([...ranges, randomnumfinal])
            }
        } else {
            console.log('which places will enter')
            if (randomnumfinal != "" && randomnumfinal != 0) {
                setRanges([randomnumfinal])
            }
        }
    }, [randomnumfinal])

    useEffect(() => {
        console.log('both condition', props, ranges, props?.rangearray, ranges?.length <= 0, props?.rangearray?.length > 0)
        if ((ranges?.length <= 0) && (props?.rangearray?.length > 0)) {
            setRanges(props?.rangearray)
        }
        if (props?.remainingbets == 0) {
            setRanges([])
        }
    }, [props])
    // common
    const OneMonthWonInfo = async (transc) => {
        try {
            let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
            console.log("OneMonthWonInfo", res)
            setOneMonthWonInfo(res)
        } catch (e) {
            console.log("PredictionInfo_err", e)
        }
    }

    // common
    const OneWeekWonInfo = async (transc) => {
        try {
            console.log('transdata', transc)
            let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
            console.log("OneWeekWonInfo", res)
            setOneWeekWonInfo(res);
        } catch (err) {
            console.log("OneWeekWonInfo_err", err)
        }
    }



    const handleOnChangeRange = (value) => {
        console.log('valuevaluevalue rangechange', value, 5 <= Number(value) && 10 >= Number(value))
        if (!props?.bet) {
            if (value > 4 && value < 96) {
                setRangeval(value);
                setInputval(value);
                props?.setRangeVal(value)
            }
        }
    }

    function generateRandom(min, max) {

        // find diff
        let difference = max - min;

        // generate random number 
        let rand = Math.random();

        // multiply with difference 
        rand = Math.floor(rand * difference);

        // add with min value 
        rand = rand + min;
        console.log('randomly genearate number', rand)
        return rand;
    }


    const submitPlay = () => {
        // Allowin true means --> that user again won maybe, but the Allowin false means --> that user onwmonth never won again.
        console.log('allowin status', allowin)
        if (allowin) {
            console.log('play scenario enter')
            if (incrementbetcount <= multipleBets) {
                console.log('111 true va enter this or waht', allowin)
                setButtonSelected(true);
                // generateRandom();
                // console.log(generateRandom(),"radomni");
                // document.getElementById("randomnum").innerHTML = generateRandom();

                let resultrandom;
                const interval = setInterval(() => {
                    //   updateTime();
                    // setRandomnum(generateRandom());
                    resultrandom = generateRandom(5, 95)
                    setResultRandom(resultrandom)
                    document.getElementById("randomnum").innerHTML = resultrandom;

                }, 10);

                setTimeout(() => {
                    setActiveRange(true)
                }, 3000)


                setTimeout(() => {
                    setRandomnumfinal(document.getElementById("randomnum").innerHTML);
                    console.log(randomnumfinal, rangeval, "timoeo");
                    console.log('outuput result', document.getElementById("randomnum").innerHTML, rangeval)

                    if (document.getElementById("randomnum").innerHTML >= rangeval) {
                        // document.getElementById("win_loss_div").innerHTML = "";

                        setWintext("You Won");
                    }
                    else {
                        // document.getElementById("win_loss_div").innerHTML = "";

                        setWintext("You Loss");

                    }
                    // document.getElementById("randomnum").innerHTML = "";

                    console.log("Clearing interval : ");
                    clearInterval(interval);
                    // document.getElementById("win_loss_div").innerHTML = "";

                }, 2000);


                // Number display timeout
                setTimeout(() => {
                    console.log("5000 timeoi");
                    document.getElementById("win_loss_div").innerHTML = "";
                    document.getElementById("randomnum").innerHTML = "";
                    // setButtonSelected(false);


                }, 3000);
                setPlay(true)
            }
        } else {
            // Enter the else means --> user never won the game
            console.log('play scenario enter')
            if (incrementbetcount <= multipleBets) {
                console.log('222 true va enter this or waht', allowin)
                setButtonSelected(true);
                setPlay(true)
                let resultrandom;
                // Now randomlygenerate --> depends upon user choose the rangeval,

                // In that case user neverever won the game
                // (The user choose greater than 74) if condition ---> ex :  76 means --> minimum = 76 ,  maximum = 95,
                // (The user choose greater than 80) elseif condition ---> ex : 88 means --> minimum = 89, maximum = 95,
                // (The user choose lesser than 74) condition ---> ex : 56 means ---> minimum = 74, maximum  = 95

                let min, max;
                if (75 <= Number(rangeval) && 80 >= Number(rangeval)) {
                    min = Number(rangeval) + 1;
                    max = 95;
                } else if (81 <= Number(rangeval) && 90 >= Number(rangeval)) {
                    min = Number(rangeval) + 1;
                    max = 95;
                } else {
                    min = Number(rangeval) + 1;
                    max = Number(rangeval) + 21;
                }

                console.log('rangeval_rangeval', rangeval)
                const interval = setInterval(() => {
                    resultrandom = generateRandom(min, max)
                    setResultRandom(resultrandom)
                    document.getElementById("randomnum").innerHTML = resultrandom;
                }, 10);

                setTimeout(() => {
                    setActiveRange(true)
                }, 3000);

                setTimeout(() => {
                    setRandomnumfinal(document.getElementById("randomnum").innerHTML);
                    console.log(randomnumfinal, rangeval, "timoeo");
                    console.log('outuput result', document.getElementById("randomnum").innerHTML, rangeval)

                    console.log("Clearing interval : ");
                    clearInterval(interval);
                    // document.getElementById("win_loss_div").innerHTML = "";

                }, 2000);

                setTimeout(() => {
                    console.log("5000 timeoi");
                    document.getElementById("win_loss_div").innerHTML = "";
                    document.getElementById("randomnum").innerHTML = "";
                }, 3000);



            }
        }



    }


    const FinishInterval = async () => {
        try {
            console.log('333 play scenario enter');
            if (incrementbetcount <= multipleBets) {
                let gamedata = {
                    predictRange: rangeval,
                    resultRange: resultrandom
                }
                console.log('rangeeeeeeeeeeeeeeee', ranges)
                let rangecondition = await DiceGameCondition(gamedata, "Range", gamecondition)
                console.log('finishgamedata', gamedata, rangecondition);
                let presresp = await GamePrediction(rangecondition, [], toFixedNumber(Number(props?.calculatewager)), props?.multiplebets, totalwager, "Range", gamesetting, allgametransacdata, props?.maxpayout);
                console.log('prespdatasgal', presresp)
                if (presresp) {
                    let data = {};
                    if (presresp?.isWin == true) {
                        console.log('won scn', Number(props?.calculatewager), Number(multiplier))
                        props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
                            let wonpercentage = Number(props?.calculatewager) * Number(multiplier) / 100;
                            let amount = (Number(props?.calculatewager) + Number(wonpercentage));
                            console.log('multipliet data', wonpercentage, amount, Object.values(val)[0], props?.calculatewager)
                            if (presresp?.isWin == true) {
                                data = {
                                    walletAddress: accountInfo,
                                    transaction: presresp,
                                    gameName: props?.pathName,
                                    Bets: multipleBets,
                                    Wager: props?.wager,
                                    CalculateWager: props?.calculatewager,
                                    TotalWager: props?.totalwager,
                                    Amount: amount,
                                    OrderId: props?.usergamedetails?._id
                                }
                            } else if (presresp?.isWin == false) {
                                data = {
                                    walletAddress: accountInfo,
                                    transaction: presresp,
                                    gameName: props?.pathName,
                                    Bets: multipleBets,
                                    Wager: props?.wager,
                                    CalculateWager: props?.calculatewager,
                                    TotalWager: props?.totalwager,
                                    Amount: presresp?.maxpayout,
                                    OrderId: props?.usergamedetails?._id
                                }
                            }
                        })

                    } else if (presresp?.isWin == false) {
                        if ("0x" == String(presresp?.Multiplier)) {
                            data = {
                                walletAddress: accountInfo,
                                transaction: presresp,
                                gameName: props?.pathName,
                                Bets: multipleBets,
                                Wager: props?.wager,
                                CalculateWager: props?.calculatewager,
                                TotalWager: props?.totalwager,
                                Amount: presresp?.maxpayout,
                                OrderId: props?.usergamedetails?._id
                            }
                        }
                    }
                    let lossamount = 0, gainamount = 0;
                    var resultamount = 0;

                    props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
                        let wonpercentage = Number(props?.calculatewager) * Number(multiplier) / 100;
                        let amount = (Number(props?.calculatewager) + Number(wonpercentage));
                        if ("0x" == String(presresp?.Multiplier)) {
                            if (presresp?.isWin == false) {
                                lossamount = presresp?.maxpayout;
                                resultamount = presresp?.maxpayout;
                            }
                        } else {
                            if (presresp?.isWin == false) {
                                console.log('ent those this', presresp?.maxpayout)
                                lossamount = presresp?.maxpayout;
                                resultamount = presresp?.maxpayout;
                            } else {
                                console.log('ent those this', presresp?.maxpayout)
                                gainamount = amount;
                                resultamount = amount;
                            }
                        }
                    });

                    console.log("lossamount, gainamount", lossamount, gainamount, data)
                    let res = await CreateGameTransactionHooks(data);
                    console.log("orderdata ruru1111", res);

                    if (res?.status == 200) {
                        let createres = await createOrderdetails(props?.pathName, props?.wager, props?.remainingbets, props?.betamount, props.selectedrange, accountInfo, props.stopgain, props.stoploss, gainamount, lossamount, props?.maxpayout, props?.calculatewager, props?.totalwager, ranges, props?.rangemultiplier);
                        console.log('createrescreateres', createres, props?.remainingbets);
                        let result = createres?.data?.data;
                        if (createres?.status == 200) {
                            if (createres?.data?.status == true) {
                                console.log("update balance", result, result?.RemainingBets == 0)
                                if (result?.RemainingBets == 0) {
                                    let reqData = { _id: result?._id, walletAddress: accountInfo }
                                    let res = await UpdateGameSettingBalancehooks(reqData);
                                    if (res?.data?.status == true) {
                                        setTimeout(() => {
                                            console.log('4444 play scenario enter');
                                            props?.setFeeData()
                                        }, 3000)
                                    }
                                    console.log("update balance 212", res)
                                }

                                console.log("condition data", (createres?.data?.data?.StopGain != 0 || createres?.data?.data?.StopLoss != 0) && (((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopGain)) / 100) <= (createres?.data?.data?.GainAmount) || ((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopLoss)) / 100) <= (createres?.data?.data?.LossAmount)))
                                console.log("stopgaindata", createres?.data?.data, createres?.data?.data?.StopGain != 0 && createres?.data?.data?.StopLoss != 0);
                                console.log('props?.remainingbets', props?.remainingbets, props?.remainingbets == 1)
                                if ((createres?.data?.data?.StopGain != 0 || createres?.data?.data?.StopLoss != 0) && (((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopGain)) / 100) <= (createres?.data?.data?.GainAmount) || ((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopLoss)) / 100) <= (createres?.data?.data?.LossAmount))) {
                                    setTimeout(function () {
                                        console.log('222 who come first')
                                        console.log('555 play scenario enter');
                                        props?.checkOrder()
                                        props.onCheck()
                                        setPlay(false)
                                    }, 4000)
                                } else if (props?.remainingbets != 1) {
                                    console.log('who come first')
                                    console.log('555 play scenario enter');
                                    setTimeout(function () {
                                        props?.checkOrder()
                                        props.onCheck()
                                        setPlay(false)
                                    }, 2000)
                                }
                            }
                        }
                    }
                    let sum = incrementbetcount + 1;
                    setIncrementBetCount(sum);
                }
                // console.log('presrepdatas', presresp, toFixedNumber(Number(presresp?.maxpayout)), Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(4));



                let resultamounts =
                    Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
                        Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
                            Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                                Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                                    Number(toFixedNumber(Number(resultamount))).toFixed(11);
                if (presresp?.isWin == false) {
                    setTimeout(function () {
                        // toast.error(incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + resultamounts, {
                        //     position: position.position,
                        //     style: style,
                        //     iconTheme: iconTheme,
                        // })
                        Swal.fire({
                            title: "You Lose!",
                            text: incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + resultamounts,
                            // imageUrl: "../assets/flip1.png",
                            iconHtml: `<img src=${down}>`,
                            timer: 4200,
                            imageWidth: 400,
                            imageHeight: 200,
                            imageAlt: "Custom image",
                          }); 
                        setActiveRange(false);
                    }, 1000);

                    setTimeout(() => {
                        console.log('111 who come first')
                        if (props?.remainingbets == 1) {
                            console.log('666 play scenario enter');
                            props?.checkOrder()
                            props.onCheck()
                            setPlay(false)
                        }
                    }, 2000);


                } else {
                    setTimeout(function () {
                        // toast.success(incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + resultamounts, {

                        // })
                        Swal.fire({
                            title: "Success!",
                            text: incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + resultamounts,
                            // imageUrl: "../assets/flip1.png",
                            iconHtml: `<img src=${winning}>`,
                            timer: 4200,
                            backdrop: `
                            rgba(0,0,123,0.4)
                            url("${winss}")
                            center top            
                            no-repeat
                          `,
                            imageWidth: 400,
                            imageHeight: 200,
                            imageAlt: "Custom image"
                          });
                        setActiveRange(false)
                    }, 1000);

                    setTimeout(() => {
                        if (props?.remainingbets == 1) {
                            console.log('111 who come first')
                            console.log('666 play scenario enter');
                            props?.checkOrder()
                            props.onCheck()
                            setPlay(false)
                        }
                    }, 2000)
                }
            }

        } catch (e) {
            console.log("FinishInterval_err", e)
        }
    }



    return (
        <>

            <div className='px-0 px-sm-3 game_container_limboo pos pb-4'>
                {/* <img src={flip1} className='img-fluid diceall diceimg1' />
                <img src={flip1} className='img-fluid diceall diceimg3' /> */}

                <span className="squaress rangeside square1"></span>
                <span className="squaress rangeside square2"></span>
                <span className="squaress rangeside square3"></span>
                {/* <span className="squaress rangeside square4"></span> */}
                <span className="squaress rangeside square5"></span>
                <span className="squaress rangeside square6"></span>
                <span className="squaress rangeside square7"></span>
                <h2 className="whtclr poppins text-center mb-4">Range</h2>

                <div className='text_div_range'>

                    <p id="randomnum"></p>

                </div>

                {/* command */}


                <div className='range_slider_div mt-5 pt-5'>
                    {console.log('ransdfasdfasdf', ranges, rangeval)}
                    <div className='range_values'>
                        {ranges && ranges?.length > 0 && ranges?.map((e, i) =>

                            <div key={i} style={{ left: e + "%" }} className={Number(rangeval) > Number(e) ? 'range_val_div green_div' : 'range_val_div red_div'}>
                                <p>{e}</p>
                            </div>
                        )}



                    </div>
                    <Slider
                        min={0}
                        max={100}
                        value={rangeval}
                        onChange={handleOnChangeRange}
                        tooltip={false}
                        handleLabel={rangeval}
                        labels={{ 0: '0', 50: '', 100: '100' }}

                    // ref={sliderRef}
                    //  alwaysShowTooltip={true}
                    // toolTipVisibleAlways = {true}
                    />
                </div>
                <div className='range_input_div mt-5 without_margin_div'>

                    <div className='range_input_div mt-5'>
                        <div className='pe-0 pe-md-3  mt-custom-val'>
                            <p className='d-flex align-items-center justify-content-between'>
                                <span className='me-2'>Roll Under</span>
                                <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg" class="styles_rollType__vbpdp"><path d="M3.944.408a.708.708 0 0 0-.487.215L.624 3.457a.708.708 0 0 0 0 1.003l2.833 2.835a.708.708 0 1 0 1-1.004L2.832 4.665h11.04a.71.71 0 0 0 0-1.418H2.84l1.616-1.62A.709.709 0 0 0 3.944.408Zm7.09 7.085a.708.708 0 0 0-.49 1.213l1.625 1.627H1.126a.708.708 0 0 0 0 1.417h11.039l-1.621 1.626a.71.71 0 0 0 .202 1.197.708.708 0 0 0 .796-.199l2.834-2.833a.708.708 0 0 0 0-.999l-2.834-2.835a.709.709 0 0 0-.507-.214Z" fill="#d48909"></path></svg>
                            </p>
                            <input className='form-control rollunder_input' type='text' value={inputval}
                            // min={5} max={95} 
                            // onChange={(e) => setValueinput(e.target.value)}
                            // onBlur={(e) => Test(e.target.value)}
                            />
                        </div>

                        <div className='pe-0 pe-md-3  mt-custom-val'>
                            <p>Win Chance</p>
                            <div className='input-group input_grp_win win_grp'>
                                <input className='form-control' value={rangeval} />
                                <div className='input-group-append'>
                                    %
                                </div>
                            </div>
                        </div>

                        <div className='ps-0 ps-md-3  mt-custom-val'>
                            <p>Multiplier</p>
                            {console.log("multiplier_multiplier", multiplier)}
                            <input className='form-control multi_3' disabled value={`${multiplier != undefined ? multiplier : ""}x`} />
                        </div>

                    </div>

                    {/* command */}


                    {/* <div id="gameContainer" className='gameContainer_moon_new comingsoon pt-0 d-flex justify-content-center align-items-center'>

    <div className='pt-0'>
    <h1 className='pt-0'>Coming Soon..</h1>
    </div>
    </div> */}











                </div>


                <div id="buttonContainer" className='text-center mx-auto'>
                    <button id="submitBet" className='btn btn-warning wallet play mt-cust-btn-range' disabled={buttonSelected} onClick={() => { submitPlay() }}>Play</button>
                    {/* <button id="takeProfits">Take Profits</button> */}
                    {/* <label for="betAmount">Bet Amount:</label> */}
                    {/* <input type="number" id="betAmount" value="1" /> */}
                </div>
                {/* wintext */}
                <p id="win_loss_div" className='text-center'>{ }</p>
            </div>

        </>
    )


}


export default Rangegame
