export const ONEDAYINSECONDS = 86400;

export const BURN = "0x000000000000000000000000000000000000dEaD";

export const BLOCKS_PER_YEAR = 10512000;

export const GAMECHAINS = [


  // {
  //   NAME: "BSC-TEST",
  //   // https://data-seed-prebsc-1-s1.binance.org:8545/
  //   RPC_URL: "https://data-seed-prebsc-2-s1.bnbchain.org:8545", // BSC TESTNET
  //   CHAIN_ID: 97,
  //   ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
  //   TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
  //   MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
  //   // MASTERCHEF: "0x34A2e0B36Fe44D82Ea393D3c2F03DFe59bB13704",
  //   // MASTERCHEF: "0x797208Fa45Bf7381462ca61a12d129870b38425D",
  //   NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },

  {
    NAME: "BSC-TEST",
    RPC_URL: "https://bsc-testnet.publicnode.com", // BSC TESTNET
    CHAIN_ID: 97,
    ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
    TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
    // MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",
    // GAMEADDRESS : "0x797208Fa45Bf7381462ca61a12d129870b38425D",
    //Testnet
    GAMEADDRESS : "0xbC9E2733994eA299Fe1A6ED24A8e4b462b232989",
    //Mainnet
    // GAMEADDRESS : "0x94faC7a2D60Fe089D5d435ed2a193D9929Dd857e",
    NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  },
  // {
  //   NAME: "AVALANCHE",
  //   RPC_URL: "https://api.avax-test.network/ext/bc/C/rpc", // ETH TESTNET
  //   CHAIN_ID: 43113,
  //   ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
  //   TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", // DITMAX Token
  //   MASTERCHEF: "0xA1Ad9d32096693f66091e02c7155E09E84F8f70f",

  //   NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
  //   {
  //     NAME: "Polygon-TEST",
  //     RPC_URL:"https://matic-mumbai.chainstacklabs.com",
  //     CHAIN_ID:80001, 
  //     TOKENADDRESS: "0x9433f3BaAE2984C6F5C8DE6989aB6dee23eaEE17",
  //      MASTERCHEF: "0x74962D8c90dCb1e4761DF94115BE449044179e5A", // new 
  //     // MASTERCHEF: "0x1afd408a544e901af55cB8735d8c6B49c47A11b2",//old
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
  //   {
  //     NAME: "BSC-MAINNET",
  //     RPC_URL:"https://bsc-dataseed1.binance.org/",
  //     CHAIN_ID:56,
  //     TOKENADDRESS: "0x9eeB6c5ff183E6001c65a12D70026b900AE76781",
  //     MASTERCHEF: "0x825A21c5A32d22b1241C7672EC9D04025960f766", 
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
  // {
  //   NAME: "BSC-MAINNET",
  //   RPC_URL:"https://bsc-dataseed1.binance.org/",
  //   CHAIN_ID:56,
  //   TOKENADDRESS: "0x9eeB6c5ff183E6001c65a12D70026b900AE76781", //irea
  //   MASTERCHEF: "0x5fb7846a3fD122De5feb59bb1Ef67F742a6804d8", 
  //   NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B",
  //   USDT:"0x55d398326f99059ff775485246999027b3197955",
  //   USDI: "0x91c7eBbDcbdE50559FDA424dd7Dc9AF142B9Ed58"
  // },

];




export const getPredictionByName = (name) => {


  let GameData = {
    'Dice': [
      {
        "2x": "##predictdice1## == ##rollDice1##  || ##predictdice2## == ##rollDice2##"
      },
      {
        "4x": "##predictdice1## + ##predictdice2## == ##rollDice1## + ##rollDice2##"
      },
      {
        "8x": "##predictdice1## == ##rollDice1## && ##predictdice2##  == ##rollDice2##"
      },
      {
        "8x": "##predictdice1## == ##rollDice2## && ##predictdice2##  == ##rollDice1##"
      }
    ],
    'CoinFlip': [
      {
        "4x": "##predictcoin## == ##resultCoin##"
      }
    ],
    'RPC' : [
      {
        "4x": "##StoneRps## == ##predictRps## || ##ScissorRps## == ##predictRps## || ##PaperRps## == ##predictRps##"
      }
    ],
    'Wheel' : [
      {
       "2x" : "##predictwheel## == ##greyWheel##" 
      },
      {
        "3x" : "##predictwheel## == ##blueWheel##" 
       },
       {
        "6x" : "##predictwheel## == ##greenWheel##" 
       },
       {
        "48x" : "##predictwheel## == ##redWheel##" 
       }
    ]

  }
  for (let i = 0; i < Object.keys(GameData)?.length; i++) {
    console.log("Object.keys(GameData)", name, Object.keys(GameData))
    if (Object.keys(GameData)[i] == name) {
      console.log("currentdataaaaaaaaaaa", Object.keys(GameData)[i], Object.values(GameData)[i]);
      return Object.values(GameData)[i]
    }

  }

}