import React, { useState, useEffect } from "react";
import Logo from '../assets/logoimg.png';


import rocket from "../assets/rocket.png";
import {  Link } from "react-router-dom";
import { getUserCliamDetailsHooks, UpdateUserCliamHooks } from "../hooks/UseUser";
import { getAccount } from "../hooks/useAccount";


export default function Sidebarheader() {
    const [accountInfo, setAccountInfo] = useState("");
    const [claimamount, setCliamAmount] = useState(0)

    useEffect(() => {
        getClaimData()
    }, [accountInfo]);


    const getClaimData = async () => {
        try {
            const walletAddress = getAccount();
            setAccountInfo(walletAddress);
            let reqData = {
                walletAddress: walletAddress
            }
            let res = await getUserCliamDetailsHooks(reqData);
            console.log("respond_cliam", res?.data?.data);
            let data = res?.data?.data;
            let sumgainamount = 0;
            if (data?.length > 0) {
                for (let i = 0; i < data.length; i++) {
                    sumgainamount = Number(sumgainamount) + Number(data[i]?.GainAmount);
                    console.log('data[i]', sumgainamount)
                }
                // console.log('sumgaindata data[i]', sumgainamount)
                setCliamAmount(sumgainamount) // useless
            }

        } catch (e) {
            console.log('getClaimData_err', e)
        }
    }

    const Cliam = async () => {
        try {
            let reqData = {
                walletAddress: accountInfo
            }
            let res = await UpdateUserCliamHooks(reqData);
            console.log('res_data', res, res?.data?.status);

            if(res?.data?.status == true){
                getClaimData()
            }
        } catch (err) {
            console.log('Cliam_err', err)
        }
    }

    return (
        <>
            <div>


                <div className="row pt-5">
                    <div className="col-md-3 col-sm-6 mt-3">

                        <Link to="/"><img className="img-fluid" src={Logo} alt="image" /></Link>
                    </div>
                    <div className="col-md-9 col-sm-6 mt-3 allbtns">
                        {/* <button type="button" class="btn btn-success contest mt-2 mx-2"><img src={contest} alt="contest" /> CONTEST</button> */}
                        {/* <Link ><button type="button" class="btn btn-success stakebtn  mt-2 mx-0" onClick={() => Cliam()}><img src={stakes} alt="contest" /> CLAIM {claimamount == 0 ? 0 : claimamount?.toFixed(4)}</button></Link> */}
                        {/* <Link to="/stake"><button type="button" class="btn btn-success stakebtn  mt-2 mx-0 ms-2"><img src={rocket} alt="contest" /> STAKE</button></Link> */}
                        {/* <Link to="/earn"><button type="button" class="btn btn-success earnbtn  mt-2  ms-2"><img src={rocket} alt="contest" /> EARN</button></Link> */}
                    </div>
                </div>


            </div>
        </>
    );
}