
import { useState, useEffect } from 'react';
// import './App.css';
import headImage from "../assets/heads-img.png"
import tailImage from "../assets/tails-img.png"
import shadoImage from "../assets/game/shadow_coin.png"
import { CreateGameTransactionHooks, getGameAllTransactionInfoHooks } from "../hooks/UseTransaction";
import { createOrderdetails, GamePrediction } from '../hooks/useBot';
import { checkAlreadyWon, DiceGameCondition } from '../hooks/useDice';
import { getAccount } from '../hooks/useAccount';
import toast from 'react-hot-toast';
import { style, iconTheme, position } from '../hooks/useToast';
import { UpdateGameSettingBalancehooks, getAllGameTransactionDetails, getGameSettingDetails } from '../hooks/UseUser';
import { toFixedNumber } from '../hooks/UseContract';
import flip1 from "../assets/flip1.png";
import flip2 from "../assets/flip2.png";
import flip3 from "../assets/flip3.png";
import winss from "../assets/winss.gif";
import winning from "../assets/winning.gif";
import down from "../assets/downs.gif";
import Swal from 'sweetalert2';

function Coin(props) {
  console.log('props?.gamepercentage', props?.gamepercentage)
  const [selectedcoin, setSelectedCoin] = useState("Tails");
  console.log("choose coin", selectedcoin, props?.bet, props?.onemonthwondata)
  const [totalflip, setTotalflip] = useState(0);
  const [headflip, setHeadflip] = useState(0);
  const [tailflip, setTailflip] = useState(0);
  const [coinimage, setCoinImage] = useState(tailImage);
  const [isRotated, setIsRotated] = useState(false);
  const [buttoncoin, setButtoncoin] = useState(0);

  const [btnDisabled, setBtnDisabled] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState([]);
  const [onemonthwonInfo, setOneMonthWonInfo] = useState([]);
  const [oneweekwonInfo, setOneWeekWonInfo] = useState([]);
  const [multipleBets, setMultipleBets] = useState(0)

  const [roatedcount, setRoatedCount] = useState(0);
  console.log('btnDisabled!btnDisabled', btnDisabled)
  const [maxpayout, setMaxPayout] = useState(0);
  const [totalwager, setTotalWager] = useState(0);

  const [incrementbetcount, setIncrementBetCount] = useState(1);
  const [accountInfo, setAccountInfo] = useState("");
  const [roating, setRoating] = useState(false);
  const [headCount, setHeadCount] = useState(0)
  const [tailCount, setTailCount] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [gamesetting, setGameSetting] = useState({})
  const [allgametransacdata, setAllGameTransData] = useState([]);
  const [allowin, setAllowin] = useState(false);
  console.log('11 clicked_data', clicked)
  // gettransactiondata
  console.log("headflip, tailflip", headflip, tailflip)
  useEffect(() => {
    (async () => {
      // console.log("headflip, tailflip", btnDisabled, totalflip, headflip, tailflip, selectedcoin);
      const walletAddress = getAccount();
      setAccountInfo(walletAddress);
      let transc = await getTransactionDetails(walletAddress);
      console.log("oneweekwonInfoZ_OneMonthWonInfo", transc);
      if (transc?.oneweekdata?.length > 0) {
        OneWeekWonInfo(transc?.oneweekdata)
      }
      if (transc?.onemonthdata?.length > 0) {
        OneMonthWonInfo(transc?.onemonthdata)
      }

    })();


  }, [headflip, tailflip, roating, gamesetting])


  useEffect(() => {
    getGameSettings();
    getAllGameTransactionData();
  }, [props])

  // useEffect(() => {
  //   console.log("headflip_tailflip props data updating shh", props, props?.selectedCoin, props?.bet, headflip, tailflip, props?.multiplebets, props?.remainingbets);
  //   console.log("clicked_data", clicked)
  //   setMultipleBets(props?.multiplebets);
  //   props.InitialselectedCoin(selectedcoin);
  //   if (props?.bet && props?.disabledtossbtn) {
  //     if (props?.remainingbets == 1) {
  //       if(!clicked){
  //         console.log("317234 venkatesh")
  //         setBtnDisabled(false);
  //         props?.selectedCoin == '' ? setSelectedCoin('Tails') : setSelectedCoin(props?.selectedCoin)
  //       }
  //     } else {
  //       if(!clicked){
  //         let myTimeout = setTimeout(function () {
  //           console.log("317234 venkatesh")
  //           setBtnDisabled(false);
  //           props?.selectedCoin == '' ? setSelectedCoin('Tails') : setSelectedCoin(props?.selectedCoin)
  //         }, 1000);
  //       }

  //     }
  //   } else {
  //     console.log("314234234 venkatesh")
  //     setBtnDisabled(true)
  //     props?.setTossStart(true)
  //   }

  //   setMaxPayout(props.maxpayout);
  //   setTotalWager(props.totalwager);
  //   let remainingrolls = (props?.multiplebets - props?.remainingbets) + 1;
  //   setIncrementBetCount(remainingrolls);
  //   if (props?.remainingbets == 0) {
  //     setRoatedCount(0)
  //   }

  // }, [headflip, tailflip, selectedcoin, props]);



  // Newone duplicate
  useEffect(() => {
    if (props?.bet && props?.disabledtossbtn) {
      setBtnDisabled(false);
      props?.selectedCoin == '' ? setSelectedCoin('Tails') : setSelectedCoin(props?.selectedCoin)
    } else {
      setBtnDisabled(true)
      props?.setTossStart(true)
    }
  }, [props]);

  useEffect(() => {
    setMultipleBets(props?.multiplebets);
    props.InitialselectedCoin(selectedcoin);
    console.log('props datasgal who come first', props?.bet, props?.disabledtossbtn, selectedcoin)
    setMaxPayout(props.maxpayout);
    setTotalWager(props.totalwager);
    let remainingrolls = (props?.multiplebets - props?.remainingbets) + 1;
    setIncrementBetCount(remainingrolls);
    if (props?.remainingbets == 0) {
      setRoatedCount(0)
    }

  }, [selectedcoin, props]);


  useEffect(() => {
    (async () => {

      console.log("headflip == 1 || tailflip == 1 first enter111", headflip == 1 || tailflip == 1, headflip, tailflip, props?.pathName, roating, roatedcount >= props.multipleBets, roatedcount, props.multipleBets);

      try {
        if ((headflip == 1) || (tailflip == 1)) {
          console.log('eeeee juju coinflip second enter111', onemonthwonInfo, oneweekwonInfo, oneweekwonInfo.length > 0 || onemonthwonInfo.length > 0, props?.gameInfo?.GameCondition);
          if (oneweekwonInfo?.length > 0 || onemonthwonInfo?.length > 0) {
            let gamedata = {
              predictcoin: selectedcoin == "Heads" ? 1 : 2,
              resultCoin: headflip == 1 ? 1 : 2
            }
            console.log('game datasss', gamedata)
            let dicecondition = await DiceGameCondition(gamedata, props?.pathName, {}, props?.gameInfo?.GameCondition);
            console.log("condition return", dicecondition)
            let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
            console.log("presresp data", presresp);

            let checkalreadywon = await checkAlreadyWon(presresp, oneweekwonInfo, onemonthwonInfo, "CoinFlip");
            console.log("checkalreadywon", checkalreadywon)

            console.log("allow data juju", roatedcount, checkalreadywon?.res1, checkalreadywon?.res2, presresp, checkalreadywon)

            if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
              console.log("never ever come this console")
              setHeadflip(0)
              setTailflip(0)
              let roatedCount = roatedcount - 1;
              setRoatedCount(roatedCount);
              await tossCoin(roatedCount)
            } else {
              FinishInterval();
            }

          } else {
            console.log("No transaction data" + " " + props?.pathName + "finishInterval Initial call lele")
            FinishInterval();
          }
        }
      } catch (e) {
        console.log("coinflip useeffect err", e)
      }



    })();
  }, [headflip, tailflip, roating])


  useEffect(() => {
    //Initially checkup useEffect
    async function fetchData() {
      let gamedata = {
        predictcoin: selectedcoin == "Heads" ? 1 : 2,
        resultCoin: selectedcoin == "Heads" ? 1 : 2
      };
      console.log('headflip data', headflip)
      console.log('Intitially gamedata', gamedata)
      let dicecondition = await DiceGameCondition(gamedata, props?.pathName, {}, props?.gameInfo?.GameCondition);
      console.log("initally condition return", dicecondition)
      let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
      console.log("initally coinflupresresp data", presresp, oneweekwonInfo, onemonthwonInfo);
      let checkalreadywon = await checkAlreadyWon(presresp, oneweekwonInfo, onemonthwonInfo, "CoinFlip");
      console.log("initally checkalreadywon", checkalreadywon);

      if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
        setAllowin(false)
      } else {
        setAllowin(true)
      }

    };
    fetchData();
  }, [props, selectedcoin, headflip, tailflip]);



  const OneMonthWonInfo = async (transc) => {
    try {
      if (props?.pathName != undefined) {
        let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
        console.log("OneMonthWonInfo", res)
        setOneMonthWonInfo(res)
      }

    } catch (e) {
      console.log("PredictionInfo_err", e)
    }
  }


  const OneWeekWonInfo = async (transc) => {
    try {
      if (props?.pathName != undefined) {
        let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
        console.log("OneWeekWonInfo coinflip", res)
        setOneWeekWonInfo(res);
      }
    } catch (err) {
      console.log("OneWeekWonInfo_err", err)
    }
  }
  const getTransactionDetails = async (address) => {
    let data = {
      walletAddress: address,
      gameName: "CoinFlip"
    }
    let res = await getGameAllTransactionInfoHooks(data);
    console.log("respond_data", res, res?.data)
    if (res?.data?.status == 200) {
      if (res?.data?.alltranscdata?.length > 0) {
        //That is useless state , just store all transactiondata
        setTransactionInfo(res?.data?.data)
      }
    }

    if (res?.data?.oneweekdata?.length > 0 || res?.data?.onemonthdata?.length > 0) {
      return {
        oneweekdata: res?.data?.oneweekdata?.length > 0 ? res?.data?.oneweekdata : [],
        onemonthdata: res?.data?.onemonthdata?.length > 0 ? res?.data?.onemonthdata : []
      }
    } else {
      return {
        oneweekdata: [],
        onemonthdata: []
      }
    }
  }

  const getGameSettings = async () => {
    try {
      let res = await getGameSettingDetails();
      console.log('getGameSettings', res?.data)
      if (res?.data?.status == true) {
        let result = res?.data?.data[0];
        if (result?.Status == "Active") {
          setGameSetting(result)
        }
      }
    } catch (e) {
      console.log("gameGameSettings_err", e)
    }
  }

  const getAllGameTransactionData = async () => {
    try {
      let res = await getAllGameTransactionDetails();
      if (res?.data?.status == true) {
        setAllGameTransData(res?.data?.alltranscdata)
      }
    } catch (e) {
      console.log("getAllTransactionData_err", e)
    }
  }


  const tossCoin = (roatedcount) => {

    try {
      let roatedCount = roatedcount + 1;
      console.log("roatedcount_multipleBets", incrementbetcount, multipleBets, roatedCount, multipleBets, roatedCount <= multipleBets);
      if (allowin) {
        if (incrementbetcount <= multipleBets) {
          setBtnDisabled(true);
          setRoatedCount(roatedCount);
          var flipResult = Math.random();
          document.getElementById("coin").classList.remove("heads");
          document.getElementById("coin").classList.remove("tails");
          console.log("flipresult", flipResult, flipResult <= 0.5);
          setTimeout(function () {
            if (flipResult <= 0.5) {
              document.getElementById("coin").classList.add("heads");
              console.log('it is head');

            }
            else {
              document.getElementById("coin").classList.add("tails");
              console.log('it is tails');
            }
          }, 100);

          setTimeout(function () {
            if (flipResult <= 0.5) {
              console.log("headflip_data enter how ubuntu", headflip)
              setHeadflip(1);
              setHeadCount(headCount + 1)
              setRoating(!roating)
            }
            else {
              console.log("tailflip_data enter how ubuntu", tailflip, roating)
              setTailflip(1);
              setTailCount(tailCount + 1)
              setRoating(!roating)
            }

          }, 3000);
        } else {
          setBtnDisabled(true)
        }

      } else {
        if (incrementbetcount <= multipleBets) {
          setBtnDisabled(true);
          setRoatedCount(roatedCount);
          document.getElementById("coin").classList.remove("heads");
          document.getElementById("coin").classList.remove("tails");
          setTimeout(function () {
            if (selectedcoin == "Tails") {
              document.getElementById("coin").classList.add("heads");
            } else {
              document.getElementById("coin").classList.add("tails");
            }
          }, 100);
          console.log('selected_Coin', selectedcoin)
          setTimeout(function () {
            if (selectedcoin == "Tails") {
              setHeadflip(1);
              setHeadCount(headCount + 1)
              setRoating(!roating)
            } else {
              setTailflip(1);
              setTailCount(tailCount + 1)
              setRoating(!roating)
            }
          }, 3000)
        } else {
          setBtnDisabled(true)
        }


      }

    } catch (err) {
      console.log("tossCoin_tossCoin_err", err)
    }

  }


  function setCoin(val) {
    console.log(val, "val_doin", isRotated, "bi", buttoncoin);
    document.getElementById("coin").classList.remove("heads");
    document.getElementById("coin").classList.remove("tails");
    if (val == "Tails") {
      document.getElementById("coin").classList.add("tails");
    }
    else {
      document.getElementById("coin").classList.add("heads");

    }
  }


  const FinishInterval = async () => {

    try {
      if (incrementbetcount <= multipleBets) {
        console.log('finishinterval who come first')
        let gamedata = {
          predictcoin: selectedcoin == "Heads" ? 1 : 2,
          resultCoin: headflip == 1 ? 1 : 2
        }
        console.log("FinishInterval_gamedatas coinflip game", gamedata)
        let dicecondition = await DiceGameCondition(gamedata, props?.pathName, {}, props?.gameInfo?.GameCondition);
        console.log("result condition return coinflip game", dicecondition, [], maxpayout, totalwager)
        //allgametransacdata --> useless
        let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
        console.log("final presrep coinflip game", presresp, dicecondition);

        if (presresp) {
          let data = {}
          if (presresp?.isWin == true) {
            props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
              if (String(Object.keys(val)[0]) == String(presresp?.Multiplier)) {
                let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
                let amount = Number(props?.calculatewager) + Number(wonpercentage);
                console.log('multipliet data', Object.values(val)[0], wonpercentage, amount)
                if (presresp?.isWin == true) {
                  data = {
                    walletAddress: accountInfo,
                    transaction: presresp,
                    gameName: props?.pathName,
                    Bets: multipleBets,
                    Wager: props?.wager,
                    CalculateWager: props?.calculatewager,
                    TotalWager: props?.totalwager,
                    Amount: amount,
                    OrderId: props?.usergamedetails?._id
                  }
                } else if (presresp?.isWin == false) {
                  data = {
                    walletAddress: accountInfo,
                    transaction: presresp,
                    gameName: props?.pathName,
                    Bets: multipleBets,
                    Wager: props?.wager,
                    CalculateWager: props?.calculatewager,
                    TotalWager: props?.totalwager,
                    Amount: presresp?.maxpayout,
                    OrderId: props?.usergamedetails?._id
                  }
                }

              } else if ("0x" == String(presresp?.Multiplier)) {
                data = {
                  walletAddress: accountInfo,
                  transaction: presresp,
                  gameName: props?.pathName,
                  Bets: multipleBets,
                  Wager: props?.wager,
                  CalculateWager: props?.calculatewager,
                  TotalWager: props?.totalwager,
                  Amount: presresp?.maxpayout,
                  OrderId: props?.usergamedetails?._id
                }
              }
            })

          } else if (presresp?.isWin == false) {
            if ("0x" == String(presresp?.Multiplier)) {
              data = {
                walletAddress: accountInfo,
                transaction: presresp,
                gameName: props?.pathName,
                Bets: multipleBets,
                Wager: props?.wager,
                CalculateWager: props?.calculatewager,
                TotalWager: props?.totalwager,
                Amount: presresp?.maxpayout,
                OrderId: props?.usergamedetails?._id
              }
            }
          }
          let lossamount = 0, gainamount = 0;
          var resultamount = 0;
          console.log('props?.gamepercentage', props?.gamepercentage)
          props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
            console.log('valllgamepercentage', val, Object.keys(val), Object.values(val)[0], String(Object.keys(val)) == String(presresp?.Multiplier), String(Object.keys(val)[0]) == String(presresp?.Multiplier));
            if (String(Object.keys(val)[0]) == String(presresp?.Multiplier)) {
              let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
              let amount = Number(props?.calculatewager) + Number(wonpercentage);
              console.log('multipliet data', Object.values(val)[0], wonpercentage, amount)
              if (presresp?.isWin == false) {
                lossamount = presresp?.maxpayout;
                resultamount = presresp?.maxpayout;
              } else {
                gainamount = amount;
                resultamount = amount;
              }
            } else if ("0x" == String(presresp?.Multiplier)) {
              if (presresp?.isWin == false) {
                lossamount = presresp?.maxpayout;
                resultamount = presresp?.maxpayout;
              }
            }

          })
          // let lossamount = 0, gainamount = 0;
          // if (presresp?.isWin == false) {
          //   lossamount = presresp?.maxpayout
          // } else {
          //   gainamount = presresp?.maxpayout
          // }
          console.log("lossamount, gainamount", lossamount, gainamount, data)


          let res = await CreateGameTransactionHooks(data);
          console.log("orderdata ruru1111", res);

          if (res?.status == 200) {
            // let createres = await createOrderdetails(props?.pathName, props?.wager, props?.remainingbets, props?.betamount, props.selectedCoin, accountInfo, props.stoploss, props.stopgain, gainamount, lossamount, props?.calculatewager);
            let createres = await createOrderdetails(props?.pathName, props?.wager, props?.remainingbets, props?.betamount, props.selectedCoin, accountInfo, props.stopgain, props.stoploss, gainamount, lossamount, props?.maxpayout, props?.calculatewager, props?.totalwager, '', '');
            console.log('createres', createres)
            let result = createres?.data?.data;
            if (createres?.status == 200) {
              if (createres?.data?.status == true) {
                console.log("update balance", result, result?.RemainingBets == 0)
                if (result?.RemainingBets == 0) {
                  let reqData = { _id: result?._id, walletAddress: accountInfo }
                  let res = await UpdateGameSettingBalancehooks(reqData);
                  console.log("update balance 212", res)
                  if (res?.data?.status == true) {
                    setTimeout(() => {
                      props?.setFeeData()
                    }, 3000)
                  }
                }
              }

              //new
              if ((createres?.data?.data?.StopGain != 0 || createres?.data?.data?.StopLoss != 0) && (((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopGain)) / 100) <= (createres?.data?.data?.GainAmount) || ((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopLoss)) / 100) <= (createres?.data?.data?.LossAmount))) {
                setTimeout(function () {
                  console.log('222 who come first')
                  props?.checkOrder()
                  props.onCheck()
                }, 4000)
              } else if (props?.remainingbets != 1) {
                console.log('who come first')
                setTimeout(function () {
                  props?.checkOrder()
                  props.onCheck()
                }, 2000)
              }


              // if (props?.remainingbets == 1) {
              //   setClicked(false)
              // } else if((createres?.data?.data?.StopGain != 0 || createres?.data?.data?.StopLoss != 0) && (((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopGain)) / 100) <= (createres?.data?.data?.GainAmount) || ((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopLoss)) / 100) <= (createres?.data?.data?.LossAmount))) {
              //   setClicked(false)
              // }
            }
          }

        }

        if (presresp?.isWin == false) {
          let resultamounts =
            Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
              Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
                Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                  Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                    Number(toFixedNumber(Number(resultamount))).toFixed(11);
          setTimeout(function () {
            // toast.error(incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + (resultamounts), {
            //   position: position.position,
            //   style: style,
            //   iconTheme: iconTheme,

            // });
            Swal.fire({
              title: "You Lose!",
              text: incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + (resultamounts),
              // imageUrl: "../assets/flip1.png",
              iconHtml: `<img src=${down}>`,
              timer: 4200,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
            });
            console.log("314234 venkatesh")
            setHeadflip(0)
            setTailflip(0)
            // setBtnDisabled(false)
          }, 1000);

          setTimeout(() => {
            console.log('111 who come first')
            if (props?.remainingbets == 1) {
              // setClicked(false)
              props?.checkOrder()
              props.onCheck()

            }
          }, 2000);
        } else {
          let resultamounts =
            Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
              Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
                Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                  Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                    Number(toFixedNumber(Number(resultamount))).toFixed(11);
          setTimeout(function () {
            // toast.success(incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + (resultamounts), {
            //   position: position.position,
            //   style: style,
            //   iconTheme: iconTheme,
            // })
            Swal.fire({
              title: "Success!",
              text: incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + (resultamounts),
              // imageUrl: "../assets/flip1.png",
              iconHtml: `<img src=${winning}>`,
              timer: 4200,
              backdrop: `
              rgba(0,0,123,0.4)
              url("${winss}")
              center top            
              no-repeat
            `,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image"
            });
            console.log("334234 venkatesh")
            setHeadflip(0)
            setTailflip(0)
            // setBtnDisabled(false)
          }, 1000);

          setTimeout(() => {
            console.log('111 who come first')
            if (props?.remainingbets == 1) {
              // setClicked(false)
              props?.checkOrder()
              props.onCheck()
            }
          }, 2000);

        }
        let sum = incrementbetcount + 1;
        setIncrementBetCount(sum);
      }

    } catch (err) {
      console.log("FinishInterval_coin_errrrrr", err)
    }
  }
  return (
    <div className='pb-4'>


      <div className='container game_container coin_flip position'>
        <h2 className="whtclr poppins text-center pb-4">Coin Flip</h2>
        <img src={flip1} className='flip1 flip' />
        <img src={flip1} className='flip2 flip' />
        <img src={flip2} className='flip3 flip' />
        <img src={flip3} className='flip4 flip' />
        <div className='coin_img_section'>

          <div id="coin">
            <div className="side-a">
              {/* <img src={tailImage}  className={`img-fluid coin_image`}  /> */}

            </div>
            <div className="side-b">
              {/* <img src={headImage}  className={`img-fluid coin_image`}  /> */}

            </div>
          </div>




          <img src={shadoImage} className='img_sahdow img-fluid' />




        </div>

        <div className='btn_grp_border'>
          <button disabled={props?.bet} className={selectedcoin == "Tails" ? 'btn btn_coin active' : 'btn btn_coin'} readOnly={props?.bet} onClick={() => { setSelectedCoin("Tails"); setCoin("Tails"); setButtoncoin(0) }}>
            <img src={tailImage} className='img-fluid coin_image' />
            <span className='ms-1'>Tails</span>
          </button>

          <button disabled={props?.bet} className={selectedcoin == "Heads" ? 'btn btn_coin active' : 'btn btn_coin'} readOnly={props?.bet} onClick={() => { setSelectedCoin("Heads"); setCoin("Heads"); setButtoncoin(1) }}>
            <img src={headImage} className='img-fluid coin_image' />
            <span className='ms-1'>Heads</span>
          </button>
        </div>

      </div>

      <div className='container dice_btn coin_flex'>
        <button className="btn btn_danger mb-3"
          disabled={btnDisabled}
          onClick={() => { tossCoin(roatedcount); setClicked(true) }}
        >
          {console.log("btnDisabled_btnDisabled", btnDisabled, incrementbetcount, incrementbetcount - 1, props?.usergamedetails, Object.keys(props?.usergamedetails)?.length > 0)}
          Toss Coin
        </button>
        <div>

          {/* props?.remainingbets == 0 ? 0 :  */}
          {/* {(Object.keys(props?.usergamedetails)?.length) > 0 && <p>
            Total Toss : <span>{(Object.keys(props?.usergamedetails)?.length) > 0 ? <>{console.log('enter this one', incrementbetcount - 1)} {incrementbetcount - 1}</>: 0}</span>
          </p>} */}

          {/* <p>
            Total Heads : <span>{headflip}</span>
          </p>

          <p>
            Total Tails : <span>{tailflip}</span>
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default Coin;
