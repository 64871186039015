import React, { useState, useEffect } from "react";
import time from '../assets/time.png';
import tablecoin from '../assets/tablecoin.png';
import pigicoin from '../assets/pigii.png';
import { getAccount } from "../hooks/useAccount";
import { getGameAllTransactionInfoHooks } from "../hooks/UseTransaction";
import { DateTimeForm } from "../hooks/useHelper";

export default function Gametable(props) {
    const [accountInfo, setAccountInfo] = useState("");
    const [transactionInfo, setTransactionInfo] = useState([]);


    //ven
    const [transdetails1, setTransDetails] = useState([])
console.log("transactionInfo_transactionInfo", transactionInfo)
    useEffect(() => {
        window.scrollTo(0, 0);
        const walletAddress = getAccount();
        setAccountInfo(walletAddress);
        transdetails(walletAddress);
    }, []);

    useEffect(() => {
        let transdetails = props?.transdetails;
        setTransactionInfo(transdetails)
    }, [props])

    const transdetails = async (address) =>{
        let data = {
            walletAddress: address,
            gameName: "Dice"
          }
        let res = await getGameAllTransactionInfoHooks(data);
        console.log("dasfasdfasd", res?.data)
        if (res?.status == 200) {
            if (res?.data?.alltranscdata?.length > 0) {
                // setTransactionInfo(res?.data?.alltranscdata)
            }
        }
    }
    const [gametable, setgametable] = useState([
        { "id": 1, }, { "id": 2, }, { "id": 3, }, { "id": 4, }, { "id": 5, }, { "id": 6, }, { "id": 7, }, { "id": 8, }, { "id": 9, }, { "id": 10, },
    ]);


    const TranscData = async ()  => {
        try{

        } catch(e){
            console.log("TranscData_err", e)
        }
    }
    return (
        <>
            <div className="alltable">
                {
                 <div className="d-flex justify-content-between align-items-center viewper pb-4">
                    <h5 className="fw-700 mb-0 bet whtclr">Bet History </h5>
                    <button type="button" class="btn btn-light fw-700" onClick={() => { props.setTransFilter(props.transfilter == "all" ? "personal" : "all") }}>{props.transfilter != "all" ? "View All" : "View Personal"}</button>
                </div>}
                {console.log("transactionInfo_transactionInfo", transactionInfo)}
            {   <table class="table table-hover table_gme gametable_width">
                    <thead>
                        <tr>
                            <th scope="col">Created At</th>
                            <th scope="col">Player</th>
                            {/* <th scope="col">Bets</th> */}
                            <th scope="col">Wager</th>
                            <th scope="col">Multiplier</th>
                            {/* <th scope="col">Multiplier</th> */}
                            <th scope="col">MaxPayout</th>
                            <th scope="col">Profit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionInfo.length > 0 ?
                        transactionInfo && transactionInfo.length > 0 && transactionInfo.map((val, i) =>
                            <tr>
                                <td style={{ color: "white" }}>{DateTimeForm(val?.createdAt, false, true)}<img src={time} alt="images" className="timess" /></td>
                                <td style={{ color: "white" }}>{`${val.WalletAddress.substring(0, 6)}...${val.WalletAddress.substring(38, 42)}`}</td>
                                {/* <td>{val.MultipleBets}</td> */}
                                <td style={{ color: "white" }}><div className="td_with_img">{Number(val?.Wager)} <img src={tablecoin} alt="images" /></div></td>
                                {/* <td>0.98x</td> */}
                                <td style={{ color: "white" }}>{val?.Multiplier} </td>
                                <td style={{ color: "white" }}><div className="td_with_img">{val?.MaxPayOut} <img src={tablecoin} alt="images" /></div></td>
                                {console.log('val?.Amount', val?.Amount)}
                                <td style={ val.isWin == true ?{ color: "green" } : {color: "red"}}><div className="td_with_img">{val.isWin == true ? ` + ${Number(val?.Amount).toFixed(6) > 0 ? Number(val?.Amount).toFixed(6) : Number(val?.Amount).toFixed(7) > 0 ? Number(val?.Amount).toFixed(7) : Number(val?.Amount).toFixed(8) > 0 ? Number(val?.Amount).toFixed(8) : Number(val?.Amount).toFixed(9) > 0 ? Number(val?.Amount).toFixed(9) : Number(val?.Amount).toFixed(10) > 0 ? Number(val?.Amount).toFixed(10) : Number(val?.Amount).toFixed(11)}` : `- ${Number(val?.Amount).toFixed(6) > 0 ? Number(val?.Amount).toFixed(6) : Number(val?.Amount).toFixed(7) > 0 ? Number(val?.Amount).toFixed(7) : Number(val?.Amount).toFixed(8) > 0 ? Number(val?.Amount).toFixed(8) : Number(val?.Amount).toFixed(9) > 0 ? Number(val?.Amount).toFixed(9) : Number(val?.Amount).toFixed(10) > 0 ? Number(val?.Amount).toFixed(10) : Number(val?.Amount).toFixed(11)}` }  <img src={tablecoin} alt="images" /></div></td>
                            </tr>
                        )
                        :
                        <tr>
                        <td colSpan={6} className="text-center no_item_text">No items found</td>
                        </tr>
                        }

                    </tbody>
                </table>

}

            </div>

        </>
    );
}