import React, { useState, useEffect } from 'react';
import { UpdateGameSettingBalancehooks, getAllGameTransactionDetails, getGameSettingDetails } from '../hooks/UseUser';
import { GamePrediction, createOrderdetails } from '../hooks/useBot';
import { CreateGameTransactionHooks, getGameAllTransactionInfoHooks } from '../hooks/UseTransaction';
import { getAccount } from '../hooks/useAccount';
import { DiceGameCondition, checkAlreadyWon } from '../hooks/useDice';
import toast from 'react-hot-toast';
import { iconTheme, position, style } from '../hooks/useToast';
import { toFixedNumber } from '../hooks/UseContract';
import winss from "../assets/winss.gif";
import winning from "../assets/winning.gif";
import down from "../assets/downs.gif";
import Swal from 'sweetalert2';
// Ensure this points to your CSS file


function PlinkoGame(props) {
    const [ballPosition, setBallPosition] = useState({});
    const [resultarrdata, setResultArrData] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(false);
    console.log('prrrrrrrrrrrr', props)


    //venkatesh
    const [gamesetting, setGameSetting] = useState({});
    const [allgametransacdata, setAllGameTransData] = useState([]);
    const [transactionInfo, setTransactionInfo] = useState([]);
    const [onemonthwonInfo, setOneMonthWonInfo] = useState([]);
    const [oneweekwonInfo, setOneWeekWonInfo] = useState([]);
    const [accountInfo, setAccountInfo] = useState("");
    const [activeplinko, setActivePlinko] = useState(false);
    const [resultplinko, setResultPlinko] = useState(0)
    const [gamecondition, setGameCondition] = useState({})
    const [totalwager, setTotalWager] = useState(0);
    const [maxpayout, setMaxPayout] = useState(0);
    const [incrementbetcount, setIncrementBetCount] = useState(1);
    const [multipleBets, setMultipleBets] = useState(0);
    const [minplinko, setMinPlinko] = useState(0)
    const [maxplinko, setMaxPlinko] = useState(0)
    const [rowcolarr, setRowColArr] = useState();
    const [resultarr, setResultArr] = useState([]);
    const [rowbuckets, setRowBuckets] = useState([47, 8, 2, 0.9, 0.6, 0.4, 0.6, 2, 8, 47]);
    const [plinkobetbuc, setPlinkoBuc] = useState()
    const [play, setPlay] = useState(false)

    console.log('Plinko_result_arr', resultarr, resultplinko)

    console.log('ballPosition data', resultarrdata)



    var plinkobets = plinkobetbuc;
    const ROWS = (Number(plinkobets) + 1);
    const COLUMNS = (plinkobets);
    console.log("ROWS_COLUMNS",plinkobets,Number(plinkobets) + 1, ROWS, plinkobets,COLUMNS);
    useEffect(() => {
        getGameSettings();
        getAllGameTransactionData();
    }, [props]);

    const getGameSettings = async () => {
        try {
            let res = await getGameSettingDetails();
            //The function use to admin withdrawpermonth data get
            console.log('getGameSettings', res?.data)
            if (res?.data?.status == true) {
                let result = res?.data?.data[0];
                if (result?.Status == "Active") {
                    setGameSetting(result)
                }
            }
        } catch (e) {
            console.log("gameGameSettings_err", e)
        }
    };


    const getAllGameTransactionData = async () => {
        try {
            // The month start to end vise, get all gamedetails
            let res = await getAllGameTransactionDetails();
            if (res?.data?.status == true) {
                setAllGameTransData(res?.data?.alltranscdata)
            }
        } catch (e) {
            console.log("getAllTransactionData_err", e)
        }
    };
    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    var container = []
    var index = 5;
    var a = [[0, 1], [0, 2], [0, 3], [0, 4], [0, 5], [0, 6], [0, 7], [0, 8], [0, 9], [0, 10], [0, 11], [0, 12],
    [1, 1], [1, 2], [1, 3], [1, 4], [1, 5], [1, 6], [1, 7], [1, 8], [1, 9], [1, 10], [1, 11], [1, 12],
    [2, 1], [2, 2], [2, 3], [2, 4], [2, 5], [2, 6], [2, 7], [2, 8], [2, 9], [2, 10], [2, 11], [2, 12],
    [3, 1], [3, 2], [3, 3], [3, 4], [3, 5], [3, 6], [3, 7], [3, 8], [3, 9], [3, 10], [3, 11], [3, 12],
    [4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [4, 6], [4, 7], [4, 8], [4, 9], [4, 10], [4, 11], [4, 12],
    [5, 1], [5, 2], [5, 3], [5, 4], [5, 5], [5, 6], [5, 7], [5, 8], [5, 9], [5, 10], [5, 11], [5, 12],
        //  [6, 1], [6, 2], [6, 3], [6, 4], [6, 5], [6, 6],[6,7], [6,8], [6,9], [6,10], [6,11], [6,12],
    ];

    useEffect(() => {
        setMultipleBets(props?.multiplebets);
        setTotalWager(props.totalwager);
        setMaxPayout(props.maxpayout);
        let remainingrolls = (props?.multiplebets - props?.remainingbets) + 1;
        console.log('props incremtn remainingbets_remainingbets', props?.multiplebets, props?.remainingbets, remainingrolls)
        setIncrementBetCount(remainingrolls);
    }, [props, resultplinko]);


    useEffect(() => {
        (async () => {
            const walletAddress = getAccount();
            setAccountInfo(walletAddress);
            // Call the function 
            let transc = await getTransactionDetails(walletAddress);
            console.log("oneweekwonInfoZ_OneMonthWonInfo", transc);
            if (transc?.oneweekdata?.length > 0) {
                OneWeekWonInfo(transc?.oneweekdata)
            }
            if (transc?.onemonthdata?.length > 0) {
                OneMonthWonInfo(transc?.onemonthdata)
            }

        })();
    }, [gamesetting]);




    useEffect(() => {
        async function fetchData() {
            console.log('useEffect_onemonthwonInfodata', onemonthwonInfo, plinkobets);
            const { min, max, arr } = PlinkoData(plinkobets);
            console.log('rrrrrrrr', arr, min, max)

            if (oneweekwonInfo?.length > 0 || onemonthwonInfo?.length > 0) {
                console.log('onemonthwonInfodata', onemonthwonInfo)
                for (let i = 0; i < onemonthwonInfo?.length; i++) {
                    console.log('vallllllll', onemonthwonInfo[i], arr);
                    for (let j = 0; j < arr?.length; j++) {
                        console.log('match arr', arr, (onemonthwonInfo[i]['Multiplier']).split('x')[0], arr[j], Number((onemonthwonInfo[i]['Multiplier']).split('x')[0]) == arr[j],
                            onemonthwonInfo[i]['allowwin'] == 0)
                        if (Number((onemonthwonInfo[i]['Multiplier']).split('x')[0]) == arr[j] && onemonthwonInfo[i]['allowwin'] == 0) {
                            console.log('enter numbers', onemonthwonInfo[i], arr, arr[j], arr[j] - 1);
                            let Indexposition = arr.indexOf(arr[j]);
                            console.log('IndexPosition', Indexposition, arr)
                            // At position 2, remove 1 item:
                            arr.splice(Indexposition, 1);
                            console.log('removeAllowinNumber', Indexposition, arr);
                        }
                    }
                }
                console.log('arrforletj', arr)
                setResultArr(arr)
                setMaxPlinko(max)
                setMinPlinko(min)
                setPlinkoBuc(props?.plinkobets)
            } else {
                setResultArr(arr)
                setMaxPlinko(max)
                setMinPlinko(min)
                setPlinkoBuc(props?.plinkobets)
            }

            console.log('arr_data_result', arr)
        }
        fetchData()
    }, [props, onemonthwonInfo]);


    console.log('resultArr_resultArr', resultarr)
    useEffect(() => {
        let plinko = {};
        console.log('resultplinko_data vbcmvbcxc', resultplinko)
        plinko[`${resultplinko}x`] = "##LesserPlinko##  <= ##resultPlinko## && ##GreaterPlinko## >= ##resultPlinko##"
        setGameCondition({ "Plinko": [plinko] })
    }, [resultplinko]);


    useEffect(() => {
        var columns = [];
        if (plinkobets == 12) {
            columns = [70, 16, 3, 2, 0.9, 0.6, 0.4, 0.6, 2, 3, 16, 70];
        } else if (plinkobets == 11) {
            columns = [65, 17, 4, 0.9, 0.6, 0.4, 0.6, 0.9, 4, 17, 65];
        } else if (plinkobets == 10) {
            columns = [47, 8, 2, 0.9, 0.6, 0.4, 0.6, 2, 8, 47];
        } else if (plinkobets == 9) {
            columns = [45, 8, 0.9, 0.6, 0.4, 0.6, 0.9, 8, 45];
        } else if (plinkobets == 8) {
            columns = [20, 4, 0.9, 0.6, 0.4, 0.6, 4, 20];
        } else if (plinkobets == 7) {
            columns = [15, 2, 0.7, 0.4, 0.7, 2, 15];
        } else if (plinkobets == 6) {
            columns = [10, 1, 0.4, 0.1, 1, 10]
        }
        console.log('current columns', columns);
        setRowBuckets(columns)
    }, [props, plinkobets])


    const getTransactionDetails = async (address) => {
        let data = {
            walletAddress: address,
            gameName: "Plinko"
        }
        // This function use gameName depends to get that user last onemonthwondata & last oneweekdata.
        let res = await getGameAllTransactionInfoHooks(data);
        console.log("respond_data", res)
        if (res?.status == 200) {
            if (res?.data?.alltranscdata?.length > 0) {
                //That is useless state , just store all transactiondata
                setTransactionInfo(res?.data?.alltranscdata)
            }
        }

        if (res?.data?.oneweekdata?.length > 0 || res?.data?.onemonthdata?.length > 0) {
            return {
                oneweekdata: res?.data?.oneweekdata?.length > 0 ? res?.data?.oneweekdata : [],
                onemonthdata: res?.data?.onemonthdata?.length > 0 ? res?.data?.onemonthdata : []
            }
        } else {
            return {
                oneweekdata: [],
                onemonthdata: []
            }
        }
    }

    const OneMonthWonInfo = async (transc) => {
        try {
            let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
            console.log("GamePrediction_OneMonthWonInfo", res, transc)
            setOneMonthWonInfo(res)
        } catch (e) {
            console.log("PredictionInfo_err", e)
        }
    }


    const OneWeekWonInfo = async (transc) => {
        try {
            console.log('transdata', transc)
            let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
            console.log("OneWeekWonInfo", res)
            setOneWeekWonInfo(res);
        } catch (err) {
            console.log("OneWeekWonInfo_err", err)
        }
    }


    useEffect(() => {
        async function fetchData() {
            try {
                console.log("activeplinko", resultplinko, activeplinko, oneweekwonInfo, onemonthwonInfo, oneweekwonInfo?.length > 0 || oneweekwonInfo?.length > 0)
                if (activeplinko) {
                    if (oneweekwonInfo?.length > 0 || onemonthwonInfo?.length > 0) {
                        console.log('activeplinko_activeplinko', resultplinko, minplinko, maxplinko)
                        let gamedata = {
                            resultPlinko: resultplinko,
                            LesserPlinko: minplinko,
                            GreaterPlinko: maxplinko
                        };
                        console.log('game datasss', gamedata)
                        let dicecondition = await DiceGameCondition(gamedata, props?.pathName, gamecondition);
                        console.log("condition return", dicecondition)
                        let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, props?.pathName, gamesetting, allgametransacdata, props?.maxpayout);
                        console.log("presresp data", presresp);

                        let checkalreadywon = await checkAlreadyWon(presresp, oneweekwonInfo, onemonthwonInfo, "Plinko");
                        console.log("checkalreadywon", checkalreadywon)

                        console.log("allow data juju", checkalreadywon?.res1, checkalreadywon?.res2, presresp, checkalreadywon);
                        if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
                            console.log("never ever come this console")

                        } else {
                            console.log("call the finishinterval");
                            FinishInterval();
                        }
                    } else {
                        console.log("111 call the finishinterval");
                        FinishInterval();
                    }
                }
            } catch (e) {
                console.log('useEff err', e)
            }
        }
        fetchData()
    }, [activeplinko]);


    useEffect(() => {
        console.log('propsbetsgal', props?.bet)
        if (props?.bet && props?.disabledPlinkobtn) {
            if (!play) {
                setBtnDisabled(false);
            }
        } else {
            setBtnDisabled(true);
            props?.setPlinkoStart(true)
        };
        console.log('props?.plinkobets', props?.plinkobets)
        if (props?.plinkobets) {
            plinkobets = props?.plinkobets;
        }
    }, [props]);

    const PlinkoData = (plinkobets) => {
        try {
            console.log('useEffect_onemonthwonInfodata', onemonthwonInfo, plinkobets);
            let arr = [];
            if (plinkobets == 6) {
                arr = [10, 1, 0.4, 0.1, 1, 10];
            } else if (plinkobets == 7) {
                arr = [15, 2, 0.7, 0.4, 0.7, 2, 15];
            } else if (plinkobets == 8) {
                arr = [20, 4, 0.9, 0.6, 0.4, 0.6, 4, 20];
            } else if (plinkobets == 9) {
                arr = [45, 8, 0.9, 0.6, 0.4, 0.6, 0.9, 8, 45];
            } else if (plinkobets == 10) {
                arr = [47, 8, 2, 0.9, 0.6, 0.4, 0.6, 2, 8, 47];
            } else if (plinkobets == 11) {
                arr = [65, 17, 4, 0.9, 0.6, 0.4, 0.6, 0.9, 4, 17, 65];
            } else if (plinkobets == 12) {
                arr = [70, 16, 3, 2, 0.9, 0.6, 0.4, 0.6, 2, 3, 16, 70]
            }
            let min = arr[0];
            let max = arr[0];
            for (let i = 0; i < arr?.length; i++) {
                console.log('vvvvvvv4353vvvvvv', arr[i], min)
                if (min >= arr[i] && arr[i] >= 1) {
                    min = arr[i];
                }
                if (max <= arr[i]) {
                    max = arr[i]
                }
            }
            console.log('aaaaaaaaaaaaa', min, max);
            return {
                min: min, max: max, arr: arr
            }
        } catch (e) {
            console.log('plinkodata_err', e)
        }
    }




    var row;
    var columns;
    var plinkoarr = [];


    const PlinkoRowData = async (ind) => {
        try {
            console.log('areswefsdf', ind)
            if (ind) {
                plinkoarr.push([plinkobets, ind])
                let arrdata = startDrop(ind)
                console.log("plinko_row_data", arrdata);
                setRowColArr(arrdata);
                return arrdata;
            }
        } catch (e) {
            console.log("PlinkoRowData_err", e)
        }
    }



    const randomizeBallPosition = async () => {
        try {
            console.log("call the function")
            console.log('incrementbetcount,multipleBets', incrementbetcount, multipleBets)
            if (incrementbetcount <= multipleBets) {
                console.log('resultarr_resultarr', resultarr, resultarr?.length);
                let min = 0;
                let max = resultarr?.length - 1;
                setBtnDisabled(true);
                setPlay(true)
                console.log('min, max data', min, max)
                const randomColumn = getRandomInt(min, max);
                console.log("randomColumn", resultarr, resultarr[randomColumn], randomColumn);
                console.log('call the func plinkobets', plinkobets)
                let data = PlinkoData(plinkobets);
                console.log("call the func data", data)
                let indexdata = 0;
                data?.arr && data?.arr?.length > 0 && data?.arr?.map((val, ind) => {
                    console.log('searchingnumber', val, resultarr[randomColumn], String(val), String(val) == resultarr[randomColumn], ind)
                    if (String(val) == resultarr[randomColumn]) {
                        indexdata = ind
                    }
                });
                console.log("index_data_index", indexdata)
                let rowcoldata = await PlinkoRowData(indexdata);
                console.log('rowcoldata_rowcoldata', rowcoldata)

                console.log('resultnum', data?.arr, indexdata);

                // setRowColArr(plinkoarr)
                let resultplin = rowbuckets[indexdata];
                console.log('result plin vbcmvbcxc', resultplin, data?.arr, indexdata);
                setTimeout(() => {
                    setResultPlinko(resultplin);
                }, 4000)
                setTimeout(() => {
                    setActivePlinko(true);
                }, 4000)

            }
        } catch (e) {
            console.log("randomizeBallPosition_err", e)
        }

    };



    const startDrop = (ind) => {
        try {
            console.log('plinkoarrplinkoarr', plinkoarr)
            for (let i = (plinkobets - 1); i >= 0; i--) {
                let data;
                console.log('rowdatasdfasdf', plinkoarr, plinkobets, i, row, columns)
                if (!row && !columns) {
                    data = [(plinkobets - 1), ind];
                } else {
                    data = [row, columns];
                }
                console.log('dafasdfasdfasrew rowdatasdfasdf', data)
                if (data) {
                    console.log('ddddddddd', data);
                    let lefdata = data[1] - 1;
                    let rigdata = data[1] + 1;
                    console.log('left_rig', data, lefdata, rigdata);
                    let arr1 = []
                    if (0 < lefdata) {
                        arr1.push([i, lefdata])
                    }
                    if (rigdata < plinkobets) {
                        arr1.push([i, rigdata])
                    } let min = 0;
                    let max = arr1?.length - 1;

                    const randomColumn = getRandomInt(min, max);
                    row = arr1[randomColumn][0];
                    columns = arr1[randomColumn][1];

                    console.log('plinkoarr_dafare', plinkoarr, [plinkobets, ind])
                    plinkoarr.unshift(arr1[randomColumn])

                }
            }
            // setIsDropping(true);
            let currentRow = 0;

            const dropInterval = setInterval(() => {
                console.log('plinkoarr data', plinkoarr)
                let rowdata = plinkoarr[currentRow][0]
                let columndata = plinkoarr[currentRow][1]
                console.log('aasfdasare', currentRow, rowdata, columndata);
                setBallPosition({ row: rowdata, column: columndata })
                currentRow++;

                if (currentRow === plinkobets + 1) {
                    clearInterval(dropInterval);
                    // setIsDropping(false)
                    // Determine the prize
                }
            }, 300);


            return plinkoarr
            // console.log('resultplin arr data', resultarr)
        } catch (e) {
            console.log('dropBall_errrr', e)
        }
    };





    const FinishInterval = async () => {
        try {
            if (incrementbetcount <= multipleBets) {
                let gamedata = {
                    resultPlinko: resultplinko,
                    LesserPlinko: minplinko,
                    GreaterPlinko: maxplinko
                };
                let plinko = {};
                plinko[`${resultplinko}x`] = "##LesserPlinko##  <= ##resultPlinko## && ##GreaterPlinko## >= ##resultPlinko##"
                let gamecondition = { "Plinko" : [plinko]};
                console.log("result plinko gamedata", gamedata,resultplinko, incrementbetcount, gamecondition, multipleBets)
                let plinkocondition = await DiceGameCondition(gamedata, "Plinko", gamecondition);
                let presresp = await GamePrediction(plinkocondition, [], props?.calculatewager, props?.multiplebets, totalwager, "Plinko", gamesetting, allgametransacdata, props?.maxpayout);
                console.log("plinkocondition_plinkocondition", plinkocondition, presresp);

                if (presresp) {
                    let data = {};
                    if (presresp?.isWin == true) {
                        props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
                            console.log('Object.keys(val)[0]', Object.keys(val)[0], presresp?.Multiplier)
                            if (String(Object.keys(val)[0]) == String(presresp?.Multiplier)) {
                                let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
                                let amount = (Number(props?.calculatewager) + Number(wonpercentage));
                                console.log('wonpercentage_wonpercentage', wonpercentage, props?.calculatewager, Object.values(val)[0], amount)
                                if (presresp?.isWin == true) {
                                    data = {
                                        walletAddress: accountInfo,
                                        transaction: presresp,
                                        gameName: props?.pathName,
                                        Bets: multipleBets,
                                        Wager: props?.wager,
                                        CalculateWager: props?.calculatewager,
                                        TotalWager: props?.totalwager,
                                        Amount: amount,
                                        OrderId: props?.usergamedetails?._id
                                    }
                                } else if (presresp?.isWin == false) {
                                    data = {
                                        walletAddress: accountInfo,
                                        transaction: presresp,
                                        gameName: props?.pathName,
                                        Bets: multipleBets,
                                        Wager: props?.wager,
                                        CalculateWager: props?.calculatewager,
                                        TotalWager: props?.totalwager,
                                        Amount: presresp?.maxpayout,
                                        OrderId: props?.usergamedetails?._id
                                    }
                                }
                            } else if ("0x" == String(presresp?.Multiplier)) {
                                data = {
                                    walletAddress: accountInfo,
                                    transaction: presresp,
                                    gameName: props?.pathName,
                                    Bets: multipleBets,
                                    Wager: props?.wager,
                                    CalculateWager: props?.calculatewager,
                                    TotalWager: props?.totalwager,
                                    Amount: presresp?.maxpayout,
                                    OrderId: props?.usergamedetails?._id
                                }
                            }

                        })
                    } else if (presresp?.isWin == false) {
                        if ("0x" == String(presresp?.Multiplier)) {
                            data = {
                                walletAddress: accountInfo,
                                transaction: presresp,
                                gameName: props?.pathName,
                                Bets: multipleBets,
                                Wager: props?.wager,
                                CalculateWager: props?.calculatewager,
                                TotalWager: props?.totalwager,
                                Amount: presresp?.maxpayout,
                                OrderId: props?.usergamedetails?._id
                            }
                        }
                    }
                    let lossamount = 0, gainamount = 0;
                    var resultamount = 0;

                    props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
                        console.log('String(Object.keys(val)[0])', String(Object.keys(val)[0]), String(presresp?.Multiplier))
                        if (String(Object.keys(val)[0]) == String(presresp?.Multiplier)) {
                            let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
                            let amount = Number(props?.calculatewager) + Number(wonpercentage);
                            console.log('multipliet data', Object.values(val)[0], wonpercentage, amount);
                            if (presresp?.isWin == false) {
                                lossamount = presresp?.maxpayout;
                                resultamount = presresp?.maxpayout;
                            } else {
                                gainamount = amount;
                                resultamount = amount;
                            }
                        } else if ("0x" == String(presresp?.Multiplier)) {
                            if (presresp?.isWin == false) {
                                lossamount = presresp?.maxpayout;
                                resultamount = presresp?.maxpayout;
                            }
                        }
                    });
                    console.log("lossamount, gainamount", lossamount, gainamount, data);
                    let res = await CreateGameTransactionHooks(data);
                    console.log("orderdata ruru1111", res);

                    if (res?.status == 200) {
                        let createres = await createOrderdetails(props?.pathName, props?.wager, props?.remainingbets, props?.betamount, resultplinko, accountInfo, props.stopgain, props.stoploss, gainamount, lossamount, props?.maxpayout, props?.calculatewager, props?.totalwager, '', '');
                        console.log('createres', createres)
                        let result = createres?.data?.data;

                        if (createres?.status == 200) {
                            if (createres?.data?.status == true) {
                                console.log("update balance", result, result?.RemainingBets == 0);
                                if (result?.RemainingBets == 0) {
                                    let reqData = { _id: result?._id, walletAddress: accountInfo }
                                    let res = await UpdateGameSettingBalancehooks(reqData);
                                    console.log("update balance 212", res)
                                    if (res?.data?.status == true) {
                                        setTimeout(() => {
                                            props?.setFeeData()
                                        }, 3000)
                                    }
                                }


                                if ((createres?.data?.data?.StopGain != 0 || createres?.data?.data?.StopLoss != 0) && (((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopGain)) / 100) <= (createres?.data?.data?.GainAmount) || ((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopLoss)) / 100) <= (createres?.data?.data?.LossAmount))) {
                                    setTimeout(function () {
                                        console.log('222 who come first')
                                        console.log('555 play scenario enter');
                                        props?.checkOrder()
                                        props.onCheck()
                                    }, 4000)
                                } else if (props?.remainingbets != 1) {
                                    console.log('who come first')
                                    console.log('555 play scenario enter');
                                    setTimeout(function () {
                                        props?.checkOrder()
                                        props.onCheck()
                                    }, 2000)
                                }

                            }


                        }
                        let sum = incrementbetcount + 1;
                        setIncrementBetCount(sum);
                    };
                }
                if (presresp?.isWin == false) {
                    let resultamounts =
                    Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
                      Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
                        Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                          Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                            Number(toFixedNumber(Number(resultamount))).toFixed(11);
                    setTimeout(function () {
                        // toast.error(incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + resultamounts, {
                        //     position: position.position,
                        //     style: style,
                        //     iconTheme: iconTheme,
                        // })
                        Swal.fire({
                            title: "You Lose!",
                            text: incrementbetcount + "/" + multipleBets + " " + "  You Lose  " + " " + "-" + resultamounts,
                            // imageUrl: "../assets/flip1.png",
                            iconHtml: `<img src=${down}>`,
                            timer: 4200,
                            imageWidth: 400,
                            imageHeight: 200,
                            imageAlt: "Custom image",
                          });  
                        setPlay(false)
                        setActivePlinko(false)
                        if (props?.remainingbets == 1) {
                            setBallPosition({ row: 13, column: 0 })
                        }

                    }, 1000);

                    setTimeout(() => {
                        console.log('111 who come first')
                        if (props?.remainingbets == 1) {
                            console.log('666 play scenario enter');
                            props?.checkOrder()
                            props.onCheck()
                        }
                    }, 4000);
                } else {
                    let resultamounts =
                    Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
                      Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
                        Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                          Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                            Number(toFixedNumber(Number(resultamount))).toFixed(11);
                    setTimeout(function () {
                        // toast.success(incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + resultamounts, {
                        //     position: position.position,
                        //     style: style,
                        //     iconTheme: iconTheme,
                        // })
                        Swal.fire({
                            title: "Success!",
                            text: incrementbetcount + "/" + multipleBets + " " + "  You Victory  " + " " + "+" + resultamounts,
                            // imageUrl: "../assets/flip1.png",
                            iconHtml: `<img src=${winning}>`,
                            timer: 4200,
                            backdrop: `
                            rgba(0,0,123,0.4)
                            url("${winss}")
                            center top            
                            no-repeat
                          `,
                            imageWidth: 400,
                            imageHeight: 200,
                            imageAlt: "Custom image"
                          });
                        setActivePlinko(false)
                        setPlay(false)
                        if (props?.remainingbets == 1) {
                            setBallPosition({ row: 13, column: 0 })
                        }
                    }, 1000);

                    setTimeout(() => {
                        if (props?.remainingbets == 1) {
                            console.log('111 who come first')
                            console.log('666 play scenario enter');
                            props?.checkOrder()
                            props.onCheck()
                        }
                    }, 3000)
                }
                let sum = incrementbetcount + 1;
                setIncrementBetCount(sum);

                console.log("gamedata_gamedata", gamedata)
            }

        } catch (e) {
            console.log("FinishInterval_err", e)
        }
    }





    return (

        <div className='pt-3 pb-3 felx_mob_div_ew pos mobileplinko'>
                            <span className="squaress wheelside square1"></span>
                <span className="squaress wheelside square2"></span>
                <span className="squaress wheelside square3"></span>
                <span className="squaress wheelside square4"></span>
                <span className="squaress wheelside square5"></span>
                <span className="squaress wheelside square6"></span>
                <span className="squaress wheelside square7"></span>
            <h2 className="whtclr poppins text-center mb-2">Plinko</h2>
            <div className="plinko-container" id="plinko_container"
            //  style={{maxWidth:rowwidth}}
            >
                <div className='bg'>
                    <div className="board">
                        {console.log('ROWS_ROWS', ROWS)}
                        {Array.from({ length: ROWS }).map((_, rowIndex) => (
                            <div key={rowIndex} className="row row_with_bucket">
                                {Array.from({ length: COLUMNS })?.map((_, columnIndex) => {
                                    return (<>
                                        {console.log('columnIndex_data', columnIndex)}
                                        <div key={columnIndex} className={`cell ${ballPosition.row === rowIndex && ballPosition.column === columnIndex ? 'ball' : ''}`}>
                                            {(plinkobets == rowIndex) && <p className='hno mb-0'>{rowbuckets[columnIndex]}x</p>}
                                            {/* Use an image or a better representation for the ball if needed */}
                                            {ballPosition.row === rowIndex && ballPosition.column === columnIndex ? <div className='ball_inner'></div> : ''}
                                        </div>
                                    </>)
                                }
                                )}
                            </div>
                        ))}

                    </div>
                    <button className='btn btn-warning wallet play mt-4' disabled={btnDisabled} onClick={() => {randomizeBallPosition()}} >Start Game</button>
                </div>
            </div> </div>
    );
}

export default PlinkoGame;