import React, { useState, useEffect, useRef } from "react";


import tablecoin from '../../assets/tablecoin.png';
import goldcoin from '../../assets/goldicon.png';
import sarrow from "../../assets/sarrow.png"
import Wallets from '../../Components/Modals/Wallet';
import {SwapTokenss } from "../../config/Farms";
import { ROUTER_ADDRESS } from "../../config/routerContract";
import {  UsegetAmountsIn,  approveSwap,  checkIsApprovedRouter,  getUserBalance, swapTokens } from "../../hooks/useSwap";
import { iconTheme, position, style } from "../../hooks/useToast";
import toast from "react-hot-toast";
import { getAccount } from "../../hooks/useAccount";

import Coinmodals from "../Modals/coinmodal"
import { swapData } from "../Modals/swapDatas";
import { toFixedNumber } from "../../hooks/UseContract";

// const options = [
//     { value: 'wETH', label: <div><img src={tablecoin} height="18px" width="18px" /> wETH </div> },
//     { value: 'wBTC', label: <div><img src={goldcoin} height="18px" width="18px" /> wBTC </div> },
//     { value: 'USDC.e', label: <div><img src={tablecoin} height="18px" width="18px" /> USDC.e </div> },
// ];

// const options1 = [
//     { value: 'CBT', label: <div><img src={tablecoin} height="18px" width="18px" /> CBT </div> },
//     { value: 'BUSD', label: <div><img src={tablecoin} height="18px" width="18px" /> BUSD </div> },
// ]

export default function Buywlp() {
    const [modalName, setModalName] = useState("")
    const [pay, setPay] = useState({
        "id": 1, "image": tablecoin, "coinname": "WBNB",
    })
    const [get, setGet] = useState( {
        "id": 2, "image": goldcoin, "coinname": "Cake",
    })
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const [walletmodal, setWalletmodal] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const inputref = useRef(null);
    const [swapTokenData, setSwapTokenData] = useState([]);
    const [coinlistdata, setCoinListData] = useState([]);
    const [outAmount, setOutAmount] = useState(0);
    const [InAmount, setInAmount] = useState(0);
    const [isTo, setisTo] = useState(false)
    const [ismax, setismax] = useState(false)
    const [inTokenAddress, setInTokenAddress] = useState('');
    const [outTokenAddress, setOutTokenAddress] = useState('');
    const [accountInfo, setAccountInfo] = useState(sessionStorage.getItem("accountInfo"));
    const [frombalance, setFromBalance] = useState(0);
    const [isApproved, setIsApproved] = useState(false);
    const [routerAddress, setRouterAddress] = useState(0)
    const [tobalance, setToBalance] = useState(0);
    const [coinmodal, setCoinmodal] = useState(false);
    const [status, setStatus] = useState(false);
    const [fromerr, setFromErr] = useState("");
    const [toerr, setToErr] = useState("");
    const [from, setFrom] = useState(true);
    const [to, setTo] = useState(false);

    console.log("2222 Number(frombalance) < Number(value)", fromerr)
    let regex = /^[0-9]+$/;
    console.log("outAmount_InAmount", outAmount, InAmount);
    console.log('isApproveddata', isApproved);

    console.log("from_to data", from, to);

    const [coinlist, setCoinlist] = useState([
        {
            "id": 1, "image": tablecoin, "coinname": "WBNB",
        },
        {
            "id": 2, "image": goldcoin, "coinname": "Cake",
        }

    ]);


    console.log("1111 balancedata_getBalance enter this",frombalance, tobalance)


    useEffect(() => {
        async function fetchData() {
            console.log("enter this")
            if (pay) {
                let filterdata = SwapTokenss.filter(el => (el?.token == pay?.coinname));
                setInTokenAddress(filterdata[0].address);
                console.log("enter this pay enter this")
                getBalance("from", filterdata[0].address)
            }
            if (get) {
                let filterdata1 = SwapTokenss.filter(el => (el?.token == get?.coinname));
                console.log("enter this get enter this")
                setOutTokenAddress(filterdata1[0].address);
                getBalance("to",filterdata1[0].address)
            }

        };
        fetchData()
    }, [pay, get, status, accountInfo])




    useEffect(() => {
        setCoinListData(coinlist)
        let routeraddress = ROUTER_ADDRESS
        setRouterAddress(routeraddress)
    }, [coinlist]);

    useEffect(() => {
        checkAllowance()
    }, []);



    const handleSearch = async (e) => {
        console.log("searchdata", e?.target?.value)
        let searchdata = await searchedToken(coinlistdata, e?.target?.value);
        console.log("searchdata", searchdata)
        setCoinListData(searchdata)

    }

    const searchedToken = async (records, searchSymbol) => {

        const filteredData = await records.filter(value => ((value.coinname).toUpperCase()).includes(searchSymbol.toUpperCase()));
        return filteredData;
    }

    const Swap = async () => {
        console.log('Swap_data', InAmount, outAmount, SwapTokenss[0]?.address, SwapTokenss[1]?.address);
        if(from) {
            let data = await swapTokens(SwapTokenss[0]?.address, SwapTokenss[1]?.address, InAmount, outAmount, accountInfo);
            console.log("swapdddddddddddd", data)
            if(data) {
                setInAmount(0);
                setOutAmount(0)
                setStatus(!status)
            }
        } else if(to) {
            let data = await swapTokens(SwapTokenss[1]?.address, SwapTokenss[0]?.address, outAmount, InAmount, accountInfo);
            console.log("swapdddddddddddd", data)
            if(data) {
                setInAmount(0);
                setOutAmount(0)
                setStatus(!status)
            }
        }

    }

    const Approve = async () => {
        try {
            if(from) {
                let data = await approveSwap(accountInfo, SwapTokenss[0]?.address);
                if(data) {
                 setIsApproved(true);
                }
            } else if(to) {
                let data = await approveSwap(accountInfo, SwapTokenss[1]?.address);
                if(data) {
                 setIsApproved(true);
                }
            }

        } catch (e) {
            console.log("approveSwap_err", e)
        }
    }

    const handleChange = (e) => {
        console.log("e.targ", e?.target?.value);

    }

    const checkAllowance = async () => {
        try {
            if(from) {
                let approved = await checkIsApprovedRouter(getAccount(), SwapTokenss[0]?.address);
                console.log("approveddata", approved)
                if(approved == true) {
                    setIsApproved(true);
                } else {
                    setIsApproved(false);
                }
            } else if (to) {
                let approved = await checkIsApprovedRouter(getAccount(), SwapTokenss[1]?.address);
                console.log("approveddata", approved)
                if(approved == true) {
                    setIsApproved(true);
                } else {
                    setIsApproved(false);
                }
            }


        } catch (e) {
            console.log("checkAllowance_err", e)
        }
    }



    const GetTokenPrice = async (value) => {

        console.log("inTokenAddress, outTokenAddress_GetTokenPrice", inTokenAddress, outTokenAddress, frombalance ,value, frombalance <= value)
        console.log('newwwwconsolllee',inTokenAddress == "" || outTokenAddress == "",Number(frombalance) < Number(value))
        if (inTokenAddress == "" || outTokenAddress == "") {
            toast.error("Please select the token", {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
            });
            return true
        }
        else if (Number(frombalance) < Number(value)) {
            // toast.error("You don't have enough balance", {
            //     position: position.position,
            //     style: style,
            //     iconTheme: iconTheme,
            //   })
            setFromErr("You don't have enough balance");
            console.log("1111 Number(frombalance) < Number(value)",  Number(frombalance), Number(value),Number(frombalance) < Number(value))
            return true
        } 
        else {
            console.log("Number(frombalance) < Number(value)",  Number(frombalance), Number(value),Number(frombalance) < Number(value))
            const amount = await UsegetAmountsIn(inTokenAddress, outTokenAddress, value);
            console.log("GetTokenPrice_data", amount, toFixedNumber(amount?.getAmountsOut));
            let toFixedNumberamount = Number(toFixedNumber(amount?.getAmountsOut));
            setOutAmount(toFixedNumberamount.toFixed(10));
            setFromErr("");
            setFrom(true);
            setTo(false);
            return true
        }

    }


    const GetSecondTokenPrice = async (value) => { 
        try {
            console.log("inTokenAddress, outTokenAddress_GetSecondTokenPrice", inTokenAddress, outTokenAddress, frombalance ,value, frombalance <= value);
            console.log("tobalance_data", tobalance, value, typeof value, Number(tobalance) <= Number(value));
            if (inTokenAddress == "" || outTokenAddress == "") {
                toast.error("Please select the token", {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
            } else if (Number(tobalance) < Number(value)) {
                // toast.error("You don't have enough balance", {
                //     position: position.position,
                //     style: style,
                //     iconTheme: iconTheme,
                //   })
                setToErr("You don't have enough balance");
            } else {
                const amount = await UsegetAmountsIn(outTokenAddress, inTokenAddress, value);
                console.log("amnountdata", amount)
                let toFixedNumberamount = Number(toFixedNumber(amount?.getAmountsOut));
                setInAmount(toFixedNumberamount);
                setToErr("");
                setTo(true);
                setFrom(false);
            }


        } catch (e) {
            console.log("GetSecondTokenPrice_err", e)
        }
    }

    const getBalance = async (query, tokenAddress) => {
        let balance = await getUserBalance(tokenAddress, accountInfo);
        console.log("balancedata_getBalance enter this",query, balance, accountInfo)
        if(accountInfo != null) {
            if (query == "from") {
                setFromBalance(balance)
            } else {
                setToBalance(balance)
            }
        }
    }



    console.log("payyyyyyyyyy",pay,get);
    return (
        <>
            {walletmodal && <Wallets onDismiss={() => setWalletmodal(false)} />}
            {coinmodal && <Coinmodals modalName={modalName}
                setInTokenAddress={(data) => setInTokenAddress(data)}
                setOutTokenAddress={(data) => setOutTokenAddress(data)}
                get={get} pay={pay}
                setPay={setPay} setGet={setGet} swapData={swapData} onDismiss={() => setCoinmodal(false)} />}
            {console.log("sdsadsad", coinmodal)}
            <div>
                <div className="borders earntab">
                    <div className="d-flex justfun justify-content-between">

                        <div className="interborder" onClick={() => { setModalName("pay"); setCoinmodal(true) }} style={{ cursor: "pointer" }}>
                            <p className="mb-0"><img src={pay?.image} className="ethimg etimg me-1" alt="eth" />  {pay?.coinname} <span className="btop"></span></p>
                        </div>
                        {console.log("InAmount_InAmount", InAmount, outAmount) /* console.log('dfjkhdklfghsdg',e.target.value); setisTo(false); setInAmount(e.target.value); setismax(false); GetTokenPrice(e.target.value)*/}
                        <input type="text" className="form-control" value={InAmount} onChange={(e) => {  setisTo(false); setInAmount(e.target.value); setismax(false); GetTokenPrice(e.target.value)  }} />
                    
                    </div>









                    {/* <div className="d-flex justfun justify-content-between">
                        <DropdownModal interchange={interchange} coinlistdata={coinlistdata} fromorto="from" setInTokenAddress={(data) => { setInTokenAddress(data); getBalance("from", data); }} />
                        <input type="number" className="form-control" value={InAmount} onChange={(e) => { setisTo(false); setInAmount(e.target.value); setismax(false); GetTokenPrice(e.target.value, "from") }} />
                    </div> */}
                    <div className="d-flex justify-content-between">

                        {console.log("frombalance", frombalance)}
                        <p className="mt-2 fw-600 mb-0">Balance <span className="nclr mb-0">{Number(frombalance)}</span></p>
                        {/* <p className="mt-2 mb-0"><span className="nclr">$ {frombalance}</span></p> */}
                    </div>
                    <div className="text-right">
                    <span className="text-danger text-right">{fromerr}</span>
                    </div>
                </div>
                 <div className="text-center m_minus"><img src={sarrow} className="img-fluid"/></div>
                <div className="borders earntab mt-0">
                    <div className="d-flex justfun justify-content-between">
                        {/* <DropdownModal interchange={intertwochange} coinlistdata={coinlistdata} fromorto="to" setOutTokenAddress={(data) => { setOutTokenAddress(data); getBalance("to", data); }} />
                        {/* To */}
                        {/* {console.log("outAmountdata", outAmount)}
                        <input type="number" className="form-control" value={outAmount} onChange={(e) => { setisTo(true); setOutAmount(e.target.value); setismax(false); GetSecondTokenPrice(e.target.value, "to") }} /> */}

                        <div className="interborder" onClick={() => { setModalName("get"); setCoinmodal(true) }} style={{ cursor: "pointer" }}>
                            <p className="mb-0"><img src={get?.image} className="ethimg etimg me-1" alt="eth" />  {get?.coinname} <span className="btop"></span></p>
                        </div>

                        {/* <input type="number" className="form-control" value={out} onChange={(e) => { setisTo(false); setInAmount(e.target.value); setismax(false); GetSecondTokenPrice(e.target.value) }} /> */}
                        <input type="text" className="form-control" value={outAmount} onChange={(e) => {  setisTo(true); setOutAmount(e.target.value); setismax(false); GetSecondTokenPrice(e.target.value)  }} />
                        
                    </div>
                    <div className="d-flex  justify-content-between">
                        <p className="mt-2 fw-600 mb-0">Balance <span className="nclr mb-0">{Number(tobalance)}</span></p>
                        <div className="text-right">
                        {console.log("Fromerr_Toerr", fromerr, toerr)}
                    <span className="text-danger text-right">{toerr}</span>
                    </div>
                        {/* <p className="mt-2 mb-0"><span className="nclr">$ {tobalance}</span></p> */}
                    </div>
                </div>
                {/* <p className="mt-3 f-14 fw-600 fees">Fees : <span className="nclr fw-600"> -%</span></p> */}
                {((sessionStorage.getItem("accountInfo") != "undefined") && (sessionStorage.getItem("accountInfo") != null) && (sessionStorage.getItem("accountInfo") != undefined)) ?
                    <div className="mt-4">
                        {/* {disabled={InAmount != 0 ? false : true}} */}
                        {console.log("InAmount_outAmount", InAmount, outAmount)}
                        {
                        !isApproved ? 
                        <button type="button" disabled={((frombalance > 0 && tobalance > 0) &&  (outAmount > 0 && InAmount > 0)) ? false : true } class="btn w-100 btn-success earnbtn walletsss" onClick={() => Approve()}>APPROVE</button> :
                            
                            <button type="button" class="btn w-100 btn-success earnbtn walletsss" disabled={!((frombalance > 0 && tobalance > 0) && (outAmount > 0 && InAmount > 0))}onClick={() => Swap()}> SWAP</button>
                            }
                    </div> :  
                    <div className="mt-4"><button type="button" class="btn w-100 btn-success earnbtn walletsss" onClick={() => setWalletmodal(true)}>CONNECT WALLET</button></div>

                }
                {/* <button type="button" class="btn w-100 btn-success earnbtn walletsss" >APPROVE AND SWAP</button> */}

            </div>
        </>
    );
}