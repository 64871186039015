import React, { useEffect, useContext } from 'react';
import SocketContext from './Components/Content/socketContext';
import jwt_decode from "jwt-decode";
import { FRONTENDURL } from './config/env';
import { getAccount } from './hooks/useAccount';

const HelperRoutes = () => {


    const socketContext = useContext(SocketContext);
    console.log("socketContext_socketContext", socketContext, socketContext?.connected)

    useEffect(() => {

        if (socketContext.socket) {
            socketContext.socket.on("FORCELOGOUT", (data) => {
                try {
                    if (data) {
                        console.log("FORCELOGOUT", data);
                        if (data?.walletAddress) {
                            localStorage.removeItem('accessToken');
                            sessionStorage.removeItem('accountInfo')
                            window.location.href = FRONTENDURL;
                        }
            
            
                    }
                } catch (e) {
                    console.log("socketError : ", e);
                    localStorage.clear();
                    // window.location.href = FRONTENDURL;
                }
            });
            try {
                let token = localStorage.getItem('accessToken');
                let walletAddress = getAccount();
                console.log("socketContext_socketContext", socketContext.socket, token, walletAddress, (token != '' && token != null && token != undefined) && (walletAddress != '' && walletAddress != null && walletAddress != undefined))
                //                 //user
                if ((token != '' && token != null && token != undefined) && (walletAddress != '' && walletAddress != null && walletAddress != undefined)) {
                    const decoded = jwt_decode(token);
                    socketContext.socket.emit("LOGINUSER", decoded)
                    console.log("welcomeddata Socket Initiated")
                }

            } catch (e) {
                if (sessionStorage) {
                    console.log("ErrSDor : ", e);
                    sessionStorage.clear();
                    window.location.href = FRONTENDURL;
                }
            }

        }
    }, [socketContext.socket])
}


export default HelperRoutes;