import tablecoin from '../../assets/tablecoin.png';
import goldcoin from '../../assets/goldicon.png';
import goldcoin1 from '../../assets/circlegreen.png';
import goldcoin2 from '../../assets/tri.png';
import goldcoin3 from '../../assets/tails-img.png';

export const swapData = [
    {
        "id": 1, "image": tablecoin, "coinname": "WBNB",
    },
    {
        "id": 2, "image": goldcoin, "coinname": "Cake",
    }
]