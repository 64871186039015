
import { useState, useEffect } from 'react';
import CircularSlider from '@fseehawer/react-circular-slider';

import logo_round from "../assets/logoimg.png";

import { CreateGameTransactionHooks } from '../hooks/UseTransaction';
import { getGameAllTransactionInfoHooks } from "../hooks/UseTransaction";
import { getAccount } from '../hooks/useAccount';
import { createOrderdetails, GamePrediction, ToastGame } from '../hooks/useBot';
import { checkAlreadyWon, DiceGameCondition } from '../hooks/useDice';
import { getAllGameTransactionDetails, getGameSettingDetails, UpdateGameSettingBalancehooks } from '../hooks/UseUser';
import { toFixedNumber } from '../hooks/UseContract';
import toast from 'react-hot-toast';

function Limbogame(props) {

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [dragval, setDragval] = useState(0);
  const [dragrandom, setDragrandom] = useState();
  const [ismobile, setIsmobile] = useState(false);

  const [incrementbetcount, setIncrementBetCount] = useState(1);
  const [multipleBets, setMultipleBets] = useState(0)
  const [maxpayout, setMaxPayout] = useState(0)
  const [totalwager, setTotalWager] = useState(0)
  const [activelimbo, setActiveLimbo] = useState(false)
  const [onemonthwonInfo, setOneMonthWonInfo] = useState([]);
  const [oneweekwonInfo, setOneWeekWonInfo] = useState([]);
  const [roatedcount, setRoatedCount] = useState(0); // useless
  const [transactionInfo, setTransactionInfo] = useState([]);
  const [accountInfo, setAccountInfo] = useState("");
  const [gamecondition, setGameCondition] = useState({})
  const [clicked, setClicked] = useState(false)
  const [allowin, setAllowin] = useState(0);
  const [toastsec, setToastSec] = useState(3000);
  const [count, setCount] = useState(0)
  const [toastId, setToastId] = useState()
  const [gamesetting, setGameSetting] = useState({})
  const [allgametransacdata, setAllGameTransData] = useState([]);

  // venkatesh
  const [dragrandomdata, setDragRandomData] = useState();
  console.log("dragval_dragrandom", dragrandomdata)


  useEffect(() => {
    (async () => {
      // just string use to get a gamecondition
      props.setDragval(dragval)
      let limbo = {};
      limbo[`${dragval}x`] = "##predictLimbo## < ##RangeLimbo##"
      console.log("limbodata", limbo);
      setGameCondition({ "Limbo": [limbo] })

      // useless because underdown useeffect check correctly
      // let gamedata = {
      //   predictLimbo: 46,
      //   RangeLimbo: 32
      // }
      // await DiceGameCondition(gamedata, "Limbo", { "Limbo": [limbo] })
      console.log('dasdfasdfasdf', dragval)
    })();
  }, [dragval]);


  useEffect(() => {
    (async () => {
      let gamedata = {
        predictLimbo: 23,
        RangeLimbo: 35
      }
      if (Object.values(gamecondition)?.length > 0) {
        // console.log("sadfasdfasf23rfasd", gamedata, gamecondition)
        let dicecondition = await DiceGameCondition(gamedata, "Limbo", gamecondition);
        console.log("sadfasdfasf23rfasd", dicecondition)
        let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, "Limbo", gamesetting, allgametransacdata, props?.maxpayout);
        console.log("initially check presresp", presresp)
        let checkalreadywon = await checkAlreadyWon(presresp, oneweekwonInfo, onemonthwonInfo, "Limbo");
        console.log('initially check', checkalreadywon);
        if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
          setAllowin(0)
        } else {
          setAllowin(1)
        }
      }
    })();
  }, [gamecondition, oneweekwonInfo, onemonthwonInfo])


  useEffect(() => {

    const windownwidth = window.screen.width;
    console.log(windownwidth, "windownwidth");
    if (windownwidth < 451) {
      setIsmobile(true);
    }

  }, []);


  useEffect(() => {
    console.log("props?.bet && props?.disabledLimbobtn", props?.remainingbets, props?.selectedLimbo, props?.bet, props?.disabledLimbobtn, !clicked)
    if (props?.bet && props?.disabledLimbobtn) {
      if (!clicked) {
        console.log("asdfghasdfas")
        setBtnDisabled(false);
        console.log("props?.selectedLimbo setdragval", props?.selectedLimbo)
        props?.selectedLimbo == '0' ? setDragval(0) : setDragval(props?.selectedLimbo)
      }
    } else {
      // Initially No orderdata gamedetails lele
      console.log("33 asdfghasdfas")
      setBtnDisabled(true)
    }

    setMultipleBets(props?.multiplebets);
    setMaxPayout(props?.maxpayout);
    setTotalWager(props.totalwager);

    props.setInitialSelectedLimbo(dragval)
    let remainingrolls = (props?.multiplebets - props?.remainingbets) + 1;
    console.log("remainingrolls", remainingrolls)
    setIncrementBetCount(remainingrolls);
    if (props?.remainingbets == 0) {
      setRoatedCount(0)
    }
  }, [props, dragval])

  useEffect(() => {
    (async () => {
      const walletAddress = getAccount();
      setAccountInfo(walletAddress);
      let transc = await getTransactionDetails(walletAddress);
      console.log("oneweekwonInfoZ_OneMonthWonInfo", transc);
      if (transc?.oneweekdata?.length > 0) {
        OneWeekWonInfo(transc?.oneweekdata)
      }
      if (transc?.onemonthdata?.length > 0) {
        OneMonthWonInfo(transc?.onemonthdata)
      }

    })();
  }, [dragval, gamesetting]);


  useEffect(() => {
    getGameSettings();
    getAllGameTransactionData();
  }, [props])

  const getTransactionDetails = async (address) => {
    let data = {
      walletAddress: address,
      gameName: "Limbo"
    }
    let res = await getGameAllTransactionInfoHooks(data);
    console.log("respond_data", res?.data)

    if (res?.data?.oneweekdata?.length > 0 || res?.data?.onemonthdata?.length > 0) {
      return {
        oneweekdata: res?.data?.oneweekdata?.length > 0 ? res?.data?.oneweekdata : [],
        onemonthdata : res?.data?.onemonthdata?.length > 0 ? res?.data?.onemonthdata : []
      }
    } else {
      return {
        oneweekdata: [],
        onemonthdata: []
      }
    }
  }

  useEffect(() => {
    (async () => {
      try {
        console.log("activelimbo", activelimbo, oneweekwonInfo, dragrandom, oneweekwonInfo?.length > 0 || oneweekwonInfo?.length > 0)
        console.log("dragrandom data", dragrandom)
        console.log(" 2nd one active limbo lossscenario", activelimbo, dragrandom, dragrandom > 0)
        console.log("2nd attempt decide win or loss winscenario", activelimbo, dragrandom, dragrandom > 0)
        console.log("2nd attempt decide win or loss wonscenario", activelimbo, dragrandom, dragrandom > 0, count)
        if (activelimbo) {
          if (dragrandom > 0) {
            setActiveLimbo(false);
            if (oneweekwonInfo?.length > 0 || oneweekwonInfo?.length > 0) {
              let gamedata = {
                predictLimbo: dragval,
                RangeLimbo: dragrandom
              }
              let dicecondition = await DiceGameCondition(gamedata, "Limbo", gamecondition)
              console.log("condition return", props?.pathName, dicecondition, dicecondition[0], props?.pathName);
              console.log("alert 444 path", props?.pathName)
              let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, "Limbo", gamesetting, allgametransacdata, props?.maxpayout);
              console.log("presresp data", presresp);

              let checkalreadywon = await checkAlreadyWon(presresp, oneweekwonInfo, onemonthwonInfo, "Limbo");
              console.log("checkalreadywon", checkalreadywon)
              console.log("allow data juju", checkalreadywon?.res1, checkalreadywon?.res2, presresp, checkalreadywon);
              console.log("3rd attempt winorloss so true or false depend upon game winscenario", checkalreadywon?.res1, checkalreadywon?.res2, presresp, checkalreadywon)
              console.log("3rd both are false lossscenario", checkalreadywon?.res1, checkalreadywon?.res2, presresp, checkalreadywon);
              console.log("3rd both are true wonscenario", checkalreadywon?.res1, checkalreadywon?.res2, presresp, checkalreadywon)
              if ((checkalreadywon?.res1 == true) || (checkalreadywon?.res2 == true)) {
                console.log("didnt enter lossscenario ")
                console.log("allow1 data juju")

                let roatedCount = roatedcount - 1;
                setRoatedCount(roatedCount);
                await playLimbo(roatedCount)
              } else {
                console.log("4th enter lossscenario", count)
                if (count == 0) {
                  setTimeout(function () {
                    setDragRandomData(dragrandom)
                  }, 3000)
                }
                FinishInterval();
              }
            } else {
              console.log("4th enter1 lossscenario")
              FinishInterval();
            }
          }
        }
      } catch (e) {
        console.log("useEffect err limbogame", e)
      }
    })();
  }, [activelimbo])

  const OneWeekWonInfo = async (transc) => {
    try {
      console.log("alert 111 path", props?.pathName, transc)
      let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, "Limbo", gamesetting, allgametransacdata, props?.maxpayout);
      console.log("OneWeeklimboWonInfo", res)
      setOneWeekWonInfo(res);
    } catch (err) {
      console.log("OneWeekWonInfo_err", err)
    }
  }


  const OneMonthWonInfo = async (transc) => {
    try {
      console.log("alert 222 path", props?.pathName)
      let res = await GamePrediction([], transc, props?.calculatewager, props?.multiplebets, 0, "Limbo", gamesetting, allgametransacdata, props?.maxpayout);
      console.log("OneMonthWonInfo", res)
      setOneMonthWonInfo(res)
    } catch (e) {
      console.log("PredictionInfo_err", e)
    }
  }

  const getGameSettings = async () => {
    try {
      let res = await getGameSettingDetails();
      console.log('getGameSettings', res?.data)
      if (res?.data?.status == true) {
        let result = res?.data?.data[0];
        if (result?.Status == "Active") {
          setGameSetting(result)
        }
      }
    } catch (e) {
      console.log("gameGameSettings_err", e)
    }
  }

  const getAllGameTransactionData = async () => {
    try {
      let res = await getAllGameTransactionDetails();
      if (res?.data?.status == true) {
        setAllGameTransData(res?.data?.alltranscdata)
      }
    } catch (e) {
      console.log("getAllTransactionData_err", e)
    }
  }


  const FinishInterval = async () => {
    try {
      console.log("incrementbetcount <= multipleBets", incrementbetcount <= multipleBets, incrementbetcount, multipleBets)
      if (incrementbetcount <= multipleBets) {
        console.log("4th entersss lossscenario")
        let gamedata = {
          predictLimbo: dragval,
          RangeLimbo: dragrandom
        }
        console.log("FinishInterval_gamedatas wheel game", props?.pathName, gamedata, gamecondition);
        let dicecondition = await DiceGameCondition(gamedata, props?.pathName, gamecondition);
        console.log("result condition return coinflip game", dicecondition, transactionInfo, props?.wager, props?.multiplebets, totalwager);
        console.log("alert 333 path", props?.pathName)
        let presresp = await GamePrediction(dicecondition, [], props?.calculatewager, props?.multiplebets, totalwager, "Limbo", gamesetting, allgametransacdata, props?.maxpayout);
        console.log('presresp lossscenario', presresp)
        if (presresp) {
          let data = {}
          if (presresp?.isWin == true) {
            props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
              console.log('check this conditionssssss', Number(Object.keys(val)[0].split('x')[0], Number(((presresp?.Multiplier).split('x')[0]))), Number((Object.keys(val)[0].split('x')[1]).split('-')[1]),
                Number(((presresp?.Multiplier).split('x')[0])))
              if (((Number(Object.keys(val)[0].split('x')[0]) <= Number(((presresp?.Multiplier).split('x')[0]))) && (Number((Object.keys(val)[0].split('x')[1]).split('-')[1]) >= Number(((presresp?.Multiplier).split('x')[0]))))) {
                console.log("result amount", Object.values(val)[0], props?.calculatewager)
                let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
                let amount = Number(props?.calculatewager) + Number(wonpercentage);
                console.log('multipliet data', wonpercentage, amount, Object.values(val)[0], props?.calculatewager)
                if (presresp?.isWin == true) {
                  data = {
                    walletAddress: accountInfo,
                    transaction: presresp,
                    gameName: props?.pathName,
                    Bets: multipleBets,
                    Wager: props?.wager,
                    CalculateWager: props?.calculatewager,
                    TotalWager: props?.totalwager,
                    Amount: amount,
                    OrderId: props?.usergamedetails?._id
                  }
                } else if (presresp?.isWin == false) {
                  data = {
                    walletAddress: accountInfo,
                    transaction: presresp,
                    gameName: props?.pathName,
                    Bets: multipleBets,
                    Wager: props?.wager,
                    CalculateWager: props?.calculatewager,
                    TotalWager: props?.totalwager,
                    Amount: presresp?.maxpayout,
                    OrderId: props?.usergamedetails?._id
                  }
                }
  
              } else if ("0x" == String(presresp?.Multiplier)) {
                data = {
                  walletAddress: accountInfo,
                  transaction: presresp,
                  gameName: props?.pathName,
                  Bets: multipleBets,
                  Wager: props?.wager,
                  CalculateWager: props?.calculatewager,
                  TotalWager: props?.totalwager,
                  Amount: presresp?.maxpayout,
                  OrderId: props?.usergamedetails?._id
                }
              }
            })
  
          } else if (presresp?.isWin == false) {
            if ("0x" == String(presresp?.Multiplier)) {
              data = {
                walletAddress: accountInfo,
                transaction: presresp,
                gameName: props?.pathName,
                Bets: multipleBets,
                Wager: props?.wager,
                CalculateWager: props?.calculatewager,
                TotalWager: props?.totalwager,
                Amount: presresp?.maxpayout,
                OrderId: props?.usergamedetails?._id
              }
            }
          }
  
          let lossamount = 0, gainamount = 0;
          var resultamount = 0;
          console.log('props?.gamepercentage', props?.gamepercentage, data)
          props?.gamepercentage && props?.gamepercentage?.length > 0 && props?.gamepercentage?.map((val, ind) => {
            console.log('valllgamepercentage', val, Object.keys(val), Object.values(val)[0]);
            if (Number(Object.keys(val)[0].split('x')[0]) <= Number(((presresp?.Multiplier).split('x')[0])) && Number((Object.keys(val)[0].split('x')[1]).split('-')[1]) >= Number(((presresp?.Multiplier).split('x')[0]))) {
              let wonpercentage = Number(props?.calculatewager) * Number(Object.values(val)[0]) / 100;
              let amount = Number(props?.calculatewager) + Number(wonpercentage);
              console.log('multipliet data', wonpercentage, amount, Object.values(val)[0], props?.calculatewager, Number(Object.values(val)[0]))
              if (presresp?.isWin == false) {
                console.log('ent those this', presresp?.maxpayout)
                lossamount = presresp?.maxpayout;
                resultamount = presresp?.maxpayout;
              } else {
                console.log('ent those this', presresp?.maxpayout)
                gainamount = amount;
                resultamount = amount;
              }
              console.log("Object.values(val)[0]", Object.values(val)[0])
            } else if ("0x" == String(presresp?.Multiplier)) {
              if (presresp?.isWin == false) {
                lossamount = presresp?.maxpayout;
                resultamount = presresp?.maxpayout;
              }
            }

          })
          // let lossa

          console.log("lossamount, gainamount", lossamount, gainamount, data);

          let res = await CreateGameTransactionHooks(data);
          console.log("orderdata ruru1111", res);

          if (res?.status == 200) {
            let createres = await createOrderdetails(props?.pathName, props?.wager, props?.remainingbets, props?.betamount, props.selectedLimbo, accountInfo, props.stopgain, props.stoploss, gainamount, lossamount, props?.maxpayout, props?.calculatewager, props?.totalwager, '', '');
            console.log('createrescreateres', createres, props?.remainingbets);
            let result = createres?.data?.data;

            if (createres?.status == 200) {
              if (createres?.data?.status == true) {
                console.log("update balance", result, result?.RemainingBets == 0)
                if (result?.RemainingBets == 0) {
                  let reqData = { _id: result?._id, walletAddress: accountInfo }
                  console.log('req Data updatabaln', reqData)
                  let res = await UpdateGameSettingBalancehooks(reqData);
                  console.log("res updategamesettingba", res)
                  if (res?.data?.status == true) {
                    setTimeout(() => {
                      props?.setFeeData()
                    }, 3000)
                  }
                  console.log("update balance 212", res)
                }
              }
              console.log('update balance 3232', props?.remainingbets)
              console.log("enter this onw")
              if (props?.remainingbets == 1) {
                console.log("remaining enter this onw")
                setTimeout(function () {
                  console.log("12remaining enter this onw")
                  props?.checkOrder()
                  props.onCheck()
                  setClicked(false)
                }, 3000)
                setTimeout(function () {
                  console.log("23remaining enter this onw")
                  setCount(0)
                  setDragrandom(0)
                  setDragval(0)
                }, 4000);

              } else {
                console.log("222 enter this onw", createres?.data?.data?.StopGain, (createres?.data?.data?.StopGain != 0 || createres?.data?.data?.StopLoss != 0) && (((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopGain)) / 100) <= (createres?.data?.data?.GainAmount) || ((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopLoss)) / 100) <= (createres?.data?.data?.LossAmount)), createres?.data?.data, createres?.data?.data?.TotalWager, createres?.data?.data?.StopLoss, createres?.data?.data?.StopGain, createres?.data?.data?.TotalWager, createres?.data?.data?.GainAmount, createres?.data?.data?.LossAmount, createres?.data?.data?.StopGain != 0 && createres?.data?.data?.StopLoss != 0 && ((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopGain)) / 100) <= (createres?.data?.data?.GainAmount) || ((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopLoss)) / 100) <= (createres?.data?.data?.LossAmount))

                if ((createres?.data?.data?.StopGain != 0 || createres?.data?.data?.StopLoss != 0) && (((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopGain)) / 100) <= (createres?.data?.data?.GainAmount) || ((Number(createres?.data?.data?.TotalWager) * (createres?.data?.data?.StopLoss)) / 100) <= (createres?.data?.data?.LossAmount))) {
                  setTimeout(function () {
                    console.log("222 enter this onw INNNNNNNNN")
                    props?.checkOrder()
                    props?.onCheck()
                    setClicked(false)
                  }, 3000)
                  setTimeout(function () {
                    console.log("23remaining enter this onw")
                    setCount(0)
                    setDragrandom(0)
                    setDragval(0)
                  }, 4000);
                } else {
                  props?.checkOrder()
                  props.onCheck()
                  setTimeout(() => {
                    console.log("333 enter this onw")
                    setCount(0)
                  }, 4000)
                }


              }
            }
          }
          console.log("5th enter lossscenario", presresp, presresp?.isWin, toastId)
          if (presresp?.isWin == false) {
            console.log("presresp?.isWin", presresp?.isWin, presresp?.maxpayout, Number(toFixedNumber(Number(presresp?.maxpayout))));
            console.log("checkt the resulamounts presresp?.isWin", Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(4))
            let resultamounts =
              Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(4) > 0 ? Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(4) : Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(5) > 0 ? Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(5) :
                Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(6) > 0 ? Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(6) : Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(7) > 0 ? Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(7) : Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(8) > 0 ?
                  Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(8) : Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(9) > 0 ? Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(9) :
                    Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(10) > 0 ? Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(10) :
                      Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(11);
            await ToastGame('Limbo', toastId, "error", incrementbetcount, multipleBets, resultamounts, 3000);
            setTimeout(function () {
              console.log("")
              console.log("44444444 asdfghasdfas")
              setBtnDisabled(false)
              setDragRandomData(0)
              setToastId("")
            }, 4000);
          } else {
            console.log("presresp?.isWin", presresp?.isWin, presresp?.maxpayout, Number(toFixedNumber(Number(presresp?.maxpayout))));
            console.log("checkt the resulamounts presresp?.isWin", Number(toFixedNumber(Number(presresp?.maxpayout))).toFixed(4))
            let resultamounts =
              Number(toFixedNumber(Number(resultamount))).toFixed(4) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(4) : Number(toFixedNumber(Number(resultamount))).toFixed(5) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(5) :
                Number(toFixedNumber(Number(resultamount))).toFixed(6) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(6) : Number(toFixedNumber(Number(resultamount))).toFixed(7) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(7) : Number(toFixedNumber(Number(resultamount))).toFixed(8) > 0 ?
                  Number(toFixedNumber(Number(resultamount))).toFixed(8) : Number(toFixedNumber(Number(resultamount))).toFixed(9) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(9) :
                    Number(toFixedNumber(Number(resultamount))).toFixed(10) > 0 ? Number(toFixedNumber(Number(resultamount))).toFixed(10) :
                      Number(toFixedNumber(Number(resultamount))).toFixed(11);
            console.log('enter the won one', presresp, resultamount)
            await ToastGame('Limbo', toastId, "success", incrementbetcount, multipleBets, resultamounts, 3000)
            setTimeout(function () {
              console.log("33334 asdfghasdfas")
              setBtnDisabled(false)
              setDragRandomData(0)
              setToastId("")
            }, 4000)
          }



          let sum = incrementbetcount + 1;
          setIncrementBetCount(sum);
        }
  
      }
   
    } catch (e) {
      console.log("FinishInterval_err", e)
    }
  }





  const playLimbo = async () => {
    try {
      if (incrementbetcount <= multipleBets) {
        console.log("enter the playing condition")
        setBtnDisabled(true);
        var x = 1; //min value
        var y = 9999; // max value
        var deg = Math.floor(((Math.random() * (x - y)) + y) / 100);
        // var deg = 1;
        let sum = 1;
        console.log(deg, "deg");
        document.getElementById("logo_pigi").classList.add("anim_color");
        console.log("allowin check", allowin, allowin != 0)

        // let res = await CheckRepeatGame(deg);
        if (allowin != 0) { // 1 != 0 ---> true // 0 != 0 false
          console.log("1st attempt decide win or loss winscenario", deg, dragval, deg > dragval)
          setDragrandom(deg);
          let Id = await ToastGame('Limbo', "", "loading");
          setToastId(Id);
          setTimeout(function () {
            setDragRandomData(deg);
          }, 4000)
        } else {
          console.log("deg data", deg, dragval, deg > dragval, count)
          if (deg > dragval) { // Initially check win or not
            // Maybe win means that count will be increase
            // setDragrandom(deg);
            console.log("win scenario wonscenario")
            if (count != 0) {
              console.log("4th win scenario wonscenario")
              console.log("again limbo start " + count + " attempt over", count)
              let minus = count + "000";
              let toastsec;
              if (Number(minus) <= 4000) {
                toastsec = 4000 - Number(minus);
              }
              console.log("minussssssss wonscenario", minus, toastsec)
              if (toastsec != undefined) {
                console.log("taostsec", toastsec, toastsec != undefined, dragval);
                if (String(toastsec) == "0") {
                  if (dragval == 1 || dragval == 2) {
                    let dragminus = 1;
                    console.log("dragminus_dragminus", dragminus, dragval, deg);
                    setDragrandom(dragminus)
                    setTimeout(function () {
                      setDragRandomData(dragminus)
                    }, 4000)
                  } else {
                    let dragminus = Math.floor(Math.random() * dragval);
                    if (dragminus == 0 || dragminus == 1) {
                      console.log("dragminus_dragminus", dragminus, dragval, deg)
                      setDragrandom(2)
                      setTimeout(function () {
                        setDragRandomData(2)
                      }, 4000)
                    } else {
                      console.log("dragminus_dragminus", dragminus, dragval, deg)
                      setDragrandom(dragminus)
                      setTimeout(function () {
                        setDragRandomData(dragminus)
                      }, 4000)
                    }

                  }

                } else {
                  console.log("5th call 3 times wonscenario")
                  setDragrandom(deg)
                }
                console.log("toastaID", toastId, toastId != "")
                if (toastId != "" && toastId != undefined) {
                  console.log("enter unempty toast", toastId)
                  await ToastGame('Limbo', toastId, "loading");
                  // toastId = Id;
                  // setToastId(Id)
                } else {
                  console.log("enter empty toast")
                  let Id = await ToastGame('Limbo', "", "loading")
                  // toastId = Id;
                  setToastId(Id)
                }
              }

            } else {
              console.log("1st win scenario wonscenario")
              console.log("again limbo start 1st attempt over", count)
              // setToastSec(3000) // useless
              let Id = await ToastGame('Limbo', "", "loading");
              console.log("23Iddataaaaaa", Id, deg)
              // toastId = Id;
              setToastId(Id)
              setDragrandom(deg)
            }
            sum = sum + count;
            console.log("sunmsadfasdfasd", sum)
            setCount(sum)
          } else {
            console.log("allowin 0 1st attempt loss & game lossscenario", toastId, deg, dragval, deg > dragval)
            if (toastId != "" && toastId != undefined) {
              console.log("deg allowin", deg)
              await ToastGame('Limbo', toastId, "loading");
              console.log("deg allowin", deg)
              if (deg == 0 || deg == 1) {
                setDragrandom(2);
                console.log("deg enter this allowin", deg)
                setTimeout(function () {
                  setDragRandomData(2);
                }, 4000)
              } else {
                setDragrandom(deg);
                setTimeout(function () {
                  console.log("deg enter this allowin", deg)
                  setDragRandomData(deg);
                }, 4000)
              }

            } else {

              let Id = await ToastGame('Limbo', "", "loading");
              console.log("Iddataaaaaa", Id, deg)
              // toastId = Id;
              setToastId(Id)
              if (deg == 0 || deg == 1) {
                setDragrandom(2);
                setTimeout(function () {
                  setDragRandomData(2);
                }, 4000)
              } else {
                setDragrandom(deg);
                setTimeout(function () {
                  setDragRandomData(deg);
                }, 4000)
              }

            }

          }
        }
        setTimeout(function () {
          console.log("enter this timeout")
          setActiveLimbo(true)

        }, 2000);


        setTimeout(function () {
          document.getElementById("logo_pigi").classList.remove("anim_color");
          setDragval(0);
          setDragrandom(0);
          document.getElementById("winstatus").innerHTML = "";


        }, 250000000);

      } else {
        setBtnDisabled(true);
      }
             
      
    } catch (e) {
      console.log("playLimbo_err", e)
    }

  }





  return (
    <div className='container game_container_limbo pos'>
                <span className="squaress wheelside square1"></span>
                <span className="squaress wheelside square2"></span>
                <span className="squaress wheelside square3"></span>
                <span className="squaress wheelside square4"></span>
                <span className="squaress wheelside square5"></span>
                <span className="squaress wheelside square6"></span>
                <span className="squaress wheelside square7"></span>
                <span className="squaress wheelside square8"></span>
                <span className="squaress wheelside square9"></span>
        <h2 className="whtclr poppins text-center pb-4">Limbo</h2>

      <div className='bgwithlno'>
        <div className='shadowbg_div'></div>

        <div className='drager_div'>
          <img src={logo_round} className='img-fluid logo_pigi' id="logo_pigi" />
          <svg class="limbo_ui_limbo__box" width={!ismobile ? "210" : "170"} height={!ismobile ? "210" : "170"} viewBox="0 0 158 158" fill="none"><circle r="73.5" transform="matrix(-1 0 0 1 79 79)" stroke="#29364D" stroke-width="11" stroke-dasharray="1 10"></circle></svg>
          <div className='circ_div_li'>
            <CircularSlider
              label=""
              min={0}
              max={100}
              dataIndex={dragval}
              labelColor="#005a58"
              labelBottom={true}
              knobColor="#fff"
              knobSize={!ismobile ? 25 : 12.5}
              hideKnob={!ismobile ? false : true}
              knobPosition={!ismobile ? 'top' : 'top'}
              knobDraggable={btnDisabled}
              trackDraggable={!ismobile?false:btnDisabled}
              progressColorFrom="#4084D5"
              progressColorTo="#00B147"
              progressSize={!ismobile ? 10 : 5}
              trackColor="#fff"
              trackSize={!ismobile ? 10 : 5}
              width={!ismobile ? 280 : 200}
              // onChange={value => { console.log("VALUEDATA", value); btnDisabled ? setDragval(value) : console.log("da", value) }}
              onChange={(value) => { if(value > 1) { btnDisabled ? setDragval(value) : console.log("da", value) }}}
            >

            </CircularSlider>
          </div>
          {console.log("dragrandom", dragrandom, dragrandomdata)}
          <div class="progress" data-percentage={dragrandomdata}>
            <span class="progress-left">
              <span class="progress-bar"></span>
            </span>
            <span class="progress-right">
              <span class="progress-bar"></span>
            </span>

          </div>

          <div className='text-center mt-5'>
            {console.log("btnDisabled_btnDisabled", btnDisabled)}
            <button className="btn btn_danger" disabled={btnDisabled}
              onClick={async() => { await playLimbo(); setClicked(true);  }}
            >
              {btnDisabled}
              Play
            </button>
            <div className='d-flex justify-content-center'>
              <div className='multiplier_content'>
                <p className='multiplier_title'>Multiplier</p>
                <div className='multiplier_box'>
                  <p className='multiplier_value'>{dragval}</p>
                  <p className='multiplier_x'>x</p>
                </div>
              </div>
            </div>

            <p id="winstatus"></p>

          </div>



        </div>
      </div>
    </div>
  );
}

export default Limbogame;
