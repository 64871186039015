import React, { useState, useEffect, useRef } from "react";
import tablecoin from '../../assets/tablecoin.png';
import goldcoin from '../../assets/goldicon.png';
import sarrow from "../../assets/sarrow.png"
import Wallets from '../../Components/Modals/Wallet';
import { SwapTokenss } from "../../config/Farms";
import { ROUTER_ADDRESS } from "../../config/routerContract";
import { UsegetAmountsIn, approveSwap, checkIsApprovedRouter, getUserBalance, swapTokens } from "../../hooks/useSwap";
import { iconTheme, position, style } from "../../hooks/useToast";
import toast from "react-hot-toast";
import { getAccount } from "../../hooks/useAccount";

import Coinmodals from "../Modals/coinmodal"
import { swapData } from "../Modals/swapDatas";
import { toFixedNumber } from "../../hooks/UseContract";


import visas from '../../assets/visas.png';
import gpay from '../../assets/gpays.png';
import { GiStarShuriken } from "react-icons/gi";


export default function Offswap(props) {

    const bigComp = () => {
        return (
            <div className="borders earntab mb-3">
                <div className="d-flex justfun align-items-center justify-content-between">
                    <div className="interborder">
                        <p className="mb-0"><img src={tablecoin} className="ethimg etimg me-1" alt="eth" />  WETH <span className="btop"></span></p>
                    </div>
                    <div className="text-end mt-3 mt-sm-0">
                        <h5>You Pay</h5>
                        <input type="text" className="form-control w-100 max_100" placeholder="0" />
                    </div>
                </div>
                <div className="mt-4 offpay">
                    <div className="d-flex gap-3 mb-4">
                        {/* <p className="dots mb-0"></p> */}
                        <GiStarShuriken className="stars" />
                        <div>
                            <h5>Using Payment Method</h5>
                            <div className="payment_det">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        <img src={visas} className="img-fluid" />
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        <img src={gpay} className="img-fluid" />
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="d-flex gap-3">
                        <GiStarShuriken className="stars" />
                        <div>
                            <h5>See Fees Calculation</h5>
                            <h5 className="mt-3">125.21 <span className="grayclrs">Total Fees</span></h5>
                            <h5 className="mt-3">321 EUR = <span className="grayclrs">1 WETH</span></h5>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    const smallComp = () => {
        return (
            <div className="borders earntab mb-3">
                <div className="d-flex justfun align-items-center justify-content-between">
                    <div className="interborder">
                        <p className="mb-0"><img src={tablecoin} className="ethimg etimg me-1" alt="eth" />  WETH <span className="btop"></span></p>
                    </div>
                    <div className="text-end mt-3 mt-sm-0">
                        <h5>You Receive (Estimate)</h5>
                        <input type="text" className="form-control w-100 max_100"  placeholder="0" />
                    </div>
                </div>
            </div>
        )
    }
    console.log("props.offswapselect", props.offswapselect);
    return (
        <>
            <div>
                {props.offswapselect == "buyss"
                    ?
                    <>
                        {bigComp()}
                        {smallComp()}
                    </>
                    :
                    <>
                        {smallComp()}
                        {bigComp()}
                    </>}

                <div className="mt-4"><button type="button" class="btn w-100 btn-success earnbtn walletsss py-2">BUY NOW</button></div>


            </div>


        </>

    );
}